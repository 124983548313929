export const HourlyWages = {
    "@url": "https://api.fortnox.se/3/articles/92",
    "articleNumber": "92",
    "description": "Betalning per timme",
    "disposableQuantity": 0,
    "ean": "",
    "housework": false,
    "purchasePrice": 0,
    "salesPrice": null,
    "stockPlace": "",
    "stockValue": 0,
    "unit": "h",
    "vat": 25,
    "webshopArticle": false,
    "value": "Betalning per timme"
}

export const otherCost = {
    "@url": "https://api.fortnox.se/3/articles/93",
    "articleNumber": "93",
    "description": "Övriga kostnader (resa och traktamente)",
    "disposableQuantity": 0,
    "ean": "",
    "housework": false,
    "purchasePrice": 0,
    "salesPrice": null,
    "stockPlace": "",
    "stockValue": 0,
    "unit": "km",
    "vat": 25,
    "webshopArticle": false,
    "value": "Övriga kostnader (resa och traktamente)"
}

export const ExpenditureAccordingToTheAttachedPDF = {
    "@url": "https://api.fortnox.se/3/articles/2",
    "articleNumber": "2",
    "description": "Utlägg enligt bifogad pdf",
    "disposableQuantity": 0,
    "ean": "",
    "housework": false,
    "purchasePrice": 0,
    "salesPrice": 0,
    "stockPlace": null,
    "stockValue": 0,
    "unit": "förp",
    "vat": null,
    "webshopArticle": false,
    "value": "Utlägg enligt bifogad pdf"
}

export const costPerDayArticle = {
    "@url": "https://api.fortnox.se/3/articles/94",
    "articleNumber": "94",
    "description": "Betalning per dag",
    "disposableQuantity": 0.0,
    "ean": "",
    "housework": false,
    "purchasePrice": 0.0,
    "salesPrice": null,
    "stockPlace": "",
    "stockValue": 0.0,
    "unit": "d",
    "vat": null,
    "webshopArticle": false
}

export const costPerWeekArticle = {
    "@url": "https://api.fortnox.se/3/articles/95",
    "articleNumber": "95",
    "description": "Betalning per vecka",
    "disposableQuantity": 0.0,
    "ean": "",
    "housework": false,
    "purchasePrice": 0.0,
    "salesPrice": null,
    "stockPlace": "",
    "stockValue": 0.0,
    "unit": "w",
    "vat": null,
    "webshopArticle": false
}

export const costPerMonthArticle = {
    "@url": "https://api.fortnox.se/3/articles/96",
    "articleNumber": "96",
    "description": "Betalning per månad",
    "disposableQuantity": 0.0,
    "ean": "",
    "housework": false,
    "purchasePrice": 0.0,
    "salesPrice": null,
    "stockPlace": "",
    "stockValue": 0.0,
    "unit": "m",
    "vat": null,
    "webshopArticle": false
}

export const costAgreementArticle = {
    "@url": "https://api.fortnox.se/3/articles/97",
    "articleNumber": "97",
    "description": "Betalning enligt överenskommelse",
    "disposableQuantity": 0.0,
    "ean": "",
    "housework": false,
    "purchasePrice": 0.0,
    "salesPrice": null,
    "stockPlace": "",
    "stockValue": 0.0,
    "unit": "a",
    "vat": null,
    "webshopArticle": false
}