/* eslint-disable */
import React, { useEffect, useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Modal,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import {
  endastraktamenteInrikesSkattepliktig,
  formatDate2,
  getModalBoxStyle,
  milersattningPrivatSkattefri,
  milersattningPrivatSkattepliktig,
  ovrigBruttolon,
  reseutgifterEnligtBifogadPdfSkattefritt,
  semesterSparadManadsavlonad,
  timlonSemester,
  traktamenteInrikesHalvdagSkattefri,
  traktamenteInrikesNattSkattepliktig,
  traktamenteInrikesSkattefri,
  traktamenteUtrikesSkattefri,
  traktamenteUtrikesSkattepliktig,
  utgifterForMaterialOchVerktygSkattefritt,
} from '../../../../utils/commonFunc';

const ViewSalaryTab = ({ selectedSalaryNestedTabValue, setLoading, loading }) => {
  const { salaryId } = useParams();
  const navigate = useNavigate();
  const [viewSalaryData, setViewSalaryData] = useState();
  const [isAddRow, setIsAddRow] = useState(false);
  const [isSaveSalaryConfModal, setIsSaveSalaryConfModal] = useState(false);
  const [typeOfSalaryValue, setTypeOfSalaryValue] = useState();
  const [isRowAdded, setIsRowAdded] = useState();
  const [isRowDeleted, setIsRowDeleted] = useState();
  const [isUpdateCalculation, setIsUpdateCalculation] = useState(false)
  const [changeActivity,setIsChangeActivity] = useState(false)
  const TABLE_HEAD = [
    { id: 'typeOfSalary', label: 'Type of Salary', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    {
      id: 'createdDate',
      label: 'Created Date',
      alignRight: false,
    },
    { id: 'amount', label: 'Amount', alignRight: false },
    { id: 'IsArticleShownInInvoice', label: 'Show Article', alignRight: false },
    {
      id: 'invoiceid',
      label:
        viewSalaryData?.salaryType === 1
          ? 'Invoice Id'
          : viewSalaryData?.salaryType === 2
            ? 'Holiday Payout Id'
            : 'Text Row',
      alignRight: false,
    },
    { id: 'source', label: 'Source', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
  ];

  if (selectedSalaryNestedTabValue !== '1') {
    TABLE_HEAD.pop();
  }

  const getViewSalaryData = async () => {
    try {
      const URL = `/admin/adminsalary/get-salary-id?id=${salaryId}`;
      const result = await axios.get(URL);
      if (result?.status === 200) {
        setViewSalaryData(result?.data?.response);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    getViewSalaryData();
  }, []);

  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const typeOfSalary = [
    { label: 'Lön ink. semesterersättning', value: timlonSemester },
    // { label: 'Övrig bruttolön, (resor/traktamente)', value: ovrigBruttolon },
    { label: 'Semester sparad månadsavlönad', value: semesterSparadManadsavlonad },
    { label: 'Utgifter för material och verktyg skattefritt', value: utgifterForMaterialOchVerktygSkattefritt },
    { label: 'Reseutgifter enligt bifogad PDF skattefritt', value: reseutgifterEnligtBifogadPdfSkattefritt },
    { label: 'Traktamente utrikes, skattefri', value: traktamenteUtrikesSkattefri },
    { label: 'Milersättning privat bil, skattefri', value: milersattningPrivatSkattefri },
    { label: 'Traktamente inrikes, halvdag skattefri', value: traktamenteInrikesHalvdagSkattefri },
    { label: 'Traktamente inrikes, skattefri', value: traktamenteInrikesSkattefri },
    { label: 'Traktamente utrikes, skattepliktig', value: traktamenteUtrikesSkattepliktig },
    { label: 'Milersättning privat bil, skattepliktig', value: milersattningPrivatSkattepliktig },
    { label: 'Traktamente inrikes, natt skattepliktig', value: traktamenteInrikesNattSkattepliktig },
    { label: 'Endagstraktamente inrikes, skattepliktig', value: endastraktamenteInrikesSkattepliktig },
  ];

  const {
    reset,
    setValue,
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (typeOfSalaryValue === timlonSemester) {
      setValue('name', 'Lön ink. semesterersättning');
    } else if (typeOfSalaryValue === ovrigBruttolon) {
      setValue('name', 'Övrig bruttolön, (resor/traktamente)');
    } else if (typeOfSalaryValue === utgifterForMaterialOchVerktygSkattefritt) {
      setValue('name', 'Utgifter för material och verktyg skattefritt');
    } else if (typeOfSalaryValue === semesterSparadManadsavlonad) {
      setValue('name', 'Semester sparad månadsavlönad');
    } else if (typeOfSalaryValue === traktamenteUtrikesSkattefri) {
      setValue('name', 'Traktamente utrikes, skattefri');
    } else if (typeOfSalaryValue === milersattningPrivatSkattefri) {
      setValue('name', 'Milersättning privat bil, skattefri');
    } else if (typeOfSalaryValue === traktamenteInrikesHalvdagSkattefri) {
      setValue('name', 'Traktamente inrikes, halvdag skattefri');
    } else if (typeOfSalaryValue === traktamenteInrikesSkattefri) {
      setValue('name', 'Traktamente inrikes, skattefri');
    } else if (typeOfSalaryValue === traktamenteUtrikesSkattepliktig) {
      setValue('name', 'Traktamente utrikes, skattepliktig');
    } else if (typeOfSalaryValue === milersattningPrivatSkattepliktig) {
      setValue('name', 'Milersättning privat bil, skattepliktig');
    } else if (typeOfSalaryValue === traktamenteInrikesNattSkattepliktig) {
      setValue('name', 'Traktamente inrikes, natt skattepliktig');
    } else if (typeOfSalaryValue === endastraktamenteInrikesSkattepliktig) {
      setValue('name', 'Endagstraktamente inrikes, skattepliktig');
    } else if (typeOfSalaryValue === reseutgifterEnligtBifogadPdfSkattefritt) {
      setValue('name', 'Reseutgifter enligt bifogad PDF skattefritt');
    }
  }, [typeOfSalaryValue]);

  const getSumAmountForArticle = (articleNumber) => {
    return viewSalaryData?.employeeSalaryHours?.reduce((total, rowData) => {
      if (rowData.articleNumber === articleNumber) {
        return total + Number(rowData.amount);
      }
      return total;
    }, 0);
  };

  const onSubmit = async (values) => {
    const formatAmount = (amount) => {
      if (typeof amount === 'string' && amount.includes(',')) {
        return parseFloat(amount.replace(',', '.'));
      }
      return parseFloat(amount);
    };

    const getId = values?.typeOfSalary ? values?.typeOfSalary.split(' ')[0] : '';
    const newRowData = {
      articleNumber: getId,
      description: values?.name,
      amount: formatAmount(values?.amount),
      isArticleShownInInvoice: true,
      invoiceId: viewSalaryData?.invoiceId ? 0 : viewSalaryData?.invoiceId,
    };

    const newData = {
      ...viewSalaryData,
      employeeSalaryHours: [newRowData, ...(viewSalaryData.employeeSalaryHours || [])],
    };

    setViewSalaryData(newData);
    setIsAddRow(false);
    setIsRowAdded(true);
    reset();
  };

  const getSalaryCalculatedData = async () => {
    try {
      const URL = '/admin/adminsalary/salary/calculation';

      const payload = {
        grossArticleAmount: getSumAmountForArticle(timlonSemester),
        taxFreeArticleAmount: getSumAmountForArticle(utgifterForMaterialOchVerktygSkattefritt),
        travelCostsAccordingToAttachedPdfTaxFree: getSumAmountForArticle(reseutgifterEnligtBifogadPdfSkattefritt),
        travelAllowance: getSumAmountForArticle(ovrigBruttolon),
        holidayPayoutAmount: getSumAmountForArticle(semesterSparadManadsavlonad),
        allowanceAbroadTaxFreeAmount: getSumAmountForArticle(traktamenteUtrikesSkattefri),
        mileageAllowanceTaxFreeAmount: getSumAmountForArticle(milersattningPrivatSkattefri),
        allowanceAbroadWithTaxAmount: getSumAmountForArticle(traktamenteUtrikesSkattepliktig),
        mileageAllowanceWithTaxAmount: getSumAmountForArticle(milersattningPrivatSkattepliktig),
        domesticAllowanceFullDayTaxFreeAmount: getSumAmountForArticle(traktamenteInrikesSkattefri),
        domesticAllowanceHalfDayTaxFreeAmount: getSumAmountForArticle(traktamenteInrikesHalvdagSkattefri),
        domesticAllowanceFullDayWithTaxAmount: getSumAmountForArticle(endastraktamenteInrikesSkattepliktig),
        domesticAllowanceHalfDayWithTaxAmount: getSumAmountForArticle(traktamenteInrikesNattSkattepliktig),
        invoiceId: viewSalaryData?.invoiceId === null ? 0 : viewSalaryData?.invoiceId,
        salaryId: viewSalaryData?.salaryId,
        userId: viewSalaryData?.userId,
        employeeSalaryHours: viewSalaryData?.employeeSalaryHours,
      };

      const result = await axios.post(URL, payload);

      if (result?.status === 200) {
        setIsUpdateCalculation(true)
        setIsChangeActivity(false)
        const data = result?.data?.response;

        const updatedData = {
          ...viewSalaryData,
          grossSalary: data?.grossSalary,
          toPay: data?.toPay,
          tax: data?.tax,
          totalSocialFee: data?.totalSocialFee,
          taxFreeArticleAmount: data?.taxFreeArticleAmount,
          totalTaxFreeArticlesAmount: data?.totalTaxFreeArticlesAmount,
          totalAllowanceAmount: data?.totalAllowanceAmount,
          totalMileageAmount: data?.totalMileageAmount,
          totalTaxfreeExpensesAmount: data?.totalTaxfreeExpensesAmount,
        };
        setViewSalaryData(updatedData);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  const calculateTotalAllowance = (viewSalaryData) => {
    if (!viewSalaryData) return 0;

    const allowanceFields = [
      viewSalaryData ?.totalAbroadAllowanceTaxFreeAmount,
      viewSalaryData ?.totalAllowanceAbroadTaxFreeNotShown,
      viewSalaryData ?.totalDomesticAllowanceFullDayTaxFreeNotShown,
      viewSalaryData ?.totalDomesticAllowanceHalfDayTaxFreeNotShown,
      viewSalaryData ?.totalDomesticFullDayAllowanceTaxFreeAmount,
      viewSalaryData ?.totalDomesticHalfDayAllowanceTaxFreeAmount,
    ];

    return allowanceFields?.reduce((total, field) => total + (field || 0), 0);
  };

  const calculateTotalMileage = (viewSalaryData) => {
    if (!viewSalaryData) return 0;

    const mileageFields = [
      viewSalaryData?.totalMileageAllowanceTaxFreeAmount,
      viewSalaryData?.totalMileageTaxFreeNotShown,

    ];

    return mileageFields.reduce((total, field) => total + (field || 0), 0);
  };

  const calculateTotalTaxfreeExpenses = (viewSalaryData) => {
    if (!viewSalaryData) return 0;

    const expensesFields = [
      viewSalaryData?.travelCostsAccordingToAttachedPdfTaxFree,
      viewSalaryData?.taxFreeArticleAmount,
      viewSalaryData?.totalExpenditureForMaterialsAndToolsAccordingToAttachedPdfTaxFreeNotShown,
      viewSalaryData?.totalTravelCostsAccordingToAttachedPdfTaxFreeNotShown,
    ];

    return expensesFields.reduce((total, field) => total + (field || 0), 0);
  };

  useEffect(() => {
    if (isRowAdded || isRowDeleted || changeActivity) {
      getSalaryCalculatedData();
    }
  }, [isRowAdded, isRowDeleted,changeActivity]);

  const handleSaveSalary = async () => {
    setLoading(true);
    try {
      const URL = '/admin/adminsalary/salaryid';
      const result = await axios.put(URL, viewSalaryData);
      if (result?.status === 200) {
        setLoading(false);
        setIsSaveSalaryConfModal(false);
        toast.success('Salary has been saved successfully.');
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  const getBankDetails = (data) => {
    if (!data) return null;

    const isForeignBank = data?.userBank === 'Foreign bank BIC / IBAN';

    if (isForeignBank) {
      return {
        iban: data?.ibanNumber,
        bic: data?.bicNumber,
        account: null,
        clearing: null,
      };
    } else {
      return {
        iban: null,
        bic: null,
        account: data?.accountNumber,
        clearing: data?.clearingNumber,
      };
    }
  };

  const bankDetails = getBankDetails(viewSalaryData);

  const handleDateChange = (e) => {
    setViewSalaryData({
      ...viewSalaryData,
      paidDate: e.target.value,
    });
  };

  const formatDateInput = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <div className="d-flex justify-content-between ml-4 mr-4 mb-2 mt-3">
        <div>
          <h6>Employee</h6>
          <div>
            <h6>
              {viewSalaryData?.userId} {viewSalaryData?.employeeName ? `- ${viewSalaryData?.employeeName}` : ''}
            </h6>
          </div>
          <div className="mt-3">
            {viewSalaryData?.userBank && (
              <div className="customer-data d-flex align-items-center mb-2">
                <h6 className="m-0 font-weight-bold">{'Bank details'}-&nbsp;</h6>
              </div>
            )}
            {bankDetails?.iban && (
              <>
                <div className="d-flex align-items-center">
                  <h6 className="m-0 ">{'IBAN'}:&nbsp;</h6>
                  <p className="m-0">{bankDetails?.iban}</p>
                </div>
                <div className="d-flex align-items-center">
                  <h6 className="m-0 ">{'BIC'}:&nbsp;</h6>
                  <p className="m-0">{bankDetails?.bic}</p>
                </div>
              </>
            )}
            {bankDetails?.account && (
              <>
                <div className="d-flex align-items-center">
                  <h6 className="m-0">{'Account no.'}:&nbsp;</h6>
                  <p className="m-0">{bankDetails?.account}</p>
                </div>

                <div className="d-flex align-items-center">
                  <h6 className="m-0">{'Clearing no.'}:&nbsp;</h6>
                  <p className="m-0">{bankDetails?.clearing}</p>
                </div>
              </>
            )}
          </div>
        </div>
        <div>
          <h6 className="mb-3">Created Date</h6>
          <div>
            <h6>{formatDate2(viewSalaryData?.createdDate)}</h6>
          </div>
        </div>
      </div>
      {selectedSalaryNestedTabValue === '1' && (
        <div className="d-flex align-items-end flex-column mx-4">
          <Button
            variant="outlined"
            color={'primary'}
            onClick={() => {
              setIsAddRow(true);
              setIsRowAdded(false);
              setIsRowDeleted(false);
              setTypeOfSalaryValue();
            }}
          >
            Add Row
          </Button>
        </div>
      )}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {TABLE_HEAD?.map((item) => (
                <TableCell style={{ fontWeight: 'bold' }} key={item?.id}>
                  {item?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {viewSalaryData?.employeeSalaryHours?.length ? (
              viewSalaryData?.employeeSalaryHours?.map((item, idx) => (
                <TableRow key={idx}>
                  <TableCell>{item?.articleNumber}</TableCell>
                  <TableCell>{item?.description}</TableCell>
                  <TableCell>{formatDate2(item?.createdDate)}</TableCell>
                  <TableCell>
                    <input
                      style={{ width: '6rem' }}
                      // eslint-disable-next-line no-unneeded-ternary
                      value={item?.amount}
                      disabled={'true'}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      defaultChecked
                      size="small"
                      disabled={
                        // Disable if the current row has an articleNumber of '138', '1381', or '2232'
                        ['138', '1381', '2232','8125',
                          '822',
                          '8121',
                          '8122'].includes(viewSalaryData?.employeeSalaryHours[idx]?.articleNumber)
                      }
                      checked={viewSalaryData?.employeeSalaryHours[idx]?.isArticleShownInInvoice}
                      color="primary"
                      onChange={(e) => {
                        const updatedViewSalaryData = { ...viewSalaryData };
                        updatedViewSalaryData.employeeSalaryHours[idx].isArticleShownInInvoice = e.target.checked; // Note the lowercase "is"
                        setViewSalaryData(updatedViewSalaryData);
                        setIsChangeActivity(true);
                      }}
                    />
                  </TableCell>

                  <TableCell>
                    <TableCell>
                      {viewSalaryData?.salaryType === 1
                        ? item?.invoiceId
                        : viewSalaryData?.salaryType === 2
                          ? item?.holidayPayoutId
                          : 'Manual'}
                    </TableCell>
                  </TableCell>
                  <TableCell>Lön</TableCell>
                  {selectedSalaryNestedTabValue === '1' && (
                    <TableCell>
                      <Tooltip title="Delete">
                        <Button
                          onClick={() => {
                            const updatedViewSalaryData = { ...viewSalaryData };
                            updatedViewSalaryData.employeeSalaryHours.splice(idx, 1);
                            setViewSalaryData(updatedViewSalaryData);
                            setIsRowDeleted(true);
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow className="text-center">
                <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                  No record found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {viewSalaryData?.employeeSalaryHours?.length ? (
        <div className="d-flex justify-content-around mb-5">
          <div className="mt-5">
            <h6>Payment Date</h6>
            <div>
              <input type="date" value={formatDateInput(viewSalaryData?.paidDate)} onChange={handleDateChange} />
            </div>
            <div className="mt-5">
              <h6>Social Tax</h6>
              <div>
                <FormControl size="small">
                  <input
                    type="number"
                    disabled={'true'}
                    value={viewSalaryData?.employeeSalaryHours?.length && viewSalaryData?.totalSocialFee?.toFixed(2)}
                  />
                </FormControl>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <h6>Gross salary</h6>
            <div>
              <FormControl size="small">
                <input
                  type="number"
                  disabled={'true'}
                  value={viewSalaryData?.employeeSalaryHours?.length && viewSalaryData?.grossSalary?.toFixed(2)}
                />
              </FormControl>
            </div>
            <div className="mt-5">
              <h6>Tax-free Allowance</h6>
              <div>
                <FormControl size="small">
                  <input
                    type="number"
                    disabled={'true'}
                    value={
                      isUpdateCalculation ? viewSalaryData?.totalAllowanceAmount?.toFixed(2) || 0 :
                        (viewSalaryData?.employeeSalaryHours?.length &&
                          calculateTotalAllowance(viewSalaryData)?.toFixed(2)) ||
                        0
                    }
                  />
                </FormControl>
              </div>
            </div>

            <div className="mt-5">
              <h6>Tax-free Mileage</h6>
              <div>
                <FormControl size="small">
                  <input
                    type="number"
                    disabled={'true'}
                    value={
                      isUpdateCalculation ? viewSalaryData?.totalMileageAmount?.toFixed(2) || 0:
                      (viewSalaryData?.employeeSalaryHours?.length &&
                        calculateTotalMileage(viewSalaryData)?.toFixed(2)) ||
                      0
                    }
                  />
                </FormControl>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <h6>Income Tax</h6>
            <div>
              <FormControl size="small">
                <input
                  type="number"
                  disabled={'true'}
                  value={viewSalaryData?.employeeSalaryHours?.length && viewSalaryData?.tax?.toFixed(2)}
                />
              </FormControl>
            </div>
            <div className="mt-5">
              <h6>Tax-free expenses/deductions</h6>
              <div>
                <FormControl size="small">
                  <input
                    type="number"
                    disabled={'true'}
                    value={
                      isUpdateCalculation ? viewSalaryData?.totalTaxfreeExpensesAmount
                      ?.toFixed(2) || 0:
                      (viewSalaryData?.employeeSalaryHours?.length &&
                        calculateTotalTaxfreeExpenses(viewSalaryData)?.toFixed(2)) ||
                      0
                    }
                  />
                </FormControl>
              </div>
            </div>
            <div className="mt-5">
              <h6>Total Payment</h6>
              <div>
                <FormControl size="small">
                  <input
                    type="number"
                    disabled={'true'}
                    value={viewSalaryData?.employeeSalaryHours?.length && viewSalaryData?.toPay?.toFixed(2)}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {selectedSalaryNestedTabValue === '1' && viewSalaryData?.employeeSalaryHours?.length ? (
        <div className="d-flex justify-content-end mx-4 bg-light text-dark p-2">
          <Button
            variant="outlined"
            color={'error'}
            style={{ color: 'rgb(195,65,82)' }}
            sx={{ padding: '10px 4px', height: 35, fontSize: '12px', width: 70, minWidth: '70px' }}
            onClick={() => navigate(-1)}
          >
            {'Cancel'}
          </Button>
          <Button variant="contained" className="ml-3" color={'primary'} onClick={() => setIsSaveSalaryConfModal(true)}>
            <span>{'Save'}</span>
          </Button>
        </div>
      ) : null}

      <Modal
        open={isAddRow}
        onClose={() => {
          setIsAddRow(false);
          clearErrors();
          reset();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Salary Article Row
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="container" style={{ overflow: 'auto', height: '450px', width: '105%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    marginTop: '9px',
                    flexDirection: 'column',
                    '& .MuiTextField-root': { width: '25ch' },
                  }}
                >
                  <Autocomplete
                    freeSolo
                    onChange={(e, selectedOption) => {
                      if (selectedOption) {
                        const getCode = selectedOption ? selectedOption.split(' ')[0] : '';
                        setTypeOfSalaryValue(getCode);
                      }
                    }}
                    id="combo-box-demo"
                    options={typeOfSalary?.map((item) => `${item?.value} (${item?.label})`)}
                    sx={{ width: 300, marginTop: '12px' }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ marginBottom: '10px' }}
                        label={'Type of Salary'}
                        id="margin-dense"
                        error={errors?.typeOfSalary}
                        {...register('typeOfSalary', {
                          required: true,
                        })}
                      />
                    )}
                  />

                  <TextField disabled={'true'} id="margin-dense" {...register('name')} />
                  <TextField
                    error={errors.amount}
                    label={'Amount'}
                    {...register('amount', {
                      required: true,
                    })}
                    id="margin-normal"
                    margin="normal"
                  />
                  <TextField label={'Source'} value={'Lön'} id="margin-normal" margin="normal" disabled={'true'} />
                </Box>
              </div>

              <Button
                variant="contained"
                onClick={() => {
                  setIsAddRow(false);
                  clearErrors();
                  reset();
                }}
              >
                Close
              </Button>
              <Button style={{ marginLeft: '10px' }} variant="contained" type="submit">
                Add
              </Button>
            </form>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={isSaveSalaryConfModal}
        onClose={() => setIsSaveSalaryConfModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={getModalBoxStyle()}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to save the salary?
          </Typography>
          <div
            style={{
              margin: '25px 0 0',
              float: 'right',
              gap: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => setIsSaveSalaryConfModal(false)}
              sx={{ float: 'right', backgroundColor: '#62b5e514' }}
              autoFocus
            >
              No
            </Button>
            <Button
              disabled={loading}
              onClick={handleSaveSalary}
              sx={{ float: 'right', backgroundColor: '#62b5e514' }}
              autoFocus
            >
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ViewSalaryTab;
