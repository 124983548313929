import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    data: {},
    isError: false,
    isLoading: false,
    message: '',
};

export const invoiceCreatedUserDetails = createAsyncThunk(
    "userData/invoiceCreatedUserDetails",
    (data) => data
  );

const createdInvoiceUserDataSlice = createSlice({
    name: 'invoiceCreatedUserData',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.message = '';
        },
    },
    extraReducers: {
        [invoiceCreatedUserDetails.pending]: (state) => {
            state.isError = false;
            state.message = '';
        },
        [invoiceCreatedUserDetails.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        [invoiceCreatedUserDetails.rejected]: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        },
    },
});

export default createdInvoiceUserDataSlice.reducer;
