import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';
import axios from 'axios';
import { ListToolbar } from '../../../../sections/@dashboard/table';
import SalaryFortnoxAsyncTable from './SalaryFortnoxAsyncTable';
import { customerDropOptions } from '../../../../utils/commonFunc';

const SalaryFortnoxAsync = ({ selectedFortnoxNestedTabValue, setSelectedFortnoxNestedTabValue, setLoading }) => {
  const [salaryAsyncData, setSalaryAsyncData] = useState([]);
  const [totalCount, setTotalCount] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);

  const [showSyncModal, setShowSyncModal] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchDropValue, setSearchDropValue] = useState();

  const [selected, setSelected] = useState([]);

  const SalaryAsyncFileTab = [
    {
      value: '1',
      tab: 1,
    },
    {
      value: '2',
      tab: 2,
    },
    {
      value: '3',
      tab: 3,
    },
  ];

  const getSalaryFortnoxAsync = async () => {
    setLoading(true);
    try {
      let URL;
      if (searchDropValue && searchQuery) {
        URL = `/admin/adminsalary/get-all-sync-salaries?PageNumber=${
          page + 1
        }&PageSize=${rowsPerPage}&active=${selectedFortnoxNestedTabValue}&searchQuery=${searchQuery}&SearchColumn=${searchDropValue}`;
      } else {
        URL = `/admin/adminsalary/get-all-sync-salaries?PageNumber=${
          page + 1
        }&PageSize=${rowsPerPage}&active=${selectedFortnoxNestedTabValue}&searchQuery=${searchQuery}`;
      }

      const result = await axios.get(URL);
      if (result?.status === 200) {
        setLoading(false);

        setSalaryAsyncData(result?.data?.response);
        setTotalCount(result?.data?.totalCount);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSalaryFortnoxAsync();
  }, [selectedFortnoxNestedTabValue, page, rowsPerPage, searchQuery]);

  const handleOnSync = () => {
    setShowSyncModal(true);
  };

  const handleFilter = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <TabContext value={selectedFortnoxNestedTabValue}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <ListToolbar
          searchQuery={searchQuery}
          onFilter={handleFilter}
          isSyncBtn={'true'}
          handleOnSync={handleOnSync}
          dropOptions={customerDropOptions}
          searchDropValue={searchDropValue}
          setSearchDropValue={setSearchDropValue}
          syncSelected={selected}
        />
        <TabList
          onChange={(e, newValue) => {
            setSelectedFortnoxNestedTabValue(newValue);
            setPage(0);
            setSelected([]);
          }}
          aria-label="nested tabs example"
        >
          <Tab label="Added on fortnox" value="1" />
          <Tab label="Details Updated On Fortnox" value="2" />
          <Tab label="Deleted on fortnox" value="3" />
        </TabList>
      </Box>
      {SalaryAsyncFileTab.map((item) => (
        <TabPanel value={item?.value} sx={{ padding: 0 }} key={item?.value}>
          <SalaryFortnoxAsyncTable
            setLoading={setLoading}
            selectedFortnoxNestedTabValue={selectedFortnoxNestedTabValue}
            salaryAsyncData={salaryAsyncData}
            setSalaryAsyncData={setSalaryAsyncData}
            getSalaryFortnoxAsync={getSalaryFortnoxAsync}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            showSyncModal={showSyncModal}
            setShowSyncModal={setShowSyncModal}
            selected={selected}
            setSelected={setSelected}
          />
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default SalaryFortnoxAsync;
