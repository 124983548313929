/* eslint-disable no-nested-ternary */
/* eslint-disable */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button, TablePagination, Checkbox, Modal, Box, Typography, Alert } from '@mui/material';
import { ListHead } from '../../../../sections/@dashboard/table';
import { formatDate2, getModalBoxStyle } from '../../../../utils/commonFunc';

export default function SalaryFortnoxAsyncTable({
  setLoading,
  selectedFortnoxNestedTabValue,
  salaryAsyncData,
  setSalaryAsyncData,
  getSalaryFortnoxAsync,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  showSyncModal,
  setShowSyncModal,
  selected,
  setSelected,
}) {
  const [orderBy, setOrderBy] = useState('createdDate');
  const [order, setOrder] = useState('desc');
  const [isAllSelected, setAllSelected] = useState(false);
  const [isError, setisError] = useState(null);

  const TABLE_HEAD = [
    { id: 'salaryId', label: 'Salary Id', alignRight: false },
    { id: 'userId', label: 'User Id', alignRight: false },
    { id: 'type', label: 'Type', alignRight: false },
    { id: 'invoiceId', label: 'Invoice  / Holiday Payout Id', alignRight: false },
    { id: 'paymentDate', label: 'Payment Date', alignRight: false },
    {
      id: 'employee',
      label: 'Employee',
      alignRight: false,
    },
    { id: 'grossSalary', label: 'Gross Salary / Holiday Amount', alignRight: false },
    { id: 'tax', label: 'Tax', alignRight: false },
    { id: 'taxFree', label: 'Tax Free', alignRight: false },
    { id: 'toPay', label: 'To Pay', alignRight: false },
  ];

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const selectAllClick = (isSelected, salaryAsyncData) => {
    if (isSelected) {
      const newSelecteds = salaryAsyncData?.map((n) => n.salaryId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelectAllClick = (event) => {
    setAllSelected(event.target.checked);
    selectAllClick(event.target.checked, salaryAsyncData);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const filteredRows = applySortFilter(
      salaryAsyncData,
      getComparator(isAsc ? 'desc' : 'asc', orderBy),
      event.target.value,
      salaryAsyncData
    );
    setSalaryAsyncData(filteredRows);
  };

  const customersList = ['Private', 'Business Customer', 'Business Construction'];

  const handleSyncProceed = async () => {
    setLoading(true);
    try {
      let URL;
      if (selectedFortnoxNestedTabValue === '1') {
        URL = '/admin/adminsalary/add-salaries-on-fortnox';
      } else if (selectedFortnoxNestedTabValue === '2') {
        URL = '/admin/adminsalary/update-salaries-on-fortnox';
      } else {
        URL = '/admin/adminsalary/Delete-salaries-on-fortnox';
      }

      const result = await axios.post(URL, selected);
      if (result?.data?.statusCode === 200) {
        toast.success(result?.data?.response);
        setShowSyncModal(false);
        getSalaryFortnoxAsync();
        setSelected([])
      } else {
        // toast.error(result?.data?.response);
        setisError(result?.data?.response);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setisError(err?.response?.data?.message);
      // toast.error(err?.response?.data?.message);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <ListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={salaryAsyncData?.length}
            numSelected={selected?.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
          />
          <TableBody>
            {(salaryAsyncData?.length &&
              salaryAsyncData?.map((item) => {
                const isItemSelected = selected.indexOf(item?.salaryId) !== -1;

                return (
                  <TableRow
                    key={item?.salaryId}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                    style={{
                      backgroundColor: item.isSelected ? 'lightgrey' : '',
                      cursor: 'pointer',
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        onChange={(event) => handleClick(event, item?.salaryId)}
                        onClick={(event) => event.stopPropagation()}
                        size="small"
                      />
                    </TableCell>

                    <TableCell>{item?.salaryId}</TableCell>
                    <TableCell>{item?.userId}</TableCell>
                    <TableCell>
                      {item?.salaryType === 1 ? 'Invoice' : item?.salaryType === 3 ? 'Manual Salary' : 'Holiday Payout'}
                    </TableCell>

                    <TableCell>
                      {item?.salaryType === 1 ? item?.invoiceId : item?.salaryType === 3 ? 0 : item?.holidayPayoutId}
                    </TableCell>

                    <TableCell> {formatDate2(item.paidDate)}</TableCell>
                    <TableCell>{item?.employeeName}</TableCell>
                    <TableCell>{item?.grossSalary}</TableCell>
                    <TableCell>{item?.tax}</TableCell>
                    <TableCell>{item?.taxFreeArticleAmount}</TableCell>
                    <TableCell>{item?.toPay}</TableCell>
                  </TableRow>
                );
              })) ||
              ''}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[100, 250, 500]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Modal
        open={showSyncModal}
        onClose={(prevState) => {
          setShowSyncModal(false);
          setisError(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={getModalBoxStyle()}>
          {isError !== null && (
            <Alert className="mb-2" severity="error">
              {isError}
            </Alert>
          )}
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to sync?
          </Typography>

          <div style={{ margin: '25px 0 0', float: 'right', gap: '10px', display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={(prevState) => {
                setShowSyncModal(false);
                setisError(null);
              }}
              sx={{ float: 'right', backgroundColor: '#62b5e514' }}
              autoFocus
            >
              {isError ? 'Close' : 'No'}
            </Button>
            {!isError && (
              <Button onClick={handleSyncProceed} sx={{ float: 'right', backgroundColor: '#62b5e514' }} autoFocus>
                Yes
              </Button>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}
