import { useEffect, useState } from 'react';

const useGetYears = () => {
  const [years, setYears] = useState([]);

  const getYears = () => {
    const currentYear = new Date().getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
    const years = range(2023, currentYear, 1); // The data begins from the year 2023, so we start the range from 2023.
    years.sort((a, b) => a - b);
    setYears(years);
  };

  useEffect(() => {
    getYears();
  }, []);
  return years;
};

export default useGetYears;
