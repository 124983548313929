/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
/* eslint-disable object-shorthand */

import DatePicker from 'react-multi-date-picker';
import InputIcon from 'react-multi-date-picker/components/input_icon';

const CustomDatePicker = ({ name, value, onChange, placeholder, formatType, error }) => {
  const handleDateChange = (date) => {
    let formattedDate;
    if (formatType === 'monthYear') {
      const selectedDate = date?.toDate();
      const year = selectedDate?.getFullYear();
      const month = (selectedDate?.getMonth() + 1)?.toString()?.padStart(2, '0');
      formattedDate = `${year}-${month}-01`;
    } else if (formatType === 'year') {
      const year = date?.year;
      formattedDate = `${year}-01-01`;
    } else {
      formattedDate = date?.format('YYYY-MM-DD');
    }
    const event = {
      target: {
        name: name,
        value: formattedDate,
        type: 'date',
      },
    };
    onChange(event);
  };
  const currentDate = new Date();

  return (
    <div className={`${error ? 'invalidInputsd' : 'datepicker_wrap'}`}>
      <DatePicker
        className={`date_picker`}
        render={<InputIcon placeholder={placeholder} />}
        value={value ? new Date(value) : null}
        format={formatType === 'monthYear' ? 'MM/YYYY' : formatType === 'year' ? 'YYYY' : 'YYYY-MM-DD'}
        onlyMonthPicker={formatType === 'monthYear'}
        onlyYearPicker={formatType === 'year'}
        onChange={handleDateChange}
        maxDate={currentDate}
      />
    </div>
  );
};

export default CustomDatePicker;
