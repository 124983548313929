/* eslint-disable prefer-const */
/* eslint-disable import/newline-after-import */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */

import { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js`;

function useInterval(callback, delay) {
  const savedCallback = useRef(() => {});
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function useFakeProgress() {
  const [fakeProgress, setFakeProgress] = useState(0);
  const [isActive, setIsActive] = useState(false);

  function stopFakeProgress() {
    setIsActive(false);
    setFakeProgress(0);
  }

  function startFakeProgress() {
    setIsActive(true);
  }

  useInterval(
    () => {
      if (fakeProgress >= 100) {
        stopFakeProgress();
      } else {
        setFakeProgress(fakeProgress + 10);
      }
    },
    isActive ? 500 : null
  );
  return [fakeProgress, startFakeProgress, stopFakeProgress];
}

export default function PdfPreview({ pdfPreview, existingPdf, show }) {
  const extractUploadPdf = pdfPreview.map((FileList) => FileList[0]);
  const [progressAmount, startFakeProgress, stopFakeProgress] = useFakeProgress();
  const [fileDataList, setFileDataList] = useState([]);
  const [existingdataList, setExistingdataList] = useState([]);
  const [fileParseDone, setFileParseDone] = useState(false);

  function fileToString(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const getFileData = async (fileDetails) => {
    try {
      const promises = fileDetails.map(async (file) => {
        const arrayBufferView = new Uint8Array(file);
        const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
        const res = await fileToString(file);
        return res;
      });
      const data = await Promise.all(promises);
      setFileDataList(data);
      setFileParseDone(true);
      stopFakeProgress();
    } catch (err) {
      setFileParseDone(false);
      stopFakeProgress();
    }
  };

  const getExistingFileData = async (fileDetails) => {
    try {
      const data = fileDetails.map((file) => {
        const byteCharacters = atob(file.fileContent);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        return blob;
      });

      setExistingdataList(data);
      setFileParseDone(true);
      stopFakeProgress();
    } catch (err) {
      console.log(err);
      setFileParseDone(false);
      stopFakeProgress();
    }
  };

  function PdfPreview({ file }) {
    const [pageCount, setPageCount] = useState(0);

    return (
      <div className='mt-4'>
      <Document
        file={file}
        onLoadError={(err) => console.log('onload err', err)}
        options={{}}
        onLoadSuccess={({ numPages }) => setPageCount(numPages)}
      >
        {Array.from(new Array(pageCount), (el, index) => (
          <Page key={index} pageNumber={index + 1} renderAnnotationLayer={false} renderTextLayer={false} />
        ))}
      </Document>
      </div>
    );
  }

  useEffect(() => {
    if (pdfPreview.length > 0) {
      const validFiles = extractUploadPdf.filter((f) => f !== undefined);
      startFakeProgress();
      getFileData(validFiles);
    }
  }, [pdfPreview]);

  useEffect(() => {
    if (existingPdf?.invoiceFile[0]?.userId && existingPdf?.invoiceFile.length > 0) {
      const filtered = existingPdf?.invoiceFile?.filter((d) => d?.userId);
      startFakeProgress();
      getExistingFileData(filtered);
    }
  }, [show, existingPdf?.invoiceFile]);

  return (
    <>
      {fileParseDone && [100, 0].includes(progressAmount)
        ? fileDataList.map((fileData, index) => <PdfPreview key={index} file={fileData} />)
        : null}

      {fileParseDone && [100, 0].includes(progressAmount)
        ? existingdataList.map((fileData, index) => <PdfPreview key={index} file={fileData} />)
        : null}
    </>
  );
}
