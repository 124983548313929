/* eslint-disable arrow-body-style */
import React from 'react';
import Table from 'react-bootstrap/Table';
import { TableCell, TableRow } from '@mui/material';
import { Modal } from 'react-bootstrap';
import { formatDate2, getArticleName } from '../../../../utils/commonFunc';

export const WorkingHoursList = ({ datesChanged, setDatesChanged, viewDate }) => {
  return (
    <Modal
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
      show={datesChanged}
      onHide={() => {
        setDatesChanged(false);
      }}
      animation={false}
      className="Apply_certificate fade.Apply_certificate.modal"
      style={{ marginTop: '65px' }}
    >
      <div className="p-3">
        <h6>
          <span className="font-weight-bold"> Calendar Summary </span>
        </h6>

        <Table responsive="sm" size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Description</th>
              <th>Hours</th>
              <th>Invoice Id</th>
              <th>Created By</th>
            </tr>
          </thead>
          <tbody>
            {viewDate?.length ? (
              viewDate?.map((item, index) => (
                <tr key={item?.id}>
                  <td>{index + 1}</td>
                  <td> {formatDate2(item?.workingDate)}</td>
                  <td>{getArticleName(item?.articleNumber)}</td>
                  <td>{item?.number}</td>
                  <td>{item?.invoiceId}</td>
                  <td>{item?.userId}</td>
                </tr>
              ))
            ) : (
              <TableRow className="text-center">
                <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                  No record found
                </TableCell>
              </TableRow>
            )}
          </tbody>
        </Table>
      </div>
    </Modal>
  );
};
