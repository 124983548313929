/* eslint-disable prefer-arrow-callback */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-fragments */

import React, { memo } from 'react';
import { Form, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomDatePicker from './CustomDatePicker';

const IncomeServiceTable = ({ incomeServiceFields, handleIncomeService }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="mb-4 p-2">
        <p>{t('2 Remuneration taxed as income from service')}</p>
        <Table responsive="lg">
          <thead>
            <tr>
              <th className="optional_row">
                {t('Year month')} <br /> {t('(MM/YYY)')}
              </th>
              <th>{t('Number of days worked')}</th>
              <th>{t('Duty etc. Hours')}</th>
              <th>{t('SEK/month')}</th>
              <th>{t('Other compensation Enter species')}</th>
              <th>{t('SEK/month')}</th>
            </tr>
          </thead>

          <tbody>
            {incomeServiceFields?.map((incomeService, idx) => (
              <tr key={idx}>
                <td>
                  <CustomDatePicker
                    name="monthdate"
                    value={incomeService.monthdate}
                    onChange={(e) => handleIncomeService(idx, 'monthdate', e.target.value)}
                    placeholder={t('MM/YYY')}
                    formatType="monthYear"
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    name="numberOfDaysWorked"
                    className={`table_rowinput form-control`}
                    value={incomeService.numberOfDaysWorked}
                    onChange={(e) => handleIncomeService(idx, 'numberOfDaysWorked', e.target.value)}
                    step="any"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        'wheel',
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    name="dutyHours"
                    className={`table_rowinput form-control`}
                    value={incomeService.dutyHours}
                    onChange={(e) => handleIncomeService(idx, 'dutyHours', e.target.value)}
                    step="any"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        'wheel',
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    name="nokMonth"
                    className={`table_rowinput form-control`}
                    value={incomeService.nokMonth}
                    onChange={(e) => handleIncomeService(idx, 'nokMonth', e.target.value)}
                    onFocus={(e) =>
                      e.target.addEventListener(
                        'wheel',
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    name="otherRemunerationEnterArt"
                    className={`table_rowinput form-control`}
                    value={incomeService.otherRemunerationEnterArt}
                    onChange={(e) => handleIncomeService(idx, 'otherRemunerationEnterArt', e.target.value)}
                    onFocus={(e) =>
                      e.target.addEventListener(
                        'wheel',
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                  />
                </td>

                <td>
                  <Form.Control
                    type="number"
                    name="nokMonthAmount"
                    className={`table_rowinput form-control`}
                    value={incomeService.nokMonthAmount}
                    onChange={(e) => handleIncomeService(idx, 'nokMonthAmount', e.target.value)}
                    onFocus={(e) =>
                      e.target.addEventListener(
                        'wheel',
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default memo(IncomeServiceTable);
