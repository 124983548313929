import React from 'react';

const Stepper = ({ steps, color = 'primary', activeStep = 0 }) => (
  <ol className={`c-stepper ${color}`}>
    {steps?.map((step, index) => (
      <li key={index} className={`c-stepper__item ${activeStep > index ? 'completed' : ''}`}>
        <div className="c-stepper__content">
          <h6 className="c-stepper__title">{step.title}</h6>
          {step.description && <p className="c-stepper__desc">{step.description}</p>}
        </div>
      </li>
    ))}
  </ol>
);

export default Stepper;
