import React from 'react';
// @mui
import { alpha, styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
    backgroundColor: alpha(theme.palette.common.black, 0.08),

    '& .container': {
        display: 'flex',
        justifyContent: 'center',
    },

    '& .circle': {
        marginLeft: 5,
        height: 10,
        width: 10,
        background: theme.palette.primary.main,
        borderRadius: 10,
        transformOrigin: 'center',
    },

    '& .one': {
        animation: 'puls 1000ms cubic-bezier(0.215, 0.61, 0.355, 1) infinite',
    },

    '& .two': {
        animation: 'puls 1000ms cubic-bezier(0.215, 0.61, 0.355, 1) 100ms infinite',
    },

    '& .three': {
        animation: 'puls 1000ms cubic-bezier(0.215, 0.61, 0.355, 1) 200ms infinite',
    },

    '@keyframes puls': {
        '0%': {
            transform: 'scale(1) translateY(10px)',
            height: 10,
        },
        '50%': {
            transform: 'scale(0.5) translateY(-30px)',
            height: 40,
        },
        '100%': {
            transform: 'scale(1) translateY(15px)',
        },
    },
}));

const Loader = () => (
    <Root>
        <div className="container">
            <div className="circle one" />
            <div className="circle two" />
            <div className="circle three" />
        </div>
    </Root>
);

export default Loader;
