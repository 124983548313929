import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
import siteLogo from '../assets/img/fakt-logo_360.png';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
};

export default function Logo({ disabledLink = false }) {
  const location = useLocation()
  const logo = <Box component="img" src={siteLogo} style={{ maxWidth: (location.pathname === "/login" || location.pathname === "/forgot-password") ? "45%" : "80%" }} />;

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
