/* eslint-disable */
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Container, IconButton, Stack, styled, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Iconify from '../../components/Iconify';
import { fDateTimeSuffix } from '../../utils/formatTime';
import Loader from '../../components/Loader';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#62b5e5',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function LogsUI() {
  const [page, setPage] = React.useState(0);
  const navigate = useNavigate();

  const location = useLocation();
  const { search } = location;

  const [loading, setLoading] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [logsData, setLogsData] = React.useState([]);
  const [logsDataCount, setLogsDataCount] = React.useState(0);

  const moduleType = new URLSearchParams(search).get('type');
  const ID = new URLSearchParams(search).get('id');

  const getLogs = async () => {
    setLoading(true);
    try {
      let url;
      if (moduleType === 'user') {
        url = `/admin/user/admin-user-logs?userId=${ID}&PageSize=${rowsPerPage}&PageNumber=${page + 1}`;
      } else if (moduleType === 'customer') {
        url = `/admin/user/admin-customer-logs?customerId=${ID}&PageSize=${rowsPerPage}&PageNumber=${page + 1}`;
      } else if (moduleType === 'invoice') {
        url = `/admin/invoice/admin-invoice-logs?invoiceId=${ID}&PageSize=${rowsPerPage}&PageNumber=${page + 1}`;
      } else if (moduleType === 'salary') {
        url = `/admin/adminsalary/admin-salary-logs?salaryId=${ID}&PageSize=${rowsPerPage}&PageNumber=${page + 1}`;
      } else if (moduleType === 'certificate') {
        url = `/adminemployeecertificate/admin-empcertificate-logs?certificateId=${ID}&PageSize=${rowsPerPage}&PageNumber=${
          page + 1
        }`;
      } else if (moduleType === 'payout') {
        url = `/admin/adminsalary/admin-holidaypayout-logs?holidayId=${ID}&PageSize=${rowsPerPage}&PageNumber=${
          page + 1
        }`;
      }

      const result = await axios.get(url);
      if (result?.status === 200) {
        setLoading(false);
        setLogsData(result?.data?.response);
        setLogsDataCount(result?.data?.totalCount);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.message);
    }
  };

  React.useEffect(() => {
    getLogs();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {loading && <Loader />}
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Stack direction="row" justifyContent="space-between">
            <IconButton onClick={() => navigate(-1)}>
              <Iconify icon="eva:arrow-back-fill" width={24} height={24} />
            </IconButton>
            <Typography variant="h4" sx={{ ml: 1 }} gutterBottom>
              {moduleType === 'user'
                ? 'User Logs'
                : moduleType === 'customer'
                ? 'Customer Logs'
                : moduleType === 'invoice'
                ? 'Invoice Logs'
                : moduleType === 'salary'
                ? 'Salary Logs'
                : moduleType === 'certificate'
                ? 'Employee Certificate Logs'
                : moduleType === 'payout'
                ? 'Holiday Payout Logs'
                : ''}
            </Typography>
          </Stack>
        </Stack>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="py-3 px-2">
              <div>
                <h6>
                  <span className="font-weight-bold">
                    {moduleType === 'user'
                      ? 'User ID'
                      : moduleType === 'customer'
                      ? 'Customer ID'
                      : moduleType === 'invoice'
                      ? 'Invoice ID'
                      : moduleType === 'salary'
                      ? 'Salary ID'
                      : moduleType === 'certificate'
                      ? 'Employee Certificate ID'
                      : moduleType === 'payout'
                      ? 'Holiday Payout ID'
                      : ''}
                    :{' '}
                  </span>

                  <span>{ID}</span>
                </h6>
              </div>
              <h6>
                <span className="font-weight-bold"> {moduleType === 'customer' ? 'Customer Name:' : 'Username:'} </span>
                <span className="mt-2">{location?.state?.name}</span>
              </h6>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center"># </StyledTableCell>
                  <StyledTableCell>Description</StyledTableCell>
                  <StyledTableCell>IP Address</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logsData?.length ? (
                  logsData?.map((row, index) => (
                    <StyledTableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="center">{page * rowsPerPage + index + 1}</TableCell>
                      <TableCell>
                        {`${row?.createdByUserName} (${row?.createdById}) ${row?.action} (${row?.comments}) (${
                          row?.entityId
                        }) at ${fDateTimeSuffix(row?.createdDate, 'time')}`}
                      </TableCell>
                      <TableCell>{row?.userIp}</TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow className="text-center">
                    <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                      No record found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={logsDataCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Container>
    </>
  );
}
