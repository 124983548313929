import React from "react";

const CheckboxUI = () => (
    <svg
      className={`checkbox-svg`}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_476_5-37" fill="white">
        <rect width="200" height="200" />
      </mask>
      <rect
        width="200"
        height="200"
        className="checkbox-box"
        strokeWidth="40"
        mask="url(#path-1-inside-1_476_5-37)"
      />
      <path
        className="checkbox-tick"
        d="M52 111.018L76.9867 136L149 64"
        strokeWidth="15"
      />
    </svg>
  );

export default CheckboxUI;