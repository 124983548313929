/* eslint-disable */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { sentenceCase } from 'change-case';
import axios from 'axios';

// MUI Icons
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

// material
import { Typography, TableContainer, TablePagination, FormControl, InputLabel, IconButton } from '@mui/material';
import { Box, Modal, Button, Card, Tooltip, MenuItem, Select, Table } from '@mui/material';
import { Stack, Checkbox, TableRow, TableBody, Snackbar, Alert, TableCell, Container } from '@mui/material';
import countries from 'countries-list';

// components
import { ListHead, ListToolbar } from '../../sections/@dashboard/table';
import SearchNotFound from '../../components/SearchNotFound';
import Scrollbar from '../../components/Scrollbar';
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Loader from '../../components/Loader';
import downloadSalaryDetailsAsPdf, { formatDate2, getModalBoxStyle, invoiceDropOptions } from '../../utils/commonFunc';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'customerName', label: 'Customer', alignRight: false },
  { id: 'createdDate', label: 'Created Date', alignRight: false },
  { id: 'dueDate', label: 'Due Date', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'invoiceStatus', label: 'Invoice Status', alignRight: false },
  { id: 'invoiceSalaryStatus', label: 'Salary Status', alignRight: false },
  { id: 'expressPayout', label: 'Express Payment', alignRight: false },
  { id: 'Salary', label: 'Salary', alignRight: false },
  { id: 'isActive', label: 'Actions', alignRight: false },
];

const WORKING_HOURS_TABLE_HEAD = [
  {
    id: 'workingDate',
    label: 'Date',
    alignRight: false,
    flex: 1,
    padding: '6px 16px',
  },
  {
    id: 'articleName',
    label: 'Article Name',
    alignRight: false,
    padding: '6px 16px',
  },
  {
    id: 'IsArticleShownInInvoice',
    label: 'Is Shown',
    alignRight: false,
    padding: '6px 16px',
  },
  {
    id: 'vat',
    label: 'VAT%',
    alignRight: false,
    padding: '6px 16px',
  },
  {
    id: 'number',
    label: 'Unit Number',
    alignRight: false,
    padding: '6px 16px',
  },
  {
    id: 'amountPerUnit',
    label: 'Amount per unit',
    alignRight: false,
    padding: '6px 16px',
  },

  { id: 'totalAmount', label: 'SEK', alignRight: false, padding: '6px 16px' },

  {
    id: 'grossAmount',
    label: 'Gross Salary',
    alignRight: false,
    padding: '6px 16px',
  },
  {
    id: 'expressFee',
    label: 'Express Fee',
    alignRight: false,
    padding: '6px 16px',
  },
  { id: 'distance', label: 'Distance', alignRight: false, padding: '6px 16px' },
  { id: 'carRegistrationNo', label: 'Car Registration No.', alignRight: false, padding: '6px 16px' },
  { id: 'tripStartedPlace', label: 'Trip Started Place', alignRight: false, padding: '6px 16px' },
  { id: 'tripStartedDate', label: 'Trip Started Date', alignRight: false, padding: '6px 16px' },
  { id: 'tripEndedDate', label: 'Trip Ended Date', alignRight: false, padding: '6px 16px' },
  { id: 'reasonForTrip', label: 'Reason For Trip', alignRight: false, padding: '6px 16px' },
  { id: 'country', label: 'Country', alignRight: false, padding: '6px 16px' },
  { id: 'destination', label: 'Destination', alignRight: false, padding: '6px 16px' },
];

const INVOICE_PDF_TABLE_HEAD = [
  {
    id: 'fileName',
    label: 'File Name',
    alignRight: false,
    padding: '6px 16px',
  },
  {
    id: 'isIncluded',
    label: 'Is Included',
    alignRight: false,
    padding: '6px 16px',
  },
  {
    id: 'action',
    label: 'Action',
    alignRight: false,
    padding: '6px 16px',
  },
];

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis?.map((el) => el[0]);
}
export default function Invoices() {
  const [invoices, setInvoices] = useState([]);
  const [originalInvoice, setoriginalInvoice] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [searchDropValue, setSearchDropValue] = useState();
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [searchQuery, setSearchQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [autoHideDuration, setAutoHideDuration] = useState(3000);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertStatus, setAlertStatus] = useState('success');
  const [isOpenModal, setIsOpenModal] = useState({
    open: false,
    index: null,
  });
  const [totalCount, setTotalCount] = useState(0);
  const [freelancerName, setFreelancerName] = useState('');
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [hideCheckboxColumn] = useState(true);
  const [activateModal, setActivateModal] = useState({
    id: null,
    show: false,
  });
  const [userPdf, setUserPdf] = useState([]);
  const { userId } = useParams();
  const selectedInvoice = localStorage.setItem('selected_invoice', userId);
  const navigate = useNavigate();

  const fetchInvoices = async () => {
    try {
      setLoading(true);
      let URL;
      if (searchDropValue && searchQuery) {
        URL = `/admin/invoice/user/${userId}?PageSize=${rowsPerPage}&PageNumber=${
          page + 1
        }&searchQuery=${searchQuery}&SearchColumn=${searchDropValue}`;
      } else {
        URL = `/admin/invoice/user/${userId}?PageSize=${rowsPerPage}&PageNumber=${page + 1}&searchQuery=${searchQuery}`;
      }
      const res = await axios.get(URL);

      if (res?.status === 200) {
        setLoading(false);
        const invoices = res.data.response?.map((invoice) => ({
          ...invoice,
          customerName: invoice.customer?.name || '',
          invoiceFileUpload: invoice.invoiceFileUpload[0] && invoice.invoiceFileUpload[0].fortnoxFileId,
          workingHours: invoice?.workingHours?.map((item) => ({
            ...item,
            number: item.number.toString().concat(item.unit),
            amountPerUnit: item?.amountPerUnit
              ?.toString()
              .concat(
                ' ',
                `SEK${item.unit === 'km' ? '' : '/'}${
                  (item.unit !== 'km' && (item.unit === 'förp' ? 'Antal' : item.unit)) || ''
                }`
              ),
            expressFee: item?.expressFee ? item?.expressFee : 0,
            grossAmount: item?.grossAmount ? item?.grossAmount : 0,
          })),
          toPayAmont: invoice?.toPayAmont ? invoice?.toPayAmont : 0,
          totalGrossAmount: invoice?.totalGrossAmount ? invoice?.totalGrossAmount : 0,
          isSelected: invoice.createdBy === Number(userId),
        }));

        setoriginalInvoice(invoices);
        const filteredRows = applySortFilter(invoices, getComparator(order, orderBy), invoices);
        setInvoices(filteredRows);
        setFilteredInvoices(filteredRows);
        setTotalCount(res.data.totalCount);
        setLoading(false);
      }
    } catch (err) {
      setInvoices([]);
      setFilteredInvoices([]);
      setLoading(false);
    }
  };

  const handleDownloadSinglePdf = async (
    fileName,
    fileContent,
    uploadFileForInvoiceUrl,
    uploadFileForAdminUrl,
    fortnoxFileId
  ) => {
    try {
      setLoading(true);
      let fileBytes;
      let res;
      if (fileContent !== '') {
        fileBytes = fileContent;
      } else if (uploadFileForInvoiceUrl || uploadFileForAdminUrl) {
        res = await axios.get(`/admin/invoice/download/${fileName}`);
        fileBytes = res?.data?.response;
      } else {
        res = await axios.get(`/admin/invoice/download/fortnox-file/${fortnoxFileId}`);
        fileBytes = res?.data?.response;
      }
      const linkSource = `data:application/pdf;base64,${fileBytes}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = fileName.endsWith('.pdf') ? fileName : `${fileName}.pdf`;
      downloadLink.click();

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchInvoices();
    }, 600);
    return () => clearTimeout(timeoutId);
  }, [page, rowsPerPage, searchQuery, searchDropValue]);

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (event) => {
    setSearchQuery(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - invoices.length) : 0;
  const isNotFound = filteredInvoices?.length === 0;

  const handleGetSalary = async (id, isMultiFreelancer) => {
    try {
      setLoading(true);
      await axios.get(`/admin/invoice/${id}`).then((res) => {
        setSelectedRow(res.data.response);
        setUserPdf(res.data.response?.invoiceFileUpload);
        if (!isMultiFreelancer) {
          setIsOpenModal(() => ({ open: true }));
        }
      });
    } catch (err) {
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeSalaryDropdown = (event) => {
    setFreelancerName(event.target.value);
  };

  const handleOpenFreelancerModal = (item) => {
    setIsOpenModal(() => ({ open: true }));
    setSelectedRow({ ...item, customer: { name: item.name } });
  };

  const newDetails = [];
  Object.keys(countries.countries).forEach((code) => {
    // if (countries.countries[code].continent === 'EU') {
    newDetails.push({ label: countries.countries[code].name });
    // }
  });

  const handleCloseModal = () => setIsOpenModal((prevState) => ({ ...prevState, open: false }));

  const createSalary = async (id) => {
    try {
      setLoading(true);
      await axios.post(`/admin/invoice/${id}`).then(() => {
        setAlertMessage(`Salary has been created successfully.`);
        setAlertStatus('success');
        setIsSnackBarOpen(true);
        fetchInvoices();
      });
      await fetchInvoices();
    } catch (err) {
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const cancelInvoice = async (invoiceId) => {
    try {
      setLoading(true);
      await axios.put(`/admin/invoice/${invoiceId}?isActive=false`).then(async () => {
        setAlertMessage(`InvoiceId #${invoiceId} has been cancelled successfully.`);
        setAlertStatus('success');
        setAutoHideDuration(2000);
        setIsSnackBarOpen(true);
        await fetchInvoices();
      });
    } catch (err) {
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const downloadPDF = async (invoiceFileUpload) => {
    try {
      setLoading(true);

      const res = await axios.get(`/admin/invoice/file/download/${invoiceFileUpload}`);

      if (res.data?.response && Array.isArray(res.data.response)) {
        res.data.response.forEach((base64PDF, index) => {
          const linkSource = `data:application/pdf;base64,${base64PDF}`;
          const downloadLink = document.createElement('a');
          const fileName = `user_invoice_${invoiceFileUpload}_${index + 1}.pdf`; // Generate unique file names
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        });
      } else {
        console.error('Invalid response format');
      }

      setLoading(false);
    } catch (error) {
      console.error('Error downloading PDFs:', error.message || error);
      setLoading(false);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const filteredRows = applySortFilter(
      filteredInvoices,
      getComparator(isAsc ? 'desc' : 'asc', orderBy),
      event.target.value,
      originalInvoice
    );
    setFilteredInvoices(filteredRows);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = invoices?.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    height: 600,
    p: 4,
    overflow: 'auto',
  };

  const handleSnackBarClose = () => {
    setIsSnackBarOpen(false);
  };

  const activateOrDeactivate = async (invoiceId, type) => {
    try {
      setLoading(true);
      await axios.put(`/admin/invoice/admin/${invoiceId}?isActive=${JSON.parse(type)}`).then(async () => {
        setAlertMessage(
          `InvoiceId #${invoiceId} has been ${type === 'true' ? 'Activated' : 'Deactivated'} successfully.`
        );
        setAlertStatus('success');
        setAutoHideDuration(2000);
        setIsSnackBarOpen(true);
        await fetchInvoices();
        setActivateModal({ show: false });
      });
    } catch (err) {
      setActivateModal({ show: false });
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
    } finally {
      setActivateModal({ show: false });
      setLoading(false);
    }
  };

  const renderInvoices = () => (
    <>
      <ListToolbar
        hideEmailButton={'true'}
        numSelected={selected.length}
        searchQuery={searchQuery}
        onFilter={handleFilter}
        dropOptions={invoiceDropOptions}
        searchDropValue={searchDropValue}
        setSearchDropValue={setSearchDropValue}
      />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table className="invoice-details">
            <ListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={invoices.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {filteredInvoices?.map((row, tableIndexs) => {
                const {
                  id,
                  amount,
                  isActive,
                  createdDate,
                  invoiceStatus,
                  expressPayout,
                  isPfdAttached,
                  invoiceSalaryStatus,
                  dueDate,
                  multipleFreelancer,
                  customer,
                  invoiceFileUpload,
                } = row;

                const isItemSelected = selected.indexOf(id) !== -1;

                return (
                  <TableRow
                    hover
                    key={id}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                    </TableCell>
                    <TableCell align="left">{id}</TableCell>
                    <TableCell align="left">{customer.name}</TableCell>
                    <TableCell align="left" style={{ fontSize: '12px', padding: '6px' }}>
                      {createdDate.split('T')[0]}
                    </TableCell>
                    <TableCell align="left" style={{ fontSize: '12px', padding: '6px' }}>
                      {dueDate && dueDate.split('T')[0]}
                    </TableCell>
                    <TableCell align="left">{amount}</TableCell>
                    <TableCell align="left" style={{ fontSize: '13px', padding: '6px' }}>
                      {invoiceStatus && (
                        <Label variant="ghost" color={invoiceStatus === 1 ? 'error' : 'success'}>
                          {sentenceCase(invoiceStatus === 1 ? 'Unpaid' : `${invoiceStatus === 2 ? 'Paid' : 'Partial'}`)}
                        </Label>
                      )}
                    </TableCell>
                    <TableCell align="left" style={{ fontSize: '13px', padding: '6px' }}>
                      {invoiceSalaryStatus && (
                        <Label variant="ghost" color={invoiceSalaryStatus === 1 ? 'error' : 'success'}>
                          {sentenceCase(
                            invoiceSalaryStatus === 1
                              ? 'Unpaid'
                              : `${invoiceSalaryStatus === 2 ? 'Salary Paid' : 'Partial'}`
                          )}
                        </Label>
                      )}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '13px', padding: '6px' }}>
                      <input type="checkbox" checked={expressPayout} readOnly />
                    </TableCell>

                    <TableCell
                      align="left"
                      style={{
                        marginTop: '-10px',
                        fontSize: '12px',
                        padding: '6px',
                      }}
                    >
                      {!multipleFreelancer ? (
                        <Tooltip title="Salary Details">
                          <Button
                            variant="outlined"
                            onClick={() => handleGetSalary(id, false)}
                            style={{
                              fontSize: '12px',
                              padding: '6px',
                              width: 70,
                            }}
                          >
                            Salary Details
                          </Button>
                        </Tooltip>
                      ) : (
                        <>
                          <FormControl sx={{ m: 1, minWidth: 110, margin: 0 }} variant="standard">
                            <InputLabel id="multi-freelancer-label" style={{ fontSize: '13px' }}>
                              Salary Details
                            </InputLabel>
                            <Select
                              defaultValue="choose"
                              labelId="multi-freelancer-label"
                              value={(tableIndexs === isOpenModal.index && freelancerName) || ''}
                              label="Salary Details"
                              onOpen={() => handleGetSalary(id, true)}
                              onChange={(event) => handleChangeSalaryDropdown(event, tableIndexs)}
                            >
                              {loading ? (
                                <MenuItem value="">Loading...</MenuItem>
                              ) : (
                                selectedRow?.salaryFreelancer?.map((items) => {
                                  const { userName, userId } = items;

                                  return (
                                    <MenuItem
                                      key={userId}
                                      value={userName}
                                      onClick={() => handleOpenFreelancerModal(items, selectedRow?.createdDate)}
                                      className={
                                        selectedRow.salaryFreelancer.find(
                                          (item) => item.userId === selectedRow.createdBy
                                        )?.userId === userId
                                          ? 'highlight-user'
                                          : ''
                                      }
                                    >
                                      {userName}
                                    </MenuItem>
                                  );
                                })
                              )}
                            </Select>
                          </FormControl>
                        </>
                      )}
                    </TableCell>

                    <TableCell
                      align="right"
                      style={{
                        display: 'flex',
                        gap: '8px',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: '6px',
                      }}
                    >
                      <div style={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
                        {invoiceSalaryStatus === 2 ? (
                          <Label
                            variant="ghost"
                            color="success"
                            className="created-ghost-button"
                            sx={{
                              padding: '10px 4px',
                              height: 35,
                              fontSize: '12px',
                              width: 70,
                              minWidth: '70px',
                            }}
                          >
                            {sentenceCase('Created')}
                          </Label>
                        ) : (
                          <Tooltip title="Create Salary">
                            <Button
                              variant="outlined"
                              onClick={() => createSalary(id)}
                              style={{ fontSize: '12px', width: 70, minWidth: '70px' }}
                            >
                              Create
                            </Button>
                          </Tooltip>
                        )}
                        {isActive ? (
                          <Tooltip title="Cancelled">
                            <Button
                              variant="contained"
                              color={isActive ? 'primary' : 'error'}
                              style={{ width: 70, minWidth: '70px', fontSize: '12px' }}
                              onClick={() => {
                                cancelInvoice(id);
                              }}
                            >
                              Cancel
                            </Button>
                          </Tooltip>
                        ) : (
                          <Button
                            variant="contained"
                            color={isActive ? 'primary' : 'error'}
                            style={{ width: 70, minWidth: '70px', fontSize: '12px' }}
                            disabled
                          >
                            Cancelled
                          </Button>
                        )}
                      </div>

                      <div>
                        {isPfdAttached && (
                          <Tooltip title="PDF">
                            <PictureAsPdfIcon
                              variant="contained"
                              color="error"
                              style={{
                                background: '#62b5e5',
                                width: 28,
                                minWidth: '28px',
                                fontSize: '28px',
                              }}
                              onClick={() => {
                                downloadPDF(id);
                              }}
                              sx={{
                                ml: 1,
                                color: '#fff',
                                cursor: 'pointer',
                              }}
                            />
                          </Tooltip>
                        )}
                      </div>

                      <div>
                        {invoiceFileUpload && (
                          <PictureAsPdfIcon
                            onClick={() => {
                              downloadPDF(invoiceFileUpload);
                            }}
                            sx={{ ml: 1, background: '#62b5e5', color: '#fff', cursor: 'pointer' }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell style={{ fontSize: '13px', padding: '6px' }}>
                      {isActive && (
                        <Tooltip title="Click To Deactivate">
                          <Button
                            variant="contained"
                            color={'error'}
                            value={'false'}
                            style={{ width: 75, minWidth: '75px', fontSize: '12px' }}
                            onClick={() => {
                              setActivateModal({ id, show: true });
                            }}
                          >
                            Deactivate
                          </Button>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <Modal
              open={isOpenModal.open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Box sx={{ height: 'fit-content' }} id="salary-modal">
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Salary Details <br />
                    User Id: #{selectedRow?.userId ? selectedRow?.userId : selectedRow?.createdBy}
                    <br />
                    User Name: {selectedRow?.userName}
                    <br />
                    {selectedRow?.customer?.name ? 'Customer Name:' : ''} {selectedRow?.customer?.name || ''}
                  </Typography>

                  <div>
                    <div
                      className="table-scroll"
                      style={{
                        maxHeight: '160px',
                        overflow: 'auto',
                        margin: '0 0 20px 0',
                      }}
                    >
                      <Table className="salary-details">
                        <ListHead
                          headLabel={WORKING_HOURS_TABLE_HEAD}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                          hideCheckboxColumnHeader={hideCheckboxColumn}
                        />
                        <TableBody>
                          {selectedRow?.workingHours?.map((row, i) => {
                            const {
                              number,
                              expressFee,
                              workingDate,
                              articleName,
                              totalAmount,
                              grossAmount,
                              amountPerUnit,
                              isArticleShownInInvoice,
                              vat,
                              distance,
                              country,
                              allowanceDestination,
                              reasonForTrip,
                              carRegistrationNo,
                              tripStartedDate,
                              tripEndedDate,
                              tripStartedPlace,
                            } = row;
                            console.log(row,)
                            return (
                              <TableRow hover key={i} tabIndex={-1}>
                                <TableCell align="left" style={{ padding: '6px 16px' }} scope="row">
                                  {workingDate !== '0001-01-01T00:00:00' ? formatDate2(workingDate) : '-'}
                                </TableCell>

                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {articleName}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {isArticleShownInInvoice ? 'Yes' : 'No'}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {vat}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {number}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {amountPerUnit}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {totalAmount || 0}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {grossAmount?.toFixed(2) || 0}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {expressFee || 0}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {distance || '-'}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {carRegistrationNo || '-'}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {tripStartedPlace || '-'}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {tripStartedDate ? formatDate2(tripStartedDate) : '-'}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {tripEndedDate ? formatDate2(tripEndedDate) : '-'}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {reasonForTrip || '-'}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {country || '-'}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {allowanceDestination || '-'}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </div>
                    {selectedRow?.workingHours?.length && (
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Total Hours
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {!selectedRow?.isFixed ? selectedRow?.totalHourlyWagesHours?.toFixed(2) : 0}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Total Hours Per Unit Amount
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {!selectedRow?.isFixed ? selectedRow?.hourlyWagesAmountPerUnit?.toFixed(2) : 0}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Amount INCL. VAT
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.vatAmount} {selectedRow?.currency}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Invoice Amount
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {!selectedRow?.isFixed ? selectedRow?.amount?.toFixed(2) : 0} {selectedRow?.currency}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Fixed Price
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.isFixed ? selectedRow?.amount?.toFixed(2) : 0} {selectedRow?.currency}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Invozio Fee
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.multipleFreelancer === false
                                ? selectedRow?.fakturaNuFee?.toFixed(2) || '0'
                                : selectedRow?.fakturaFee?.toFixed(2) || '0'}{' '}
                              SEK
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Express Payout Amount
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.multipleFreelancer === false
                                ? selectedRow?.expressPayoutAmount?.toFixed(2) || '0'
                                : selectedRow?.expressPayoutAmount?.toFixed(2) || '0'}{' '}
                              SEK
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Pension Amount
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.pensionAmount?.toFixed(2)} SEK
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Holiday Pay Amount
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.holidaySavingsAmount?.toFixed(2)} SEK
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              VAT%
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.vat}
                            </TableCell>
                          </TableRow>

                          {new Date(selectedRow.createdDate) > new Date('2023-12-31T23:59:59') ? (
                            ''
                          ) : (
                            <TableRow>
                              <TableCell
                                style={{ padding: '7px 10px' }}
                                align="left"
                                colSpan={6}
                                sx={{ py: 3, fontWeight: 'bold' }}
                              >
                                Administrator Fee
                              </TableCell>
                              <TableCell
                                style={{
                                  padding: '7px 10px',
                                  textAlign: 'right',
                                }}
                                align="left"
                                colSpan={6}
                                sx={{ py: 3 }}
                              >
                                {selectedRow?.administratorFee?.toFixed(2) || 0}
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Total Gross Salary
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.totalGrossAmount?.toFixed(2)} SEK
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Tax Free Article
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.taxfreeArticle?.toFixed(2)} SEK
                            </TableCell>
                          </TableRow>

                          {selectedRow?.multipleFreelancer === false ? (
                            <TableRow>
                              <TableCell
                                style={{ padding: '7px 10px' }}
                                align="left"
                                colSpan={6}
                                sx={{ py: 3, fontWeight: 'bold' }}
                              >
                                Travel And Allowence
                              </TableCell>
                              <TableCell
                                style={{
                                  padding: '7px 10px',
                                  textAlign: 'right',
                                }}
                                align="left"
                                colSpan={6}
                                sx={{ py: 3 }}
                              >
                                {selectedRow?.totalTravelAllowance?.toFixed(2) || '0'} SEK
                              </TableCell>
                            </TableRow>
                          ) : (
                            <TableRow>
                              <TableCell
                                style={{ padding: '7px 10px' }}
                                align="left"
                                colSpan={6}
                                sx={{ py: 3, fontWeight: 'bold' }}
                              >
                                Travel And Allowence
                              </TableCell>
                              <TableCell
                                style={{
                                  padding: '7px 10px',
                                  textAlign: 'right',
                                }}
                                align="left"
                                colSpan={6}
                                sx={{ py: 3 }}
                              >
                                {selectedRow?.travelAllowance?.toFixed(2) || '0'} SEK
                              </TableCell>
                            </TableRow>
                          )}

                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Express Payout
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.expressPayout ? 'Yes' : 'No'}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Pension
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.pension ? 'Yes' : 'No'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Holiday Pay
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.holidaySavings ? 'Yes' : 'No'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Foreign Bank Fee
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.foreignBankFee?.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Total amount to be paid to bank
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                                minWidth: 90,
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              {selectedRow?.toPayAmont?.toFixed(2)} SEK
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    )}
                  </div>
                </Box>
                {console.log(userPdf,'====u')}
                {userPdf && userPdf.length > 0 && (
                  <Box sx={{ height: 'fit-content' }} id="salary-modal">
                    <div>
                      <div
                        className="table-scroll"
                        style={{
                          maxHeight: '160px',
                          overflow: 'auto',
                          margin: '15px 10px 10px 10px',
                        }}
                      >
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                          Invoice PDF
                        </Typography>
                        <Table className="salary-details">
                          <ListHead
                            headLabel={INVOICE_PDF_TABLE_HEAD}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                            hideCheckboxColumnHeader={hideCheckboxColumn}
                          />

                          <TableBody>
                            {userPdf
                              ?.filter((row) =>
                                selectedRow?.userId
                                  ? row?.userId === selectedRow?.userId
                                  : row?.userId === selectedRow?.createdBy
                              )
                              ?.map((row, i) => {
                                const {
                                  fileName,
                                  fileContent,
                                  uploadFileForInvoiceUrl,
                                  uploadFileForAdminUrl,
                                  fortnoxFileId,
                                } = row;
                                return (
                                  <TableRow hover key={i} tabIndex={-1}>
                                    <TableCell align="left" style={{ padding: '6px 16px' }}>
                                      {fileName}
                                    </TableCell>
                                    <TableCell align="left" style={{ padding: '6px 16px' }}>
                                      {uploadFileForInvoiceUrl ? 'Yes' : 'No'}
                                    </TableCell>
                                    <TableCell align="left" style={{ padding: '6px 16px' }}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                          handleDownloadSinglePdf(
                                            fileName,
                                            fileContent,
                                            uploadFileForInvoiceUrl,
                                            uploadFileForAdminUrl,
                                            fortnoxFileId
                                          )
                                        }
                                      >
                                        Download
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  </Box>
                )}
                <div
                  style={{
                    margin: '25px 0 0',
                    float: 'right',
                    gap: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <PictureAsPdfIcon
                    onClick={() => {
                      downloadSalaryDetailsAsPdf();
                    }}
                    sx={{
                      ml: 1,
                      background: '#62b5e5',
                      color: '#fff',
                      mt: 7,
                      cursor: 'pointer',
                      marginTop: '0',
                    }}
                  />
                  <Button onClick={handleCloseModal} sx={{ float: 'right', backgroundColor: '#62b5e514' }} autoFocus>
                    Close
                  </Button>
                </div>
              </Box>
            </Modal>

            <Modal
              open={isOpenModal.open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Box sx={{ height: 'fit-content' }} id="salary-modal">
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Salary Details <br />
                    User Id: #{selectedRow?.userId ? selectedRow?.userId : selectedRow?.createdBy}
                    <br />
                    User Name: {selectedRow?.userName}
                    <br />
                    {selectedRow?.customer?.name ? 'Customer Name:' : ''} {selectedRow?.customer?.name || ''}
                  </Typography>
                  <div>
                    <div
                      className="table-scroll"
                      style={{ maxHeight: '160px', overflow: 'auto', margin: '0 0 20px 0' }}
                    >
                      <Table className="salary-details">
                        <ListHead
                          headLabel={WORKING_HOURS_TABLE_HEAD}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                          hideCheckboxColumnHeader={hideCheckboxColumn}
                        />
                        <TableBody>
                          {selectedRow?.workingHours?.map((row, i) => {
                             const {
                              number,
                              expressFee,
                              workingDate,
                              articleName,
                              totalAmount,
                              grossAmount,
                              amountPerUnit,
                              isArticleShownInInvoice,
                              vat,
                              distance,
                              country,
                              allowanceDestination,
                              reasonForTrip,
                              carRegistrationNo,
                              tripStartedDate,
                              tripEndedDate,
                              tripStartedPlace,
                            } = row;
                            return (
                              <TableRow hover key={i} tabIndex={-1}>
                                <TableCell align="left" style={{ padding: '6px 16px' }} scope="row">
                                  {workingDate !== '0001-01-01T00:00:00' ? formatDate2(workingDate) : '-'}
                                </TableCell>

                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {articleName}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {isArticleShownInInvoice ? 'Yes' : 'No'}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {vat}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {number}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {amountPerUnit}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {totalAmount || 0}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {grossAmount?.toFixed(2) || 0}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {expressFee || 0}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {distance || '-'}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {carRegistrationNo || '-'}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {tripStartedPlace || '-'}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {tripStartedDate ? formatDate2(tripStartedDate) : '-'}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {tripEndedDate ? formatDate2(tripEndedDate) : '-'}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {reasonForTrip || '-'}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {country || '-'}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {allowanceDestination || '-'}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </div>
                    {selectedRow?.workingHours?.length && (
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Total Hours
                            </TableCell>
                            <TableCell
                              style={{ padding: '7px 10px', textAlign: 'right' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {!selectedRow?.isFixed ? selectedRow?.totalHourlyWagesHours?.toFixed(2) : 0}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Total Hours Per Unit Amount
                            </TableCell>
                            <TableCell
                              style={{ padding: '7px 10px', textAlign: 'right' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {!selectedRow?.isFixed ? selectedRow?.hourlyWagesAmountPerUnit?.toFixed(2) : 0}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Invoice Amount
                            </TableCell>
                            <TableCell
                              style={{ padding: '7px 10px', textAlign: 'right' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {!selectedRow?.isFixed ? selectedRow?.amount?.toFixed(2) : 0}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Pension Amount
                            </TableCell>
                            <TableCell
                              style={{ padding: '7px 10px', textAlign: 'right' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.pensionAmount?.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Holiday Pay Amount
                            </TableCell>
                            <TableCell
                              style={{ padding: '7px 10px', textAlign: 'right' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.holidaySavingsAmount?.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Fixed Price
                            </TableCell>
                            <TableCell
                              style={{ padding: '7px 10px', textAlign: 'right' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.isFixed ? selectedRow?.amount?.toFixed(2) : 0}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              VAT%
                            </TableCell>
                            <TableCell
                              style={{ padding: '7px 10px', textAlign: 'right' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.vat}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Amount INCL. VAT
                            </TableCell>
                            <TableCell
                              style={{ padding: '7px 10px', textAlign: 'right' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.vatAmount}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Administrator Fee
                            </TableCell>
                            <TableCell
                              style={{ padding: '7px 10px', textAlign: 'right' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.administratorFee?.toFixed(2) || 0}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Total Gross Salary
                            </TableCell>
                            <TableCell
                              style={{ padding: '7px 10px', textAlign: 'right' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.totalGrossAmount?.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Tax Free Article
                            </TableCell>
                            <TableCell
                              style={{ padding: '7px 10px', textAlign: 'right' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.taxfreeArticle?.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Express Payout
                            </TableCell>
                            <TableCell
                              style={{ padding: '7px 10px', textAlign: 'right' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.expressPayout ? 'Yes' : 'No'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Pension
                            </TableCell>
                            <TableCell
                              style={{ padding: '7px 10px', textAlign: 'right' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.pension ? 'Yes' : 'No'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Holiday Pay
                            </TableCell>
                            <TableCell
                              style={{ padding: '7px 10px', textAlign: 'right' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.holidaySavings ? 'Yes' : 'No'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Total amount to be paid to bank
                            </TableCell>
                            <TableCell
                              style={{ padding: '7px 10px', textAlign: 'right', minWidth: 90 }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              {selectedRow?.toPayAmont?.toFixed(2)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    )}
                  </div>
                </Box>
                {userPdf && userPdf.length > 0 && (
                  <Box sx={{ height: 'fit-content' }} id="salary-modal">
                    <div>
                      <div
                        className="table-scroll"
                        style={{
                          maxHeight: '160px',
                          overflow: 'auto',
                          margin: '15px 10px 10px 10px',
                        }}
                      >
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                          Invoice PDF
                        </Typography>
                        <Table className="salary-details">
                          <ListHead
                            headLabel={INVOICE_PDF_TABLE_HEAD}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                            hideCheckboxColumnHeader={hideCheckboxColumn}
                          />

                          <TableBody>
                            {userPdf
                              ?.filter((row) =>
                                selectedRow?.userId
                                  ? row?.userId === selectedRow?.userId
                                  : row?.userId === selectedRow?.createdBy
                              )
                              ?.map((row, i) => {
                                const {
                                  fileName,
                                  fileContent,
                                  uploadFileForInvoiceUrl,
                                  uploadFileForAdminUrl,
                                  fortnoxFileId,
                                } = row;
                                return (
                                  <TableRow hover key={i} tabIndex={-1}>
                                    <TableCell align="left" style={{ padding: '6px 16px' }}>
                                      {fileName}
                                    </TableCell>
                                    <TableCell align="left" style={{ padding: '6px 16px' }}>
                                      {uploadFileForInvoiceUrl ? 'Yes' : 'No'}
                                    </TableCell>
                                    <TableCell align="left" style={{ padding: '6px 16px' }}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                          handleDownloadSinglePdf(
                                            fileName,
                                            fileContent,
                                            uploadFileForInvoiceUrl,
                                            uploadFileForAdminUrl,
                                            fortnoxFileId
                                          )
                                        }
                                      >
                                        Download
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  </Box>
                )}
                <div style={{ margin: '25px 0 0', float: 'right', gap: '10px', display: 'flex', alignItems: 'center' }}>
                  <PictureAsPdfIcon
                    onClick={() => {
                      downloadSalaryDetailsAsPdf();
                    }}
                    sx={{ ml: 1, background: '#62b5e5', color: '#fff', mt: 7, cursor: 'pointer', marginTop: '0' }}
                  />
                  <Button onClick={handleCloseModal} sx={{ float: 'right', backgroundColor: '#62b5e514' }} autoFocus>
                    Close
                  </Button>
                </div>
              </Box>
            </Modal>

            <Modal
              open={activateModal.show}
              onClose={() => setActivateModal({ show: false })}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={getModalBoxStyle()}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Are you sure you want to deactivate this invoice?
                </Typography>
                <div style={{ margin: '25px 0 0', float: 'right', gap: '10px', display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={() => setActivateModal({ show: false })}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    No
                  </Button>
                  <Button
                    onClick={() => activateOrDeactivate(activateModal.id, 'false')}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    Yes
                  </Button>
                </div>
              </Box>
            </Modal>

            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={searchQuery} isLoading={loading} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[50, 100, 250, 500]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
  return (
    <>
      <Page title="Invoices">
        {loading && <Loader />}
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Stack direction="row" alignItems="center">
              <IconButton onClick={() => navigate(-1)}>
                <Iconify icon="eva:arrow-back-fill" width={24} height={24} />
              </IconButton>
              <Typography variant="h4" sx={{ ml: 1 }}>
                Invoices
              </Typography>
            </Stack>
          </Stack>

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isSnackBarOpen}
            autoHideDuration={autoHideDuration}
            onClose={handleSnackBarClose}
          >
            <Alert onClose={handleSnackBarClose} severity={alertStatus} sx={{ width: '100%' }}>
              {alertMessage}
            </Alert>
          </Snackbar>
          <Card>
            <Box sx={{ width: '100 %', typography: 'body1' }}>{renderInvoices()}</Box>
          </Card>
        </Container>
      </Page>
    </>
  );
}
