import React from 'react';
import { Button } from '@mui/material';
import { Modal } from 'react-bootstrap';
import SignaturePadComponent from './SignaturePad';

const OpenSignatureModal = ({ isOpen, onClose, onSave }) => {
  if (!isOpen) return null;

  return (
    <Modal
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
      show={isOpen}
      onHide={onClose}
      animation={false}
      centered
      style={{ marginTop: '65px' }}
    >
      <div style={modalStyle}>
        <div style={modalContentStyle}>
          <Button className="mt-2" onClick={onClose} style={closeButtonStyle}>
            X
          </Button>
          <h2>Draw your signature:</h2>
          <SignaturePadComponent onSave={onSave} />
        </div>
      </div>
    </Modal>
  );
};

const modalStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const modalContentStyle = {
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '8px',
  position: 'relative',
};

const closeButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  color: 'black',
  border: 'none',
  borderRadius: '50%',
  cursor: 'pointer',
};

export default OpenSignatureModal;
