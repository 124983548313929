/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */

import React from 'react';
import { Button, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ErrosAlert = (props) => {
  const { messages, isError, setshowSyncModal, setisError, setMessages, handleButtonClick } = props;

  // Erros Message Line
  const updateErrorLines = messages.customerUpdatedOnFortnoxError?.split('* ')?.filter((line) => line.trim() !== '');
  const addCuserrorLines = messages.customeraddedOnFortnoxError?.split('* ')?.filter((line) => line.trim() !== '');
  const invoiceErrorLines = messages.invoiceaddedOnFortnoxError?.split('* ')?.filter((line) => line.trim() !== '');
  const invoiceCancelErrors = messages.invoiceCanceledOnFortnoxError?.split('* ')?.filter((line) => line.trim() !== '');
  const invoicefileUploadErros = messages.invoiceFileuploadedOnFortnoxError
    ?.split('* ')
    ?.filter((line) => line.trim() !== '');
  const taxReductionError = messages.invoiceTaxReductioncreatedOnFortnoxError
    ?.split('* ')
    ?.filter((line) => line.trim() !== '');
  const invoiceUpdateError = messages.invoiceUpdatedOnFortnoxError?.split('* ')?.filter((line) => line.trim() !== '');

  // Success Message Line
  const customerAddSuccess = messages.customerUpdatedOnFortnox?.split('* ')?.filter((line) => line.trim() !== '');
  const InvoiceAddSuccessLines = messages.invoiceaddedOnFortnox?.split('* ')?.filter((line) => line.trim() !== '');
  const čustomerSuccessLines = messages.customeraddedOnFortnox?.split('* ')?.filter((line) => line.trim() !== '');
  const cancelSuccessLine = messages.invoiceCanceledOnFortnox?.split('* ')?.filter((line) => line.trim() !== '');
  const fileSuccessLines = messages.invoiceFileuploadedOnFortnox?.split('* ')?.filter((line) => line.trim() !== '');
  const TaxSuccessLines = messages.invoiceTaxReductioncreatedOnFortnox
    ?.split('* ')
    ?.filter((line) => line.trim() !== '');
  const invoiceUpSuccessLines = messages.invoiceUpdatedOnFortnox?.split('* ')?.filter((line) => line.trim() !== '');

  return (
    <>
      <div>
        <div style={{ position: 'relative', right: '70px' }}>
          {isError && (
            <Button
              onClick={() => {
                setshowSyncModal(false);
                setisError(null);
                setMessages({});
              }}
              sx={{ float: 'right', backgroundColor: '#62b5e514' }}
              autoFocus
            >
              <CloseIcon />
            </Button>
          )}
        </div>
        <div
          style={{
            // maxHeight: '300px',
            // overflowY: 'auto',
            paddingRight: '10px',
          }}
          className="errors_shown"
        >
          {/* All Errors Message Alert */}

          <div className="mt-1">
            {messages.customerUpdatedOnFortnoxError && (
              <Alert severity="error">
                <ul>
                  {updateErrorLines?.map((line, index) => {
                    return (
                      <li style={{ lineHeight: 2 }} className="errorLine" key={index}>
                        {line.trim()}
                      </li>
                    );
                  })}
                </ul>
              </Alert>
            )}
          </div>

          <div className="mt-1">
            {messages.customeraddedOnFortnoxError && (
              <Alert severity="error">
                <ul>
                  {addCuserrorLines?.map((line, index) => {
                    const idMatch = line.match(/CustomerId\s*:\s*(\d+)/);
                    const hasVAT = line.includes('VAT-nummer');

                    return (
                      <li style={{ lineHeight: 2 }} className="errorLine" key={index}>
                        {line.trim()}
                        {idMatch &&
                          (hasVAT ? (
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => handleButtonClick(idMatch[1])}
                              style={{ float: 'right', height: 30 }}
                            >
                              Send Email
                            </Button>
                          ) : null)}
                      </li>
                    );
                  })}
                </ul>
              </Alert>
            )}
          </div>

          <div className="mt-1">
            {messages.invoiceaddedOnFortnoxError && (
              <Alert severity="error">
                <ul>
                  {invoiceErrorLines?.map((line, index) => {
                    return (
                      <li style={{ lineHeight: 2 }} className="errorLine" key={index}>
                        {line.trim()}
                      </li>
                    );
                  })}
                </ul>
              </Alert>
            )}
          </div>

          <div className="mt-1">
            {messages.invoiceCanceledOnFortnoxError && (
              <Alert severity="error">
                <ul>
                  {invoiceCancelErrors?.map((line, index) => {
                    return (
                      <li style={{ lineHeight: 2 }} className="errorLine" key={index}>
                        {line.trim()}
                      </li>
                    );
                  })}
                </ul>
              </Alert>
            )}
          </div>

          <div className="mt-1">
            {messages.invoiceFileuploadedOnFortnoxError && (
              <Alert severity="error">
                <ul>
                  {invoicefileUploadErros?.map((line, index) => {
                    return (
                      <li style={{ lineHeight: 2 }} className="errorLine" key={index}>
                        {line.trim()}
                      </li>
                    );
                  })}
                </ul>
              </Alert>
            )}
          </div>

          <div className="mt-1">
            {messages.invoiceTaxReductioncreatedOnFortnoxError && (
              <Alert severity="error">
                <ul>
                  {taxReductionError?.map((line, index) => {
                    return (
                      <li style={{ lineHeight: 2 }} className="errorLine" key={index}>
                        {line.trim()}
                      </li>
                    );
                  })}
                </ul>
              </Alert>
            )}
          </div>

          <div className="mt-1">
            {messages.invoiceUpdatedOnFortnoxError && (
              <Alert severity="error">
                <ul>
                  {invoiceUpdateError?.map((line, index) => {
                    return (
                      <li style={{ lineHeight: 2 }} className="errorLine" key={index}>
                        {line.trim()}
                      </li>
                    );
                  })}
                </ul>
              </Alert>
            )}
          </div>

          {/* All Success Message Alert */}
          <div className="mt-1">
            {messages.customerUpdatedOnFortnox && (
              <Alert className="mb-2 mt-1" severity="success" sx={{ backgroundColor: '#ACE1AF', color: 'black' }}>
                <ul>
                  {customerAddSuccess?.map((line, index) => {
                    return (
                      <li style={{ lineHeight: 2 }} className="success_line" key={index}>
                        {line.trim()}
                      </li>
                    );
                  })}
                </ul>
              </Alert>
            )}
          </div>

          <div className="mt-1">
            {messages.customeraddedOnFortnox && (
              <Alert className="mb-2 mt-1" severity="success" sx={{ backgroundColor: '#ACE1AF', color: 'black' }}>
                <ul>
                  {čustomerSuccessLines?.map((line, index) => {
                    return (
                      <li style={{ lineHeight: 2 }} className="success_line" key={index}>
                        {line.trim()}
                      </li>
                    );
                  })}
                </ul>
              </Alert>
            )}
          </div>

          <div className="mt-1">
            {messages.invoiceaddedOnFortnox && (
              <Alert className="mb-2 mt-1" severity="success" sx={{ backgroundColor: '#ACE1AF', color: 'black' }}>
                <ul>
                  {InvoiceAddSuccessLines?.map((line, index) => {
                    return (
                      <li style={{ lineHeight: 2 }} className="success_line" key={index}>
                        {line.trim()}
                      </li>
                    );
                  })}
                </ul>
              </Alert>
            )}
          </div>

          <div className="mt-1">
            {messages.invoiceCanceledOnFortnox && (
              <Alert className="mb-2 mt-1" severity="success" sx={{ backgroundColor: '#ACE1AF', color: 'black' }}>
                <ul>
                  {cancelSuccessLine?.map((line, index) => {
                    return (
                      <li style={{ lineHeight: 2 }} className="success_line" key={index}>
                        {line.trim()}
                      </li>
                    );
                  })}
                </ul>
              </Alert>
            )}
          </div>

          <div className="mt-1">
            {messages.invoiceFileuploadedOnFortnox && (
              <Alert className="mb-2 mt-1" severity="success" sx={{ backgroundColor: '#ACE1AF', color: 'black' }}>
                <ul>
                  {fileSuccessLines?.map((line, index) => {
                    return (
                      <li style={{ lineHeight: 2 }} className="success_line" key={index}>
                        {line.trim()}
                      </li>
                    );
                  })}
                </ul>
              </Alert>
            )}
          </div>

          <div className="mt-1">
            {messages.invoiceTaxReductioncreatedOnFortnox && (
              <Alert className="mb-2 mt-1" severity="success" sx={{ backgroundColor: '#ACE1AF', color: 'black' }}>
                <ul>
                  {TaxSuccessLines?.map((line, index) => {
                    return (
                      <li style={{ lineHeight: 2 }} className="success_line" key={index}>
                        {line.trim()}
                      </li>
                    );
                  })}
                </ul>
              </Alert>
            )}
          </div>

          <div className="mt-1">
            {messages.invoiceUpdatedOnFortnox && (
              <Alert className="mb-2 mt-1" severity="success" sx={{ backgroundColor: '#ACE1AF', color: 'black' }}>
                <ul>
                  {invoiceUpSuccessLines?.map((line, index) => {
                    return (
                      <li style={{ lineHeight: 2 }} className="success_line" key={index}>
                        {line.trim()}
                      </li>
                    );
                  })}
                </ul>
              </Alert>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrosAlert;
