export const swe = {
  translations: {
    'Select Your Invoice Language': 'Välj ditt fakturaspråk',
    'Preliminary Invoice': 'Preliminär Faktura',
    Preliminaryinvoice: 'Preliminärfaktura',
    'Customer no': 'Kund nr',
    'Invoice No': 'Faktura nr',
    'Not created yet': 'Inte skapad än',
    'Your reference': 'Er referens',
    'Your VAT number': 'Ert VAT-nummer',
    'Our reference': 'Vår referens',
    'Payment terms': 'Betalningsvillkor',
    Days: 'Dagar',
    'Due date': 'Förfallodatum',
    'Invoice date': 'Fakturadatum',
    'Article No.': 'Artnr.',
    Name: 'Benämning',
    Quantity: 'Antal',
    Unit: 'Enhet',
    'Price per unit': 'Pris per enhet',
    Total: 'Totalt',
    'Fixed Price': 'Fast pris',
    'Excluding VAT': 'Exklusive moms',
    'VAT %': 'Moms %',
    VAT: 'Moms',
    'Round-off': 'Öresutj',
    Currency: 'Valuta',
    'ROT / RUT': 'ROT / RUT',
    'To pay': 'Att betala',
    'PLEASE NOTE THAT THIS IS A PRELIMINARY INVOICE': 'OBSERVERA ATT DETTA ÄR PRELIMINÄRFAKTURA',
    'Org. Number': 'Org. Antal',
    'Vat Number': 'Momsnummer',
    'Total working hours:': 'Totalt arbetade timmar:'
  },
};
