import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import axios from 'axios';
// material
import {
  Tab,
  Box,
  Card,
  Chip,
  Alert,
  Table,
  Modal,
  Stack,
  Select,
  Button,
  Dialog,
  Tooltip,
  Checkbox,
  TableRow,
  Snackbar,
  MenuItem,
  TextField,
  TableBody,
  TableCell,
  Container,
  InputLabel,
  Typography,
  IconButton,
  FormControl,
  ListItemText,
  OutlinedInput,
  DialogActions,
  DialogContent,
  TableContainer,
  TablePagination,
  DialogContentText,
  backdropClasses,
} from '@mui/material';

// components
import { fetchEmailTemplate } from '../../../redux/auth/authSlice';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import { emailTempDropoptions, getModalBoxStyle } from '../../../utils/commonFunc';
import Iconify from '../../../components/Iconify';
import SearchNotFound from '../../../components/SearchNotFound';
import { ListHead, ListToolbar } from '../../../sections/@dashboard/table';
import Loader from '../../../components/Loader';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'templateName', label: 'Template Name', alignRight: false },
  { id: 'templateSubject', label: 'Template Type', alignRight: false },
  { id: 'createdDate', label: 'Last Update', alignRight: false },
  { id: '', label: 'Actions' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const escapeRegExp = (value) => value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    const searchRegex = new RegExp(escapeRegExp(query), 'i');
    return filter(array, (_row) =>
      Object.keys(_row).some((field) => {
        if (_row[field]) return searchRegex.test(_row[field].toString());
        return false;
      })
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function EmailTemplates() {
  const [page, setPage] = useState(0);
  const [users, setUsers] = useState([]);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState('name');
  const [personName, setPersonName] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [alertStatus, setAlertStatus] = useState('success');
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [selectedTabValue, setSelectedTabValue] = useState('2');
  const [autoHideDuration, setAutoHideDuration] = useState(3000);
  const [isAllSelected, setAllSelected] = useState(false);
  const [show, setShow] = useState({
    EditModal: false,
    CreateTemplateModal: false,
    DeleteTemplateModal: false,
  });
  const [activateModal, setActivateModal] = useState({
    id: null,
    show: false,
    isActive: 'false',
  });
  const [searchDropValue, setSearchDropValue] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { emailTemplate, isLoading } = useSelector((data) => data.auth);
  const { reset, watch, setValue, register, handleSubmit } = useForm();

  useEffect(() => {
    register('html');
    dispatch(fetchEmailTemplate());
  }, []);

  useEffect(() => {
    if (emailTemplate.length > 0) {
      setEmailTemplates(emailTemplate);
      selectAllClick(isAllSelected, emailTemplate);
    }
  }, [emailTemplate]);

  const handleCreateNewTemplate = async (values) => {
    const templateReplyTo = users?.filter((user) => values.templateReplyTo.includes(user.email));
    try {
      setLoading(true);
      setShow({ EditModal: false, CreateTemplateModal: false });
      await axios.post(`/adminemailtemplate`, {
        ...values,
        templateActive: true,
        emailTemplateUserList: templateReplyTo?.map((item) => ({ userId: item.userId })),
      });
      dispatch(fetchEmailTemplate());
      setLoading(false);
      setAlertMessage('Template has been created successfully.');
      setAlertStatus('success');
      setAutoHideDuration(3000);
      setIsSnackBarOpen(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setAlertMessage('Found an error. Can not create templete');
      setAlertStatus('error');
      setAutoHideDuration(3000);
      setIsSnackBarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleEditEmailTemplate = async (values) => {
    const templateReplyTo = users?.filter((user) => values.templateReplyTo.includes(user.email));
    try {
      setLoading(true);
      setShow({ EditModal: false, CreateTemplateModal: false });
      await axios.put('/adminemailtemplate', {
        ...values,
        emailTemplateUserList: templateReplyTo?.map((item) => ({ userId: item.userId })),
      });
      setLoading(false);
      setAlertMessage('Template has been updated successfully.');
      setAlertStatus('success');
      setAutoHideDuration(3000);
      setIsSnackBarOpen(true);
      dispatch(fetchEmailTemplate());
    } catch (err) {
      console.log(err);
      setLoading(false);
      setAlertMessage('Found an error');
      setAlertStatus('error');
      setAutoHideDuration(3000);
      setIsSnackBarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteEmailTemplate = async (selectedList) => {
    try {
      setLoading(true);
      setShow({ DeleteTemplateModal: false });
      await axios.delete(`/adminemailtemplate`, { data: selectedList });
      dispatch(fetchEmailTemplate());
      setLoading(false);
      setSelected([]);
      setAlertMessage('Template has been deleted successfully.');
      setAlertStatus('success');
      setAutoHideDuration(3000);
      setIsSnackBarOpen(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setAlertMessage('Found an error');
      setAlertStatus('error');
      setAutoHideDuration(3000);
      setIsSnackBarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const getTemplateDetails = (id) => {
    const template = emailTemplates.find((emailTemplates) => emailTemplates.id === id);
    setShow({ EditModal: true });
    const userData = template.emailTemplateUserList;
    reset({ ...template, templateReplyTo: userData?.map((item) => item.signUpUser.email) });
    setPersonName(userData?.map((item) => item.signUpUser.email));
  };

  const handleEnableOrDisable = async (id, type) => {
    try {
      setLoading(true);
      await axios.put(`/adminemailtemplate/isActive/${id}?isActive=${type}`);
      setLoading(false);
      console.log(type);
      setAlertMessage(`${type === 'true' ? 'Enabled' : 'Disabled'} successfully.`);
      setAlertStatus('success');
      setActivateModal({ show: false });
      setAutoHideDuration(3000);
      setIsSnackBarOpen(true);
      dispatch(fetchEmailTemplate());
    } catch (err) {
      console.log(err);
      setLoading(false);
      setAlertMessage('Found an error');
      setAlertStatus('error');
      setActivateModal({ show: false });
      setAutoHideDuration(3000);
      setIsSnackBarOpen(true);
    } finally {
      setLoading(false);
      setActivateModal({ show: false });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const selectAllClick = (isSelected, emailTemplate) => {
    if (isSelected) {
      const newSelecteds = emailTemplate?.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleSelectAllClick = (event) => {
    setAllSelected(event.target.checked);
    selectAllClick(event.target.checked, emailTemplate);
  };

  // const handleSelectAllClick = (event) => {
  //     if (event.target.checked) {
  //         const newSelecteds = emailTemplates.map((n) => n.id);
  //         setSelected(newSelecteds);
  //         return;
  //     }
  //     setSelected([]);
  // };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSnackBarClose = () => {
    setIsSnackBarOpen(false);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - emailTemplates.length) : 0;

  const filteredRows = applySortFilter(emailTemplates, getComparator(order, orderBy), searchQuery);

  const isNotFound = filteredRows.length === 0;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      let URL;
      if (searchDropValue && searchQuery) {
        URL = `/admin/user?PageSize=500&PageNumber=1&searchQuery=${searchQuery}&SearchColumn=${searchDropValue}`;
      } else {
        URL = `/admin/user?PageSize=500&PageNumber=1&searchQuery=${searchQuery}`;
      }
      const res = await axios.get(URL);
      const users = res?.data?.response?.map((ele) => ({ ...ele, isEdit: false }));
      const filteredRows = applySortFilter(users, getComparator(order, orderBy), searchQuery);
      setUsers(filteredRows);
    } catch (err) {
      setUsers([]);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [searchQuery]);

  // const API_URL = process.env.REACT_APP_API_URL;
  const UPLOAD_ENDPOINT = '/image/uploadImage';
  function uploadAdapter(loader) {
    return {
      upload: () =>
        new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then(async (file) => {
            body.append('image', file);
            try {
              const res = await axios.post(UPLOAD_ENDPOINT, {
                body,
              });
              resolve({
                default: res.image_url,
              });
            } catch (error) {
              reject(error);
            }

            // fetch(`${API_URL}${UPLOAD_ENDPOINT}`, {
            //     method: "post",
            //     body,
            // })
            //     .then((res) => res.json())
            //     .then((res) => {
            //         resolve({
            //             default: res.image_url,
            //         });
            //     })
            //     .catch((err) => {
            //         reject(err);
            //     });
          });
        }),
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => uploadAdapter(loader);
  }

  const renderTemplates = () => (
    <>
      <ListToolbar
        hideEmailButton={'true'}
        numSelected={selected.length}
        searchQuery={searchQuery}
        onFilter={handleFilter}
        onDelete={() => setShow({ DeleteTemplateModal: true })}
        dropOptions={emailTempDropoptions}
        searchDropValue={searchDropValue}
        setSearchDropValue={setSearchDropValue}
      />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <ListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={emailTemplates.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                const { id, createdDate, templateName, templateSubject, templateActive } = row;
                const isItemSelected = selected.indexOf(id) !== -1;
                return (
                  <TableRow
                    hover
                    key={index}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                    </TableCell>
                    <TableCell align="left">{id}</TableCell>
                    <TableCell align="left">{templateName}</TableCell>
                    <TableCell align="left">{templateSubject}</TableCell>
                    <TableCell align="left">{createdDate?.split('T')[0]}</TableCell>
                    <TableCell align="left" style={{ width: '0px' }}>
                      <Tooltip arrow title={'Click to edit'}>
                        <IconButton
                          onClick={(event) => {
                            getTemplateDetails(id);
                            event.stopPropagation();
                          }}
                          style={{ color: 'rgb(255, 255, 255)', background: '#c34152' }}
                        >
                          <Iconify icon="material-symbols:edit" width={24} height={24} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="left">
                      <Tooltip arrow title={templateActive ? 'Click to disable' : 'Click to enable'}>
                        <Button
                          variant="contained"
                          color={templateActive ? 'primary' : 'error'}
                          onClick={() => {
                            setActivateModal({
                              id,
                              show: true,
                              isActive: templateActive ? 'true' : 'false',
                            });
                          }}
                          sx={{
                            backgroundColor: templateActive ? '#1976d2' : '#c34152', // Background color
                            color: '#f7f8fa', // Text color
                          }}
                          // onClick={() => handleEnableOrDisable(templateActive, id)}
                        >
                          {templateActive ? 'DISABLE' : 'ENABLE'}
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={searchQuery} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={emailTemplates.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Modal
        open={show.CreateTemplateModal || show.EditModal || false}
        onClose={() => setShow({ EditModal: false, CreateTemplateModal: false })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ width: '59%' }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {show.EditModal ? 'Edit Email Template' : 'Create New Template'}
          </Typography>
          <form
            onSubmit={handleSubmit((values) => {
              if (show.EditModal) {
                handleEditEmailTemplate(values);
              } else {
                handleCreateNewTemplate(values);
              }
            })}
          >
            <div className="container" style={{ overflow: 'auto', height: '450px', width: '105%' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', '& .MuiTextField-root': { width: '75ch' } }}>
                <TextField
                  label={'Template Name'}
                  {...register('templateName', { required: true })}
                  id="Template-Name"
                  margin="normal"
                />
                <TextField label={'Subject'} {...register('templateSubject')} id="Subject" margin="normal" />
                <TextField label={'Form Name'} {...register('templateFromName')} id="Form-Name" margin="normal" />
                <FormControl sx={{ mt: 2, mb: 2, width: 735 }}>
                  <InputLabel id="demo-multiple-checkbox-label">Reply To</InputLabel>
                  <Select
                    multiple
                    value={personName}
                    MenuProps={MenuProps}
                    onChange={handleChange}
                    id="demo-multiple-checkbox"
                    labelId="demo-multiple-checkbox-label"
                    input={<OutlinedInput label="Reply To" {...register('templateReplyTo')} />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected?.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {users?.map((items, index) => {
                      const { email } = items;
                      return (
                        <MenuItem key={index} value={email}>
                          <Checkbox checked={personName.indexOf(email) > -1} />
                          <ListItemText primary={email} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <CKEditor
                  editor={ClassicEditor}
                  data={show.EditModal ? watch('html') : '<p></p>' || '<p></p>'}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setValue('html', data);
                  }}
                  config={{
                    extraPlugins: [
                      uploadPlugin,
                      // Image,
                      // ImageResizeEditing,
                      // ImageResizeHandles /* ... */,
                    ],
                    mediaEmbed: {
                      previewsInData: true,
                    },
                    removePlugins: [
                      'Image',
                      'ImageEditing ',
                      'CKFinderEditing',
                      'CKFinder',
                      'ImageCaption',
                      'ImageStyle',
                      'ImageToolbar',
                      'ImageUpload',
                      'EasyImage',
                    ],
                  }}
                />
              </Box>
            </div>
            <div className="template-buttons">
              <Button variant="outlined" onClick={() => setShow({ EditModal: false, CreateTemplateModal: false })}>
                Cancel
              </Button>
              <Button style={{ marginLeft: '10px' }} variant="contained" type="submit">
                {show.EditModal ? 'Save' : 'Create'}
              </Button>
            </div>
          </form>
        </Box>
      </Modal>

      <Modal
        open={activateModal.show}
        onClose={() => setActivateModal({ show: false })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={getModalBoxStyle()}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to {activateModal.isActive === 'true' ? 'disable' : 'enable'} this template?
          </Typography>
          <div style={{ margin: '25px 0 0', float: 'right', gap: '10px', display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={() => setActivateModal({ show: false })}
              sx={{ float: 'right', backgroundColor: '#62b5e514' }}
              autoFocus
            >
              No
            </Button>
            <Button
              //    onClick={() => handleEnableOrDisable(templateActive, id)}
              onClick={() => {
                handleEnableOrDisable(activateModal.id, activateModal.isActive === 'true' ? 'false' : 'true');
              }}
              sx={{ float: 'right', backgroundColor: '#62b5e514' }}
              autoFocus
            >
              Yes
            </Button>
          </div>
        </Box>
      </Modal>

      <Dialog
        open={show.DeleteTemplateModal || false}
        onClose={() => setShow({ DeleteTemplateModal: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {selected.length > 1 ? 'these templates' : 'this template'}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShow({ DeleteTemplateModal: false })}>No</Button>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleDeleteEmailTemplate(selected);
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  const handleTabChange = (e) => {
    const value = e.target.innerText;
    setSearchQuery('');
    setPage(0);
    setSelected([]);
    const filteredRows = applySortFilter(emailTemplate, getComparator(order, orderBy), searchQuery);
    if (value === 'ALL') {
      setSelectedTabValue('1');
      setEmailTemplates(filteredRows);
    } else if (value === 'ENABLED') {
      setSelectedTabValue('2');
      setEmailTemplates(filteredRows.filter((f) => f.templateActive));
    } else if (value === 'DISABLED') {
      setSelectedTabValue('3');
      setEmailTemplates(filteredRows.filter((f) => !f.templateActive));
    }
  };

  return (
    <Page title="Email Template">
      {(loading || isLoading) && <Loader />}
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Stack direction="row" justifyContent="space-between">
            <IconButton onClick={() => navigate(-1)}>
              <Iconify icon="eva:arrow-back-fill" width={24} height={24} />
            </IconButton>
            <Typography variant="h4" sx={{ ml: 1 }} gutterBottom>
              Email Templates
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Tooltip arrow title={'Create new template'}>
              <Button
                style={{ marginLeft: '10px' }}
                variant="contained"
                onClick={() => {
                  setShow({ CreateTemplateModal: true });
                  reset({});
                  setPersonName([]);
                }}
              >
                CREATE TEMPLATE
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isSnackBarOpen}
          autoHideDuration={autoHideDuration}
          onClose={handleSnackBarClose}
        >
          <Alert onClose={handleSnackBarClose} severity={alertStatus} sx={{ width: '100%' }}>
            {alertMessage}
          </Alert>
        </Snackbar>
        <Card>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={selectedTabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={(e) => handleTabChange(e)} aria-label="lab API tabs example">
                  <Tab label="ENABLED" value="2" />
                  <Tab label="DISABLED" value="3" />
                  <Tab label="ALL" value="1" />
                </TabList>
              </Box>
              <TabPanel value="1">{renderTemplates()}</TabPanel>
              <TabPanel value="2">{renderTemplates()}</TabPanel>
              <TabPanel value="3">{renderTemplates()}</TabPanel>
            </TabContext>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
