import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    data: {},
    isError: false,
    isLoading: false,
    message: '',
};

export const fetchGlobalData = createAsyncThunk(
    'globalData/fetchGlobalData',
    async (data, thunkAPI) => {
        try {
            const res = await axios.get('/admin/invoice/invoice-global-data');
            if (!(res.data.statusCode === 200 || res.data.statusCode === 201)) {
                return thunkAPI.rejectWithValue(res.data.message);
            }
            return res.data.response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const globalDataSlice = createSlice({
    name: 'globalData',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.message = '';
        },
    },
    extraReducers: {
        [fetchGlobalData.pending]: (state) => {
            state.isError = false;
            state.message = '';
        },
        [fetchGlobalData.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        [fetchGlobalData.rejected]: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        },
    },
});

export default globalDataSlice.reducer;
