import ReactCalendar from "react-calendar";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

// helpers
export const dateAlreadyClicked = (dates, date) => {
  return dates?.some((d) => dayjs(date).isSame(dayjs(d.date), "day"));
};

const Calendar = ({ className, currentIndex, setDates, dates, onChange }) => {
  const onClickDay = (date) => {
    onChange();
    let allDates = [];
    Object.keys(dates).forEach((freelancerName, index) => {
      if (index === currentIndex) {
        allDates = dates[freelancerName];
      }
    });

    setDates((prevState) => {
      return {
        ...prevState,
        [currentIndex]: [
          ...prevState[currentIndex],
          {
            date,
            id: uuidv4(),
          },
        ],
      };
    });
  };

  const tileClassName = ({ date }) => {
    let allDates = [];
    Object.keys(dates).forEach((freelancerName, index) => {
      if (index === currentIndex) {
        allDates = dates[freelancerName];
      }
    });
    const classNames = ["calender-tile"];
    // give active days a special class
    if (dateAlreadyClicked(allDates, date))
      return ["calender-tile-active", ...classNames];
    return classNames;
  };

  return (
    <ReactCalendar
      className={className}
      tileClassName={tileClassName}
      onClickDay={onClickDay}
    />
  );
};

export default Calendar;