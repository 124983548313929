/* eslint-disable */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import { Checkbox, TablePagination, TableContainer, Tooltip, Button } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';

import { toast } from 'react-toastify';
import axios from 'axios';
import { ListHead } from '../../../../sections/@dashboard/table';
import { formatDate2 } from '../../../../utils/commonFunc';
import PaySlipModal from './PaySlipModal';
import { useNavigate } from 'react-router-dom';

export default function SalariesTable({
  tab,
  handleSalariesClick,
  setSalarySelected,
  salarySelected,
  setRowsPerPage,
  salariesData,
  setSalariesData,
  totalCount,
  rowsPerPage,
  selectedSalaryNestedTabValue,
  setLoading,
}) {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState('createdDate');
  const [order, setOrder] = useState('desc');

  const [salarySlipData, setSalarySlipData] = useState({});

  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const TABLE_HEAD = [
    { id: 'salaryId', label: 'Salary Id', alignRight: false },
    { id: 'userId', label: 'User Id', alignRight: false },
    { id: 'type', label: 'Type', alignRight: false },
    { id: 'invoiceId', label: 'Invoice  / Holiday Payout Id', alignRight: false },
    { id: 'paymentDate', label: 'Payment Date', alignRight: false },
    {
      id: 'employee',
      label: 'Employee',
      alignRight: false,
    },
    { id: 'grossSalary', label: 'Gross Salary / Holiday Amount', alignRight: false },
    { id: 'tax', label: 'Tax', alignRight: false },
    { id: 'taxFree', label: 'Tax Free', alignRight: false },
    { id: 'toPay', label: 'To Pay', alignRight: false },
    { id: 'sendEmail', label: 'Send email', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'downloadSlip', label: 'Action', alignRight: false },
  ];

  if (selectedSalaryNestedTabValue !== '1') {
    TABLE_HEAD.splice(-2, 1);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const filteredRows = applySortFilter(
      salariesData,
      getComparator(isAsc ? 'desc' : 'asc', orderBy),
      event.target.value,
      salariesData
    );
    setSalariesData(filteredRows);
  };

  const selectAllClick = (isSelected, filteredUsers) => {
    if (isSelected) {
      const newSelecteds = filteredUsers?.map((n) => n.salaryId);
      setSalarySelected(newSelecteds);
      return;
    }
    setSalarySelected([]);
  };

  const handleSelectAllClick = (event) => {
    selectAllClick(event.target.checked, salariesData);
  };

  const handleClick = (event, id) => {
    const selectedIndex = salarySelected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(salarySelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(salarySelected.slice(1));
    } else if (selectedIndex === salarySelected?.length - 1) {
      newSelected = newSelected.concat(salarySelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(salarySelected.slice(0, selectedIndex), salarySelected.slice(selectedIndex + 1));
    }
    setSalarySelected(newSelected);
  };
  /* eslint-disable no-magic-numbers */
  let label;
  if (tab === 2) label = 'Created';
  else if (tab === 3) {
    label = 'Sent';
  } else if (tab === 1 || !tab) {
    label = 'Paid';
  }

  const handleSendEmailClick = (event, salaryId) => {
    event.stopPropagation();

    setSalariesData((prevData) =>
      prevData.map((item) => (item.salaryId === salaryId ? { ...item, isSendEmail: !item.isSendEmail } : item))
    );
  };

  const handleDownloadSlip = async (event, id) => {
    event.stopPropagation();
    setLoading(true);
    try {
      const URL = `/admin/adminsalary/get-salary-id?id=${id}`;
      const result = await axios.get(URL);
      if (result?.status === 200) {
        setDownloadModalOpen(true);
        setLoading(false);
        setSalarySlipData(result?.data?.response);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <ListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={salariesData?.length}
            numSelected={salarySelected?.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
            hideCheckboxColumnHeader={!tab && 'true'}
          />
          <TableBody>
            {salariesData?.length ? (
              salariesData?.map((item) => {
                const isItemSelected = salarySelected.indexOf(item?.salaryId) !== -1;
                return (
                  <TableRow
                    hover
                    key={item?.salaryId}
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => handleSalariesClick(item?.salaryId)}
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                    role="checkbox"
                  >
                    {tab && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={(event) => handleClick(event, item?.salaryId)}
                          onClick={(event) => event.stopPropagation()}
                          size="small"
                        />
                      </TableCell>
                    )}
                    <TableCell>{item?.salaryId}</TableCell>
                    <TableCell>{item?.userId}</TableCell>
                    <TableCell>
                      {item?.salaryType === 1 ? 'Invoice' : item?.salaryType === 3 ? 'Manual Salary' : 'Holiday Payout'}
                    </TableCell>

                    <TableCell>
                      {item?.salaryType === 1 ? item?.invoiceId : item?.salaryType === 3 ? 0 : item?.holidayPayoutId}
                    </TableCell>

                    <TableCell> {formatDate2(item.paidDate)}</TableCell>
                    <TableCell>{item?.employeeName}</TableCell>
                    <TableCell>{item?.grossSalary}</TableCell>
                    <TableCell>{item?.tax}</TableCell>
                    <TableCell>{item?.totalTaxFreeArticlesAmount}</TableCell>
                    <TableCell>{item?.toPay}</TableCell>
                    {selectedSalaryNestedTabValue === '1' && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={item?.isSendEmail}
                          onChange={(event) => handleSendEmailClick(event, item.salaryId)}
                          onClick={(event) => event.stopPropagation()}
                          size="small"
                        />
                      </TableCell>
                    )}
                    <TableCell>{label}</TableCell>

                    <TableCell>
                      <div className="d-flex">
                        <Tooltip title="Download Pay Slip">
                          <Button
                            onClick={(event) => handleDownloadSlip(event, item?.salaryId)}
                            style={{
                              fontSize: '30px',
                              // padding: '8px',
                              // width: 80,
                            }}
                          >
                            <FaDownload style={{ fontSize: '18px' }} />
                          </Button>
                        </Tooltip>
                        <Tooltip title="View logs">
                          <Button
                            type="submit"
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/dashboard/view-logs?type=salary&id=${item?.salaryId}`, {
                                state: {
                                  type: 'name',
                                  name: item?.employeeName,
                                },
                              });
                            }}
                            style={{
                              width: 50,
                              minWidth: '50px',
                              fontSize: '12px',
                              padding: '6px',
                            }}
                          >
                            <HistoryIcon />
                          </Button>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow className="text-center">
                <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                  No record found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[100, 250, 500]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <PaySlipModal
        salarySlipData={salarySlipData}
        downloadModalOpen={downloadModalOpen}
        setDownloadModalOpen={setDownloadModalOpen}
        setLoading={setLoading}
      />
    </>
  );
}
