/* eslint-disable */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import Stepper from '../../../components/Stepper';

const InvoiceCreatedModal = ({
  t,
  setInvoiceId,
  setDates,
  setCalculatedAmounts,
  setCustomer,
  reset,
  navigate,
}) => {
  return (
    <Modal show={true} onHide={() => setInvoiceId(null)} style={{ marginTop: '100px' }}>
      <Modal.Header>
        <Modal.Title>{t('Your invoice has been created')}</Modal.Title>
        <AiOutlineClose className="ml-auto cursor-pointer" size={24} onClick={() => setInvoiceId(null)} />
      </Modal.Header>
      <Modal.Body className="bg-secondary-custom">
        <Stepper
          color="success"
          activeStep={1}
          steps={[
            { title: t('You have created an invoice') },
            { title: t('Invoice sent for review by client support') },
            {
              title: t('The invoice is sent to your customer and you receive a confirmation email'),
            },
          ]}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setDates({
              0: [],
              1: [],
              2: [],
              3: [],
              4: [],
              5: [],
              6: [],
              7: [],
              8: [],
              10: [],
              11: [],
              12: [],
              13: [],
              14: [],
              15: [],
            });
            setCalculatedAmounts({});
            setCustomer({});
            reset({
              allowance: 0,
              travel: 0,
              currency: 'SEK',
              vat: 25,
              showDates: 'true',
              amount: 0,
              selectedCustomer: [],
              description: '',
              email: '',
              secondaryEmail: '',
              invoiceHours: [],
            });
            setInvoiceId(null);
          }}
        >
          {t('Create another invoice')}
        </Button>
        <Button className="text-light" variant="primary" onClick={() => navigate('/dashboard/invoices')}>
          {t('Done')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InvoiceCreatedModal;