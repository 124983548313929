/* eslint-disable arrow-body-style */
import { filter } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TabContext, TabPanel } from '@mui/lab';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import axios from 'axios';
// material
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  TableHead,
  Tooltip,
  TextField,
} from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

// components
import { socialUsersDropOptions } from '../../utils/commonFunc';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { ListHead, ListToolbar } from '../../sections/@dashboard/table';
import Loader from '../../components/Loader';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'link', label: 'Link', alignRight: false },
  { id: 'usersCount', label: 'Users Count', alignRight: false },
  { id: 'userslist', label: 'Users List', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const escapeRegExp = (value) => value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    const searchRegex = new RegExp(escapeRegExp(query), 'i');
    return filter(array, (_row) =>
      Object.keys(_row).some((field) => {
        if (_row[field]) return searchRegex.test(_row[field].toString());
        return false;
      })
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function SocialUsers() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState('');
  const [socialUsers, setSocialUsers] = useState([]);
  const [selectedTabValue] = useState('2');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isOpenUsers, setIsOpenUsers] = useState(false);
  const navigate = useNavigate();
  const { isLoading } = useSelector((data) => data.auth);
  const [copied, setCopied] = useState('');
  const [generateLinkItem, setGenerateLinkItem] = useState('');
  const [isOpenLink, setIsOpenLink] = useState(false);
  const [genCopied, setGenCopied] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [generateLinkName, setGenerateLinkName] = useState({
    socialPlatformId: '',
    name: '',
  });
  const [isNameEmpty, setNameEmpty] = useState(false);
  const [isAllSelected, setAllSelected] = useState(false);
  const [searchDropValue, setSearchDropValue] = useState();

  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 800,
    width: '100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (event) => {
    setSearchQuery(event.target.value);
  };
  const generateLink = async () => {
    try {
      setLoading(true);
      const res = await axios.post('/adminsocialusers');
      const { link, socialPlatformId } = res?.data.response;
      setGenerateLinkItem(link);
      setGenerateLinkName((prev) => ({ ...prev, socialPlatformId }));
      setIsOpenLink(true);
    } catch (err) {
      // setUsers([]);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - socialUsers.length) : 0;

  const filteredRows = applySortFilter(socialUsers, getComparator(order, orderBy), searchQuery);

  const isNotFound = filteredRows.length === 0;

  const fetchLinks = async () => {
    try {
      setLoading(true);
      let URL;
      if (searchDropValue && searchQuery) {
        URL = `/adminsocialusers?PageSize=${rowsPerPage}&PageNumber=${
          page + 1
        }&searchQuery=${searchQuery}&SearchColumn=${searchDropValue}`;
      } else {
        URL = `/adminsocialusers?PageSize=${rowsPerPage}&PageNumber=${page + 1}&searchQuery=${searchQuery}`;
      }

      const res = await axios.get(URL);
      if (res?.status === 200) {
        setLoading(false);
        setSocialUsers(res?.data.response);
        selectAllClick(isAllSelected, res?.data.response);

        setTotalCount(res.data.totalCount);
      }
    } catch (err) {
      if (err?.response?.data?.message === 'Something went wrong please try again after sometime.') {
        fetchLinks();
        setLoading(true);
      } else {
        setLoading(false);
      }
      console.log(err);
    }
    // finally {
    //   setLoading(false);
    // }
  };

  useEffect(() => {
    fetchLinks();
  }, [page, rowsPerPage, searchQuery]);

  const selectAllClick = (isSelected, filteredRows) => {
    if (isSelected) {
      const newSelecteds = filteredRows?.map((n) => n.socialPlatformId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleSelectAllClick = (event) => {
    setAllSelected(event.target.checked);
    selectAllClick(event.target.checked, filteredRows);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleOpenModal = (id) => {
    setIsOpenUsers(true);
    const filteredUsers = socialUsers.find((u) => u.socialPlatformId === id);
    if (filteredUsers?.signUpUser.length > 0) {
      setSelectedUsers(filteredUsers?.signUpUser);
    }
  };

  const onCopy = useCallback(
    (id) => {
      setCopied(id);
      setTimeout(() => {
        setCopied('');
      }, 3000);
    },
    [copied]
  );

  const onGenCopy = useCallback(() => {
    setGenCopied(true);
    setTimeout(() => {
      setGenCopied(false);
    }, 3000);
  }, []);
  const handleDelete = async () => {
    await deleteLinks();
  };

  const deleteLinks = async () => {
    try {
      setLoading(true);
      await axios.delete('/adminsocialusers', { data: selected }).then((res) => {
        const isDeletedCount = res.data.response.isDeleted;
        if (isDeletedCount > 0) {
          toast(
            `${
              isDeletedCount === res.data.totalCount
                ? `Selected ${isDeletedCount > 1 ? 'links' : 'link'} has been deleted successfully.`
                : `${isDeletedCount} ${isDeletedCount > 1 ? 'links' : 'link'} out of ${
                    res.data.totalCount
                  } has been deleted successfully.`
            }`
          );
        } else {
          toast(`Selected ${isDeletedCount > 1 ? 'links' : 'link'} can't been deleted.`);
        }
        fetchLinks();
      });
    } catch (err) {
      toast(err?.response.data?.message);
    } finally {
      setLoading(false);
      setSelected([]);
    }
  };

  const handleSaveLink = async () => {
    try {
      if (generateLinkName.name === '') {
        setNameEmpty(true);
      } else {
        setNameEmpty(false);
        setLoading(true);
        const res = await axios.put('/adminsocialusers', { ...generateLinkName, signUpUser: [], count: 0 });
        toast.success(res?.data?.response);
        fetchLinks();
        setIsOpenLink(false);
      }
    } catch (error) {
      toast.error(error?.response.data?.message);
    } finally {
      setLoading(false);
    }
  };

  // Download user list pdf ---------------------------
  const downloadUserListAsPdf = () => {
    const element = document.getElementById('usersList-modal');
    if (element) {
      html2canvas(element).then((canvas) => {
        const imgWidth = 210;
        const pageHeight = 290;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        const doc = new jsPDF('p', 'mm'); // eslint-disable-line new-cap
        let position = 0;
        const pageData = canvas.toDataURL('image/jpeg', 1.0);
        const imgData = encodeURIComponent(pageData);
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          doc.setLineWidth(5);
          doc.setDrawColor(255, 255, 255);
          doc.rect(0, 0, 210, 295);
          heightLeft -= pageHeight;
        }
        doc.save('users-list.pdf');
      });
    }
  };

  const renderTemplates = () => {
    return (
      <>
        <ListToolbar
          hideEmailButton={'true'}
          numSelected={selected.length}
          searchQuery={searchQuery}
          onFilter={handleFilter}
          onDelete={handleDelete}
          dropOptions={socialUsersDropOptions}
          searchDropValue={searchDropValue}
          setSearchDropValue={setSearchDropValue}
        />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={socialUsers.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredRows?.map((row, index) => {
                  const { socialPlatformId, link, count, name } = row;
                  const isItemSelected = selected.indexOf(socialPlatformId) !== -1;
                  return (
                    <TableRow
                      hover
                      key={index}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, socialPlatformId)} />
                      </TableCell>
                      {name.length > 17 ? (
                        <Tooltip title={name} arrow placement="top">
                          <TableCell align="left" style={{ fontSize: '13px', padding: '6px' }}>
                            {name?.substring(0, 17).concat('...')}
                          </TableCell>
                        </Tooltip>
                      ) : (
                        <TableCell align="left" style={{ fontSize: '13px', padding: '6px' }}>
                          {name}
                        </TableCell>
                      )}
                      <Tooltip title={link} arrow placement="top">
                        <TableCell align="left" style={{ fontSize: '13px', padding: '6px' }}>
                          {link?.substring(0, 17).concat('...')}
                          <CopyToClipboard text={link} onCopy={() => onCopy(socialPlatformId)}>
                            <Iconify icon="bx:copy" className="ml-2 cursor-pointer" width={20} height={20} />
                          </CopyToClipboard>
                          {copied === socialPlatformId && <span className="text-success ml-2">Copied link</span>}
                        </TableCell>
                      </Tooltip>
                      <TableCell align="left">{count}</TableCell>
                      <TableCell align="left" style={{ padding: '6px' }}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleOpenModal(socialPlatformId);
                          }}
                          disabled={count === 0}
                          style={{
                            width: 50,
                            minWidth: '50px',
                            fontSize: '12px',
                          }}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={searchQuery} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <Modal
          open={isOpenUsers}
          onClose={() => setIsOpenUsers(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={boxStyle}>
            <Box sx={{ height: 'fit-content' }} id="usersList-modal">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Users List
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>User Id</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Created Date</TableCell>
                    <TableCell>Invoice Count</TableCell>
                    <TableCell>Total Invoice Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedUsers?.map((row, index) => {
                    const { userId, firstName, email, createdDate, invoiceCount, totalInvoiceAmount } = row;
                    return (
                      <TableRow key={index}>
                        <TableCell>{userId}</TableCell>
                        <TableCell>{firstName}</TableCell>
                        {email.length > 17 ? (
                          <Tooltip title={email} arrow placement="top">
                            <TableCell align="left" style={{ fontSize: '13px', padding: '6px' }}>
                              {email?.substring(0, 17).concat('...')}
                            </TableCell>
                          </Tooltip>
                        ) : (
                          <TableCell align="left" style={{ fontSize: '13px', padding: '6px' }}>
                            {email}
                          </TableCell>
                        )}
                        <TableCell>{createdDate?.split('T')[0]}</TableCell>
                        <TableCell>{invoiceCount}</TableCell>
                        <TableCell>{totalInvoiceAmount}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <Button variant="contained" onClick={() => setIsOpenUsers(false)} sx={{ mt: 1 }}>
              Close
            </Button>

            <PictureAsPdfIcon
              onClick={() => {
                downloadUserListAsPdf();
              }}
              sx={{ ml: 1, background: '#62b5e5', color: '#fff', mt: 7, cursor: 'pointer', marginTop: '0' }}
            />
          </Box>
        </Modal>

        <Modal
          style={{ maxWidth: '500px', margin: '0 auto' }}
          open={isOpenLink}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={boxStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Generate Your Link
            </Typography>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '100%' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                error={isNameEmpty}
                helperText={isNameEmpty ? 'This filed is rquired*' : ''}
                id="name"
                label="Enter Name.."
                variant="standard"
                onChange={(e) => {
                  setGenerateLinkName((prev) => ({ ...prev, name: e.target.value }));
                  setNameEmpty(false);
                }}
              />
            </Box>
            <p className="text-break mt-3">
              {generateLinkItem}
              <CopyToClipboard text={generateLinkItem} onCopy={onGenCopy}>
                <Button variant="outlined" color={genCopied ? 'success' : 'primary'} className="ml-2 p-1 mt-2">
                  <Iconify icon="bx:copy" className="cursor-pointer" width={16} height={16} />
                  <span className="ml-1">{genCopied ? 'Copied' : 'Copy'}</span>
                </Button>
              </CopyToClipboard>
            </p>
            <Button
              variant="contained"
              onClick={() => {
                setIsOpenLink(false);
                setGenerateLinkName({
                  id: '',
                  name: '',
                });
              }}
              className="mr-2"
            >
              Close
            </Button>
            <Button variant="contained" onClick={handleSaveLink}>
              Save
            </Button>
          </Box>
        </Modal>

        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    );
  };

  // const handleTabChange = (e) => {
  //     const value = e.target.innerText;
  //     setSearchQuery('');
  //     setPage(0);
  //     setSelected([]);
  // };

  return (
    <Page title="Email Template">
      {(loading || isLoading) && <Loader />}
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Stack direction="row" justifyContent="space-between">
            <IconButton onClick={() => navigate(-1)}>
              <Iconify icon="eva:arrow-back-fill" width={24} height={24} />
            </IconButton>
            <Typography variant="h4" sx={{ ml: 1 }} gutterBottom>
              Social Media Users
            </Typography>
          </Stack>
          <Button variant="contained" onClick={generateLink}>
            Generate link
          </Button>
        </Stack>
        <Card>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={selectedTabValue}>
              <TabPanel value="1">{renderTemplates()}</TabPanel>
              <TabPanel value="2">{renderTemplates()}</TabPanel>
              <TabPanel value="3">{renderTemplates()}</TabPanel>
            </TabContext>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
