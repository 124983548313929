/* eslint-disable */
import React, { Fragment, useEffect, useState, useRef } from 'react';
import Select, { components } from 'react-select';
import axios from 'axios';
import { Button, Modal, Popover, OverlayTrigger, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { MdGroupAdd, MdModeEditOutline } from 'react-icons/md';

import { Controller, useForm } from 'react-hook-form';
import { RxCross2 } from 'react-icons/rx';
import { debounce } from 'debounce';
import { AiOutlineClose, AiOutlineDelete, AiFillInfoCircle } from 'react-icons/ai';
import { BsPerson } from 'react-icons/bs';
import { BiRightArrowAlt } from 'react-icons/bi';
import { FaRegCheckCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import countries from 'countries-list';
import { useTranslation } from 'react-i18next';
import { getInvoiceData } from '../../../redux/auth/authSlice';
import ShowInvoiceDialog from '../../../components/ShowInvoiceDialog';
import EditCustomerDialog from '../../../components/common/EditCustomerDialog';
import Loader from '../../../components/Loader';
import invoiceImg from '../../../assets/img/abc.png';
import Calender from '../../../components/Calender';
import WorkingHoursRow from './WorkingHoursRow';
import OtherArticlesRow from './OtherArticlesRow';
import OtherArticlesOptionsUI from './OtherArticlesOptionsUI';
import {
  HourlyWages,
  costAgreement,
  costPerDay,
  costPerMonth,
  costPerWeek,
  otherCost,
} from '../../../utils/stringUtils';
import {
  otherArticlesTotalAmount,
  otherArticlesArray,
  formatDateStr,
  compareInvoicesHours,
} from '../../../utils/commonFunc';
import { Alert } from '@mui/material';
import HowCalculatedModal from './HowCalculatedModal';
import InvoiceCreatedModal from './InvoiceCreatedModal';

const InvoiceTool = () => {
  const { t } = useTranslation();

  // ---------------------------------useForm-------------------------------------

  const {
    reset,
    watch,
    trigger,
    control,
    setValue,
    register,
    setFocus,
    getValues,
    unregister,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      vat: 25,
      travel: 0,
      allowance: 0,
      currency: 'SEK',
      showDates: 'true',
      defaultAmount: [500],
      defaultHours: [8],
    },
  });

  const [customer, setCustomer] = useState({});
  const [article, setArticle] = useState({});
  const [articles, setArticles] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [callUse, setCallUse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [pensionFee, setPensionFee] = useState(5);
  const [holidaySavingFee, setHolidaySavingFee] = useState(12);
  const [invoiceId, setInvoiceId] = useState(null);
  const [totalHours, setTotalHours] = useState([0]);
  const [showModal, setShowModal] = useState(false);
  const [approvelDate, setApprovelDate] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [accountType, setAccountType] = useState(null);
  const [callUseEffect, setCallUseEffect] = useState(0);
  const [showInvoice, setShowInvoice] = useState(false);
  const [showRootRut, setShowRootRut] = useState(false);
  const [selectedList, setSelectedList] = useState([{}]);
  const [showReadMore, setShowReadMore] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showAgreement, setShowAgreement] = useState(false);
  const [getCalculations, setGetCalculations] = useState(false);
  const [getErrorMessages, setgetErrorMessages] = useState(null);
  const formattedText = getErrorMessages?.split(/,\s*\*\s*|\*\s*/);

  const [articleAmountHourlyWages, setArticleAmountHourlyWages] = useState(0);

  const [showExpressPayment, setShowExpressPayment] = useState(false);
  const [showWorkDatesNotification, setShowWorkDatesNotification] = useState(null);
  const [previousSelectedFreelancers, setPreviousSelectedFreelancers] = useState([]);
  const [isCollective, setCollective] = useState(false);
  const [isPensionDrop, setPensionDrop] = useState(false);
  const [isHolidaySavingDrop, setHolidaySavingDrop] = useState(false);

  const [pdfError, setPDFError] = useState(null);
  const [searchParams] = useSearchParams();
  const [otherValuesChanged, setOtherValuesChanged] = useState(false);

  const [show, setShow] = useState({
    data: {},
    PDFData: [],
    show: false,
    indexValue: 0,
    invoiceData: {},
    selectedUser: {},
    showVatDetails: false,
    showCalculations: false,
    showCurrencyDetails: false,
    showFreelancerSelect: false,
  });

  const [unitDateStr, setUnitDateStr] = useState({
    value: 0,
    dateStr: [],
    indexValue: null,
  });

  const [calculatedAmounts, setCalculatedAmounts] = useState({
    net: 0,
    tax: 0,
    amount: 0,
  });

  const [seperatedUserCalculations, setSeperatedUserCalculations] = useState([
    {
      net: 0,
      tax: 0,
      amount: 0,
    },
  ]);
  const [isSeparatelyShown, setIsSeparatelyShown] = useState(false);
  const [userInvoiceData, setUserInvoiceData] = useState([]);

  function getSeparatedUserCalculationsTotal(calculation) {
    return calculation.reduce((total, current) => {
      Object.keys(current || {}).forEach((key) => {
        if (!total[key]) {
          total[key] = 0;
        }
        total[key] += current[key] || 0;
      });
      return total;
    }, {});
  }

  const prefillingData = useSelector((state) => state?.auth);
  const invoiceCreatedUserId = useSelector((state) => state?.createdInvoiceUserData)?.data?.userId;

  const dispatch = useDispatch();

  const [showTooltipError, setShowTooltipError] = useState({
    nullPDF: false,
    nullUnit: false,
    LessAmount: false,
    LessAmountEUR: false,
    LessAmountUSD: false,
    LessAmountGBP: false,
    LessAmountCHF: false,
    LessAmountCAD: false,
    LessAmountAUS: false,
    LessAmountNOK: false,
    LessAmountDKK: false,
    LessAmountJPY: false,
    LessAmountCNY: false,
    NullAmount: false,
    invalidUnit: false,
    NullCustomer: false,
    negativeHours: false,
    inputZeroValue: false,
    inputGreater24Value: false,
    negativeAmount: false,
    noDateSelected: false,
    notOfFixedPrice: false,
    inputNegatieveValue: false,
    selectedFreelancerLimit: false,
    travelAllowanceLessValue: false,
    travelAllowanceGraterValue: false,
  });

  const [dates, setDates] = useState({
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: [],
    10: [],
    11: [],
    12: [],
    13: [],
    14: [],
    15: [],
  });

  const [otherArticlesData, setOtherArticlesData] = useState({
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: [],
    10: [],
    11: [],
    12: [],
    13: [],
    14: [],
    15: [],
  });

  const [removeClickedDate, setRemoveClickedDate] = useState({
    dateStr: 0,
    indexValue: 0,
  });

  const [selectedTab, setSelectedTab] = useState({
    index: 0,
    date: '',
    validateUnit: false,
    validateAmount: false,
    validatePdfFile: false,
    validateArticle: false,
    validateCalender: false,
  });

  // ---------------------------------Articles-------------------------------------
  // const pensionData = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]

  // -----------------------------------useSelector-----------------------------------

  const selectRef = useRef(null);
  const navigate = useNavigate();
  const { id: userID } = useParams();
  const renderAfterCalled = useRef(false);

  const [freelancers, setFreelancers] = useState([]);

  const globalData = useSelector((state) => state?.globalData?.data);
  const user = useSelector((s) => s?.auth?.user);

  const closeInvoiceTool = () => {
    setShowModal(false);
    dispatch(getInvoiceData({}));
    navigate('/dashboard/app');
  };

  // -----------------------------------register-----------------------------------

  const amountFieldRegister = register('amount');
  // -----------------------------------watch-----------------------------------

  const vat = watch('vat');
  const house = watch('house');
  const amount = watch('amount');
  const travel = watch('travel');
  const pension = watch('pension');
  const isFixed = watch('isFixed');
  const currency = watch('currency');
  const apartment = watch('apartment');
  const allowance = watch('allowance');
  const invoiceFile = watch('invoiceFile');
  const rutDeduction = watch('rutDeduction');
  const invoiceHours = watch('invoiceHours');
  const rootDeduction = watch('rootDeduction');
  const expressPayout = watch('expressPayout');
  const holidaySavings = watch('holidaySavings');
  const selectedFreelancers = watch('freelancer');
  const selectedCustomer = watch('selectedCustomer');
  const showDates = watch('showDates');

  const isOthCost = otherArticlesTotalAmount('oth', otherArticlesData);
  const isExpCost = otherArticlesTotalAmount('exp', otherArticlesData);
  const [initialInvoiceHours, setInitialInvoiceHours] = useState(invoiceHours);
  // ---------------------------------addNewCustomer-------------------------------------

  const addNewCustomer = async (values) => {
    if (
      values?.accountType != '1' &&
      (values?.isVATRegistred === 'true' || values?.isVATRegistred === true) &&
      !values?.vatNumber
    ) {
      return toast.error(t('Please enter your VAT number'));
    }
    let countryCode = '';
    Object.keys(countries.countries).forEach((code) => {
      if (countries.countries[code].name === values.country) {
        countryCode = code;
      }
      if (values.country === 'USA') {
        countryCode = 'US';
      }
    });
    let concatVatNumber;
    if (values?.accountType == '1') {
      values.vatNumber = '';
    } else if (values?.isVATRegistred === 'false' || values?.isVATRegistred === false) {
      values.vatNumber = '';
    } else if (values?.isVATRegistred === 'true' || values?.isVATRegistred === true) {
      concatVatNumber = countryCode + values.vatNumber;
      values.vatNumber = concatVatNumber;
    }
    try {
      setLoading(true);
      const res = await axios.post('/admin/user/add-customer', {
        countryCode,
        ...values,
        id: 0,
        createdBy: userID,
      });
      if (res.data.response) {
        setShowDialog(false);
        fetchCustomers();
        toast.success(t('Added new customer successfully'));
      } else {
        throw new Error(res.data.message);
      }
    } catch (err) {
      setCustomers([]);
      setLoading(false);
      if (err?.response) {
        toast.error(err?.response?.data?.message);
      } else {
        toast.error(err?.message);
      }
    }
  };
  // --------------------------------onEditCustomer--------------------------------------

  const onEditCustomer = async (values) => {
    if (
      values?.accountType != '1' &&
      (values?.isVATRegistred === 'true' || values?.isVATRegistred === true) &&
      !values?.vatNumber
    ) {
      return toast.error(t('Please enter your VAT number'));
    }

    let countryCode = '';
    const invoiceHours = watch('invoiceHours');
    Object.keys(countries.countries).forEach((code) => {
      if (countries.countries[code].name === values.country) {
        countryCode = code;
      }
      if (values.country === 'USA') {
        countryCode = 'US';
      }
    });
    let concatVatNumber;
    if (values?.accountType == '1') {
      values.vatNumber = '';
    } else if (values?.isVATRegistred === 'false' || values?.isVATRegistred === false) {
      values.vatNumber = '';
    } else if (values?.isVATRegistred === 'true' || values?.isVATRegistred === true) {
      concatVatNumber = countryCode + values.vatNumber;
      values.vatNumber = concatVatNumber;
    }

    try {
      setLoading(true);
      await axios.put('/admin/user', {
        id: customer?.customerId,
        CountryCode: countryCode,
        ...values,
      });
      setShow({
        ...show,
        show: false,
      });
      await fetchCustomers();
      setCustomer({
        customerId: customer?.customerId,
        ...values,
      });
      // reset({
      //   selectedCustomer: {
      //     ...customer,
      //     ...values,
      //   },
      // });
      // if (invoiceHours) {
      //   reset({
      //     invoiceHours: [...invoiceHours],
      //   });
      // }
      toast.success(t('Customer updated successfully'));
      setLoading(false);
    } catch (err) {
      toast.error(err?.response?.data?.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // -----------------------------------fetchCustomers-----------------------------------

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/admin/user/user/${userID}`);
      setCustomers(res.data.response.customers);
      setFreelancers(res.data.response.freelancers);
      setUserDetails(res.data.response.user);
    } catch (err) {
      setCustomers([]);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // -----------------------------------fetchArticles-----------------------------------

  const fetchArticles = async () => {
    try {
      setLoading(true);
      const res = await axios.get('/admin/article');
      const filtredArticles = res?.data.filter(
        (article) =>
          article?.articleNumber === HourlyWages ||
          article?.articleNumber === costPerDay ||
          article?.articleNumber === costPerWeek ||
          article?.articleNumber === costPerMonth ||
          article?.articleNumber === costAgreement
      );
      const cloneArticles = [...filtredArticles];
      const indexToRemove = cloneArticles.findIndex((item) => item?.articleNumber === HourlyWages);
      if (indexToRemove !== -1) {
        cloneArticles.splice(indexToRemove, 1); // Remove the element from its current position
        cloneArticles.unshift(filtredArticles[indexToRemove]); // Add the element to the first position
      }
      const customOrder = [HourlyWages, costPerDay, costPerWeek, costPerMonth, costAgreement];

      const customSort = (a, b) => {
        const indexA = customOrder.indexOf(a?.articleNumber);
        const indexB = customOrder.indexOf(b?.articleNumber);
        return indexA - indexB;
      };

      const sortedArticles = cloneArticles.sort(customSort);

      setArticles(sortedArticles);
      setLoading(false);
    } catch (err) {
      setArticles([]);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // --------------------------------fetchCustomers--------------------------------------

  function isPensionApplicable(ssn) {
    return typeof ssn === 'string' && ssn.length === 12 && !ssn.endsWith('000');
  }

  useEffect(() => {
    const func = async () => {
      try {
        setLoading(true);
        setShow((prevState) => ({ ...prevState, showVatDetails: false }));
        await fetchCustomers();
        await fetchArticles();
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    func();
  }, [reset]);

  // -----------------------------------Dispatch-----------------------------------

  // useEffect(() => {
  //   dispatch(getAllFreelancers());
  //   // eslint-disable-next-line
  // }, [updateFreelancesrList])

  // ------isFixed checkbox
  useEffect(() => {
    if (rootDeduction || rutDeduction) {
      setValue('isFixed', false);
    }
  }, [rootDeduction, rutDeduction]);

  // useEffect(() => {
  //   if (expressPayout) {
  //     setShowExpressPayment(true);
  //   } else {
  //     setShowExpressPayment(false);
  //   }
  // }, [expressPayout]);

  // -----------------------------------invoicecalculations-----------------------------------
  const anyArrayHasValue = Object.values(otherArticlesData)?.some((array) => array.length > 0);
  const hasWorkingHours = Object.values(dates)?.some((array) => array.length > 0);

  useEffect(() => {
    const fetchInvoiceCalculations = async () => {
      const invoiceHours = watch('invoiceHours');

      try {
        if (invoiceHours || anyArrayHasValue) {
          setSpinner(true);

          const calculations = await Promise.all(
            invoiceHours?.map(async (userInvoiceHours, userIndex) => {
              const userOtherArticles = otherArticlesData[userIndex] || [];
              const totalAmount = Object.values(userInvoiceHours).reduce(
                (total, entry) => total + entry.units * entry.amount,
                0
              );

              const userArticleHourlyAmount = Object.values(userInvoiceHours).reduce(
                (total, entry) => (entry.article.articleNumber === '92' ? total + entry.units * entry.amount : total),
                0
              );

              const otherCostAmount = userOtherArticles
                .filter((article) => article.key === 'oth')
                .reduce((total, article) => total + parseFloat(article.value), 0);

              const expCostAmount = userOtherArticles
                .filter((article) => article.key === 'exp')
                .reduce((total, article) => total + parseFloat(article.value), 0);
              const filteredInvoiceHours = invoiceHours.filter((item) => { return item !== undefined || item !== null })
              const payload = {
                vat: vat,
                travel: 0,
                allowance: 0,
                pension: pension,
                amount:
                  invoiceHours || anyArrayHasValue ? parseFloat(totalAmount + otherCostAmount + expCostAmount) : 0,
                rutDeduction: rutDeduction,
                rootDeduction: rootDeduction,
                expressPayout: expressPayout,
                holidaySavings: holidaySavings,
                pensionFee: pension ? pensionFee : 0,
                tax: parseFloat(globalData.tax.globalValue),
                fee: parseFloat(globalData.fee.globalValue),
                forpAmount: expCostAmount ? expCostAmount : 0,
                hourlyWagesAmount: userArticleHourlyAmount ? userArticleHourlyAmount : 0,
                rutTaxValue: parseFloat(globalData.rutTax.globalValue),
                socialFee: parseFloat(globalData.socialFee.globalValue),
                rootTaxValue: parseFloat(globalData.rootTax.globalValue),
                expressFee: expressPayout ? Number(globalData.expressFee.globalValue) : 0,
                holidaySavingsFee: holidaySavings ? holidaySavingFee : 0,
                currencyRate: globalData.currencies.find((item) => item.description === currency).globalValue,
                currencyName: currency,
                otherCostAmount: otherCostAmount ? otherCostAmount : 0,
                userId: Number(userID),
                invoiceType: customer?.customerAddress ? customer?.customerAddress?.[0]?.invoiceType : customer?.invoiceType,
                numberOfFreelancers: filteredInvoiceHours?.length
              };

              const res = await axios.post('/admin/invoice/invoicecalculations', payload);
              return res?.data.response;
            })
          );
          setSpinner(false);

          setSeperatedUserCalculations(calculations);
          const totalCalculation = getSeparatedUserCalculationsTotal(calculations);
          setCalculatedAmounts(totalCalculation);
        }
      } catch (err) {
        console.error(err);
        setSpinner(false);
      } finally {
        setSpinner(false);
      }
    };

    if (amount && globalData?.tax?.globalValue && globalData?.fee?.globalValue) {
      fetchInvoiceCalculations();
    }

    // eslint-disable-next-line
  }, [getCalculations, anyArrayHasValue, isSeparatelyShown, customers]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setGetCalculations(!getCalculations);
    }, 800);
    return () => clearTimeout(timeoutId);
  }, [
    globalData,
    amount,
    callUse,
    pension,
    currency,
    callUseEffect,
    rutDeduction,
    rootDeduction,
    holidaySavings,
    article,
    pensionFee,
    vat,
    allowance,
    travel,
    isExpCost,
    isOthCost,
    expressPayout,
    holidaySavingFee,
  ]);

  // -----------------------------------TotalHours-----------------------------------

  useEffect(() => {
    const invoiceHours = watch('invoiceHours');
    const totalWorkingHours = [];
    if (Array.isArray(invoiceHours) && invoiceHours[0] !== null) {
      invoiceHours?.forEach((allIndex, index) => {
        let total = 0;
        Object.keys(allIndex)?.forEach((date) => {
          const splitDate = date?.split('-');
          const fullDate = `${splitDate[0]}-${splitDate[1]}-${splitDate[2]}`;
          if (new Date(fullDate).getTime() > new Date().getTime()) {
            if (showWorkDatesNotification === null) {
              setApprovelDate({ indexValue: index, dateStr: fullDate });
              if (searchParams?.size !== 1) {
                setShowWorkDatesNotification(true);
              }
            }
          }
          total += Number(allIndex[date]?.article?.articleNumber === HourlyWages ? allIndex[date].units : 0);
        });
        totalWorkingHours[index] = total;
      });
    } else {
      setTotalHours([]);
    }
    setTotalHours(totalWorkingHours);
  }, [dates, showWorkDatesNotification, invoiceHours, amount, article, !isFixed, unitDateStr.value]);

  // ----------------------------------Invoice data------------------------------------
  // prefill invoice-tool functionality
  useEffect(() => {
    if (
      prefillingData?.getInvoiceData &&
      Object.keys(prefillingData?.getInvoiceData)?.length > 0 &&
      searchParams?.size === 1
    ) {
      const {
        invoiceHours,
        description,
        selectedCustomer,
        freelancer,
        showDates,
        file,
        isFixed,
        vat,
        amount,
        otherArticles,
      } = prefillingData?.getInvoiceData;

      setCustomer({
        ...selectedCustomer,
        customerId: selectedCustomer?.customerId,
      });
      const selectedFreelancers = freelancer?.map((item) => ({
        ...item,
        value: item.userId,
      }));

      reset({
        vat,
        amount,
        isFixed,
        description,
        selectedCustomer,
        invoiceFile: file,
        freelancer: selectedFreelancers,
        showDates: showDates && JSON.stringify(JSON.parse(showDates)),
        invoiceHours: invoiceHours && JSON.parse(JSON.stringify(invoiceHours)),
        rootDeduction: prefillingData?.getInvoiceData?.fetchInvoices?.rootDeduction,
        house: prefillingData?.getInvoiceData?.fetchInvoices?.house,
        apartment: prefillingData?.getInvoiceData?.fetchInvoices?.apartment,
        rotApartmentNo: prefillingData?.getInvoiceData?.fetchInvoices?.rotApartmentNo,
        brfNo: prefillingData?.getInvoiceData?.fetchInvoices?.brfNo,
        rutDeduction: prefillingData?.getInvoiceData?.fetchInvoices?.rutDeduction,
        pension: prefillingData?.getInvoiceData?.fetchInvoices?.pension,
        holidaySavings: prefillingData?.getInvoiceData?.fetchInvoices?.holidaySavings,
        expressPayout: prefillingData?.getInvoiceData?.fetchInvoices?.expressPayout,
        currency: prefillingData?.getInvoiceData?.fetchInvoices?.currency,

      });
      setPensionFee(prefillingData?.getInvoiceData?.fetchInvoices?.pensionFee);
      setHolidaySavingFee(prefillingData?.getInvoiceData?.fetchInvoices?.holidaySavingsFee);

      let allSelectedDates = {};

      if (Array.isArray(invoiceHours) && invoiceHours[0] !== undefined) {
        invoiceHours?.forEach((item, index) => {
          Object.keys(item)?.forEach((date) => {
            const splitDate = date?.split('-');
            const fullDate = `${splitDate[0]}-${splitDate[1]}-${splitDate[2]}`;
            const uuid = splitDate?.slice(3).join('-');
            if (uuid) {
              const newDate = new Date(fullDate);
              const previousDates = allSelectedDates[index];
              allSelectedDates = {
                ...allSelectedDates,
                [index]: previousDates
                  ? [
                    ...previousDates,
                    {
                      date: newDate,
                      id: uuid,
                    },
                  ]
                  : [
                    {
                      date: newDate,
                      id: uuid,
                    },
                  ],
              };
            } else {
              const newDate = new Date(date);
              const previousDates = allSelectedDates[index];
              allSelectedDates = {
                ...allSelectedDates,
                [index]: previousDates
                  ? [
                    ...previousDates,
                    {
                      date: newDate,
                    },
                  ]
                  : [
                    {
                      date: newDate,
                    },
                  ],
              };
            }
          });
        });
      }
      setInitialInvoiceHours(JSON.parse(JSON.stringify(invoiceHours)));
      setPreviousSelectedFreelancers(selectedFreelancers);

      const selectedList = [];
      if (freelancer?.length > 0) {
        selectedList.push(...freelancer);
      }

      if (selectedList.length > 0) {
        const lastFreelancer = selectedList.pop();
        selectedList.unshift(lastFreelancer);
        setSelectedList(selectedList);
      }
      setDates((prevState) => ({ ...prevState, ...allSelectedDates }));
      setShow((prevState) => ({ ...prevState, showFreelancerSelect: true }));

      setOtherArticlesData((prevData) => {
        const updatedData = { ...prevData };
        otherArticles?.forEach((item) => {
          let indexToUpdate = -1; // Initialize indexToUpdate outside the if-else block

          // Check if the item belongs to the current user
          if (item.userId === invoiceCreatedUserId) {
            indexToUpdate = 0; // Update index to 0 if the user is the owner
          } else {
            // Find the index of the selected freelancer
            const freelancerIndex = selectedFreelancers?.findIndex((freelancer) => freelancer?.userId === item?.userId);
            // If the freelancer is found, update the index
            if (freelancerIndex !== -1) {
              // Add 1 to the index to account for the owner's index at 0
              indexToUpdate = freelancerIndex + 1;
            }
          }

          // Make sure the index is within the valid range
          if (indexToUpdate !== -1 && indexToUpdate <= 15) {
            // Check if the item already exists in the array
            const existingIndex = updatedData[indexToUpdate]?.findIndex(
              (existingItem) =>
                existingItem.value === item.value &&
                existingItem.userId === item.userId &&
                existingItem.key === item.key
            );
            // If the item does not exist, push it into the array
            if (existingIndex === -1) {
              if (!updatedData[indexToUpdate]) {
                updatedData[indexToUpdate] = []; // Initialize array if it doesn't exist
              }
              updatedData[indexToUpdate].push({
                value: item.value,
                userId: item.userId,
                key: item.key,
              });
            }
          }
        });
        return updatedData;
      });
    } else {
      selectRef.current.focus();
    }

    // return () => {
    //   dispatch(getInvoiceData({}));
    // };
  }, [searchParams?.size === 1]);
  // ----------------------------------createInvoice------------------------------------

  const handleScroll = () => {
    window.scrollTo(0, 50);
  };

  useEffect(() => {
    handleScroll();
  }, [getErrorMessages]);

  const createInvoice = async (values) => {
    const { userId } = userDetails;
    let readers = [];
    const fileItems = [];
    const totalWorkingDates = [];
    const amountPerFreelancers = [];
    let freelancersPerDate = [];

    // Uploaded pdf File
    values?.invoiceFile?.forEach((item) => {
      if (item instanceof FileList) {
        Array.from(item)?.forEach((file) => {
          fileItems?.push(file);
          const reader = new FileReader();
          const fileReadPromise = new Promise((resolve, reject) => {
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
          });
          reader.readAsDataURL(file);
          readers.push(fileReadPromise);
        });
      }
    });

    values?.invoiceFile?.forEach((item) => {
      if (item?.fileContent) {
        fileItems.push({
          fileName: item?.fileName,
          fileContent: item?.fileContent,
          filesUploadid: item?.filesUploadid,
          invoiceApprovalId: item?.invoiceApprovalId,
          userId:
            values?.freelancer && values?.freelancer[index - 1] && index !== 0
              ? values?.freelancer[index - 1]?.userId
              : user?.userId || user?.userId,
        });
      }
    });
    const fileBytes = await Promise.all(readers);
    let file = [];
    let indexValue = 0;
    fileItems.forEach((item, index) => {
      // For Existing File
      if (item?.fileContent) {
        file.push({
          fileName: item?.fileName,
          fileContent: item?.fileContent,
          filesUploadid: item?.filesUploadid,
          invoiceApprovalId: item?.invoiceApprovalId,
          userId:
            values?.freelancer && values?.freelancer[index - 1] && index !== 0
              ? values?.freelancer[index - 1]?.userId
              : user?.userId || user?.userId,
        });
      } else {
        file.push({
          fileName: item?.name,
          fileContent: fileBytes[indexValue].split(',')[1],
          userId:
            values?.freelancer && values?.freelancer[index - 1] && index !== 0
              ? values?.freelancer[index - 1]?.userId
              : user?.userId || user?.userId,
        });
        indexValue++;
      }
    });

    if (values.invoiceHours.length > 1) {
      values.invoiceHours.forEach((items, invoiceHoursIndex) => {
        Object.keys(items).forEach((date) => {
          freelancersPerDate.push(items[date].amount * items[date].units);
        });
        if (Object.keys(items).length === freelancersPerDate.length) {
          let total = 0;
          freelancersPerDate.forEach((items) => {
            total += items;
          });
          if (anyArrayHasValue) {
            total += isOthCost || 0;
            total += isExpCost || 0;
          }
          amountPerFreelancers.push(total);
          freelancersPerDate = [];
        }
      });
    }

    values.invoiceHours.forEach((items, index) => {
      Object.keys(items).forEach((date) => {
        const [year, month, day] = date?.split('-');
        totalWorkingDates.push({
          id: 0,
          workingDate: `${year}-${month}-${day}`,
          dateId: date,
          number: items[date].units,
          unit: items[date].article.unit,
          amountPerUnit: items[date].amount,
          articleNumber: items[date].article.articleNumber,
          workingDescription: items[date].workingDescription || '',
          houseworkType: items[date].houseworkType ? items[date].houseworkType.value : '',
          userId:
            values?.freelancer && values?.freelancer[index - 1] && index !== 0
              ? values?.freelancer[index - 1]?.userId
              : parseInt(userId, 10) || userId,
          workingHour: items[date].article.articleNumber === HourlyWages ? items[date].units : 0,
          // workingHour: (items[date].article.articleNumber === HourlyWages && Number(items[date].units) > 13) ? 13 : (items[date].article.articleNumber === HourlyWages) ? items[date].units : 0,
        });
      });
    });

    if (anyArrayHasValue) {
      Object.entries(otherArticlesData).forEach(([key, value], index) => {
        // Check if the array has values
        if (value?.length > 0) {
          value?.map((item) =>
            totalWorkingDates.push({
              id: 0,
              number: 1,
              unit: otherArticlesArray(item, 'unit'), // get from article
              amountPerUnit: item?.value,
              articleNumber: otherArticlesArray(item, 'articleNo'),
              workingDescription: otherArticlesArray(item, 'desc'),
              houseworkType: '',
              workingHour: 0,
              userId:
                values?.freelancer && values?.freelancer[index - 1] && index !== 0
                  ? values?.freelancer[index - 1]?.userId
                  : parseInt(userId, 10) || userId,
            })
          );
        }
      });
    }

    let invoiceFreelancer;

    if (!values?.isFixed) {
      if (values.freelancer && values.freelancer?.length) {
        invoiceFreelancer = values.freelancer?.map((item) => ({
          amount: 0,
          userId: item.userId,
        }));
        // If logged-in user is not included
        const isInvoiceCreatorIncluded = values.freelancer?.some((freelancer) => freelancer.userId === userID);
        if (!isInvoiceCreatorIncluded) {
          invoiceFreelancer.push({
            amount: 0,
            userId: Number(userID),
          });
        }
      } else {
        invoiceFreelancer = [
          {
            amount: 0,
            userId: Number(userID),
          },
        ];
      }
    } else {
      if (values.freelancer && values.freelancer?.length) {
        invoiceFreelancer = values.freelancer?.map((item, index) => ({
          amount: amountPerFreelancers[index + 1] || 0,
          userId: item.userId,
        }));
        // If logged-in user is not included
        const isInvoiceCreatorIncluded = values.freelancer?.some((freelancer) => freelancer.userId === userID);
        if (!isInvoiceCreatorIncluded) {
          invoiceFreelancer.push({
            amount: amountPerFreelancers[0],
            userId: Number(userID),
          });
        }
      } else {
        invoiceFreelancer = [
          {
            amount: Number(values?.amount),
            userId: Number(userID),
          },
        ];
      }
    }

    try {
      setLoading(true);
      const res = await axios.post('/admin/invoice', {
        invoiceId: 0,
        sendInvoice: 1,
        vat: values.vat,
        pension,
        amount: parseFloat(values.amount),
        isFixed: values.isFixed,
        currency: values.currency,
        brfNo: values.brfNo || '',
        registerAssignment: false,
        showDates: values.showDates,
        house: values.house || false,
        holidaySavings,
        description: values.description,
        taxValue: calculatedAmounts.tax,
        totalToPay: calculatedAmounts.net,
        rutDeduction: values.rutDeduction,
        rootDeduction: values.rootDeduction,
        file: file.length > 0 ? file : null,
        expressPayout: values.expressPayout,
        workingHours: [...totalWorkingDates],
        email: values?.selectedCustomer?.email,
        apartment: values.apartment || false,
        pensionFee: pension ? pensionFee : 0,
        holidaySavingsFee: holidaySavings ? holidaySavingFee : 0,
        tax: parseInt(globalData.tax.globalValue, 10),
        rotApartmentNo: values.rotApartmentNo || '',
        pensionAmount: calculatedAmounts?.pension || 0,
        customerId: values.selectedCustomer.customerId,
        forpAmount: isExpCost ? isExpCost : 0,
        holidaySavingsAmount: calculatedAmounts?.holidayPayAmount,
        amountIncVat: Math.round(calculatedAmounts?.amountIncludingVat),
        secondaryEmailAddress: values.selectedCustomer.secondaryEmailAddress,
        multipleFreelancer: values?.freelancer && values?.freelancer.length > 0 ? true : false,
        currencyRate: globalData.currencies.find((item) => item.description === values.currency).globalValue,
        invoiceFreelancer,
        createdByAdmin: true,
        createdBy: userID,
      });

      setInvoiceId(res.data.response.id);

      toast.success(t('Invoice created successfully'));
      setShowSubmit(false);
      setLoading(false);
    } catch (err) {
      handleScroll();
      setgetErrorMessages(err?.response?.data?.message);
      setShowSubmit(false);
      setLoading(false);

      // toast.error(err.message);
    } finally {
      setShowSubmit(false);
      setLoading(false);
    }
  };

  // ----------------------------------updateInvoice------------------------------------

  const updateInvoices = async (values) => {
    let readers = [];
    const fileItems = [];
    const totalWorkingDates = [];
    const amountPerFreelancers = [];
    let freelancersPerDate = [];

    // Uploaded pdf File
    values?.invoiceFile?.forEach((item) => {
      if (item instanceof FileList) {
        Array.from(item)?.forEach((file) => {
          fileItems?.push(file);
          const reader = new FileReader();
          const fileReadPromise = new Promise((resolve, reject) => {
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
          });
          reader.readAsDataURL(file);
          readers.push(fileReadPromise);
        });
      }
    });

    values?.invoiceFile?.forEach((item) => {
      if (item?.fileContent) {
        fileItems.push({
          fileName: item?.fileName,
          fileContent: item?.fileContent,
          filesUploadid: item?.filesUploadid,
          invoiceApprovalId: item?.invoiceApprovalId,
          userId: item?.userId,
        });
      }
    });
    const fileBytes = await Promise.all(readers);
    let file = [];
    let indexValue = 0;
    fileItems.forEach((item, index) => {
      // For Existing File
      if (item?.fileContent) {
        file.push({
          fileName: item?.fileName,
          fileContent: item?.fileContent,
          filesUploadid: item?.filesUploadid,
          invoiceApprovalId: item?.invoiceApprovalId,
          userId:
            values?.freelancer && values?.freelancer[index - 1] && index !== 0
              ? values?.freelancer[index - 1]?.userId
              : user?.userId || user?.userId,
        });
      } else {
        file.push({
          fileName: item?.name,
          fileContent: fileBytes[indexValue].split(',')[1],
          userId:
            values?.freelancer && values?.freelancer[index - 1] && index !== 0
              ? values?.freelancer[index - 1]?.userId
              : user?.userId || user?.userId,
        });
        indexValue++;
      }
    });

    if (values?.invoiceHours?.length > 1) {
      values?.invoiceHours?.forEach((items, invoiceHoursIndex) => {
        Object.keys(items)?.forEach((date) => {
          freelancersPerDate.push(items[date].amount * items[date].units);
        });
        if (Object.keys(items).length === freelancersPerDate.length) {
          let total = 0;
          freelancersPerDate?.forEach((items) => {
            total += items;
          });
          amountPerFreelancers.push(total);
          freelancersPerDate = [];
        }
      });
    }

    const loginedUserIndex = values?.freelancer?.findIndex((item) => item?.userId === invoiceCreatedUserId);
    let adjustedList;
    if (loginedUserIndex !== -1) {
      adjustedList = [...values?.freelancer]; // Create a copy of the original list

      // shift logged-in userID at at beginning
      const removedItem = adjustedList.splice(loginedUserIndex, 1)[0];
      adjustedList.unshift(removedItem);
    } else {
      adjustedList = [...values?.freelancer];
      const userObject = { userId: invoiceCreatedUserId }; // logged in userID
      adjustedList.unshift(userObject);
    }

    values?.invoiceHours?.forEach((items, index) => {
      Object.keys(items)?.forEach((date) => {
        const [year, month, day] = date?.split('-');
        totalWorkingDates.push({
          id: 0,
          workingDate: `${year}-${month}-${day}`,
          dateId: date,
          number: items[date].units,
          unit: items[date].article.unit,
          amountPerUnit: items[date].amount,
          articleNumber: items[date].article.articleNumber,
          workingDescription: items[date].workingDescription || '',
          houseworkType: items[date].houseworkType ? items[date].houseworkType.value : '',
          userId: adjustedList[index]?.userId,
          workingHour: items[date].article.articleNumber === HourlyWages ? items[date].units : 0,
          // workingHour: (items[date].article.articleNumber === HourlyWages && Number(items[date].units) > 13) ? 13 : (items[date].article.articleNumber === HourlyWages) ? items[date].units : 0,
        });
      });
    });

    if (anyArrayHasValue) {
      Object.entries(otherArticlesData).forEach(([key, value], index) => {
        // Check if the array has values
        if (value?.length > 0) {
          value?.map((item) =>
            totalWorkingDates.push({
              id: 0,
              number: 1,
              unit: otherArticlesArray(item, 'unit'), // get from article
              amountPerUnit: item?.value,
              articleNumber: otherArticlesArray(item, 'articleNo'),
              workingDescription: otherArticlesArray(item, 'desc'),
              houseworkType: '',
              workingHour: 0,
              userId: loginedUserIndex === -1 ? invoiceCreatedUserId : adjustedList[index]?.userId,
            })
          );
        }
      });
    }

    let invoiceFreelancer;

    if (!values?.isFixed) {
      if (values.freelancer && values.freelancer?.length) {
        invoiceFreelancer = values.freelancer?.map((item) => ({
          amount: 0,
          userId: item.userId,
        }));
        // If logged-in user is not included
        const isInvoiceCreatorIncluded = values.freelancer?.some((freelancer) => freelancer.userId === Number(userID));
        if (!isInvoiceCreatorIncluded) {
          invoiceFreelancer.push({
            amount: 0,
            userId: Number(userID),
          });
        }
      } else {
        invoiceFreelancer = [
          {
            amount: 0,
            userId: Number(userID),
          },
        ];
      }
    } else {
      if (values.freelancer && values.freelancer.length) {
        invoiceFreelancer = values.freelancer.map((item, index) => {
          if (item.userId === Number(userID)) {
            return {
              amount: amountPerFreelancers[0],
              userId: item.userId,
            };
          } else {
            return {
              amount: amountPerFreelancers[index + 1] || 0,
              userId: item.userId,
            };
          }
        });

        const isInvoiceCreatorIncluded = values.freelancer.some((freelancer) => freelancer.userId === Number(userID));

        if (!isInvoiceCreatorIncluded) {
          invoiceFreelancer.push({
            amount: amountPerFreelancers[0],
            userId: Number(userID),
          });
        }
      } else {
        invoiceFreelancer = [
          {
            amount: Number(values?.amount),
            userId: Number(userID),
          },
        ];
      }
    }

    try {
      const { getInvoiceData } = prefillingData;
      setLoading(true);
      const res = await axios.put('/admin/invoice/edit-invoices', {
        invoiceId: getInvoiceData ? getInvoiceData?.id : 0,
        sendInvoice: 1,
        vat: values.vat,
        pension,
        amount: parseFloat(values.amount),
        isFixed: values.isFixed,
        currency: values.currency,
        brfNo: values.brfNo || '',
        registerAssignment: false,
        showDates: values.showDates,
        house: values.house || false,
        holidaySavings,
        description: values.description,
        taxValue: calculatedAmounts.tax,
        totalToPay: calculatedAmounts.net,
        rutDeduction: values.rutDeduction,
        rootDeduction: values.rootDeduction,
        file: file.length > 0 ? file : null,
        expressPayout: values.expressPayout,
        workingHours: [...totalWorkingDates],
        email: values?.selectedCustomer?.email,
        apartment: values.apartment || false,
        pensionFee: pension ? pensionFee : 0,
        holidaySavingsFee: holidaySavings ? holidaySavingFee : 0,
        tax: parseInt(globalData.tax.globalValue, 10),
        rotApartmentNo: values.rotApartmentNo || '',
        pensionAmount: calculatedAmounts?.pension || 0,
        customerId: values.selectedCustomer.customerId,
        forpAmount: isExpCost ? isExpCost : 0,
        holidaySavingsAmount: calculatedAmounts?.holidayPayAmount,
        amountIncVat: Math.round(calculatedAmounts?.amountIncludingVat),
        secondaryEmailAddress: values.selectedCustomer.secondaryEmailAddress,
        multipleFreelancer: values.freelancer.length > 0,
        currencyRate: globalData.currencies.find((item) => item.description === values.currency).globalValue,
        invoiceFreelancer,
      });

      if (res?.status === 200) {
        toast.success(t('Invoice updated successfully'));
        setShowSubmit(false);
        setLoading(false);
        navigate('/dashboard/invoices');
      } else {
        toast.success(t('Something went wrong, Please try again'));
      }
    } catch (err) {
      handleScroll();
      setShowSubmit(false);
      setLoading(false);
      setgetErrorMessages(err?.response?.data?.message);
      // toast.error(err?.response?.data?.message);
    } finally {
      setShowSubmit(false);
      setLoading(false);
    }
  };

  // --------------------------------SelectCustomer--------------------------------------

  const handleTypeSelect = (value) => {
    if (value.accountType === 3) {
      setValue('vat', 0);
    } else if (value.accountType === 2 && value?.country !== 'Sweden') {
      setValue('vat', 0);
    } else {
      setValue('vat', 25);
    }
    setAccountType(value.accountType);
    setCustomer({ customerId: customer?.customerId, ...value });
    selectRef.current.blur();
    setValue('selectedCustomer', { ...value });
    if (customer?.accountType !== 0 || customer?.accountType !== 1) {
      setValue('rootDeduction', false);
      setValue('rutDeduction', false);
    }
  };

  // ---------------------------------OnDateChange-------------------------------------

  const handleChangeDate = () => {
    renderAfterCalled.current = true;
  };

  useEffect(() => {
    if (renderAfterCalled.current) {
      const invoiceHours = watch('invoiceHours');
      if (!invoiceHours && !anyArrayHasValue) {
        setValue('amount', 0);
        setCalculatedAmounts({
          amount: 0,
          net: 0,
          tax: 0,
        });
      } else {
        let totalAmount = 0;
        let hourlyWagesValue = 0;
        invoiceHours?.forEach((allIndex) => {
          Object.keys(allIndex).forEach((date) => {
            if (allIndex[date]?.article?.articleNumber === HourlyWages) {
              hourlyWagesValue += parseFloat(allIndex[date].amount) * parseFloat(allIndex[date].units);
            }
            totalAmount +=
              parseFloat(allIndex[date].amount === '' ? 0 : allIndex[date].amount) *
              parseFloat(allIndex[date].units === '' ? 0 : allIndex[date].units);
          });
        });
        if (amount !== Number(totalAmount)) setCallUse(!callUse);
        if (anyArrayHasValue) {
          totalAmount += isOthCost || 0;
          totalAmount += isExpCost || 0;
        }

        setArticleAmountHourlyWages(hourlyWagesValue);
        setValue('amount', totalAmount.toFixed(2));
        setCalculatedAmounts((prevState) => ({
          ...prevState,
          amount: totalAmount.toFixed(2),
        }));
      }
    }
  }, [dates, anyArrayHasValue, isExpCost, isOthCost]);
  // ---------------------------------onClickFixedPrice-------------------------------------
  const handleChangeFixedPrice = (event) => {
    const isFixed = event.target.checked;
    const invoiceHours = watch('invoiceHours');
    if (isFixed) {
      setValue('amount', '');
      if (!initialInvoiceHours) {
        setInitialInvoiceHours(JSON.parse(JSON.stringify(invoiceHours)));
      }
      const modifiedData = invoiceHours;

      modifiedData?.forEach((group) => {
        Object.values(group).forEach((item) => {
          item.amount = '';
        });
      });

      setValue(`invoiceHours`, modifiedData);
    } else if (!isFixed) {
      let updatedHours;
      const isInvoiceHoursSame = compareInvoicesHours(initialInvoiceHours, invoiceHours);
      if (isInvoiceHoursSame) {
        setValue(`invoiceHours`, initialInvoiceHours);
        updatedHours = initialInvoiceHours;
      } else {
        setValue(`invoiceHours`, invoiceHours);
        updatedHours = invoiceHours;
      }
      setSelectedIndex(null);
      if (updatedHours || anyArrayHasValue) {
        let totalAmount = 0;
        let hourlyWagesValue = 0;

        updatedHours?.forEach((allIndex) => {
          Object.keys(allIndex).forEach((date) => {
            if (allIndex[date]?.article?.articleNumber === HourlyWages) {
              hourlyWagesValue += parseFloat(allIndex[date].amount) * parseFloat(allIndex[date].units);
            }
            totalAmount +=
              parseFloat(allIndex[date].amount === '' ? 0 : allIndex[date].amount) *
              parseFloat(allIndex[date].units === '' ? 0 : allIndex[date].units);
          });
        });
        if (anyArrayHasValue) {
          totalAmount += isOthCost || 0;
          totalAmount += isExpCost || 0;
        }
        setArticleAmountHourlyWages(hourlyWagesValue);
        setCallUse(!callUse);
        setValue('amount', totalAmount.toFixed(2));
        setCalculatedAmounts((prevState) => ({
          ...prevState,
          amount: totalAmount.toFixed(2),
        }));
      } else {
        setValue('amount', 0);
        setCalculatedAmounts({ amount: 0 });
      }
    }
  };

  // ---------------------------------handleChangeInvoiceArticle-------------------------------------

  const handleChangeInvoiceArticle = (e, dateStr, indexValue) => {
    setArticle(e);
    const invoiceHours = watch('invoiceHours');
    setValue(`invoiceHours[${indexValue}.[${dateStr}].article]`, e);
    if (invoiceHours || anyArrayHasValue) {
      let totalAmount = 0;
      let hourlyWagesValue = 0;

      if (
        e?.articleNumber === costPerDay ||
        e?.articleNumber === costPerMonth ||
        e?.articleNumber === costPerWeek ||
        e?.articleNumber === costAgreement
      ) {
        setValue(`invoiceHours[${indexValue}.[${dateStr}].units`, 1);
        invoiceHours?.forEach((allIndex) => {
          Object.keys(allIndex).forEach((date) => {
            totalAmount +=
              parseFloat(allIndex[date].units === '' ? 0 : allIndex[date].units) *
              parseFloat(allIndex[date].amount === '' ? 0 : allIndex[date].amount);
          });
        });
      } else if (e?.articleNumber === HourlyWages) {
        setValue(`invoiceHours[${indexValue}.[${dateStr}].units`, 8);
        invoiceHours?.forEach((allIndex) =>
          Object.keys(allIndex).forEach((date) => {
            totalAmount +=
              parseFloat(allIndex[date].units === '' ? 0 : allIndex[date].units) *
              parseFloat(allIndex[date].amount === '' ? 0 : allIndex[date].amount);
          })
        );
      }

      invoiceHours?.forEach((allIndex) => {
        Object.keys(allIndex).forEach((date) => {
          if (allIndex[date].units && allIndex[date].amount) {
            if (allIndex[date]?.article?.articleNumber === HourlyWages) {
              hourlyWagesValue += parseFloat(allIndex[date].amount) * parseFloat(allIndex[date].units);
            }
          }
        });
      });
      if (anyArrayHasValue) {
        totalAmount += isOthCost || 0;
        totalAmount += isExpCost || 0;
      }

      setArticleAmountHourlyWages(hourlyWagesValue);
      setValue('amount', totalAmount.toFixed(2));
      setCalculatedAmounts((prevState) => ({
        ...prevState,
        amount: totalAmount.toFixed(2),
      }));
    }
  };

  // ---------------------------------ChangeUnit-------------------------------------

  const handleChangeUnit = () => {
    let totalAmount = 0;
    const invoiceHours = watch('invoiceHours');
    if (invoiceHours) {
      invoiceHours?.forEach((allIndex) => {
        Object.keys(allIndex).forEach((date) => {
          if (allIndex[date].units && allIndex[date].amount) {
            totalAmount +=
              parseFloat(
                Number(allIndex[date].units) < 0 || Number.isNaN(allIndex[date].units)
                  ? 0
                  : Number(allIndex[date].units)
              ) * Number(allIndex[date].amount);

            // totalAmount +=
            //   parseFloat((allIndex[date]?.article?.unit === "h" && Number(allIndex[date].units) > 13) ? 13 : Number(allIndex[date].units) < 0 ? 0 : allIndex[date].units === "" ? 0 : Number(allIndex[date].units)) *
            //   Number(allIndex[date].amount)
          }
        });
      });
    }
    if (!isFixed) {
      setValue('amount', totalAmount.toFixed(2));
      setCalculatedAmounts((prevState) => ({
        ...prevState,
        amount: totalAmount.toFixed(2),
      }));
    }
    setCallUseEffect(callUseEffect);
    if (amount === totalAmount) {
      setCallUse(!callUse);
    }
  };

  // ----------------------------------ChangeAmount------------------------------------

  const handleChangeAmount = () => {
    let amounts;

    let totalAmount = 0;
    let hourlyWagesValue = 0;

    const invoiceHours = watch('invoiceHours');
    if (invoiceHours || anyArrayHasValue) {
      invoiceHours?.forEach((allIndex) => {
        Object.keys(allIndex).forEach((date) => {
          amounts = allIndex[date].amount === '';
          if (allIndex[date].units && allIndex[date].amount) {
            if (allIndex[date]?.article?.articleNumber === HourlyWages) {
              hourlyWagesValue += parseFloat(allIndex[date].amount) * parseFloat(allIndex[date].units);
            }
            totalAmount +=
              parseFloat(allIndex[date].units === '' ? 0 : allIndex[date].units) *
              parseFloat(allIndex[date].amount < 0 || allIndex[date].amount === '' ? 0 : Number(allIndex[date].amount));
          }
        });
      });
    }
    if (anyArrayHasValue) {
      totalAmount += isOthCost || 0;
      totalAmount += isExpCost || 0;
    }

    setArticleAmountHourlyWages(hourlyWagesValue);
    if (!isFixed) {
      setValue('amount', totalAmount.toFixed(2));
      setCalculatedAmounts((prevState) => ({
        ...prevState,
        amount: totalAmount.toFixed(2),
      }));
    }
    if (amount !== totalAmount) setCallUse(!callUse);
  };

  const handleOtherArticleValue = (e, key) => {
    const { userId } = userDetails;
    setOtherValuesChanged(true);

    setOtherArticlesData((prevData) => {
      const updatedData = { ...prevData };

      const index = selectedTab.index;

      if (e.target.checked) {
        // If checked, push the new data to the array at the specified index
        updatedData[index] = updatedData[index] || []; // Create array if it doesn't exist
        updatedData[index].push({
          key,
          value: '500',
          userId: index === 0 ? userId : selectedFreelancers[index - 1]?.userId,
        });
      } else {
        // If unchecked, remove the element with the matching key
        if (updatedData[index] && updatedData[index].length > 0) {
          updatedData[index] = updatedData[index].filter((obj) => obj.key !== key);

          if (updatedData[index].length === 0) {
            delete updatedData[index];
          }
        }
      }

      return updatedData;
    });

    if (key === 'exp') {
      setPDFError((prevErrors) => ({
        ...prevErrors,
        [selectedTab.index]: {
          validate: e.target.checked && key === 'exp' && 'Note: PDF uploads should be under 5MB',
        },
      }));
    }

    // Reset value and PDF error message if checkbox is unchecked and key is "exp"
    if (!e.target.checked && key === 'exp') {
      setValue(`invoiceFile[${selectedTab.index}]`, []);
    }
  };

  const handleTotalAmountChange = (e) => {
    const amount = Number(e.target.value);
    function setTooltipError(condition, errorType) {
      if (condition) {
        setShowTooltipError({ [errorType]: true });
        setTimeout(() => {
          setShowTooltipError({ [errorType]: false });
        }, 5000);
      }
    }
    if (amount) {
      setTooltipError(amount < 1000 && currency === 'SEK', 'LessAmount');
      setTooltipError(amount < 100 && currency === 'EUR', 'LessAmountEUR');
      setTooltipError(amount < 108 && currency === 'USD', 'LessAmountUSD');
      setTooltipError(amount < 86 && currency === 'GBP', 'LessAmountGBP');
      setTooltipError(amount < 96 && currency === 'CHF', 'LessAmountCHF');
      setTooltipError(amount < 148 && currency === 'CAD', 'LessAmountCAD');
      setTooltipError(amount < 166 && currency === 'AUD', 'LessAmountAUS');
      setTooltipError(amount < 1180 && currency === 'NOK', 'LessAmountNOK');
      setTooltipError(amount < 746 && currency === 'DKK', 'LessAmountDKK');
      setTooltipError(amount < 15760 && currency === 'JPY', 'LessAmountJPY');
      setTooltipError(amount < 774 && currency === 'CNY', 'LessAmountCNY');
    }
  };

  useEffect(() => {
    if (isFixed) {
      const otherAmount = isExpCost + isOthCost;
      let totalAmount = 0;
      if (amount) {
        totalAmount = amount - otherAmount;
      }

      let totalUnits = 0;

      invoiceHours?.forEach((item) => {
        Object.values(item).forEach((entry) => {
          totalUnits += Number(entry.units);
        });
      });

      const amountPerUnit = totalAmount / totalUnits;
      // each freelancer gets value after calculation
      invoiceHours?.forEach((item) => {
        Object.values(item).forEach((entry) => {
          entry.amount = amountPerUnit;
        });
      });

      setValue(`invoiceHours`, invoiceHours);
    }
  }, [isExpCost, isOthCost, amount]);
  // -----------------------------------RemoveDate-----------------------------------

  useEffect(() => {
    unregister(`invoiceHours[${removeClickedDate.indexValue}.[${removeClickedDate.dateStr}]`);
    unregister(`invoiceHours[${removeClickedDate.indexValue}.[${removeClickedDate.dateStr}].units]`);
    unregister(`invoiceHours[${removeClickedDate.indexValue}.[${removeClickedDate.dateStr}].houseworkType]`);
    unregister(`invoiceHours[${removeClickedDate.indexValue}.[${removeClickedDate.dateStr}].amount]`);
    unregister(`invoiceHours[${removeClickedDate.indexValue}.[${removeClickedDate.dateStr}].article]`);

    let totalAmount = 0;
    let hourlyWagesValue = 0;
    if (invoiceHours) {
      invoiceHours.forEach((allIndex) => {
        Object.keys(allIndex).forEach((date) => {
          if (allIndex[date]?.article?.articleNumber === HourlyWages) {
            hourlyWagesValue += parseFloat(allIndex[date].amount) * parseFloat(allIndex[date].units);
          }
          totalAmount +=
            parseFloat(allIndex[date].amount === '' ? 0 : allIndex[date].amount) *
            parseFloat(allIndex[date].units === '' ? 0 : allIndex[date].units);
        });
      });
      if (anyArrayHasValue) {
        totalAmount += isOthCost || 0;
        totalAmount += isExpCost || 0;
      }

      if (prefillingData?.getInvoiceData?.amount) {
        setArticleAmountHourlyWages(hourlyWagesValue);
        setValue('amount', prefillingData?.getInvoiceData?.amount.toFixed(2));
        setCalculatedAmounts((prevState) => ({
          ...prevState,
          amount: prefillingData?.getInvoiceData?.amount?.toFixed(2),
        }));
      }

      if (otherValuesChanged || removeClickedDate?.dateStr !== 0) {
        setArticleAmountHourlyWages(hourlyWagesValue);
        setValue('amount', totalAmount.toFixed(2));
        setCalculatedAmounts((prevState) => ({
          ...prevState,
          amount: totalAmount.toFixed(2),
        }));
      }
    }
  }, [register, removeClickedDate, otherArticlesData]);
  // ---------------------------------removeUnapprovedDate-------------------------------------

  const removeUnapprovedDate = () => {
    setDates((prevState) => {
      const updatedDates = { ...prevState };

      Object.keys(updatedDates).forEach((item) => {
        updatedDates[item] = updatedDates[item]?.slice(0, -1);
      });

      return updatedDates;
    });

    setRemoveClickedDate({ ...approvelDate });
    setShowWorkDatesNotification(false);
  };

  // -----------------------------------On Submit Amount Validation-----------------------------------

  useEffect(() => {
    if (renderAfterCalled.current) {
      if (selectedTab.date === '' && !anyArrayHasValue && !hasWorkingHours) {
        renderAfterCalled.current = true;
        setShowTooltipError({ noDateSelected: true });
        setTimeout(() => {
          setShowTooltipError({ noDateSelected: false });
        }, 5000);
      } else if (selectedTab.validateAmount) {
        setFocus(`invoiceHours[${selectedTab.index}.[${selectedTab.date}].amount`);
        setShowTooltipError({ NullAmount: true });
        setTimeout(() => {
          setShowTooltipError({
            NullAmount: false,
          });
        }, 3000);
      } else if (selectedTab.validateUnit) {
        setFocus(`invoiceHours[${selectedTab.index}.[${selectedTab.date}].units]`);
        setShowTooltipError({ nullUnit: true });
        setTimeout(() => {
          setShowTooltipError({
            nullUnit: false,
          });
        }, 3000);
      } else if (selectedTab.validateArticle) {
        setFocus(`invoiceHours[${selectedTab.index}.[${selectedTab.date}].article]`);
      } else if (selectedTab.validatePdfFile) {
        setFocus(`invoiceFile[${selectedTab.index}]`);
        setShowTooltipError({ nullPDF: true });
        setTimeout(() => {
          setShowTooltipError({
            nullPDF: false,
          });
        }, 3000);
      }
    }
  }, [selectedTab.validateCalender]);

  const handleAmountError = (errorType) => {
    setSelectedIndex(0);
    setFocus(`amount`);
    const errorObject = {};
    errorObject[errorType] = true;
    setShowTooltipError(errorObject);
    setTimeout(() => {
      setShowTooltipError({
        [errorType]: false,
      });
    }, 5000);
  };

  const amountValidation = (calledForShowInvoice) => {
    let isUnitFilled = {};
    let isDateSelected = {};
    let isAmountFilled = {};
    let isFileSelected = {};
    let isArticleSelected = {};
    const invoiceFile = watch('invoiceFile');
    const invoiceHours = watch('invoiceHours');
    const totalAmountVal = parseInt(getValues('amount'));

    if (!selectedCustomer) {
      selectRef.current.focus();
      setShowTooltipError({
        NullCustomer: true,
      });
      setTimeout(() => {
        setShowTooltipError({
          NullCustomer: false,
        });
      }, 3000);
    } else if (invoiceHours || anyArrayHasValue) {
      !anyArrayHasValue &&
        selectedList?.forEach((item, index) => {
          if (!invoiceHours[index]) isDateSelected = { index, dateSelected: true };
        });

      !anyArrayHasValue &&
        invoiceHours?.forEach((values, allIndex) => {
          Object.keys(values).forEach((date, index) => {
            if (values[date].amount === '') {
              // isAmountFilled = { index: allIndex, date, nullAmount: true };
            } else if (values[date].units === '') {
              isUnitFilled = { index: allIndex, date, nullUnit: true };
            } else if (values[date].article === undefined) {
              isArticleSelected = { index: allIndex, date, nullArticle: true };
            } else if (
              invoiceFile[allIndex] &&
              invoiceFile[allIndex][0] &&
              invoiceFile[allIndex][0].size > 5 * 1024 * 1024
            ) {
              isFileSelected = { index: allIndex, nullFile: true };
            }
          });
        });

      const isAnyFileSizeGreaterThan5MB = invoiceFile?.some((fileList) =>
        Array.from(fileList).some((file) => file.size > 5 * 1024 * 1024)
      );

      if (isAnyFileSizeGreaterThan5MB) {
        toast.error('You cannot upload a file larger than 5MB');
      }

      if (isAmountFilled?.nullAmount) {
        renderAfterCalled.current = true;
        setSelectedTab((prevState) => {
          return {
            ...prevState,
            index: isAmountFilled.index,
            validateCalender: !prevState.validateCalender,
            date: isAmountFilled.date,
            validateUnit: false,
            validateAmount: isAmountFilled.nullAmount,
          };
        });
      } else if (isUnitFilled?.nullUnit) {
        renderAfterCalled.current = true;
        setSelectedTab((prevState) => {
          return {
            ...prevState,
            index: isUnitFilled.index,
            validateCalender: !prevState.validateCalender,
            date: isUnitFilled.date,
            validateAmount: false,
            validateUnit: isUnitFilled.nullUnit,
          };
        });
      } else if (isArticleSelected?.nullArticle) {
        renderAfterCalled.current = true;
        setSelectedTab((prevState) => {
          return {
            ...prevState,
            index: isArticleSelected.index,
            validateCalender: !prevState.validateCalender,
            date: isArticleSelected.date,
            validateAmount: false,
            validateUnit: false,
            validateArticle: isArticleSelected.nullArticle,
          };
        });
      } else if (isFileSelected?.nullFile) {
        renderAfterCalled.current = true;
        setSelectedTab((prevState) => {
          return {
            index: isFileSelected.index,
            validateCalender: !prevState.validateCalender,
            validateAmount: false,
            validateUnit: false,
            validatePdfFile: isFileSelected.nullFile,
          };
        });
      } else if (totalAmountVal < 1000 && currency === 'SEK') {
        handleAmountError('LessAmount');
      } else if (totalAmountVal < 100 && currency === 'EUR') {
        handleAmountError('LessAmountEUR');
      } else if (totalAmountVal < 108 && currency === 'USD') {
        handleAmountError('LessAmountUSD');
      } else if (totalAmountVal < 86 && currency === 'GBP') {
        handleAmountError('LessAmountGBP');
      } else if (totalAmountVal < 96 && currency === 'CHF') {
        handleAmountError('LessAmountCHF');
      } else if (totalAmountVal < 148 && currency === 'CAD') {
        handleAmountError('LessAmountCAD');
      } else if (totalAmountVal < 166 && currency === 'AUD') {
        handleAmountError('LessAmountAUS');
      } else if (totalAmountVal < 1180 && currency === 'NOK') {
        handleAmountError('LessAmountNOK');
      } else if (totalAmountVal < 746 && currency === 'DKK') {
        handleAmountError('LessAmountDKK');
      } else if (totalAmountVal < 15760 && currency === 'JPY') {
        handleAmountError('LessAmountJPY');
      } else if (totalAmountVal < 774 && currency === 'CNY') {
        handleAmountError('LessAmountCNY');
      } else if (amount === '' && isFixed) {
        setFocus(`amount`);
        setShowTooltipError({
          NullAmount: true,
        });
        setTimeout(() => {
          setShowTooltipError({
            NullAmount: false,
          });
        }, 3000);
      } else {
        if (calledForShowInvoice) {
          setShowInvoice(true);
        } else {
          setShowSubmit(true);
        }
      }
    } else {
      renderAfterCalled.current = true;
      setSelectedTab((prevState) => {
        return {
          ...prevState,
          index: 0,
          validateCalender: !prevState.validateCalender,
          date: '',
        };
      });
    }
  };
  // -----------------------------------See How We Calculated-----------------------------------

  const handleSeeCalculations = async () => {
    const invoiceData = [];
    let otherCost = isOthCost || 0;
    let invoiveAsDescribedBellowAmount = isExpCost || 0;
    let hourlyWagesAmount = 0;
    let paymentPerDayAmount = 0;
    let paymentPerWeekAmount = 0;
    let paymentPerMonthAmount = 0;
    let paymentAsAgreedAmount = 0;

    const invoiceHours = watch('invoiceHours');

    if (!invoiceHours) {
      invoiceData.push({
        otherCost,
        invoiveAsDescribedBellowAmount,
      });
    }

    const userInvoiceData = (invoiceHours || []).map((items) => {
      let currentHourlyWagesAmount = 0;
      let currentPaymentPerDayAmount = 0;
      let currentPaymentPerWeekAmount = 0;
      let currentPaymentPerMonthAmount = 0;
      let currentPaymentAsAgreedAmount = 0;

      items = items || {};

      Object.keys(items)?.forEach((key) => {
        const item = items[key];
        if (item.article?.articleNumber === HourlyWages) {
          currentHourlyWagesAmount += Number(item.units || 0);
        } else if (item.article?.articleNumber === costPerDay) {
          currentPaymentPerDayAmount += Number(item.amount || 0);
        } else if (item.article?.articleNumber === costPerWeek) {
          currentPaymentPerWeekAmount += Number(item.amount || 0);
        } else if (item.article?.articleNumber === costPerMonth) {
          currentPaymentPerMonthAmount += Number(item.amount || 0);
        } else if (item.article?.articleNumber === costAgreement) {
          currentPaymentAsAgreedAmount += Number(item.amount || 0);
        } else {
          amountValidation();
        }
      });

      hourlyWagesAmount += currentHourlyWagesAmount;
      paymentPerDayAmount += currentPaymentPerDayAmount;
      paymentPerWeekAmount += currentPaymentPerWeekAmount;
      paymentPerMonthAmount += currentPaymentPerMonthAmount;
      paymentAsAgreedAmount += currentPaymentAsAgreedAmount;

      return {
        hourlyWagesAmount: currentHourlyWagesAmount,
        paymentPerDayAmount: currentPaymentPerDayAmount,
        paymentPerWeekAmount: currentPaymentPerWeekAmount,
        paymentPerMonthAmount: currentPaymentPerMonthAmount,
        paymentAsAgreedAmount: currentPaymentAsAgreedAmount,
      };
    });

    invoiceData.push({
      otherCost,
      invoiveAsDescribedBellowAmount,
      hourlyWagesAmount,
      paymentPerDayAmount,
      paymentPerWeekAmount,
      paymentPerMonthAmount,
      paymentAsAgreedAmount,
    });
    setUserInvoiceData(userInvoiceData);
    setShow({
      ...show,
      showCalculations: true,
      invoiceData: { ...invoiceData[0] },
    });
  };

  // ---------------------------------Remove Unselected Freelancer-------------------------------------

  const handleRemoveFreelancer = (previousSelectedFreelancers, currentSelectedFreelancers) => {
    let removedIndex = {};
    const invoiceHours = watch('invoiceHours');
    if (currentSelectedFreelancers) {
      setSelectedTab((prevState) => {
        return { ...prevState, index: 0 };
      });
      const currentItemsUserId = currentSelectedFreelancers?.map((items) => items.userId);
      if (previousSelectedFreelancers !== undefined) {
        previousSelectedFreelancers?.forEach((item, index) => {
          if (!currentItemsUserId.includes(item.userId)) {
            removedIndex = { index, removedItem: true };
          }
        });
      }

      if (removedIndex?.removedItem && invoiceHours && invoiceHours[removedIndex?.index + 1] !== undefined) {
        Object.keys(invoiceHours[removedIndex.index + 1])?.forEach((date) => {
          unregister(`invoiceHours[${removedIndex.index + 1}]`);
          setRemoveClickedDate({
            dateStr: date,
            indexValue: removedIndex.index + 1,
          });
        });

        let lastIndex;
        const allItems = [];
        let NewDatesList = dates;
        delete NewDatesList[removedIndex.index + 1];
        Object.keys(dates)?.forEach((items, index) => {
          lastIndex = index + 1;
          allItems.push(dates[items]);
        });
        allItems?.forEach((values, index) => {
          NewDatesList[index] = values;
        });
        NewDatesList[lastIndex] = [];
        setDates(NewDatesList);

        const array = [];
        let newArray = [];
        let removeItem = invoiceHours;
        const freelancersCount = currentSelectedFreelancers.length;
        Array.from(Array(freelancersCount + 2).fill([]))?.forEach((items, index) => {
          if (removedIndex.index + 1 !== index) array.push(removeItem[index] ? { ...removeItem[index] } : undefined);
        });
        for (let i = 0; i <= array.length; i = i + 1) {
          if (array[i] !== undefined) {
            newArray[i] = array[i];
          }
        }

        reset({
          vat: vat,
          invoiceHours: newArray,
          holidaySavings: holidaySavings,
          selectedCustomer: selectedCustomer,
          freelancer: currentSelectedFreelancers,
          showDates: 'true',
        });
      } else {
        const freelancersCount = currentSelectedFreelancers.length;
        if (invoiceHours !== undefined) {
          if (removedIndex.index !== undefined) {
            unregister(`invoiceHours[${removedIndex.index + 1}]`);
            const array = [];
            let newArray = [];
            let removeItem = invoiceHours;
            Array.from(Array(freelancersCount + 2).fill([]))?.forEach((items, index) => {
              if (removedIndex.index + 1 !== index)
                array.push(removeItem[index] ? { ...removeItem[index] } : undefined);
            });
            for (let i = 0; i <= array.length; i = i + 1) {
              if (array[i] !== undefined) {
                newArray[i] = array[i];
              }
            }
            reset({
              vat: vat,
              invoiceHours: newArray,
              holidaySavings: holidaySavings,
              selectedCustomer: selectedCustomer,
              freelancer: currentSelectedFreelancers,
              showDates: 'true',
            });
          }
        }
        let lastIndex;
        const allItems = [];
        let NewDatesList = dates;
        delete NewDatesList[removedIndex.index + 1];
        Object.keys(dates)?.forEach((items, index) => {
          lastIndex = index + 1;
          allItems.push(dates[items]);
        });
        allItems?.forEach((values, index) => {
          NewDatesList[index] = values;
        });
        NewDatesList[lastIndex] = [];
        setDates(NewDatesList);
      }
    }
  };
  // ---------------------------------Handle Freelancer Select-------------------------------------

  const handleFreelancerSelect = (selectedFreelancer, i) => {
    setValue('freelancer', selectedFreelancer);
    const { createdBy, userId, createdDate, isDeleted, updatedDate } = userDetails;
    const loginedUserIndex = selectedFreelancer.findIndex((item) => item?.userId === invoiceCreatedUserId);
    if (loginedUserIndex !== -1) {
      const adjustedList = [...selectedFreelancer]; // Create a copy of the original list

      // Remove the logged-in user from its current position and insert it at the beginning
      const removedItem = adjustedList.splice(loginedUserIndex, 1)[0];
      adjustedList.unshift(removedItem);

      setSelectedList(adjustedList);
    } else {
      setSelectedList([
        {
          userId,
          createdBy,
          createdDate,
          isDeleted,
          updatedDate,
          value: userId,
          signUpUser: { ...userDetails },
        },
        ...selectedFreelancer,
      ]);
    }

    if (i?.removedValue?.userId) {
      setOtherArticlesData((prevData) => {
        const updatedData = { ...prevData };
        const userIdToRemove = i?.removedValue?.userId; // Example userId to remove
        let foundIndex = null;

        // Find the index of the user ID to remove
        for (const key of Object.keys(updatedData)) {
          const currentData = updatedData[key];
          if (currentData.some((item) => item.userId === userIdToRemove)) {
            foundIndex = parseInt(key);
            break;
          }
        }

        // If the user ID is found, shift the data
        if (foundIndex !== null) {
          for (let i = foundIndex; i < Object.keys(updatedData).length - 1; i++) {
            const currentKey = Object.keys(updatedData)[i];
            const nextKey = Object.keys(updatedData)[i + 1];
            updatedData[currentKey] = updatedData[nextKey];
          }

          // Clear the last index
          updatedData[Object.keys(updatedData).length - 1] = [];
        }

        return updatedData;
      });
    }
    setPreviousSelectedFreelancers(selectedFreelancer);
    handleRemoveFreelancer(previousSelectedFreelancers, selectedFreelancer);
  };

  // ---------------------------------Calculation After Unselect Freelancer-------------------------------------

  // useEffect(() => {
  //   let forpValue = 0;
  //   let totalAmount = 0;
  //   if (invoiceHours) {
  //     invoiceHours.forEach((allIndex) => {
  //       if (allIndex)
  //         Object.keys(allIndex).forEach((date) => {
  //           if (allIndex[date]?.article?.articleNumber === InvoiveAsDescribedBellow) {
  //             forpValue += parseFloat(allIndex[date].amount) * parseFloat(allIndex[date].units)
  //           }
  //           totalAmount += parseFloat(allIndex[date].amount === "" ? 0 : allIndex[date].amount) * parseFloat(allIndex[date].units === "" ? 0 : allIndex[date].units)
  //         });
  //     })
  //     if (amount !== Number(totalAmount)) {
  //       setCallUse(!callUse)
  //     }
  //     setArticleAmountForp(forpValue);
  //     setValue("amount", totalAmount.toFixed(2));
  //     setCalculatedAmounts((prevState) => ({ ...prevState, amount: totalAmount }));
  //   }
  // }, [invoiceHours])

  // ---------------------------------onSubmit-------------------------------------

  const onSubmit = () => amountValidation();

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <MdGroupAdd size={25} />
      </components.DropdownIndicator>
    );
  };

  const Option = (props) => {
    return (
      <OverlayTrigger
        placement="right"
        overlay={
          <Popover style={{ margin: 0 }}>
            <Popover.Header
              className={`${selectedFreelancers?.length === undefined ? 'text-primary-custom' : 'text-danger'}`}
              as="h3"
            >
              {selectedFreelancers?.length === undefined && t('You Can Select Up to 15 Freelancers')}
              {selectedFreelancers?.length === 15 && t('You Have Selected 15 Freelancers')}
            </Popover.Header>
          </Popover>
        }
      >
        <span>
          <components.Option {...props} />
        </span>
      </OverlayTrigger>
    );
  };
  const descriptionValue = watch('description');

  useEffect(() => {
    let errorMessage = '';

    const descriptionLength = descriptionValue?.length;

    if (descriptionLength > 0 && descriptionLength < 5) {
      errorMessage = 'Enter at least 5 characters';
    } else if (descriptionLength > 1024) {
      errorMessage = 'Maximum character limit 1024';
    }

    setError('description', {
      message: errorMessage,
    });
  }, [descriptionValue, setError]);

  const allArticlesHaveNumber92 = () => {
    return invoiceHours?.every((item) =>
      Object.values(item).every((article) => article.article?.articleNumber === '92')
    );
  };

  return (
    <div className="invoice-tool py-5">
      {loading && <Loader />}
      <h4 className="mb-4 text-dark main-heading px-3">{t('THE INVOICING TOOL')}</h4>

      <div className="m-auto w-50">
        {getErrorMessages && (
          <div className="position-relative">
            <div className="mt-1">
              <Alert severity="error">
                <ul>
                  {formattedText?.map((text, index) => {
                    return (
                      <li style={{ lineHeight: 2 }} className="errorLine" key={index}>
                        {text}
                      </li>
                    );
                  })}
                </ul>
              </Alert>
            </div>
            <RxCross2
              onClick={() => setgetErrorMessages(null)}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: 0,
                right: 0,
                color: 'black',
                fontSize: '18px',
              }}
            />
          </div>
        )}
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="mt-3 row mx-0">
        <div className="col-12 bg-secondary-custom px-3 py-4">
          <h5 className="heading">1 {t('Customer')}</h5>
          <p>{t('Here you specify who the invoice should be sent to.')}</p>
          <div className="row m-0 mb-3 flex-sm-column flex-xs-column flex-md-row">
            <div className="col p-3 bg-white border customer-box abc d-flex flex-column mb-3 justify-content-between">
              <div>
                <div className="d-flex justify-content-between mb-2 flex-column-on-mobile">
                  <h6 className="mb-0">{t('Select a customer')}</h6>
                </div>
                <OverlayTrigger
                  show={showTooltipError.NullCustomer}
                  placement="top"
                  overlay={
                    <Popover id={`popover-positioned-top`} style={{ margin: 0 }}>
                      <Popover.Header className="text-danger" as="h3">
                        {showTooltipError.NullCustomer && t('Select a customer from the list')}
                      </Popover.Header>
                    </Popover>
                  }
                >
                  <div>
                    <Controller
                      control={control}
                      name="selectedCustomer"
                      render={({ field, fieldState }) => (
                        <Select
                          size="lg"
                          {...field}
                          labelKey="name"
                          ref={selectRef}
                          className="mb-3"
                          onChange={handleTypeSelect}
                          selected={field?.value?.name}
                          id="createInvoiceCustomerSelect"
                          // menuIsOpen={!loading && openCustomerList}
                          getOptionLabel={(option) => option?.name}
                          menuPortalTarget={document.querySelector('body')}
                          placeholder={t('Select Customer')}
                          options={customers?.map((customer) => ({
                            ...customer,
                            value: customer.customerId,
                          }))}
                          noOptionsMessage={({ inputValue }) => (!inputValue ? t('No options') : t('No results found'))}
                        />
                      )}
                    />
                  </div>
                </OverlayTrigger>
              </div>

              <div>
                <Button className="mb-3" onClick={() => setShowDialog(true)} variant="primary">
                  {t('Add new Customer')}
                </Button>
              </div>
            </div>
            <div className="col p-3 bg-white ml-2 border customer-box2 position-relative mb-3">
              {customer && customer?.email && (
                <Fragment key={customer?.email}>
                  <span
                    variant="link"
                    className="icon-edit bg-primary"
                    onClick={() => setShow({ ...show, show: true })}
                  >
                    <MdModeEditOutline />
                  </span>
                  <div className="d-flex align-items-center">
                    <h6 className="m-0">{t('Name')}:</h6>
                    <p className="m-0 ml-3">{customer?.name}</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <h6 className="m-0">{t('Address')}:</h6>
                    <p className="m-0 ml-3">{customer?.address}</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <h6 className="m-0">{t('City')}:</h6>
                    <p className="m-0 ml-3">{customer?.city}</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <h6 className="m-0">{t('Country')}:</h6>
                    <p className="m-0 ml-3">{customer?.country}</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <h6 className="m-0">{t('Zip Code')}:</h6>
                    <p className="m-0 ml-3">{customer?.zipCode}</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <h6 className="m-0">{t('Email')}:</h6>
                    <p className="m-0 ml-3">{customer?.email}</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <h6 className="m-0">{t('Phone Number')}:</h6>
                    <p className="m-0 ml-3">{customer?.phoneNumber}</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <h6 className="m-0">{accountType === 1 ? t('SSN No.') + ':' : t('Org No') + ':'}</h6>
                    <p className="m-0 ml-3">{accountType === 1 ? customer?.ssn : customer?.orgNo}</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <h6 className="m-0">{t('Reference')}:</h6>
                    <p className="m-0 ml-3">{customer?.reference}</p>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
          <div>
            {errors.selectedCustomer && (
              <div className="alert alert-danger" role="alert">
                {' '}
                {errors.selectedCustomer.message}
              </div>
            )}
          </div>
          <Button variant="primary" onClick={() => setCollective(!isCollective)} className="mb-3">
            {t('Collective invoice')}
          </Button>
          {isCollective && (
            <div className="p-4 bg-white border mb-3">
              <Button
                variant="primary"
                className="mb-3"
                onClick={() => {
                  if (watch('selectedCustomer')) {
                    setShow((prevState) => {
                      return {
                        ...prevState,
                        showFreelancerSelect: watch('freelancer') ? true : !prevState.showFreelancerSelect,
                      };
                    });
                  } else {
                    selectRef.current.focus();
                    setShowTooltipError({
                      NullCustomer: true,
                    });
                    setTimeout(() => {
                      setShowTooltipError({
                        NullCustomer: false,
                      });
                    }, 3000);
                  }
                }}
              >
                {t('Select Freelancers')}
              </Button>
              <div style={{ maxWidth: '900px' }}>
                {show.showFreelancerSelect && (
                  <Controller
                    name="freelancer"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Select
                        isMulti
                        size="lg"
                        {...field}
                        selected={field?.value?.signUpUser?.email}
                        options={freelancers?.map((freelancers) => ({
                          ...freelancers,
                          value: freelancers.userId,
                        }))}
                        getOptionLabel={(option) =>
                          option?.signUpUser?.email +
                          '(' +
                          option?.signUpUser?.firstName +
                          ' ' +
                          option?.signUpUser?.lastName +
                          ')'
                        }
                        closeMenuOnSelect={
                          freelancers?.length - 1 === selectedFreelancers?.length || selectedFreelancers?.length > 13
                        }
                        labelKey="name"
                        components={{ DropdownIndicator, Option }}
                        className="mb-3"
                        id="createInvoiceCustomerSelect"
                        placeholder={t('Select Freelancers')}
                        menuPortalTarget={document.querySelector('body')}
                        isOptionDisabled={() => selectedFreelancers?.length >= 15}
                        onChange={(selectedFreelancer, i) => {
                          handleFreelancerSelect(selectedFreelancer, i);
                        }}
                        noOptionsMessage={({ inputValue }) =>
                          !inputValue ? t('No freelancer found please add to select freelancer') : t('No results found')
                        }
                      />
                    )}
                  />
                )}
              </div>
            </div>
          )}
          <h5 className="heading">2 {t('Specification')}</h5>
          <div className="p-4 bg-white border mb-3">
            <h6>{t('Description')}</h6>
            <p>
              {' '}
              {t(
                'Describe your work in as much detail as you can. This information constitutes the description on the invoice sent to your customer.'
              )}
            </p>
            <textarea
              className="form-control m-0"
              {...register('description', {
                required: 'Enter at least 5 characters',
                minLength: {
                  value: 5,
                  message: 'Enter at least 5 characters',
                },
                maxLength: {
                  value: 1024,
                  message: 'Maximum character limit 1024',
                },
              })}
            />
            {errors?.description?.message && (
              <div>
                {errors.description && (
                  <div className="alert alert-danger mt-3" role="alert">
                    {errors.description?.message}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="col-12 mt-3 p-0 worked-hours">
          <div className="row m-0 bg-secondary-custom px-3 py-4">
            <div className="col p-0">
              <div className="d-flex justify-content-between mb-3 mobile-column worked-hours-div">
                <div className="d-flex heading-div">
                  <h5 className="heading">3 {t('Worked hours')}</h5>
                  <AiFillInfoCircle
                    className="ml-2 cursor-pointer"
                    size={22}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Here you report your time worked. It is important that you
                  record work dates and hours according to how you actually
                  work. The registered working hours form the basis for your
                  employment, income information for, among other things,
                  the Swedish Social Insurance Agency and employer's
                  certificate. If you invoice several clients for the same
                  assignment, remember to divide your hours between the
                  invoices so that the hours are not registered twice."
                  />
                </div>

                {selectedList?.length > 1 && (
                  <div className="test-btns-div">
                    {selectedList?.map((item, index) => {
                      return (
                        <div key={index} className="single-tag ">
                          <div className="ml-2 mb-2">
                            <Button
                              variant="outline-primary rounded-pill"
                              active={selectedTab.index === index}
                              onClick={() =>
                                setSelectedTab((prevState) => {
                                  return { ...prevState, index };
                                })
                              }
                            >
                              {item?.signUpUser?.firstName ? item?.signUpUser?.firstName : item?.name}{' '}
                              {item?.signUpUser?.lastName ? item.signUpUser.lastName : ''}
                            </Button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <p>
                {t('Click on one date and start invoice')}
                <Button className="p-0" onClick={() => setShowReadMore(true)} variant="link" size="sm">
                  {t('Read more')}
                </Button>
              </p>
              {selectedList?.map((item, indexValue) => {
                return (
                  <div key={indexValue}>
                    {selectedTab.index === indexValue && (
                      <>
                        <div className="d-flex calender-list">
                          <OverlayTrigger
                            show={showTooltipError.noDateSelected}
                            placement="top"
                            overlay={
                              <Popover id={`popover-positioned-top`} style={{ margin: 0 }}>
                                <Popover.Header className="text-danger" as="h3">
                                  {selectedTab.index === indexValue &&
                                    showTooltipError.noDateSelected &&
                                    !anyArrayHasValue &&
                                    t('Please Select The date From The Calender')}
                                </Popover.Header>
                              </Popover>
                            }
                          >
                            <div className="Calander-div">
                              <Calender
                                dates={dates}
                                setDates={setDates}
                                className="mb-3 w-100"
                                currentIndex={indexValue}
                                onChange={handleChangeDate}
                              />
                            </div>
                          </OverlayTrigger>
                          <div className="calender-div-data ml-4">
                            <div className="row">
                              <div className="col"></div>
                              <div className="col"></div>
                              {(hasWorkingHours || anyArrayHasValue) && (
                                <>
                                  <div className="col">
                                    <h6>{t('Show Date')}</h6>
                                    <div className="d-flex">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input cursor-pointer"
                                          type="radio"
                                          id="showHoursYes"
                                          value={true}
                                          {...register(`showDates`)}
                                        />
                                        <label className="form-check-label cursor-pointer" htmlFor="showHoursYes">
                                          {' '}
                                          {t('Yes')}
                                        </label>
                                      </div>
                                      <div className="form-check ml-3">
                                        <input
                                          className="form-check-input cursor-pointer"
                                          type="radio"
                                          id="showHoursNo"
                                          value={false}
                                          disabled={rootDeduction}
                                          {...register(`showDates`)}
                                        />
                                        <label className="form-check-label cursor-pointer" htmlFor="showHoursNo">
                                          {t('No')}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <Button
                                      style={{ textDecoration: 'underline' }}
                                      onClick={async () => {
                                        const validated = await trigger(
                                          ['selectedCustomer', 'description', 'email', 'customCheck', 'amount'],
                                          {
                                            shouldFocus: true,
                                          }
                                        );
                                        if (validated) {
                                          amountValidation(true);
                                        }
                                      }}
                                      variant="link"
                                      size="md"
                                    >
                                      {t('Preview Invoice')}
                                    </Button>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="data-set">
                              <div className="overflow mt-3 px-0 mr-2 mb-0">
                                {articles.length > 0 &&
                                  dates[indexValue]?.map((item, index) => {
                                    const dateStr = formatDateStr(item?.date, item?.id);

                                    let total = 0;
                                    const dayArticle = watch(`invoiceHours[${indexValue}.[${dateStr}].article]`)
                                      ? watch(`invoiceHours[${indexValue}.[${dateStr}].article]`)
                                      : null;
                                    const dateHours = watch(`invoiceHours[${indexValue}.[${dateStr}].units`);
                                    const amountPerHr = watch(`invoiceHours[${indexValue}.[${dateStr}].amount`);
                                    if (dateHours >= 1 && dateHours <= 24 && amountPerHr)
                                      total =
                                        dayArticle?.articleNumber === otherCost && dateHours > 13
                                          ? 13 * amountPerHr
                                          : dayArticle?.articleNumber === otherCost && dateHours <= 0
                                            ? 0 * (amountPerHr < 0 ? 1 : amountPerHr)
                                            : dayArticle?.articleNumber == 94 && dateHours > 220
                                              ? 220 * amountPerHr
                                              : dateHours < 0
                                                ? 0
                                                : dateHours * (amountPerHr < 0 ? 0 : amountPerHr);
                                    return (
                                      <React.Fragment key={index}>
                                        <WorkingHoursRow
                                          index={index}
                                          rootDeduction={rootDeduction}
                                          rutDeduction={rutDeduction}
                                          dayArticle={dayArticle}
                                          dates={dates}
                                          indexValue={indexValue}
                                          setDates={setDates}
                                          setRemoveClickedDate={setRemoveClickedDate}
                                          dateStr={dateStr}
                                          handleChangeInvoiceArticle={handleChangeInvoiceArticle}
                                          showTooltipError={showTooltipError}
                                          selectedIndex={selectedIndex}
                                          articles={articles}
                                          control={control}
                                          isFixed={isFixed}
                                          setShowTooltipError={setShowTooltipError}
                                          register={register}
                                          currency={currency}
                                          total={total}
                                          handleChangeAmount={handleChangeAmount}
                                          watch={watch}
                                          setSelectedIndex={setSelectedIndex}
                                          setUnitDateStr={setUnitDateStr}
                                          handleChangeUnit={handleChangeUnit}
                                          setOtherArticlesData={setOtherArticlesData}
                                          showDates={showDates}
                                          setValue={setValue}
                                        />
                                      </React.Fragment>
                                    );
                                  })}

                                <OtherArticlesRow
                                  otherArticleValues={otherArticlesData}
                                  isFixed={isFixed}
                                  currency={currency}
                                  setOtherArticleValues={setOtherArticlesData}
                                  showTooltipError={showTooltipError}
                                  setShowTooltipError={setShowTooltipError}
                                  indexValue={indexValue}
                                  setOtherValuesChanged={setOtherValuesChanged}
                                  calculatedAmounts={calculatedAmounts}
                                  isLoading={spinner}
                                />
                              </div>
                            </div>
                            {/* other articles */}
                            <OtherArticlesOptionsUI
                              dates={dates}
                              indexValue={indexValue}
                              handleSeeCalculations={handleSeeCalculations}
                              setShowSubmit={setShowSubmit}
                              totalHours={totalHours}
                              handleOtherArticleValue={handleOtherArticleValue}
                              otherArticleValues={otherArticlesData}
                              anyArrayHasValue={anyArrayHasValue}
                              hasWorkingHours={hasWorkingHours}
                            />
                          </div>
                        </div>
                        <OverlayTrigger
                          show={showTooltipError.nullPDF}
                          placement="top"
                          overlay={
                            <Popover id={`popover-positioned-top`} style={{ margin: 0 }}>
                              {/* <Popover.Header className="text-danger" as="h3">
                                {showTooltipError.nullPDF && t('You forgot to add a pdf file')}
                              </Popover.Header> */}
                            </Popover>
                          }
                        >
                          <div className="d-flex align-items-center mt-3">
                            {invoiceFile &&
                              ((Array.isArray(invoiceFile[indexValue]) &&
                                invoiceFile[indexValue].fileContent !== '' &&
                                invoiceFile[indexValue].length > 0) ||
                                (Object.keys(invoiceFile[indexValue] || {}).length > 0 &&
                                  invoiceFile[indexValue].fileContent !== '')) ? (
                              <div className="d-flex align-items-center">
                                <p className="m-0 ml-2">
                                  {invoiceFile[indexValue]?.length > 0
                                    ? invoiceFile[indexValue][0]?.name
                                    : invoiceFile[indexValue]?.fileName}
                                </p>
                                <AiOutlineDelete
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={'Delete'}
                                  className="ml-2 cursor-pointer"
                                  onClick={() => {
                                    watch(`invoiceFile[${indexValue}]`);
                                    setValue(`invoiceFile[${indexValue}]`, []);
                                    setPDFError((prevErrors) => ({
                                      ...prevErrors,
                                      [indexValue]: {
                                        type: '',
                                        message: ``,
                                      },
                                    }));
                                  }}
                                />
                                <span
                                  style={{
                                    fontWeight: 'bold',

                                    color:
                                      pdfError && pdfError[selectedTab.index]?.type === 'success'
                                        ? '#096c09'
                                        : 'rgb(211 45 45)',
                                    marginLeft: '5px',
                                  }}
                                >
                                  {pdfError && pdfError[selectedTab.index]?.message}
                                </span>
                              </div>
                            ) : (
                              <div className="drop-zone position-relative w-100 text-center">
                                <div>
                                  <input
                                    type="file"
                                    {...register(`invoiceFile[${indexValue}]`, {
                                      onChange: (e) => {
                                        const file = e.target.files[0];

                                        // Check if the file is a PDF
                                        if (file && file.type !== 'application/pdf') {
                                          setError('invoiceFile', {
                                            type: 'pdf',
                                            message: 'Only PDFs are valid.',
                                          });
                                        } else {
                                          // Check if the file size is within the limit (5MB)
                                          if (file && file.size > 5 * 1024 * 1024) {
                                            setPDFError((prevErrors) => ({
                                              ...prevErrors,
                                              [indexValue]: {
                                                type: 'fileSize',
                                                message: `File size exceeds the limit 5MB`,
                                              },
                                            }));
                                          } else {
                                            setPDFError((prevErrors) => ({
                                              ...prevErrors,
                                              [indexValue]: {
                                                type: 'success',
                                                message: <FaRegCheckCircle />,
                                              },
                                            }));
                                            setShow({
                                              ...show,
                                              PDFData: watch('invoiceFile'),
                                            });
                                          }
                                        }
                                      },
                                    })}
                                    accept="application/pdf"
                                  ></input>
                                  <span>{t('Drag or upload pdf')}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        </OverlayTrigger>
                      </>
                    )}
                  </div>
                );
              })}

              {(Number(customer?.accountType) === 0 || Number(customer?.accountType) === 1) && (
                <div className="work-details-col-wrapper mt-3 bg-secondary-custom">
                  <div className="white-box mt-2">
                    <h6>{t('Root and Rut tax deduction')}</h6>
                    <p>
                      {' '}
                      {t(
                        'If your invoice relates to a job that entitles your client to claim allowances for home maintenance and repairs, click below.'
                      )}
                    </p>
                    <div className="checkbox-wrapper">
                      <div className="form-check d-flex align-items-center mb-2">
                        <input
                          className="form-check-input cursor-pointer"
                          type="checkbox"
                          id="rootDeduction"
                          {...register('rootDeduction', {
                            value: false,
                            onChange: (event) => {
                              event.target.checked && setValue('rutDeduction', false);
                              if (event.target.checked) {
                                setValue('house', false);
                                setValue('apartment', true);
                                setValue('showDates', 'true');
                              }
                            },
                          })}
                        />
                        <label className="form-check-label pl-2 mt-1 cursor-pointer" htmlFor="rootDeduction">
                          {t('Root-deduction')}
                        </label>
                      </div>
                      <div className="inner-fields">
                        {rootDeduction && (
                          <>
                            <label className="mt-3 choose">{t('Choose type of accommodation')}</label>
                            <br></br>
                            <div className="mb-2">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input cursor-pointer"
                                  id="customCheck"
                                  name="example1"
                                  {...register('house', {
                                    onChange: (event) => {
                                      event.target.checked && setValue('apartment', false);
                                      if (!apartment) {
                                        setValue('house', true);
                                      }
                                    },
                                  })}
                                />
                                <label className="custom-control-label cursor-pointer" htmlFor="customCheck">
                                  {t('House')}
                                </label>
                              </div>
                            </div>
                            {house && (
                              <>
                                <input
                                  type="text"
                                  {...register('rotApartmentNo')}
                                  className="form-control w-50 bg-light my-2"
                                  placeholder={t('Property')}
                                  autoFocus
                                />
                              </>
                            )}
                            <div className="mb-3">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input cursor-pointer"
                                  id="customCheck2"
                                  name="example1"
                                  {...register('apartment', {
                                    value: true,
                                    onChange: (event) => {
                                      event.target.checked && setValue('house', false);
                                      if (!house) {
                                        setValue('apartment', true);
                                      }
                                    },
                                  })}
                                />
                                <label className="custom-control-label cursor-pointer" htmlFor="customCheck2">
                                  {t('Apartment')}
                                </label>
                              </div>
                            </div>
                            {apartment && (
                              <>
                                <input
                                  type="text"
                                  {...register('brfNo')}
                                  placeholder={t('Brf org. number')}
                                  className="form-control w-50 bg-light my-2"
                                  autoFocus
                                />
                                <input
                                  type="text"
                                  {...register('rotApartmentNo')}
                                  placeholder={t('Apartment number')}
                                  className="form-control w-50 bg-light "
                                />
                                <br />
                              </>
                            )}
                          </>
                        )}
                      </div>
                      <div className="form-check d-flex align-items-center">
                        <input
                          className="form-check-input cursor-pointer"
                          type="checkbox"
                          id="rutDeduction"
                          {...register('rutDeduction', {
                            value: false,
                            onChange: (event) => event.target.checked && setValue('rootDeduction', false),
                          })}
                        />
                        <label className="form-check-label pl-2 mt-1 cursor-pointer" htmlFor="rutDeduction">
                          {t('Rut-deduction')}
                        </label>
                      </div>
                    </div>
                    <div className="hsd-link mt-2">
                      <Link to="" className="pointer-link" onClick={() => setShowRootRut(true)}>
                        {' '}
                        {t('Read more about Root- and Rut-deduction')}
                        <BiRightArrowAlt />
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              <div className="row m-0 px-3 py-3 justify-content-between align-items-center bg-primary">
                <div className="col p-0">
                  <p className="text-light m-0">{t('Invoice amount excl. VAT')}</p>
                </div>
                <div className="col-custom d-flex align-items-center ">
                  <div className="form-check d-flex align-items-center mr-2">
                    {/* <OverlayTrigger
                      show={
                        (isFixed && showTooltipError.LessAmount) ||
                        showTooltipError.NullAmount ||
                        showTooltipError.negativeAmount ||
                        showTooltipError.fixedPriceWithoutDate ||
                        showTooltipError.notOfFixedPrice
                      }
                      placement="top"
                      overlay={
                        <Popover id={`popover-positioned-top`} style={{ margin: 0 }}>
                          <Popover.Header className="text-danger" as="h3">
                            {showTooltipError.fixedPriceWithoutDate && t('Please Select The date From The Calender')}
                            {showTooltipError.notOfFixedPrice && t('Please Select Fixed Price')}
                            {showTooltipError.negativeAmount && t("Amount value can't be Negative")}
                            {showTooltipError.LessAmount && t("Amount value can't be Less then 500")}
                            {showTooltipError.NullAmount && t('Please Enter Amount')}
                          </Popover.Header>
                        </Popover>
                      }
                    > */}
                    <input
                      className="form-check-input text-dark"
                      type="checkbox"
                      id="isFixed"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={
                        !allArticlesHaveNumber92() &&
                        t("All articles must be 'Payment per hour' to enable fixed pricing.")
                      }
                      disabled={!allArticlesHaveNumber92() || rootDeduction || rutDeduction}
                      onClick={(event) => handleChangeFixedPrice(event)}
                      {...register('isFixed')}
                    />
                    {/* </OverlayTrigger> */}
                    <label className="form-check-label pl-2 mt-1 text-white cursor-pointer" htmlFor="isFixed">
                      {t('Fixed Price')}
                    </label>
                  </div>
                  <div>
                    <OverlayTrigger
                      style={{ marginRight: '80px' }}
                      show={
                        showTooltipError.LessAmount ||
                        showTooltipError.NullAmount ||
                        showTooltipError.negativeAmount ||
                        showTooltipError.LessAmountEUR ||
                        showTooltipError.LessAmountUSD ||
                        showTooltipError.LessAmountGBP ||
                        showTooltipError.LessAmountCHF ||
                        showTooltipError.LessAmountCAD ||
                        showTooltipError.LessAmountAUS ||
                        showTooltipError.LessAmountNOK ||
                        showTooltipError.LessAmountDKK ||
                        showTooltipError.LessAmountJPY ||
                        showTooltipError.LessAmountCNY
                      }
                      placement="left"
                      overlay={
                        <Popover id={`popover-positioned-top`} style={{ margin: 0 }}>
                          <Popover.Header className="text-danger" as="h3">
                            {showTooltipError.notOfFixedPrice && t('Please Select Fixed Price')}
                            {showTooltipError.negativeAmount && t("Amount value can't be Negative")}
                            {showTooltipError.NullAmount && t('Please enter amount')}
                            {showTooltipError.LessAmount && t('The minimum allowable amount is 1000.')}
                            {showTooltipError.LessAmountEUR && t('The minimum allowable amount is 100.')}
                            {showTooltipError.LessAmountUSD && t('The minimum allowable amount is 108.')}
                            {showTooltipError.LessAmountGBP && t('The minimum allowable amount is 86.')}
                            {showTooltipError.LessAmountCHF && t('The minimum allowable amount is 96.')}
                            {showTooltipError.LessAmountCAD && t('The minimum allowable amount is 148.')}
                            {showTooltipError.LessAmountAUS && t('The minimum allowable amount is 166.')}
                            {showTooltipError.LessAmountNOK && t('The minimum allowable amount is 1180.')}
                            {showTooltipError.LessAmountDKK && t('The minimum allowable amount is 746.')}
                            {showTooltipError.LessAmountJPY && t('The minimum allowable amount is 15760.')}
                            {showTooltipError.LessAmountCNY && t('The minimum allowable amount is 774.')}
                          </Popover.Header>
                        </Popover>
                      }
                    >
                      <input
                        className="border-0 btn-primary w-75 m-0 p-0 bg-light text-dark"
                        placeholder={currency}
                        min="0"
                        readOnly={(!watch('invoiceHours') && !anyArrayHasValue) || !isFixed}
                        {...register('totalAmount')}
                        onClick={() => {
                          if (!isFixed) {
                            setShowTooltipError({
                              notOfFixedPrice: true,
                            });
                            setTimeout(() => {
                              setShowTooltipError({
                                notOfFixedPrice: false,
                              });
                            }, 3000);
                          }
                        }}
                        onKeyDown={(e) => {
                          // Allow only numbers, decimal point, and specific control keys
                          if (
                            !/[\d.]/.test(e.key) &&
                            !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete'].includes(e.key)
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onKeyUp={(e) => handleTotalAmountChange(e)}
                        {...amountFieldRegister}
                        onChange={debounce(async (e) => {
                          await amountFieldRegister.onChange(e);
                        }, 600)}
                      />
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
              <div className="currency-vat d-flex px-4 py-1 justify-content-between align-items-center bg-secondary-custom mb-3">
                <div className="d-flex align-items-center">
                  <p className="text-primary-custom text-small font-weight-bold m-0">{t('CURRENCY')}:</p>

                  <select
                    className="ml-2 cursor-pointer"
                    {...register('currency', {
                      value: 'SEK',
                      onChange: () =>
                        setShow((prevState) => ({
                          ...prevState,
                          showCurrencyDetails: true,
                        })),
                    })}
                  >
                    {globalData &&
                      globalData.currencies &&
                      globalData.currencies?.map((value) => {
                        return (
                          <option key={value.id} value={value.description}>
                            {value.description}
                          </option>
                        );
                      })}
                  </select>

                  <p className="text-primary-custom text-small font-weight-bold m-0 ml-2">{t('VAT')}:</p>
                  <select
                    className="ml-2 cursor-pointer"
                    {...register('vat', {
                      value: 25,
                      onChange: () =>
                        setShow((prevState) => ({
                          ...prevState,
                          showVatDetails: true,
                        })),
                    })}
                  >
                    {globalData &&
                      globalData.vat &&
                      globalData.vat?.map((value) => {
                        return (
                          <option key={value.id} value={value.globalValue}>
                            {value.globalValue}%
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div>
                  <div className="text-primary-custom text-small font-weight-bold m-0">
                    {t('INVOICE AMOUNT INCL. VAT')}:{' '}
                    {spinner && !expressPayout ? (
                      <div className="spinner-border text-primary-custom spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : calculatedAmounts?.amountIncludingVat < 0 ? (
                      0
                    ) : amount == '' ? (
                      0
                    ) : (
                      Math.round(calculatedAmounts?.amountIncludingVat || 0)
                    )}{' '}
                    {currency}
                  </div>
                </div>
              </div>
              <div>
                {errors.amount && (
                  <div className="alert alert-danger" role="alert">
                    {errors.amount.message}
                  </div>
                )}
              </div>
              <div className="float-right">
                <a
                  className="cursor-pointer"
                  onClick={() =>
                    setShow((prevState) => ({
                      ...prevState,
                      showCurrencyDetails: true,
                    }))
                  }
                >
                  {t('How we calculate the currency rates')}?
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 bg-secondary-custom px-3 py-3 mt-3">
          <div className="row m-0 mb-3 px-3 py-3 justify-content-between align-items-center bg-primary">
            <div className="col p-0">
              <p className="text-light m-0">
                {t('A minimum fee of 250 SEK applies if 2% of the invoice amount is lower.')}
              </p>
            </div>
          </div>

          <div className="bg-primary px-4 py-4 d-flex justify-content-between mb-3">
            <p className="m-0 text-white font-weight-bold">{t('Paid to your bank account')}</p>
            <p className="m-0 text-white font-weight-bold">
              SEK {/* {currency} */}{' '}
              {spinner ? (
                <span className="spinner-border text-light spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </span>
              ) : amount == 0 ? (
                0
              ) : Math.round(calculatedAmounts?.toPayInSek) ? (
                Math.round(calculatedAmounts?.toPayInSek)
              ) : (
                0
              )}
            </p>
          </div>
          {(hasWorkingHours || anyArrayHasValue) && (
            <p className="text-right text-primary-custom mb-0">
              <a
                className="cursor-pointer"
                onClick={() => {
                  handleSeeCalculations(0);
                  setShowSubmit(false);
                }}
              >
                {t('See how we calculated')}?
              </a>
            </p>
          )}
          <div className="section-padding mb-4">
            <div className="col-sm-12">
              <div className="invoice-offers row mt-4">
                <div className="col w-100">
                  <div className="col-md-8">
                    {customer?.paymentTerm == '45' || customer?.paymentTerm == '60' ? (
                      <h6 className="text-dark mb-3 mt-3">
                        {t('Do you want Express payment on the same day?')} <a href="#">{t('Read more')}</a>
                      </h6>
                    ) : (
                      <h6 className="text-dark mb-3 mt-3">
                        {t('Do you want Express payment on the same day?')}{' '}
                        <a
                          href="#"
                          onClick={() => {
                            setShowExpressPayment(true);
                            setValue('expressPayout', true);
                          }}
                        >
                          {t('Read more')}
                        </a>
                      </h6>
                    )}

                    <input
                      className="cursor-pointer"
                      type="checkbox"
                      id="express-betalning"
                      name="express-betalning"
                      disabled={customer?.paymentTerm == '45' || customer?.paymentTerm == '60'}
                      {...register('expressPayout')}
                      onClick={(e) => {
                        if (e.target.checked) {
                          setShowExpressPayment(true);
                          setValue('expressPayout', true);
                        } else {
                          setShowExpressPayment(false);
                          setValue('expressPayout', false);
                        }
                      }}
                    />
                    <img src={invoiceImg} className="ml-3 w-25 d-inline-block" />
                  </div>
                </div>
                <div className="col-custom d-block align-items-center justify-content-between pr-0 mt-5">
                  {isPensionApplicable(userDetails?.ssn) && (
                    <div className="form-check d-flex align-items-center mr-2">
                      <input
                        className="form-check-input text-dark cursor-pointer"
                        type="checkbox"
                        id="pension"
                        onClick={() => setPensionDrop(pension ? false : true)}
                        {...register('pension')}
                      />
                      <label
                        htmlFor="pension"
                        className="form-check-label pl-2 mt-1 text-primary-custom cursor-pointer"
                      >
                        <b>{t('Pension')}</b>
                      </label>

                      <Dropdown
                        style={{ visibility: pension ? 'visible' : 'hidden' }}
                        className="ml-2"
                        show={isPensionDrop}
                        onToggle={() => setPensionDrop(!isPensionDrop)}
                      >
                        <Dropdown.Toggle className="text-primary-custom bg-custom" variant="secondary">
                          {pensionFee}%
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{
                            overflow: 'auto',
                            height: '178px',
                          }}
                        >
                          {globalData &&
                            globalData.currencies &&
                            globalData?.pension?.map((value) => {
                              return (
                                <Dropdown.Item
                                  className="text-primary-custom"
                                  key={value.id}
                                  value={value.globalValue}
                                  onClick={(e) => {
                                    setPensionFee(parseInt(value.globalValue, 10));
                                  }}
                                >
                                  {value.globalValue}%
                                </Dropdown.Item>
                              );
                            })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}

                  <div className="form-check d-flex align-items-center mr-2 mt-2">
                    <input
                      className="form-check-input text-dark cursor-pointer"
                      type="checkbox"
                      id="holidayPay"
                      onClick={() => setHolidaySavingDrop(holidaySavings ? false : true)}
                      {...register('holidaySavings')}
                    />
                    <label
                      className="form-check-label pl-2 mt-1 cursor-pointer text-primary-custom"
                      htmlFor="holidayPay"
                    >
                      <b>{t('Holiday pay')}</b>
                    </label>

                    <Dropdown
                      style={{
                        visibility: holidaySavings ? 'visible' : 'hidden',
                      }}
                      className="ml-2"
                      show={isHolidaySavingDrop}
                      onToggle={() => setHolidaySavingDrop(!isHolidaySavingDrop)}
                    >
                      <Dropdown.Toggle className="text-primary-custom bg-custom" variant="secondary">
                        {holidaySavingFee}%
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        style={{
                          overflow: 'auto',
                          height: '178px',
                        }}
                      >
                        {globalData &&
                          globalData.currencies &&
                          globalData?.holidaySavingsFee?.map((value) => {
                            return (
                              <Dropdown.Item
                                className="text-primary-custom"
                                key={value.id}
                                value={value.globalValue}
                                onClick={(e) => {
                                  setHolidaySavingFee(parseInt(value.globalValue, 10));
                                }}
                              >
                                {value.globalValue}%
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Button
            className="mb-2 p-0"
            onClick={async () => {
              const validated = await trigger(['selectedCustomer', 'description', 'email', 'customCheck', 'amount'], {
                shouldFocus: true,
              });
              if (validated) {
                amountValidation(true);
              }
            }}
            variant="link"
            size="sm"
          >
            {t('See invoice that will be sent to customer')}
          </Button>
          <div>
            {errors.customCheck && (
              <div className="alert alert-danger" role="alert">
                {errors.customCheck.message}
              </div>
            )}
          </div>
          <div className="mt-3">
            <Modal show={showModal} onHide={() => setShowModal(false)}>
              <Modal.Header>
                <Modal.Title>{t('Close Invoice Tool')}</Modal.Title>
                <AiOutlineClose className="ml-auto cursor-pointer" size={24} onClick={() => setShowModal(false)} />
              </Modal.Header>
              <Modal.Body>
                {t('Are you sure you want to close the invoice tool? Any changes will be removed.')}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  {t('No')}
                </Button>
                <Button className="text-light" variant="primary" onClick={closeInvoiceTool}>
                  {t('Yes')}
                </Button>
              </Modal.Footer>
            </Modal>

            <Button onClick={() => setShowModal(true)} variant="outline-primary" size="md" className="bg-light">
              {t('Cancel')}
            </Button>
            <Button variant="primary" type="submit" className="ml-3 text-light filled orange" size="md">
              {t('Complete billing')}
            </Button>
          </div>
        </div>
      </form>
      {showDialog && (
        <EditCustomerDialog
          title={t('Add new Customer')}
          show={true}
          onClose={() => setShowDialog(null)}
          onSubmit={addNewCustomer}
          paymentTerms={globalData.paymentTerms}
          globalData={globalData}
        />
      )}
      {console.log(customer, '====customer')}

      {show.show && (
        <EditCustomerDialog
          title={'Edit Customer'}
          show={true}
          onClose={() =>
            setShow({
              ...show,
              show: false,
            })
          }
          onSubmit={onEditCustomer}
          globalData={globalData}
          paymentTerms={globalData.paymentTerms}
          customer={{
            name: customer?.name,
            orgNo: customer?.orgNo,
            ssn: customer?.ssn,
            phoneNumber: customer?.phoneNumber,
            reference: customer?.reference,
            email: customer?.email,
            secondaryEmailAddress: customer?.secondaryEmailAddress,
            address: customer?.address,
            address2: customer?.address2,
            co: customer?.co,
            city: customer?.city,
            zipCode: customer?.zipCode,
            isVATRegistred: customer?.isVATRegistred,
            country: customer?.country,
            paymentTerm: customer?.paymentTerm,
            accountType: customer?.accountType,
            vatNumber: customer?.vatNumber,
            invoiceType: customer?.customerAddress ? customer?.customerAddress?.[0]?.invoiceType : customer?.invoiceType,
            yourElectronicReference: customer?.customerAddress ? customer?.customerAddress?.[0]?.yourElectronicReference : customer?.yourElectronicReference,
            extraField1: customer?.customerAddress ? customer?.customerAddress?.[0]?.extraField1 : customer?.extraField1,
            extraField2: customer?.customerAddress ? customer?.customerAddress?.[0]?.extraField2 : customer?.extraField2,
          }}
        />
      )}
      {showInvoice && (
        <ShowInvoiceDialog
          show={true}
          selectedFreelancers={selectedFreelancers}
          onClose={() => setShowInvoice(false)}
          customer={selectedCustomer}
          formValues={getValues()}
          calculatedAmounts={calculatedAmounts}
          currency={currency}
          user={userDetails}
          otherArticleValues={otherArticlesData}
          createdDate={prefillingData?.getInvoiceData?.fetchInvoices?.createdDate}
          getPdfUrl={show?.PDFData}
          totalHours={totalHours}
        />
      )}

      <HowCalculatedModal
        t={t}
        show={show}
        setShow={setShow}
        currency={currency}
        calculatedAmounts={calculatedAmounts}
        otherArticlesData={otherArticlesData}
        seperatedUserCalculations={seperatedUserCalculations}
        selectedList={selectedList}
        isSeparatelyShown={isSeparatelyShown}
        setIsSeparatelyShown={setIsSeparatelyShown}
        userInvoiceData={userInvoiceData}
        customer={customer?.customerAddress ? customer?.customerAddress?.[0] : customer}
      />

      {showSubmit && (
        <Modal show={true} onHide={() => setShowSubmit(false)} className="center" style={{ marginTop: '100px' }}>
          <Modal.Header>
            <Modal.Title>
              {t('Summary')}
              <h6 className="m-0 text-primary-custom">
                {t('You are about to create an invoice with the following information')}
              </h6>
            </Modal.Title>
            <AiOutlineClose className="ml-auto cursor-pointer" size={24} onClick={() => setShowSubmit(false)} />
          </Modal.Header>
          <Modal.Body className="bg-secondary-custom">
            <div className="d-flex align-items-center mx-4 my-3">
              <BsPerson size={36} />
              <h6 className="m-0 ml-4">{customer?.name}</h6>
            </div>
            <div className="d-flex align-items-center mx-4 my-3">
              <div className="infolist-icon">
                <p className="m-0">{currency}</p>
                <svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} viewBox="0 0 20 20">
                  <path d="M19 17.55H1a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h18a.5.5 0 0 1 .5.5v10c0 .275-.225.5-.5.5zm-17.5-1h17v-9h-17v9zM18 5.55H2a.5.5 0 0 1 0-1h16a.5.5 0 0 1 0 1zM17 3.55H3a.5.5 0 0 1 0-1h14a.5.5 0 0 1 0 1z" />
                </svg>
              </div>
              <h6 className="m-0 ml-4">
                {currency} {amount} {t('excl.')} {t('VAT')}
              </h6>
            </div>
            <div className="d-flex align-items-center mx-4 my-3">
              <svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} viewBox="0 0 20 20">
                <path d="M10.5 0h-4a.5.5 0 0 0-.5.5v5.586c-.582.206-1 .762-1 1.414C5 8.327 5.673 9 6.5 9S8 8.327 8 7.5c0-.652-.418-1.208-1-1.414V3h3.5a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5zm-4 8a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zM10 2H7V1h3v1z" />
                <path d="M19.088 6.945c-.354-.916-.818-1.628-1.38-2.118a3.303 3.303 0 0 0-2.234-.826H8.5a.5.5 0 0 0 0 1h6.974c2.79 0 3.469 4.236 3.522 8H2.999v-8h1.5a.5.5 0 0 0 0-1h-1.5v-.5a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-.5h5v5.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-5.5h7.5a.5.5 0 0 0 .5-.5c0-2.785-.307-4.99-.912-6.555zM2 14H1V4h1v10zm9 5H9v-5h2v5z" />
              </svg>
              <h6 className="m-0 ml-4">
                {t('Email')} {customer?.email}
              </h6>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowSubmit(false)}>
              {t('Cancel')}
            </Button>
            <Button
              className="text-light"
              variant="primary"
              onClick={() => (searchParams?.size === 1 ? updateInvoices(getValues()) : createInvoice(getValues()))}
            >
              {searchParams?.size === 1 ? t('Update invoice') : t('Create invoice')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {invoiceId && (
        <InvoiceCreatedModal
          t={t}
          setInvoiceId={setInvoiceId}
          setDates={setDates}
          setCalculatedAmounts={setCalculatedAmounts}
          setCustomer={setCustomer}
          reset={reset}
          navigate={navigate}
        />
      )}

      {showReadMore && (
        <Modal centered show={true} onHide={() => setShowReadMore(false)}>
          <Modal.Header>
            <Modal.Title>{t('Why is working time important to fill in?')}</Modal.Title>
            <AiOutlineClose className="ml-auto cursor-pointer" size={24} onClick={() => setShowReadMore(false)} />
          </Modal.Header>
          <Modal.Body>
            <p>
              {t(
                'When you invoice through Invozio, you are to be able to invoice without F-tax, employed by Invozio during the assignment period. We also want to be able to ensure that you work under good working conditions. The registered working hours form the basis for your employment and income information, including the social insurance office and employment certificate.'
              )}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="text-light" variant="primary" onClick={() => setShowReadMore(false)}>
              {t('Close')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {showWorkDatesNotification && (
        <Modal centered show={true} onHide={() => setShowWorkDatesNotification(false)}>
          <Modal.Header>
            <AiOutlineClose
              className="ml-auto cursor-pointer"
              size={24}
              onClick={() => {
                removeUnapprovedDate();
              }}
            />
          </Modal.Header>
          <Modal.Body>{t('Your stated work dates have not taken place, is this correct?')}</Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={() => removeUnapprovedDate()}>
              {t('Cancel')}
            </Button>
            <Button className="outlines-primary" onClick={() => setShowWorkDatesNotification(false)}>
              {t('Approve Work Date')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <Modal
        centered
        show={show.showVatDetails}
        onHide={() => setShow((prevState) => ({ ...prevState, showVatDetails: false }))}
      >
        <Modal.Header>
          <Modal.Title>{t('VAT')}</Modal.Title>
          <AiOutlineClose
            className="ml-auto cursor-pointer"
            size={24}
            onClick={() => setShow((prevState) => ({ ...prevState, showVatDetails: false }))}
          />
        </Modal.Header>
        <Modal.Body>
          <p>
            {t(`The Swedish Tax Agency's main rule is that goods and services invoiced within Sweden have a VAT surcharge of 25%. However, there are a few exceptional cases where the tax rate can be 12%, 6% or 0%.
                     Examples of extraordinary circumstances with 6% VAT are copyrighted material.
             Exports, work in international waters, CSN-eligible education, and certain types of healthcare are VAT exempt. Artist and musician gages have a 25% VAT surcharge, except when the artist or musician owns the invoicing company, in which case the gages are VAT exempt. If you are unsure which VAT rate applies to your work, don't hesitate to contact us at`)}
            <a href="mailto:support@invozio.com">
              <u> support@invozio.com</u>
            </a>
          </p>
        </Modal.Body>
      </Modal>

      <Modal
        show={show.showCurrencyDetails}
        className="invoice-modal"
        onHide={() => setShow((prevState) => ({ ...prevState, showCurrencyDetails: false }))}
      >
        <Modal.Header>
          <Modal.Title>{t('How we calculate the currency rates')}</Modal.Title>
          <AiOutlineClose
            className="ml-auto cursor-pointer"
            size={24}
            onClick={() =>
              setShow((prevState) => ({
                ...prevState,
                showCurrencyDetails: false,
              }))
            }
          />
        </Modal.Header>
        <Modal.Body>
          <p>
            {t(
              "You can also invoice in EUR, USD, GBP, CHF, CAD, AUD, NOK, DKK, JPY and CNY. But the salary will be paid in SEK, at Swedbank's exchange rate on the day the invoice is paid. Due to the fact that we cannot guarantee the rate today, the amount to your bank account as below does not match."
            )}
          </p>
          <p>
            {t('Contact us if you have any questions')}
            <a href="mailto:info@invozio.com">
              <u> info@invozio.com</u>
            </a>
          </p>
        </Modal.Body>
      </Modal>

      {showRootRut && (
        <Modal size="lg" show={true} onHide={() => setShowRootRut(false)}>
          <Modal.Header>
            <Modal.Title>{t('Root and square deductions')}</Modal.Title>
            <AiOutlineClose className="ml-auto cursor-pointer" size={24} onClick={() => setShowRootRut(false)} />
          </Modal.Header>
          <Modal.Body>
            <p>{t('A person who hires you to do ROT')}</p>
            <p>{t('Who can use the deduction?')}</p>
            <p>{t('A buyer who was unlimitedly liable')}</p>
          </Modal.Body>
        </Modal>
      )}
      {showAgreement && (
        <Modal size="lg" show={true} onHide={() => setShowAgreement(false)}>
          <Modal.Header>
            <Modal.Title>{t('Agreement On General Fixed-Term Employment')}</Modal.Title>
            <AiOutlineClose className="ml-auto cursor-pointer" onClick={() => setShowAgreement(false)} />
          </Modal.Header>
          <Modal.Body>
            <p>{t('I agree that my personal')}</p>
            <p>
              {t('General Terms and Conditions')}
              <br />
              <b>1--</b> {t('Scope These General')}
              <br />
              <b>2--</b> {t('Examination and credit')}
              <br />
            </p>
            <p>
              <b>3--</b> {t('Right to work')}
              <br />
              <b>4--</b> {t('Employment The Applicant')}
              <br />
              <b>5--</b> {t('Certificate of fitness for work')}
              {t('The Applicant certifies')}
              <br />
              <b>6--</b> {t('Work The Employee')}
            </p>
            <p>
              {t('the right to refuse')}
              <br />
            </p>
            <p>
              <b>7--</b> {t('Intellectual property rights')}
              <br></br>
              <b>8--</b> {t('Confidentiality')}
              <br></br>
              <b>9--</b> {t('Personal data processing')}
            </p>
            <p>
              {t('described. Suppose')}
              <br></br>
              <b>10--</b> {t('Insurance Provided that')}
              <br></br>
              <b>11--</b> {t('Disclaimer Bastian Group')}
              <br></br> <b>12--</b> {t('Communication with the Client')} <br></br> <b>13--</b>{' '}
              {t('Termination of agreement')}
            </p>
            <p>
              {t('Group about circumstances')}
              <br></br>
              <b>14--</b> {t('Right to reclaim')}
              <br></br>
              <b>15--</b> {t('Amendment of General')}
            </p>
            <Modal.Footer>
              <Button className="text-light" variant="primary" onClick={() => setShowAgreement(false)}>
                {t('Close')}
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      )}

      {showExpressPayment && (
        <Modal size="lg" className="invoice-modal" show={true} onHide={() => setShowExpressPayment(false)}>
          <Modal.Header>
            <Modal.Title>{t('Express payment')}</Modal.Title>
            <AiOutlineClose className="ml-auto cursor-pointer" size={24} onClick={() => setShowExpressPayment(false)} />
          </Modal.Header>
          <Modal.Body>
            <p>
              {t(
                `To qualify for Express Payment with Invozio, both you and your customer must have an 'Approved' credit status without remarks as per Invozio's credit policy. We conduct a credit check (UC) on both you and your customer the first time you use Express Payment, and for each new customer thereafter. Additionally, we perform a delivery check with your customer to verify the accuracy of the invoice. This check is initially done and then randomly at various times.`
              )}
            </p>
            <p>
              {t(
                `Payment of your salary is made on the same day once all criteria are met. However, the exact timing of payment depends on how quickly your customer verifies the invoice. If the delivery check is completed before 14:00 on the same day, we aim to pay you the same day or at the latest by the next morning after a weekend or holiday. The cost for Express Payment is 6% of the total invoice amount if this fee does not reach 500 SEK, a minimum fee of 500 SEK is charged, compared to the standard 2% and the minimum fee of 250 SEK if you wait for your customer to pay the invoice.`
              )}
            </p>
            <p>
              {t(
                "Conditions for 'Approved' status include no payment remarks for both you and the customer, the customer not being yourself or someone with whom you have a significant common interest, workdays not older than 30 days, customer registration with the Swedish Companies Registration Office, customer approval of your created invoice, and no dispute regarding the work performed. By choosing Express Payment, you consent to us conducting a UC check on both you and your customer. Express Payments are not accepted for private individuals and sole proprietorships."
              )}
            </p>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default InvoiceTool;