import React, { useRef, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import { Button } from '@mui/material';

// Signature Pad Component
const SignaturePadComponent = ({ onSave }) => {
  const sigPad = useRef(null);
  const [isEmpty, setIsEmpty] = useState(true);

  const clear = () => {
    sigPad.current.clear();
    setIsEmpty(true);
  };

  const save = () => {
    if (sigPad.current.isEmpty()) {
      alert('Please provide a signature first.');
    } else {
      const dataURL = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
      onSave(dataURL);
      setIsEmpty(false);
    }
  };

  return (
    <div className="signature-border">
      <SignaturePad
        ref={sigPad}
        canvasProps={{ width: 500, height: 200, border: 1, className: 'sigCanvas' }}
        onEnd={() => setIsEmpty(false)}
      />

      <div>
        <Button onClick={clear}>Clear</Button>
        <Button onClick={save}>Save</Button>
      </div>
    </div>
  );
};

export default SignaturePadComponent;
