/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Typography, Box, Modal } from '@mui/material';
import axios from 'axios';
import { getModalBoxStyle } from '../../../utils/commonFunc';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const MultipleUsersPDF = ({ openPDFModal, setOpenPDFModal, selectedUserId }) => {
  const [selectedUsersData, setSelectedUsersData] = useState([]);
  const [isPDFDownloaded, setIsPDFDownloaded] = useState();

  const mapIDs = useCallback(async () => {
    try {
      const result = await Promise.all(
        selectedUserId?.map(async (id) => {
          const res = await axios.get(`/admin/user/user/${id}`);
          if (res?.status === 200) {
            return res?.data?.response;
          }
        })
      );
      if (result) {
        setSelectedUsersData(result);
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  }, [selectedUserId]);

  useEffect(() => {
    mapIDs();
  }, [mapIDs]);

  const handleDownloadPDF = async () => {
    setIsPDFDownloaded(false);
    const element = document.getElementById('users-info-div');

    if (element) {
      const doc = new jsPDF('p', 'mm');
      const imgWidth = 220;
      const pageHeight = 250;

      const addPage = (imgData) => {
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, 0, imgWidth, pageHeight);
      };

      const generatePDF = async (index) => {
        if (index >= element.children.length) {
          return Promise.resolve(); // Indicate completion
        }

        const canvas = await html2canvas(element.children[index]);
        const imgData = canvas.toDataURL('image/jpeg', 1.0);

        if (index === 0) {
          doc.addImage(imgData, 'PNG', 0, 0, imgWidth, pageHeight);
        } else {
          addPage(imgData);
        }

        return generatePDF(index + 1);
      };

      await generatePDF(0);
      doc.save(`users-info.pdf`);
      setIsPDFDownloaded(true);
    }
  };

  return (
    <div>
      <Modal
        open={openPDFModal}
        onClose={() => setOpenPDFModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <div > */}
        <Box sx={getModalBoxStyle()}>
          <p className="user">User Details</p>
          <div id="users-info-div">
            {selectedUsersData &&
              selectedUsersData?.length > 0 &&
              selectedUsersData?.map((item) => (
                <Box key={item?.user?.userId} style={{ borderBottom: '1px solid grey' }}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    <div className="person-info">
                      <div className="view-details">
                        <div className="user-heading d-flex align-items-center">
                          <h2 className="m-0">
                            Personal Information
                            <span style={{ marginLeft: '5px' }}>(User Id - {item?.user?.userId})</span>
                          </h2>
                        </div>

                        <div className="first-group">
                          <div className="left-data">
                            <h1>First Name</h1>
                            <p>{item?.user?.firstName}</p>
                          </div>
                          <div className="right-data">
                            <h1>Last Name</h1>
                            <p>{item?.user?.lastName}</p>
                          </div>
                        </div>
                        <div className="first-group">
                          <div className="left-data">
                            <h1>Email Address</h1>
                            <p>{item?.user?.email}</p>
                          </div>
                          <div className="right-data">
                            <h1>Address</h1>
                            <p>{item?.user?.address}</p>
                          </div>
                        </div>
                        <div className="first-group">
                          <div className="left-data">
                            <h1>Postal code</h1>
                            <p>{item?.user?.zipCode}</p>
                          </div>
                          <div className="right-data">
                            <h1>City</h1>
                            <p>{item?.user?.city}</p>
                          </div>
                        </div>
                        <div className="first-group">
                          <div className="left-data">
                            <h1>Country</h1>
                            <p>{item?.user?.country}</p>
                          </div>
                          <div className="right-data">
                            <h1>Telephone Number</h1>
                            <p>{item?.user?.phoneNo}</p>
                          </div>
                        </div>
                        <div className="first-group">
                          <div className="left-data">
                            <h1>{item?.user?.dateOfBirth !== '' ? 'Date of Birth' : 'SSN No.'} </h1>
                            <p>{item?.user?.dateOfBirth !== '' ? item?.user?.dateOfBirth : item?.user?.ssn}</p>
                          </div>
                          <div className="right-data">
                            <h1>Account Type</h1>
                            <p>
                              {item?.accountType === 1
                                ? 'Private Customer'
                                : item?.accountType === 2
                                ? 'Business Customer'
                                : 'Business Construction'}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="bank-details">
                        <div className="bank-information">
                          <h4>Bank information</h4>
                        </div>

                        <div className="info-group">
                          <div className="details">
                            <h1>Bank</h1>
                            <p>{item?.user?.customerSalaryInformation[0]?.bank}</p>
                          </div>
                          <div className="card-details d-flex">
                            <div className="details">
                              <h1>Clearing number</h1>
                              <p>{item?.user?.customerSalaryInformation[0]?.clearingNumber}</p>
                            </div>
                            <div className="details">
                              <h1>Account number</h1>
                              <p>{item?.user?.customerSalaryInformation[0]?.accountNumber}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Typography>
                </Box>
              ))}
          </div>
          <div
            className="footer-btn"
            style={{ margin: '25px 0 0', float: 'right', gap: '10px', display: 'flex', alignItems: 'center' }}
          >
            <Button sx={{ float: 'right', backgroundColor: '#62b5e514' }} onClick={() => setOpenPDFModal(false)}>
              {'Close'}
            </Button>
            <Button
              onClick={handleDownloadPDF}
              sx={{ float: 'right', backgroundColor: '#62b5e514' }}
              autoFocus
              disabled={isPDFDownloaded === false}
            >
              Download
            </Button>
          </div>
        </Box>
        {/* </div> */}
      </Modal>
    </div>
  );
};
