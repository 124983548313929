/* eslint-disable */
import React, { memo, useState } from 'react';
import jsPDF from 'jspdf';
import { Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { Button, TableCell, TableRow } from '@mui/material';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const DownloadPdf = ({
  downloadSelectedRow,
  toggleDownload,
  settoggleDownload,
  filteredYear,
  filteredMonth,
  getDate,
  SelectedFileType,
  setSelectedFileType,
}) => {
  const location = useLocation();
  const [IsPDFDownloaded, setIsPDFDownloaded] = useState(false);

  const handleDownloadPDF = async () => {
    setIsPDFDownloaded(true);
    const element = document.getElementById('holiday_savings');
    if (element) {
      const doc = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 210;
      const canvas = await html2canvas(element, {
        scale: 5,
        useCORS: true,
      });
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const { width, height } = canvas;
      const aspectRatio = height / width;
      const pdfHeight = imgWidth * aspectRatio;
      doc.addImage(imgData, 'PNG', 0, 0, imgWidth, pdfHeight);
      if (location?.pathname === '/dashboard/pension-savings') {
        doc.save('pension_savings.pdf');
      } else {
        doc.save('holiday_savings.pdf');
      }
      setIsPDFDownloaded(false);
      settoggleDownload(false);
      setSelectedFileType('');
    }
  };

  const handleDownload = () => {
    const isPensionSavingsRoute = location?.pathname === '/dashboard/pension-savings';
    const flattenedData = downloadSelectedRow.map((entry) => {
      const { userId, firstName, lastName, email, ssn, dateOfBirth, phoneNo } = entry.users;
      const ssnOrDob = ssn || dateOfBirth;
      if (isPensionSavingsRoute) {
        const { totalPensionAmount } = entry;
        return {
          'User ID': userId,
          'First Name': firstName,
          'Last Name': lastName,
          Email: email,
          'SSN or DOB': ssnOrDob,
          'Total Pension Amount': totalPensionAmount,
          'Phone Number': phoneNo,
        };
      } else {
        const { balance, totalHolidayAmount } = entry;
        return {
          'User ID': userId,
          'First Name': firstName,
          'Last Name': lastName,
          Email: email,
          'SSN or DOB': ssnOrDob,
          Balance: balance,
          'Total Holiday Amount': totalHolidayAmount,
        };
      }
    });
    const ws = XLSX.utils.json_to_sheet(flattenedData);
    const range = XLSX.utils.decode_range(ws['!ref']);
    for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
      for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
        const cellRef = XLSX.utils.encode_cell({ r: rowNum, c: colNum });
        if (!ws[cellRef]) continue;
        ws[cellRef].s = { alignment: { horizontal: 'left' } };
      }
    }

    ws['!cols'] = [
      { wch: 8 }, // User ID width
      { wch: 15 }, // First Name width
      { wch: 15 }, // Last Name width
      { wch: 30 }, // Email width
      { wch: 20 }, // SSN or DOB width
      { wch: 20 }, // Pension Amount width
      { wch: 15 }, // Phone Number width
    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Users');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const buf = new ArrayBuffer(wbout.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xff;
    const fileName = isPensionSavingsRoute ? 'pension.xlsx' : 'holiday_savings.xlsx';
    saveAs(new Blob([buf], { type: 'application/octet-stream' }), fileName);
    setSelectedFileType('');
    settoggleDownload(false);
    setIsPDFDownloaded(false);
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
      show={toggleDownload}
      onHide={() => {
        settoggleDownload(false);
        setSelectedFileType('');
      }}
      animation={false}
      className="DownloadHolidaySavings fade.DownloadHolidaySavings.modal"
      style={{ marginTop: '65px' }}
    >
      <div id="holiday_savings">
        <Modal.Header closeButton>
          <Modal.Title>
            {location?.pathname === '/dashboard/pension-savings' ? 'Pension savings pdf' : 'Holiday savings pdf '}
          </Modal.Title>
          <p>
            {filteredMonth === '' && filteredYear === '' && getDate?.from === '' && getDate?.to === ''
              ? ''
              : 'Selected period'}
            {filteredMonth === '' && filteredYear !== '' && `Year ${filteredYear}`}
            {filteredMonth !== '' &&
              `Month ${new Date(filteredYear, filteredMonth - 1).toLocaleString('default', {
                month: 'long',
                year: 'numeric',
              })}`}
            {getDate?.from && getDate?.to && `From ${getDate.from} To ${getDate.to}`}
          </p>
        </Modal.Header>
        <div className="container">
          <Table responsive="sm" size="sm">
            <thead>
              <tr>
                <th>Employee Id</th>
                <th>Employee</th>
                <th>Email</th>
                <th>SSN/DOB</th>
                {location?.pathname === '/dashboard/pension-savings' ? <th>Pension Amount</th> : <th>Balance</th>}
                {location?.pathname !== '/dashboard/pension-savings' && <th>Amount</th>}
                <th>Phone Number</th>
              </tr>
            </thead>
            <tbody>
              {downloadSelectedRow?.length ? (
                downloadSelectedRow?.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.users?.userId}</td>
                    <td>
                      {item?.users?.firstName} {item?.users?.lastName}
                    </td>
                    <td style={{ wordBreak: 'break-word' }}> {item?.users?.email}</td>
                    <td> {item?.users?.ssn || item?.users?.dateOfBirth}</td>
                    {location?.pathname !== '/dashboard/pension-savings' && <td>{item?.balance?.toFixed(2)}</td>}
                    {location?.pathname === '/dashboard/pension-savings' ? (
                      <td> {item?.totalPensionAmount?.toFixed(2)} </td>
                    ) : (
                      <td> {item?.totalHolidayAmount?.toFixed(2)} </td>
                    )}
                    <td> {item?.users?.phoneNo}</td>
                  </tr>
                ))
              ) : (
                <TableRow className="text-center">
                  <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                    No record found
                  </TableCell>
                </TableRow>
              )}
            </tbody>
          </Table>
        </div>
      </div>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            settoggleDownload(false);
            setSelectedFileType('');
          }}
        >
          {'Cancel'}
        </Button>
        {SelectedFileType === 'PDF' ? (
          <Button
            onClick={handleDownloadPDF}
            disabled={IsPDFDownloaded}
            type="submit"
            variant="contained"
            color={'primary'}
          >
            Download
          </Button>
        ) : (
          <Button
            onClick={handleDownload}
            disabled={IsPDFDownloaded}
            type="submit"
            variant="contained"
            color={'primary'}
          >
            Download
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default memo(DownloadPdf);
