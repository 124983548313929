/* eslint-disable */
import { useTranslation } from 'react-i18next';
import { otherCost } from '../../../utils/stringUtils';
import { otherArticleOptions } from '../../../utils/commonFunc';
import CheckboxUI from '../../../components/CheckboxUI';

const OtherArticlesOptionsUI = ({
  dates,
  indexValue,
  handleSeeCalculations,
  setShowSubmit,
  totalHours,
  handleOtherArticleValue,
  otherArticleValues,
  anyArrayHasValue,
  hasWorkingHours,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            visibility: dates[indexValue]?.length === 0 && 'hidden',
          }}
        >
          {otherArticleOptions?.map((item) => (
            <div className="text-right w-100 mt-2 mb-0 d-flex justify-content-between mb-1" key={item?.value}>
              <div className={`checkbox-wrapper-37 d-flex`}>
                <input
                  type="checkbox"
                  name="checkbox"
                  checked={otherArticleValues[indexValue]?.some((obj) => {
                    return obj.key === item?.key;
                  })}
                  id={item?.value === otherCost ? 'other-cost' : 'expenditure'}
                  onChange={(e) => handleOtherArticleValue(e, item?.value === otherCost ? 'oth' : 'exp')}
                />
                <label htmlFor={item?.value === otherCost ? 'other-cost' : 'expenditure'} className="terms-label mr-2">
                  <CheckboxUI />
                </label>
                <span className="mb-1">
                  {item?.value === otherCost ? t(otherArticleOptions[0]?.label) : t(otherArticleOptions[1]?.label)}
                </span>
              </div>
            </div>
          ))}
        </div>

        <div>
          {(hasWorkingHours || anyArrayHasValue) && (
            <p className="text-right text-primary mb-0">
              <a
                className="how_calculate_link"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleSeeCalculations(indexValue);
                  setShowSubmit(false);
                }}
              >
                {t('See how we calculated')}?
              </a>
            </p>
          )}

          <p className="text-right w-100 mb-0">
            {t('Total working hours')} : {totalHours[indexValue] ? totalHours[indexValue] : 0}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OtherArticlesOptionsUI;
