import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Get user from localStorage
let user = JSON.parse(localStorage.getItem('admin'));

if (user) {
  if (new Date().getTime() > new Date(user.expireIn).getTime()) {
    user = null;
    localStorage.removeItem('admin');
  }
}

const initialState = {
  user: user?.userData || null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  emailTemplate: [],
  notifications: [],
};

export const registerWithEmailAndPassword = createAsyncThunk(
  'auth/registerWithEmailAndPassword',
  async (user, thunkAPI) => {
    try {
      const res = await axios.post('/signup/add', user);
      if (!(res.data.statusCode === 200 || res.data.statusCode === 201)) {
        return thunkAPI.rejectWithValue(res.data.message);
      }
      return res.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const loginWithEmailAndPassword = createAsyncThunk('auth/loginWithEmailAndPassword', async (user, thunkAPI) => {
  try {
    const res = await axios.post('/auth/login', {
      ...user,
      role: 1,
    });
    if (!(res.data.statusCode === 200 || res.data.statusCode === 201)) {
      return thunkAPI.rejectWithValue(res.data.message);
    }
    if (res.data.response) {
      localStorage.setItem('admin', JSON.stringify(res.data.response));
    }
    return res.data.response.userData;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const resetPasswordForEmail = createAsyncThunk('auth/resetPasswordForEmail', async (email, thunkAPI) => {
  try {
    const res = await axios.get(`/signup/forgot-password?email=${email}`);
    if (!(res.data.statusCode === 200 || res.data.statusCode === 201)) {
      return thunkAPI.rejectWithValue(res.data.message);
    }
    return res.data.response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updatePassword = createAsyncThunk('auth/updatePassword', async (data, thunkAPI) => {
  try {
    const res = await axios.post('/signup/update-password', {
      newPassword: data.password,
      token: data.token,
    });
    if (!(res.data.statusCode === 200 || res.data.statusCode === 201)) {
      return thunkAPI.rejectWithValue(res.data.message);
    }
    return res.data.response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getUser = createAsyncThunk('auth/getUser', async (payload, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const res = await axios.get(`/admin/${state.auth.user.userId}`);
    if (!(res.data.statusCode === 200 || res.data.statusCode === 201)) {
      return thunkAPI.rejectWithValue(res.data.message);
    }
    return res.data.response.userData;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateUser = createAsyncThunk('auth/updateUser', async (payload, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const res = await axios.put('/admin', {
      id: state.auth.user.userId,
      ...payload,
    });
    if (!(res.data.statusCode === 200 || res.data.statusCode === 201)) {
      return thunkAPI.rejectWithValue(res.data.message);
    }
    return res.data.response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchEmailTemplate = createAsyncThunk('auth/emailTemplates', async (payload, thunkAPI) => {
  try {
    const res = await axios.get('/adminemailtemplate');
    if (!(res.data.statusCode === 200 || res.data.statusCode === 201)) {
      return thunkAPI.rejectWithValue(res.data.message);
    }
    return res.data.response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Prefilling data
export const getInvoiceData = createAsyncThunk('auth/getTimeSheetData', (data) => data);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut: (state) => {
      state.user = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
      localStorage.removeItem('admin');
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: {
    [registerWithEmailAndPassword.pending]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
    [registerWithEmailAndPassword.fulfilled]: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [registerWithEmailAndPassword.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },
    [loginWithEmailAndPassword.pending]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
    [loginWithEmailAndPassword.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.user = action.payload;
    },
    [loginWithEmailAndPassword.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
      state.user = null;
    },
    [resetPasswordForEmail.pending]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
    [resetPasswordForEmail.fulfilled]: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [resetPasswordForEmail.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },
    [updatePassword.pending]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
    [updatePassword.fulfilled]: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [updatePassword.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },
    [getUser.pending]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
    [getUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    [getUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },
    [updateUser.pending]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
    [updateUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    [updateUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },
    [fetchEmailTemplate.pending]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
    [fetchEmailTemplate.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.emailTemplate = action.payload;
    },
    [fetchEmailTemplate.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },
    [getInvoiceData.pending]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getInvoiceData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.getInvoiceData = action.payload;
    },
    [getInvoiceData.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },
  },
});

export const { logOut, reset, setNotifications } = authSlice.actions;

export default authSlice.reducer;
