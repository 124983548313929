import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// utils
import { fToNow } from '../../utils/formatTime';
// components
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { setNotifications } from '../../redux/auth/authSlice';

// ----------------------------------------------------------------------
NotificationsPopover.propTypes = {
  addNotifications: PropTypes.func,
};

export default function NotificationsPopover({ addNotifications, pageSize, pageNumber, isLoading, totalCount }) {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // const [notifications, setNotifications] = useState([]);
  const notifications = useSelector((state) => state.auth.notifications);
  const [loading, setLoading] = useState(false);
  const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  const [open, setOpen] = useState(null);
  const [statusCode, setStatusCode] = useState(0);

  useEffect(() => {
    fetchInvoices();
  }, [pageSize, pageNumber]);

  useEffect(() => {
    isLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const fetchInvoices = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/admin/adminqa/get-all-notifications?PageSize=${pageSize}&PageNumber=${pageNumber + 1}`
      );
      setStatusCode(res.status);
      const list = [];
      res.data.response.forEach((values) => {
        const storedDate = new Date(values.createdDate);
        const d1 = new Date();
        const UTCCurrentDate = new Date(
          d1.getUTCFullYear(),
          d1.getUTCMonth(),
          d1.getUTCDate(),
          d1.getUTCHours(),
          d1.getUTCMinutes(),
          d1.getUTCSeconds()
        );
        const previousDate = new Date(storedDate.getFullYear(), storedDate.getMonth(), storedDate.getDate());
        const todayDate = new Date(d1.getUTCFullYear(), d1.getUTCMonth(), d1.getUTCDate());
        const minutes = (new Date(UTCCurrentDate).getTime() - new Date(storedDate).getTime()) / 1000 / 60;
        const diffTime = Math.abs(todayDate - previousDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        list.push({
          date: storedDate,
          year: diffDays >= 365 ? `about ${Math.round(diffDays / 365)} year ago` : 0,
          days: diffDays < 1 ? 0 : `about ${diffDays} day ago`,
          minutesDiff: minutes < 1 ? 'less than 1 minute' : `about ${Math.round(minutes)} minutes ago`,
          hours: minutes >= 60 ? `about ${Math.round(minutes / 60)} hours ago` : 0,
        });
      });

      const mappedResponse = res.data.response?.map((notification, index) => ({
        userName: notification.createdByUserName,
        id: notification.id,
        title: notification.notificationType.notification,
        description: notification.notificationType.content,
        email: notification?.email,
        notificationTypeId: notification?.notificationTypeId,
        isReactivated: notification?.isReactivated,
        avatar: null,
        type: 'order_placed',
        invoiceNumber: notification.invoiceNumber,
        createdBy: notification.createdBy,
        createdAt: list[index],
        isUnRead: notification.status !== 1,
        routeName: getRouteName(notification.notificationType.notification),
        notificationVal: notification.notificationVal,
      }));
      totalCount(res.data.totalCount);
      // setNotifications(mappedResponse);
      dispatch(setNotifications(mappedResponse));
      addNotifications(mappedResponse);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      // setNotifications([]);
      dispatch(setNotifications([]));
      if (err?.response) {
        setStatusCode(err?.response?.status);
      } else {
        setStatusCode(500);
      }
    }
  };

  const getRouteName = (title) => {
    switch (title.toString().toLowerCase()) {
      case 'invoice':
      case 'express payout':
        return 'invoices';
      case 'support':
        return 'support';
      case 'employee certificate':
        return 'employer-certificate';
      default:
        return title;
    }
  };
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = async ({ clickToViewAll }) => {
    try {
      if (totalUnRead > 0) {
        await axios.put('/admin/AdminQA').then(() => {
          dispatch(
            setNotifications(
              notifications?.map((notification) => ({
                ...notification,
                isUnRead: false,
              }))
            )
          );

          if (clickToViewAll) {
            setOpen(false);
            navigate('/dashboard/notifications');
          }
        });
      } else {
        setOpen(false);
        navigate('/dashboard/notifications');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 36, height: 36, p: 1, minHeight: 0 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButton>

      <MenuPopover open={Boolean(open)} anchorEl={open} onClose={handleClose} sx={{ width: 240, p: 0, mt: 1, ml: 0.5 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {statusCode === 0 ? <b>Loading...</b> : `You have ${totalUnRead} unread messages`}
              {!statusCode === 200 && `Found an error-> statusCode-${statusCode}`}
            </Typography>
          </Box>

          <Box sx={{ p: 1, minHeight: 0 }}>
            <Button
              fullWidth
              disableRipple
              onClick={() => {
                handleMarkAllAsRead({ clickToViewAll: true });
              }}
            >
              View All
            </Button>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton
                color="primary"
                onClick={() => {
                  handleMarkAllAsRead({ clickToViewAll: false });
                }}
              >
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
          >
            {notifications.slice(0, 2)?.map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 4)?.map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>
        </Scrollbar>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.object,
    id: PropTypes.number,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {/* {fToNow(notification.createdAt)} */}
            {notification.createdAt.year !== 0 && notification.createdAt.year}
            {notification.createdAt.year === 0 &&
              ((notification.createdAt.days !== 0 ? notification.createdAt.days : notification.createdAt.hours) ||
                (notification.createdAt.hours !== 0
                  ? notification.createdAt.hours
                  : notification.createdAt.minutesDiff))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp;
        <br /> <b>{notification.userName}</b>&nbsp;{noCase(notification.description)},&nbsp;&nbsp;
        {`#${notification.id}`}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_package.svg" />,
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_shipping.svg" />,
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_mail.svg" />,
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_chat.svg" />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
