/* eslint-disable */
import React, { memo, useEffect, useRef, useState } from 'react';
import { Modal, Table, InputGroup } from 'react-bootstrap';
import { Col, Row, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CertificateEmployeeContent from './CertificateEmployeeContent';
import { Button, Typography } from '@mui/material';
import CustomDatePicker from './CustomDatePicker';
import OpenSignatureModal from './SignatureModal';

const ApplyCertificateForm = ({
  fields,
  checkList,
  setcheckList,
  handleChange,
  setFields,
  ToggleShow,
  Toggleclose,
  setfieldsError,
  submitForCertificate,
  fieldsError,
  firstYearDataTable,
  handlefirstYearDataTableChange,
  handleSecondYearDataTableChange,
  secondYearDataTable,
  incomeServiceFields,
  handleIncomeService,
  loading,
}) => {
  const { t } = useTranslation();
  const reportTemplateRef = useRef();
  const message = 'Field value should be greater than 3';
  const [isDisable, setisDisable] = useState(false);
  const [isTextError, setisTextError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [signatureType, setSignatureType] = useState(null);



  const handleCharLength = (fieldName, value) => {
    if (value?.length < 3) {
      setisTextError((prev) => ({
        ...prev,
        [fieldName + 'Error']: true,
      }));
    } else {
      setisTextError((prev) => ({
        ...prev,
        [fieldName + 'Error']: false,
      }));
    }
  };
  useEffect(() => {
    const startDate = new Date(fields?.reportedTimeFromDate).getFullYear();
    const endDate = new Date(fields?.reportedTimeEndDate).getFullYear();
    if (startDate === endDate) {
      setisDisable(true);
    } else {
      setisDisable(false);
    }
  }, [fields?.reportedTimeFromDate, fields?.reportedTimeEndDate]);

  const handleImageChange = (name) => (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFields((prevFields) => ({
          ...prevFields,
          [name]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const onSaveSignature = (dataURL) => {
    setFields((prevFields) => ({
      ...prevFields,
      [signatureType]: dataURL,
    }));
    setIsModalOpen(false);
  };

  const openModal = (type) => {
    setSignatureType(type);
    setIsModalOpen(true);
  };

  return (
    <React.Fragment>
      <Modal
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={ToggleShow?.show}
        onHide={() => {
          Toggleclose(false);
          setfieldsError(false);
          setisTextError(false);
          setFields((prev) => ({
            ...prev,
            nameClarification: '',
          }));
        }}
        animation={false}
        className="Apply_certificate fade.Apply_certificate.modal"
        style={{ marginTop: '65px' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('Employer certificate')}</Modal.Title>
        </Modal.Header>

        <Form className="mt-3 p-2" onSubmit={submitForCertificate}>
          <div ref={reportTemplateRef} className="save_certificate">
            {/* 1st section */}
            <div className="first_part">
              <Row>
                <Col lg={4} md={4} sm={12}>
                  <h5 className="mt-2"> {t('1 Personal data')}</h5>
                </Col>
                <Col lg={4} md={4} sm={12}>
                  <p>{t('SO 11 (2019-11-18)')}</p>
                </Col>
              </Row>
            </div>

            <div className="ml-2 mr-2">
              <Row>
                <InputGroup>
                  <Col lg={4} md={4} sm={12}>
                    <label className="common_label">{t("Employee's last name")}</label>
                  </Col>

                  <Col lg={4} md={4} sm={12}>
                    <label className="common_label">{t('First name')}</label>
                  </Col>

                  <Col lg={4} md={4} sm={12}>
                    <label className="common_label">{t('Social security number (12 digits)')}</label>
                  </Col>
                </InputGroup>
              </Row>
            </div>

            <div className="ml-2 mr-2 border border-dark p-2">
              <Row>
                <InputGroup>
                  <Col className="first_remove" lg={4} md={4} sm={12}>
                    <Form.Control
                      type="text"
                      name="lastName"
                      className={`remove_border form-control ${fieldsError?.lastNameError ? 'invalidInput' : ''}`}
                      placeholder={t('Kessel')}
                      value={fields.lastName}
                      onChange={(e) => {
                        handleChange('lastName')(e);
                        handleCharLength('lastName', e.target.value);
                      }}
                    />
                    {isTextError?.lastNameError && <Form.Text className="form_textt">{t(message)}</Form.Text>}
                  </Col>
                  <Col className="first_remove" lg={4} md={4} sm={12}>
                    <Form.Control
                      type="text"
                      name="firstName"
                      className={`remove_border form-control ${fieldsError?.firstNameError ? 'invalidInput' : ''}`}
                      placeholder={t('Mikael')}
                      value={fields.firstName}
                      onChange={(e) => {
                        handleChange('firstName')(e);
                        handleCharLength('firstName', e.target.value);
                      }}
                    />
                    {isTextError?.firstNameError && <Form.Text className="form_textt">{t(message)}</Form.Text>}
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <Form.Control
                      type="number"
                      name="socialSecurityNumber"
                      className={`remove_border form-control ${
                        fieldsError?.socialSecurityNumberError ? 'invalidInput' : ''
                      }`}
                      value={fields?.socialSecurityNumber}
                      onChange={handleChange('socialSecurityNumber')}
                      placeholder={t('7 9 0 5 0 2 7 1 7 8')}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          'wheel',
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                    />
                  </Col>
                </InputGroup>
              </Row>
            </div>

            {/* 2nd Section */}
            <div className="mt-3">
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <h5>{t('2 Details of employment')}</h5>
                </Col>
              </Row>
            </div>

            <div className="ml-2 mr-2 border border-dark p-2">
              <Row>
                <InputGroup>
                  <Col lg={3} md={3} sm={12}>
                    <label className="common_label">{t('Employment period from')}</label>
                  </Col>
                  <Col lg={3} md={3} sm={12}>
                    <Form.Control
                      type="date"
                      name="month"
                      className={`form-control ${fieldsError?.employmentPeriodFromError ? 'invalidInput' : ''}`}
                      value={fields.employmentPeriodFrom}
                      onChange={handleChange('employmentPeriodFrom')}
                      max={new Date().toISOString().split('T')[0]}
                    />
                  </Col>
                  <Col lg={1} md={1} sm={6}>
                    <label className="common_label">{t('End Date')}</label>
                  </Col>

                  <Col lg={3} md={3} sm={6}>
                    <Form.Control
                      type="date"
                      name="employmentPeriodEnd"
                      className={`form-control ${
                        checkList?.isStillEmployed ? '' : fieldsError?.employmentPeriodEndError ? 'invalidInput' : ''
                      }`}
                      value={fields.employmentPeriodEnd || ''}
                      onChange={handleChange('employmentPeriodEnd')}
                      max={new Date().toISOString().split('T')[0]}
                      disabled={checkList?.isStillEmployed}
                    />
                  </Col>
                  <Col lg={2} md={2} sm={12}>
                    <label className="common_label">
                      <Form.Check
                        type="checkbox"
                        checked={checkList?.isStillEmployed}
                        onChange={handleChange('isStillEmployed')}
                        style={{ accentColor: '#62b5e5' }}
                      />
                      {t('still employed')}
                    </label>
                  </Col>
                </InputGroup>

                <InputGroup className="mt-2">
                  <Col lg={3} md={3} sm={12}>
                    <label className="common_label">{t('Position (employed as)')}</label>
                  </Col>
                  <Col lg={7} md={7} sm={12}>
                    <Form.Control
                      type="text"
                      name="employeePosition"
                      placeholder={t('Freelancer')}
                      value={t(fields?.employeePosition)}
                      disabled
                    />
                  </Col>
                  <Col lg={2} md={2} sm={12}>
                    <label className="common_label change_position">{t('Extent in %')}</label>
                  </Col>
                </InputGroup>

                <InputGroup className="mt-2">
                  <Col lg={3} md={3} sm={6}>
                    <label className="common_label">{t('Leave of absence from')}</label>
                  </Col>
                  <Col lg={3} md={3} sm={6}>
                    <Form.Control
                      type="date"
                      name="leaveAbsenceFrom"
                      value={fields?.leaveAbsenceFrom}
                      onChange={handleChange('leaveAbsenceFrom')}
                      max={new Date().toISOString().split('T')[0]}
                    />
                  </Col>
                  <Col lg={1} md={1} sm={6}>
                    <label className="common_label">{t('End Date')}</label>
                  </Col>
                  <Col lg={3} md={3} sm={6}>
                    <Form.Control
                      type="date"
                      name="leaveAbsenceEnd"
                      value={fields?.leaveAbsenceEnd}
                      onChange={handleChange('leaveAbsenceEnd')}
                      max={new Date().toISOString().split('T')[0]}
                    />
                  </Col>
                  <Col lg={2} md={2} sm={6}>
                    <Form.Control
                      style={{ fontSize: '12px' }}
                      type="number"
                      name="extendsIn"
                      value={fields?.extendsIn}
                      placeholder={t('Extent in %')}
                      onChange={handleChange('extendsIn')}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          'wheel',
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                    />
                  </Col>
                </InputGroup>
              </Row>
            </div>

            {/* 3rd Section */}

            {/* 4th Section */}
            <div className="mt-3">
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <h5>{'3 Working hours'}</h5>
                </Col>
              </Row>
            </div>

            <div className="ml-2 mr-2 border border-dark p-2">
              <Row>
                <InputGroup>
                  <Col lg={4} md={4} sm={12}>
                    <label className="common_label">
                      <Form.Check
                        type="checkbox"
                        label={t('Full-time - enter hours per week')}
                        checked={checkList?.isFullTimehoursPerWeek}
                        onChange={handleChange('isFullTimehoursPerWeek')}
                        style={{ accentColor: '#62b5e5' }}
                      />
                    </label>
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <Form.Control
                      type="number"
                      name="fullTimehoursPerWeek"
                      className={`form-control ${
                        !checkList?.isFullTimehoursPerWeek
                          ? ''
                          : fieldsError?.fullTimehoursPerWeekError
                          ? 'invalidInput'
                          : ''
                      }`}
                      value={fields.fullTimehoursPerWeek || ''}
                      onChange={handleChange('fullTimehoursPerWeek')}
                      placeholder={t('Full time hours per week')}
                      min={0}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          'wheel',
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      disabled={!checkList?.isFullTimehoursPerWeek}
                    />
                  </Col>
                </InputGroup>

                <InputGroup className="mt-2">
                  <Col lg={3} md={3} sm={12}>
                    <label className="common_label">
                      <Form.Check
                        type="checkbox"
                        label={t('Part-time - enter hours per week')}
                        checked={checkList?.isPartTimehoursPerWeek}
                        onChange={handleChange('isPartTimehoursPerWeek')}
                        style={{ accentColor: '#62b5e5' }}
                      />
                    </label>
                  </Col>
                  <Col lg={3} md={3} sm={12}>
                    <Form.Control
                      type="number"
                      name="partTimehoursPerWeek"
                      className={`form-control ${
                        !checkList?.isPartTimehoursPerWeek
                          ? ''
                          : fieldsError?.partTimehoursPerWeekError
                          ? 'invalidInput'
                          : ''
                      }`}
                      placeholder={t('Part time hours per week')}
                      value={fields?.partTimehoursPerWeek || ''}
                      onChange={handleChange('partTimehoursPerWeek')}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          'wheel',
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      disabled={!checkList?.isPartTimehoursPerWeek}
                    />
                  </Col>

                  <Col lg={1} md={1} sm={12}>
                    <label className="common_label">{t('Which makes')}</label>
                  </Col>

                  <Col lg={3} md={3} sm={12}>
                    <Form.Control
                      type="number"
                      name="percentageFullTimeEmployement"
                      className={`form-control ${
                        !checkList?.isPartTimehoursPerWeek
                          ? ''
                          : fieldsError?.percentageFullTimeEmployementError
                          ? 'invalidInput'
                          : ''
                      }`}
                      value={fields?.percentageFullTimeEmployement || ''}
                      onChange={handleChange('percentageFullTimeEmployement')}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          'wheel',
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      disabled={!checkList?.isPartTimehoursPerWeek}
                    />
                  </Col>

                  <Col lg={2} md={2} sm={12}>
                    <label className="common_label">{t('% of full-time employment')}</label>
                  </Col>
                </InputGroup>

                <Col lg={12} md={12} sm={12}>
                  <label className="common_label">
                    <Form.Check
                      type="checkbox"
                      label={t("Varying working hours (e.g. intermittent employment,' employment' or similar)")}
                      checked={checkList?.isVaryingHours}
                      onChange={handleChange('isVaryingHours')}
                      style={{ accentColor: '#62b5e5' }}
                    />
                  </label>
                </Col>
              </Row>
            </div>

            {/* 5th Section */}
            <div className="mt-3">
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <h5>{t('4 Special information about the employment')}</h5>
                </Col>
              </Row>
            </div>

            <div className="ml-2 mr-2 border border-dark p-2">
              <Row>
                <InputGroup>
                  <Col lg={6} md={6} sm={12}>
                    <label className="common_label">{t('Employed by the employment agency for rental')}</label>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <InputGroup>
                      <Form.Check
                        className="common_label pl-4"
                        type="radio"
                        label={t('Yes')}
                        checked={checkList?.specialInfoEmployment}
                        onChange={() =>
                          setcheckList({
                            ...checkList,
                            specialInfoEmployment: !checkList.specialInfoEmployment,
                          })
                        }
                        style={{ accentColor: '#62b5e5' }}
                      />
                      <label className="ml-2 common_label">{t('No')}</label>
                      <Form.Check
                        className="common_label pl-4"
                        type="radio"
                        checked={!checkList?.specialInfoEmployment}
                        onChange={() =>
                          setcheckList({
                            ...checkList,
                            specialInfoEmployment: !checkList.specialInfoEmployment,
                          })
                        }
                        style={{ accentColor: '#62b5e5' }}
                      />
                    </InputGroup>
                  </Col>
                </InputGroup>
              </Row>
            </div>

            {/* 6th Section */}
            <div className="mt-3">
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <h5>{t('5 Reason why the employment has ended in whole or in part')}</h5>
                </Col>
              </Row>
            </div>

            <div className="ml-2 mr-2 border border-dark p-2">
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <label className="common_label">
                    <Form.Check
                      type="checkbox"
                      label={t("The employee's own request")}
                      checked={checkList?.employeeOwnRequest}
                      onChange={handleChange('employeeOwnRequest')}
                      style={{ accentColor: '#62b5e5' }}
                    />
                  </label>
                </Col>

                <InputGroup className="mt-2">
                  <Col lg={3} md={3} sm={12}>
                    <label className="common_label">
                      <Form.Check
                        type="checkbox"
                        label={t('Other reason - state what')}
                        checked={checkList?.isOtherReason}
                        onChange={handleChange('isOtherReason')}
                        style={{ accentColor: '#62b5e5' }}
                      />
                    </label>
                  </Col>
                  <Col lg={9} md={9} sm={12}>
                    <Form.Control
                      type="text"
                      name="otherReason"
                      className={`form-control ${
                        checkList?.employeeOwnRequest || !checkList?.isOtherReason
                          ? ''
                          : fieldsError?.otherReasonError
                          ? 'invalidInput'
                          : ''
                      }`}
                      value={fields?.otherReason || ''}
                      placeholder={t('Reason')}
                      onChange={(e) => {
                        handleChange('otherReason')(e);
                        handleCharLength('otherReason', e.target.value);
                      }}
                      disabled={checkList?.employeeOwnRequest || !checkList?.isOtherReason}
                    />

                    {!checkList?.employeeOwnRequest && checkList?.isOtherReason && isTextError?.otherReasonError && (
                      <Form.Text className="form_textt">{t(message)}</Form.Text>
                    )}
                  </Col>
                </InputGroup>
              </Row>
            </div>

            {/* Seven Section */}
            <div className="mt-3">
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <h5>{t('6 Compensation due to termination of employment')}</h5>
                </Col>
              </Row>
            </div>

            <div className="ml-2 mr-2 border border-dark p-2">
              <Row>
                <InputGroup>
                  <Col lg={6} md={6} sm={12}>
                    <label className="common_label">
                      {t('Has an agreement on severance pay or other compensation been entered into')}
                    </label>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <InputGroup>
                      <Form.Check
                        className="common_label pl-4"
                        type="radio"
                        label={t('Yes')}
                        checked={checkList?.isCompensationTermination}
                        onChange={() =>
                          setcheckList({
                            ...checkList,
                            isCompensationTermination: !checkList.isCompensationTermination,
                          })
                        }
                        style={{ accentColor: '#62b5e5' }}
                      />
                      <label className="ml-2 common_label">{t('No')}</label>
                      <Form.Check
                        className="common_label pl-4"
                        type="radio"
                        checked={!checkList?.isCompensationTermination}
                        onChange={() =>
                          setcheckList({
                            ...checkList,
                            isCompensationTermination: !checkList.isCompensationTermination,
                          })
                        }
                        style={{ accentColor: '#62b5e5' }}
                      />
                    </InputGroup>
                  </Col>
                </InputGroup>
              </Row>
            </div>

            {/* Eighth Section */}
            <div className="mt-3">
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <h5>{t('7 Offer of continued work')}</h5>
                </Col>
              </Row>
            </div>

            <div className="ml-2 mr-2 border border-dark p-2">
              <Row>
                <InputGroup>
                  <Col lg={3} md={3} sm={12}>
                    <InputGroup>
                      <Form.Check
                        type="checkbox"
                        name="isContinueWorkFrom"
                        className="common_label"
                        checked={!checkList?.isContinueWorkFrom}
                        onChange={() =>
                          setcheckList({
                            ...checkList,
                            isContinueWorkFrom: !checkList.isContinueWorkFrom,
                          })
                        }
                        label={t('No')}
                        style={{ accentColor: '#62b5e5' }}
                        disabled={checkList?.isStillEmployed}
                      />
                      <Form.Check
                        name="isContinueWorkFrom"
                        className="common_label pl-4"
                        label={t('Yes,from')}
                        type="checkbox"
                        checked={checkList?.isContinueWorkFrom}
                        onChange={() =>
                          setcheckList({
                            ...checkList,
                            isContinueWorkFrom: !checkList.isContinueWorkFrom,
                          })
                        }
                        style={{ accentColor: '#62b5e5' }}
                        disabled={checkList?.isStillEmployed}
                      />
                    </InputGroup>
                  </Col>

                  <Col lg={3} md={3} sm={12}>
                    <Form.Control
                      type="date"
                      name="continueWorkFromDate"
                      className={`form-control ${
                        checkList?.isStillEmployed || !checkList?.isContinueWorkFrom
                          ? ''
                          : fieldsError?.continueWorkFromDateError
                          ? 'invalidInput'
                          : ''
                      }`}
                      value={fields?.continueWorkFromDate || ''}
                      onChange={handleChange('continueWorkFromDate')}
                      max={new Date().toISOString().split('T')[0]}
                      disabled={checkList?.isStillEmployed || !checkList?.isContinueWorkFrom}
                    />
                  </Col>
                  <Col lg={1} md={1} sm={6}>
                    <label className="common_label">{t('End Date')}</label>
                  </Col>
                  <Col lg={3} md={3} sm={6}>
                    <Form.Control
                      type="date"
                      name="continueWorkEndDate"
                      className={`form-control ${
                        checkList?.isStillEmployed || !checkList?.isContinueWorkFrom
                          ? ''
                          : fieldsError?.continueWorkEndDateError
                          ? 'invalidInput'
                          : ''
                      }`}
                      value={fields.continueWorkEndDate || ''}
                      onChange={handleChange('continueWorkEndDate')}
                      max={new Date().toISOString().split('T')[0]}
                      disabled={checkList?.isStillEmployed || !checkList?.isContinueWorkFrom}
                    />
                  </Col>
                  <Col lg={2} md={2} sm={12}>
                    <label className="common_label">
                      <Form.Check
                        type="checkbox"
                        label={t('until further notice')}
                        checked={checkList?.isUntilFurtherNotice}
                        onChange={handleChange('isUntilFurtherNotice')}
                        style={{ accentColor: '#62b5e5' }}
                        disabled={checkList?.isStillEmployed}
                      />
                    </label>
                  </Col>
                </InputGroup>

                <InputGroup>
                  <Col lg={12} md={12} sm={12}>
                    <label className="common_label">
                      <Form.Check
                        type="checkbox"
                        label={t('Variable working hours (hourly employment)')}
                        checked={checkList?.isContinueVariableWorkingHours}
                        onChange={handleChange('isContinueVariableWorkingHours')}
                        style={{ accentColor: '#62b5e5' }}
                        disabled={checkList?.isStillEmployed}
                      />
                    </label>
                  </Col>
                </InputGroup>
                <Col lg={8} md={8} sm={12}>
                  <InputGroup>
                    <label className="common_label">{t('Has the employee accepted?')}</label>
                    <Form.Check
                      className="common_label pl-4"
                      type="checkbox"
                      label={t('Yes')}
                      checked={checkList?.isContinueEmployeeAccepted}
                      onChange={() =>
                        setcheckList({
                          ...checkList,
                          isContinueEmployeeAccepted: !checkList.isContinueEmployeeAccepted,
                        })
                      }
                      style={{ accentColor: '#62b5e5' }}
                      disabled={checkList?.isStillEmployed}
                    />
                    <label className="ml-2 common_label">{t('No')}</label>
                    <Form.Check
                      className="common_label pl-4"
                      type="checkbox"
                      label={t('state when he/she refused')}
                      checked={!checkList?.isContinueEmployeeAccepted}
                      onChange={() =>
                        setcheckList({
                          ...checkList,
                          isContinueEmployeeAccepted: !checkList.isContinueEmployeeAccepted,
                        })
                      }
                      style={{ accentColor: '#62b5e5' }}
                      disabled={checkList?.isStillEmployed}
                    />
                  </InputGroup>
                </Col>
                <Col lg={4} md={4} sm={6}>
                  <Form.Control
                    type="date"
                    name="isContinueEmployeeAcceptedDate"
                    className={`form-control ${
                      checkList?.isStillEmployed || !checkList?.isContinueWorkFrom
                        ? ''
                        : fieldsError?.isContinueEmployeeAcceptedDateError
                        ? 'invalidInput'
                        : ''
                    }`}
                    value={fields?.isContinueEmployeeAcceptedDate || ''}
                    onChange={handleChange('isContinueEmployeeAcceptedDate')}
                    max={new Date().toISOString().split('T')[0]}
                    disabled={checkList?.isStillEmployed || !checkList?.isContinueWorkFrom}
                  />
                </Col>
              </Row>
            </div>

            {/* 8th Section */}
            <div className="mt-3">
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <h5>
                    {t(
                      "8 The employer's (or representative's) signature - note that the form continues on the next page"
                    )}
                  </h5>
                </Col>
              </Row>
            </div>

            <div className="ml-2 mr-2 border border-dark p-2">
              <Row>
                <InputGroup>
                  <Col lg={1} md={1} sm={12}>
                    <label className="common_label">{t('Signature')}</label>
                  </Col>

                  <Col className="first_remove mt-1" lg={5} md={5} sm={12}>
                    <InputGroup>
                      <Form.Check
                        type="checkbox"
                        name="isDigtally"
                        className="common_label"
                        checked={!checkList?.isDigtally}
                        onChange={() =>
                          setcheckList({
                            ...checkList,
                            isDigtally: !checkList.isDigtally,
                          })
                        }
                        label={t('Sign pad')}
                        style={{ accentColor: '#62b5e5' }}
                      />
                      <Form.Check
                        name="isDigtally"
                        className="common_label pl-4"
                        label={t('Upload Image')}
                        type="checkbox"
                        checked={checkList?.isDigtally}
                        onChange={() =>
                          setcheckList({
                            ...checkList,
                            isDigtally: !checkList.isDigtally,
                          })
                        }
                        style={{ accentColor: '#62b5e5' }}
                      />
                    </InputGroup>
                    {!checkList?.isDigtally ? (
                      <>
                        <Button onClick={() => openModal('signature')}>Add Signature</Button>
                        <OpenSignatureModal
                          isOpen={isModalOpen}
                          onClose={() => setIsModalOpen(false)}
                          onSave={onSaveSignature}
                        />
                        {fields?.signature && (
                          <div>
                            <img
                              src={fields?.signature}
                              alt="Signature"
                              style={{
                                cursor: 'move',
                                width: '200px',
                              }}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {fields?.signature && (
                          <div className="mt-3">
                            <Button
                              onClick={() =>
                                setFields((prev) => ({
                                  ...prev,
                                  signature: null,
                                }))
                              }
                              className="remove_image"
                            >
                              X
                            </Button>
                            <img
                              src={fields?.signature}
                              alt="Signature"
                              style={{
                                cursor: 'move',
                                width: '200px',
                              }}
                            />
                          </div>
                        )}
                        {fields?.signature === null && (
                          <Form.Control
                            type="file"
                            name="signature"
                            className={`form-control mt-2 ${fieldsError?.signatureError ? 'invalidInput' : ''}`}
                            onChange={handleImageChange('signature')}
                          />
                        )}
                      </>
                    )}
                  </Col>

                  <Col lg={2} md={2} sm={12}>
                    <label className="common_label">{t('Name clarification')}</label>
                  </Col>
                  <Col lg={3} md={3} sm={12}>
                    <Form.Control
                      type="text"
                      name="nameClarification"
                      className={`form-control ${fieldsError?.nameClarificationError ? 'invalidInput' : ''}`}
                      placeholder={t('Name clarification')}
                      value={fields.nameClarification}
                      onChange={(e) => {
                        handleChange('nameClarification')(e);
                        handleCharLength('nameClarification', e.target.value);
                      }}
                    />
                    {isTextError?.nameClarificationError && <Form.Text className="form_textt">{t(message)}</Form.Text>}
                  </Col>
                </InputGroup>
              </Row>
            </div>

            {/* Ten Section */}
            <div className="mt-3">
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <h5>{t('9 Personal Identification Number (12 digits)')}</h5>
                </Col>
              </Row>
            </div>

            <div className="ml-2 w-50 mr-2 border border-dark p-2">
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <Form.Control
                    type="number"
                    name="socialSecurityNumber"
                    className={`remove_border form-control ${
                      fieldsError?.socialSecurityNumberError ? 'invalidInput' : ''
                    }`}
                    placeholder={t('55454545456')}
                    value={fields.socialSecurityNumber}
                    onChange={handleChange('socialSecurityNumber')}
                    onFocus={(e) =>
                      e.target.addEventListener(
                        'wheel',
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                  />
                </Col>
              </Row>
            </div>

            {/* 11th Section */}
            <div className="mt-3">
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <h5>{t('10 Time worked')}</h5>
                  <p></p>
                </Col>
              </Row>
            </div>

            <div className="ml-2 mr-2 border border-dark p-2">
              <div className="table_wrap d-flex">
                {/* First Table */}
                <Table responsive="lg">
                  <thead>
                    <tr>
                      <th>
                        {t('Year')}
                        <CustomDatePicker
                          name="reportedTimeFromDate"
                          value={fields.reportedTimeFromDate}
                          onChange={handleChange('reportedTimeFromDate')}
                          placeholder={t('yyyy')}
                          formatType="year"
                          error={fieldsError?.reportedTimeFromDateError}
                        />
                      </th>
                      <th>
                        {t('Hours worked')} <br /> {t('(not over-, more- or filling time)')}
                      </th>
                      <th>{t('Absence')}</th>
                      <th>{t('Overtime')}</th>
                      <th>
                        {t('Mertid')} <br />
                        {t('Filling time')}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {firstYearDataTable?.map((month, idx) => (
                      <tr key={idx}>
                        <td>{t(month?.month)}</td>
                        <td>
                          <Form.Control
                            type="number"
                            name="hoursworked"
                            className={`table_rowinput form-control`}
                            value={month.hoursworked}
                            onChange={(e) => handlefirstYearDataTableChange(idx, 'hoursworked', e.target.value)}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                'wheel',
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            name="absence"
                            className={`table_rowinput form-control`}
                            value={month?.absence}
                            onChange={(e) => handlefirstYearDataTableChange(idx, 'absence', e.target.value)}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                'wheel',
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            name="overtime"
                            className={`table_rowinput form-control`}
                            value={month?.overtime}
                            onChange={(e) => handlefirstYearDataTableChange(idx, 'overtime', e.target.value)}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                'wheel',
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            name="mertidFillingTime"
                            className={`table_rowinput form-control`}
                            value={month?.mertidFillingTime}
                            onChange={(e) => handlefirstYearDataTableChange(idx, 'mertidFillingTime', e.target.value)}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                'wheel',
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                {/* Second Table */}

                <Table responsive="lg">
                  <thead>
                    <tr>
                      <th>
                        {t('Year')}
                        <CustomDatePicker
                          name="reportedTimeEndDate"
                          value={fields.reportedTimeEndDate}
                          onChange={handleChange('reportedTimeEndDate')}
                          placeholder={t('yyyy')}
                          formatType="year"
                          error={fieldsError?.reportedTimeEndDateError}
                        />
                      </th>
                      <th>
                        {t('Hours worked')} <br /> {t('(not over-, more- or filling time)')}
                      </th>
                      <th>{t('Absence')}</th>
                      <th>{t('Overtime')}</th>
                      <th>
                        {t('Mertid')} <br />
                        {t('Filling time')}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {secondYearDataTable?.map((month, idx) => (
                      <tr key={idx}>
                        <td>{t(month?.month)}</td>
                        <td>
                          <Form.Control
                            type="number"
                            name="hoursworked"
                            className={`table_rowinput form-control`}
                            value={month?.hoursworked}
                            onChange={(e) => handleSecondYearDataTableChange(idx, 'hoursworked', e.target.value)}
                            disabled={isDisable}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                'wheel',
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            name="absence"
                            className={`table_rowinput form-control`}
                            value={month?.absence}
                            onChange={(e) => handleSecondYearDataTableChange(idx, 'absence', e.target.value)}
                            disabled={isDisable}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                'wheel',
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            name="overtime"
                            className={`table_rowinput form-control`}
                            value={month?.overtime}
                            onChange={(e) => handleSecondYearDataTableChange(idx, 'overtime', e.target.value)}
                            disabled={isDisable}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                'wheel',
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            name="mertidFillingTime"
                            className={`table_rowinput form-control`}
                            value={month?.mertidFillingTime}
                            onChange={(e) => handleSecondYearDataTableChange(idx, 'mertidFillingTime', e.target.value)}
                            disabled={isDisable}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                'wheel',
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <Row className="mt-2">
                <Col lg={9} md={9} sm={12}>
                  <InputGroup>
                    <label className="common_label">
                      {t('The working time above has been stated in teaching hours')}
                    </label>
                    <Form.Check
                      className="common_label pl-4"
                      name="selected_hours"
                      type="radio"
                      label={t('No')}
                      checked={!checkList?.isTeachingHours}
                      onChange={() =>
                        setcheckList({
                          ...checkList,
                          isTeachingHours: !checkList.isTeachingHours,
                        })
                      }
                      style={{ accentColor: '#62b5e5' }}
                    />
                    <label className="ml-2 common_label">{t('Yes')}</label>
                    <Form.Check
                      name="selected_hours"
                      className="common_label pl-4"
                      type="radio"
                      checked={checkList?.isTeachingHours}
                      onChange={() =>
                        setcheckList({
                          ...checkList,
                          isTeachingHours: !checkList.isTeachingHours,
                        })
                      }
                      style={{ accentColor: '#62b5e5' }}
                    />
                    <label className="common_label">
                      {t('If yes, enter the full-time measure of teaching hours/week')}
                    </label>
                  </InputGroup>
                </Col>

                <Col lg={3} md={3} sm={12}>
                  <Form.Control
                    type="number"
                    name="teachingHours"
                    // className={`form-control ${fieldsError?.teachingHoursError ? 'invalidInput' : ''}`}
                    placeholder={t('4.5')}
                    value={fields.teachingHours}
                    onChange={handleChange('teachingHours')}
                    min={0}
                    step="any"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        'wheel',
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    disabled
                  />
                </Col>
                <label className="common_label ml-3">
                  {t(
                    "If the work concerns artistic work, the number of days worked in each month must also be entered in the field for worked days on the form 'Supplementing to employer's certificate'."
                  )}
                </label>
              </Row>
            </div>

            {/* Twelth Section */}
            <div className="mt-3">
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <h5>{t('11 Information about the salary')}</h5>
                </Col>
              </Row>
            </div>

            <div className="ml-2 mr-2 border border-dark p-2">
              <Row>
                <InputGroup>
                  <Col lg={2} md={2} sm={6}>
                    <label className="common_label">{t('Salary refers to years')}</label>
                  </Col>
                  <Col
                    className={`notrequired ${
                      fieldsError?.salaryRefersError ? 'invalidInput mb-2' : 'isValidinp mb-2'
                    }`}
                    lg={3}
                    md={3}
                    sm={12}
                  >
                    <CustomDatePicker
                      name="salaryRefers"
                      value={fields?.salaryRefers}
                      onChange={handleChange('salaryRefers')}
                      placeholder={t('yyyy')}
                      formatType="year"
                    />
                  </Col>

                  <Col lg={7} md={7} sm={12}>
                    <InputGroup>
                      <Form.Check
                        className="common_label"
                        type="checkbox"
                        label={t('Monthly Salary')}
                        checked={checkList?.isMonthlySalary}
                        onChange={handleChange('isMonthlySalary')}
                        style={{ accentColor: '#62b5e5' }}
                      />
                      <Form.Check
                        className="common_label pl-4"
                        type="checkbox"
                        label={t('Weekly salary')}
                        checked={checkList?.isWeeklySalary}
                        onChange={handleChange('isWeeklySalary')}
                        style={{ accentColor: '#62b5e5' }}
                      />
                      <Form.Check
                        className="common_label pl-4"
                        type="checkbox"
                        label={t('Daily salary')}
                        checked={checkList?.isDalilySalary}
                        onChange={handleChange('isDalilySalary')}
                        style={{ accentColor: '#62b5e5' }}
                      />
                      <Form.Check
                        className="common_label pl-4"
                        type="checkbox"
                        label={t('Hourly salary')}
                        checked={checkList?.isHourlySalary}
                        onChange={handleChange('isHourlySalary')}
                        style={{ accentColor: '#62b5e5' }}
                      />
                    </InputGroup>
                  </Col>

                  <Col lg={8} md={8} sm={6}>
                    <Form.Control
                      type="number"
                      name="amountForOverTime"
                      value={fields?.amountForOverTime}
                      onChange={handleChange('amountForOverTime')}
                      min={0}
                      step="any"
                      onFocus={(e) =>
                        e.target.addEventListener(
                          'wheel',
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                    />
                  </Col>

                  <Col lg={4} md={4} sm={12}>
                    <label className="common_label">{t('Amount in SEK')}</label>
                  </Col>
                </InputGroup>

                <InputGroup>
                  <label className="common_label pl-3 mt-3">{t('Has the hourly wage for overtime,')}</label>
                  <label className="common_label mt-3">
                    {t('overtime or fill-in work varied?')}
                    <Form.Check
                      className="common_label pl-4"
                      name="is_worried"
                      type="radio"
                      label={t('No - report that hourly salary below')}
                      checked={!checkList?.isFillingWorkvaried}
                      onChange={() =>
                        setcheckList({
                          ...checkList,
                          isFillingWorkvaried: !checkList.isFillingWorkvaried,
                        })
                      }
                      style={{ accentColor: '#62b5e5' }}
                    />
                    <Form.Check
                      className="common_label pl-4 text-left"
                      name="is_worried"
                      type="radio"
                      label={t(
                        "Yes - Report that salary month by month on the form Completion to employer's certificate"
                      )}
                      checked={checkList?.isFillingWorkvaried}
                      onChange={() =>
                        setcheckList({
                          ...checkList,
                          isFillingWorkvaried: !checkList.isFillingWorkvaried,
                        })
                      }
                      style={{ accentColor: '#62b5e5' }}
                    />
                  </label>
                </InputGroup>

                <InputGroup className="mt-2">
                  <Col lg={1} md={1} sm={12}>
                    <label className="common_label pl-1">{t('Overtime, enter')}</label>
                  </Col>
                  <Col lg={3} md={3} sm={12}>
                    <Form.Control
                      type="number"
                      name="overTimeHours"
                      className={`form-control ${
                        !checkList?.isFillingWorkvaried ? '' : fieldsError?.overTimeHoursError ? 'invalidInput' : ''
                      }`}
                      value={fields.overTimeHours || ''}
                      onChange={handleChange('overTimeHours')}
                      step="any"
                      onFocus={(e) =>
                        e.target.addEventListener(
                          'wheel',
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      disabled={!checkList?.isFillingWorkvaried}
                    />
                  </Col>
                  <Col lg={3} md={3} sm={12}>
                    <label className="common_label">{t('SEK/hour Mertid/filling time, enter')}</label>
                  </Col>
                  <Col lg={3} md={3} sm={12}>
                    <Form.Control
                      type="number"
                      name="hoursMertidFilllingTime"
                      className={`form-control ${
                        !checkList?.isFillingWorkvaried
                          ? ''
                          : fieldsError?.hoursMertidFilllingTimeError
                          ? 'invalidInput'
                          : ''
                      }`}
                      value={fields.hoursMertidFilllingTime || ''}
                      onChange={handleChange('hoursMertidFilllingTime')}
                      step="any"
                      onFocus={(e) =>
                        e.target.addEventListener(
                          'wheel',
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      disabled={!checkList?.isFillingWorkvaried}
                    />
                  </Col>
                  <Col lg={1} md={1} sm={12}>
                    <label className="common_label">{t('SEK/hour')}</label>
                  </Col>
                </InputGroup>
                <label className="common_label pl-3 mt-3">
                  {t(
                    'Salary in addition to the monthly, weekly, daily or hourly salary (e.g. on-call or OB compensation, gage etc.)'
                  )}
                  <Form.Check
                    className="common_label pl-4"
                    name="is_salaryAddition"
                    type="radio"
                    label={t('No')}
                    checked={!checkList?.isSalaryInAdditional}
                    onChange={() =>
                      setcheckList({
                        ...checkList,
                        isSalaryInAdditional: !checkList.isSalaryInAdditional,
                      })
                    }
                    style={{ accentColor: '#62b5e5' }}
                  />
                  <Form.Check
                    className="common_label pl-4"
                    name="is_salaryAddition"
                    type="radio"
                    label={t('Yes')}
                    checked={checkList?.isSalaryInAdditional}
                    onChange={() =>
                      setcheckList({
                        ...checkList,
                        isSalaryInAdditional: !checkList.isSalaryInAdditional,
                      })
                    }
                    style={{ accentColor: '#62b5e5' }}
                  />
                  {t(
                    "Report that salary month by the form 'Supplement to employer's certificate' month on and other taxable compensation that is not included in the salary stated above?"
                  )}
                </label>
              </Row>
            </div>

            {/* Thirteen Section */}
            <div className="mt-3">
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <h5>{t('12 Residency pay and holiday pay (only for study-related activities)')}</h5>
                </Col>
              </Row>
            </div>

            <div className="ml-2 mr-2 border border-dark p-2">
              <Row>
                <InputGroup>
                  <Col lg={5} md={5} sm={12}>
                    <label className="common_label d-flex">
                      {t('Employee with holiday pay')}
                      <Form.Check
                        className="pr-2"
                        name="is_holiday"
                        type="radio"
                        label={t('No')}
                        checked={!checkList?.isEmployeeWithHoliday}
                        onChange={() =>
                          setcheckList({
                            ...checkList,
                            isEmployeeWithHoliday: !checkList.isEmployeeWithHoliday,
                          })
                        }
                        style={{ accentColor: '#62b5e5' }}
                      />
                      <Form.Check
                        type="radio"
                        className="ml-3"
                        name="is_holiday"
                        checked={checkList?.isEmployeeWithHoliday}
                        onChange={() =>
                          setcheckList({
                            ...checkList,
                            isEmployeeWithHoliday: !checkList.isEmployeeWithHoliday,
                          })
                        }
                        style={{ accentColor: '#62b5e5' }}
                      />{' '}
                      {t('If yes, enter the number of paid holiday days')}
                    </label>
                  </Col>

                  <Col lg={2} md={2} sm={12}>
                    <Form.Control
                      type="number"
                      name="numberOfPaidHoliday"
                      className={`form-control ${
                        !checkList?.isEmployeeWithHoliday
                          ? ''
                          : fieldsError?.numberOfPaidHolidayError
                          ? 'invalidInput'
                          : ''
                      }`}
                      value={fields.numberOfPaidHoliday || ''}
                      onChange={handleChange('numberOfPaidHoliday')}
                      step="any"
                      onFocus={(e) =>
                        e.target.addEventListener(
                          'wheel',
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      disabled={!checkList?.isEmployeeWithHoliday}
                    />
                  </Col>

                  <Col lg={3} md={3} sm={6}>
                    <label className="common_label">{t('Enter paid holiday pay in SEK')}</label>
                  </Col>

                  <Col lg={2} md={2} sm={6}>
                    <Form.Control
                      type="number"
                      name="holidayPayInKroner"
                      className={`form-control ${
                        !checkList?.isEmployeeWithHoliday
                          ? ''
                          : fieldsError?.holidayPayInKronerError
                          ? 'invalidInput'
                          : ''
                      }`}
                      value={fields.holidayPayInKroner || ''}
                      onChange={handleChange('holidayPayInKroner')}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          'wheel',
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      disabled={!checkList?.isEmployeeWithHoliday}
                    />
                  </Col>
                </InputGroup>

                <InputGroup className="mt-2">
                  <Col lg={8} md={8} sm={12}>
                    <label className="common_label d-flex">
                      {t('Employee with living wage')}
                      <Form.Check
                        className="pr-2 ml-1"
                        name="is_livingwages"
                        type="radio"
                        label={t('No')}
                        checked={!checkList?.isEmployeeWithLivingWage}
                        onChange={() =>
                          setcheckList({
                            ...checkList,
                            isEmployeeWithLivingWage: !checkList.isEmployeeWithLivingWage,
                          })
                        }
                        style={{ accentColor: '#62b5e5' }}
                      />
                      <Form.Check
                        type="radio"
                        className="ml-3"
                        name="is_livingwages"
                        checked={checkList?.isEmployeeWithLivingWage}
                        onChange={() =>
                          setcheckList({
                            ...checkList,
                            isEmployeeWithLivingWage: !checkList.isEmployeeWithLivingWage,
                          })
                        }
                        style={{ accentColor: '#62b5e5' }}
                      />{' '}
                      {t('If yes, enter earned living wage in SEK')}
                    </label>

                    <label className="common_label"></label>
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <Form.Control
                      type="number"
                      name="earnedLivingWage"
                      className={`form-control ${
                        !checkList?.isEmployeeWithLivingWage
                          ? ''
                          : fieldsError?.earnedLivingWageError
                          ? 'invalidInput'
                          : ''
                      }`}
                      value={fields.earnedLivingWage || ''}
                      onChange={handleChange('earnedLivingWage')}
                      step="any"
                      onFocus={(e) =>
                        e.target.addEventListener(
                          'wheel',
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      disabled={!checkList?.isEmployeeWithLivingWage}
                    />
                  </Col>
                </InputGroup>
              </Row>
            </div>

            {/* 14th Section */}
            <div className="mt-3">
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <h5>{t('13 Other information')}</h5>
                </Col>
              </Row>
            </div>

            <div className="ml-2 mr-2 border border-dark p-2">
              <Row>
                <InputGroup>
                  <Col lg={6} md={6} sm={12}>
                    <label className="common_label">{t("See appendix 'Other information'.")}</label>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <Form.Control
                      type="text"
                      name="otherInformation"
                      placeholder={t('other information')}
                      value={fields?.otherInformation}
                      onChange={(e) => {
                        handleChange('otherInformation')(e);
                        handleCharLength('otherInformation', e.target.value);
                      }}
                    />
                  </Col>
                </InputGroup>
              </Row>
            </div>

            {/* 15th Section */}
            <div className="mt-3">
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <h5>{t("14 The employer's (or representative's) signature")}</h5>
                </Col>
              </Row>
            </div>

            <div className="ml-2 mr-2 border border-dark p-2">
              <Row>
                <InputGroup>
                  <Col lg={6} md={6} sm={12}>
                    <label className="common_label">{t('The employers name and address')}</label>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <Form.Control
                      type="text"
                      name="employersNameAndAddress"
                      className={`remove_border form-contro`}
                      placeholder={t('Invozio LV SIA, AHTRI 12, 10151 Tallinn')}
                      value={'Invozio LV SIA, AHTRI 12, 10151 Tallinn'}
                      disabled
                    />
                  </Col>
                </InputGroup>

                <InputGroup>
                  <Col lg={6} md={6} sm={12}>
                    <label className="common_label">{t('Organization number')}</label>
                  </Col>
                  <Col className="mt-2" lg={6} md={6} sm={12}>
                    <Form.Control
                      type="number"
                      name="organizationNumber"
                      className={`remove_border form-control`}
                      value="50 20 85 40 62"
                      // value={fields.organizationNumber}
                      // onChange={handleChange("organizationNumber")}
                      placeholder={t('5 0 2 0 8 5 4 0 6 2')}
                      disabled
                    />
                  </Col>
                </InputGroup>

                <InputGroup>
                  <Col lg={4} md={4} sm={12}>
                    <label className="common_label">{t('Date')}</label>
                  </Col>

                  <Col lg={4} md={4} sm={12}>
                    <label className="common_label">{t('Signature')}</label>
                  </Col>

                  <Col lg={4} md={4} sm={12}>
                    <label className="common_label">{t('Name clarification')}</label>
                  </Col>
                </InputGroup>

                <div className="ml-2 w-100 mr-2 border border-dark p-2">
                  <InputGroup>
                    <Col className="first_remove" lg={4} md={4} sm={12}>
                      <Form.Control
                        type="date"
                        name="employersDate"
                        className={`remove_border form-control ${
                          fieldsError?.employersDateError ? 'invalidInput' : ''
                        }`}
                        value={fields.employersDate}
                        onChange={handleChange('employersDate')}
                        max={new Date().toISOString().split('T')[0]}
                      />
                    </Col>

                    <Col className="first_remove mt-1" lg={4} md={4} sm={12}>
                      <InputGroup>
                        <Form.Check
                          type="checkbox"
                          name="isDigtally"
                          className="common_label"
                          checked={!checkList?.isDigtally}
                          onChange={() =>
                            setcheckList({
                              ...checkList,
                              isDigtally: !checkList.isDigtally,
                            })
                          }
                          label={t('Sign pad')}
                          style={{ accentColor: '#62b5e5' }}
                        />
                        <Form.Check
                          name="isDigtally"
                          className="common_label pl-4"
                          label={t('Upload Image')}
                          type="checkbox"
                          checked={checkList?.isDigtally}
                          onChange={() =>
                            setcheckList({
                              ...checkList,
                              isDigtally: !checkList.isDigtally,
                            })
                          }
                          style={{ accentColor: '#62b5e5' }}
                        />
                      </InputGroup>
                      {!checkList?.isDigtally ? (
                        <>
                          <Button onClick={() => openModal('signature')}>Add Signature</Button>
                          <OpenSignatureModal
                            isOpen={isModalOpen}
                            onClose={() => setIsModalOpen(false)}
                            onSave={onSaveSignature}
                          />
                          {fields?.signature && (
                            <div>
                              <img
                                src={fields?.signature}
                                alt="Signature"
                                style={{
                                  cursor: 'move',
                                  width: '200px',
                                }}
                              />
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {fields?.signature && (
                            <div className="mt-3">
                              <Button
                                onClick={() =>
                                  setFields((prev) => ({
                                    ...prev,
                                    signature: null,
                                  }))
                                }
                                className="remove_image"
                              >
                                X
                              </Button>
                              <img
                                src={fields?.signature}
                                alt="Signature"
                                style={{
                                  cursor: 'move',
                                  width: '200px',
                                }}
                              />
                            </div>
                          )}
                          {fields?.signature === null && (
                            <Form.Control
                              type="file"
                              name="signature"
                              className={`form-control mt-2 ${fieldsError?.signatureError ? 'invalidInput' : ''}`}
                              onChange={handleImageChange('signature')}
                            />
                          )}
                        </>
                      )}
                    </Col>

                    <Col className="first_remove" lg={4} md={4} sm={12}>
                      <Form.Control
                        type="text"
                        name="nameClarification"
                        className={`remove_border form-control ${
                          fieldsError?.nameClarificationError ? 'invalidInput' : ''
                        }`}
                        value={fields.nameClarification}
                        onChange={(e) => {
                          handleChange('nameClarification')(e);
                          handleCharLength('nameClarification', e.target.value);
                        }}
                      />
                      {isTextError?.nameClarificationError && (
                        <Form.Text className="form_textt">{t(message)}</Form.Text>
                      )}
                    </Col>
                  </InputGroup>
                </div>
              </Row>
            </div>

            {/* Content Start */}
            <CertificateEmployeeContent
              fields={fields}
              setFields={setFields}
              checkList={checkList}
              setcheckList={setcheckList}
              handleChange={handleChange}
              fieldsError={fieldsError}
              incomeServiceFields={incomeServiceFields}
              handleIncomeService={handleIncomeService}
              handleCharLength={handleCharLength}
              isTextError={isTextError}
              message={message}
              handleImageChange={handleImageChange}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              onSaveSignature={onSaveSignature}
              openModal={openModal}
            />
          </div>
          <Modal.Footer>
      

            <Button
              variant="secondary"
              onClick={() => {
                Toggleclose(false);
                setfieldsError(false);
                setisTextError(false);
                setFields((prev) => ({
                  ...prev,
                  nameClarification: '',
                }));
              }}
            >
              {t('Cancel')}
            </Button>

            <Button disabled={loading} type="submit" variant="contained" color={'primary'}>
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default memo(ApplyCertificateForm);
