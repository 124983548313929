import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { swe } from './lang/swe';
// const retrievedValue = localStorage.getItem("i18nextLng");

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      de: swe,
    },
    fallbackLng: 'en',
    debug: false,
    // lng: retrievedValue || "de", // default lang
    lng: 'en', // default lang
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
