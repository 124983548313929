/* eslint-disable import/order */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-fragments */

import React, { memo } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { empCertificateLeft, empCertificateRight } from 'src/utils/commonFunc';
import IncomeServiceTable from './IncomeServiceTable';
import { Button } from '@mui/material';
import OpenSignatureModal from './SignatureModal';

const CertificateEmployeeContent = ({
  fields,
  checkList,
  setcheckList,
  handleChange,
  fieldsError,
  incomeServiceFields,
  handleIncomeService,
  handleCharLength,
  isTextError,
  message,
  isModalOpen,
  setIsModalOpen,
  onSaveSignature,
  openModal,
  setFields,
  handleImageChange,
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {/* INSTRUCTIONS FOR THE EMPLOYER'S CERTIFICATE 2019-11-18 */}
      <div className="ml-2 mr-2 p-2">
        <h3>{t("INSTRUCTIONS FOR THE EMPLOYER'S CERTIFICATE 2019-11-18")}</h3>
        <p className="common_label">
          t
          {t(
            "The form is determined by the IAF (Inspektionen för Arbetslöhetsförsäkringen) and drawn up in consultation with Sweden's unemployment insurance funds. The employer must issue an employer's certificate on request (section 47 of the Act (1997:238) on unemployment insurance)."
          )}
        </p>
        <p className="common_label">
          {t(
            "The employer's certificate is needed - when an employee applies for unemployment benefits - when an employee seeks activity support The information provided in the certificate may be insufficient to assess the right to compensation. The employer may therefore need to provide additional information to the unemployment fund."
          )}
        </p>
        <p className="common_label">
          {t(
            "Employer certificates shall not be issued to persons whose work has been financed with special employment support, e.g. entry level job. For other forms of employment support, an employer's certificate must be issued. This applies, for example, to wage subsidy employment, start-up jobs and OSA."
          )}
        </p>
        <p className="common_label">
          {t('Special certificates are available, for example, for employees who are employed in their own business.')}
        </p>

        <p className="common_label">
          {t('The form must be machine-readable. Therefore, please fill in the form on the computer.')}
        </p>
        <p className="common_label">
          {t(
            'If the information is filled in by hand, it is important that the information is filled in correctly. Use a ballpoint pen with blue or black writing. Please text. Write within the marked frames:'
          )}
        </p>
      </div>

      {/* 16th Section */}
      <div className="ml-2 mr-2 border border-dark p-2">
        <Row>
          <InputGroup>
            <Col lg={2} md={2} sm={12}>
              <label className="common_label">{t('Write within the marked boxes:')}</label>
            </Col>

            <Col lg={2} md={2} sm={12}>
              <label className="common_label">{t('Right:')}</label>
            </Col>
            <Col lg={3} md={3} sm={12}>
              <Form.Control
                type="text"
                name="writeWithinMarkedBoxRight"
                placeholder={t('Text inside the frame')}
                disabled
              />
            </Col>

            <Col lg={2} md={2} sm={6}>
              <label className="common_label">{t('Wrong:')}</label>
            </Col>

            <Col lg={3} md={3} sm={6}>
              <Form.Control
                type="text"
                // name="writeWithinMarkedWrong"
                placeholder={t('Text outside the frame')}
                disabled
              />
            </Col>
          </InputGroup>

          <InputGroup className="mt-2 mb-2">
            <Col lg={4} md={4} sm={6}>
              <label className="common_label">{t("Use cross instead of 'tick':")}</label>
            </Col>

            <Col lg={2} md={2} sm={12}>
              <Form.Check
                className="pr-2"
                name="is_rightCheck"
                type="radio"
                label={t('Yes')}
                checked={checkList?.isCrossInstedofTick}
                onChange={() =>
                  setcheckList({
                    ...checkList,
                    isCrossInstedofTick: !checkList.isCrossInstedofTick,
                  })
                }
                style={{ accentColor: '#62b5e5' }}
                disabled
              />
            </Col>
            <Col lg={2} md={2} sm={12}>
              <Form.Check
                className="pr-2"
                name="is_rightCheck"
                type="radio"
                label={t('No')}
                checked={!checkList?.isCrossInstedofTick}
                onChange={() =>
                  setcheckList({
                    ...checkList,
                    isCrossInstedofTick: !checkList.isCrossInstedofTick,
                  })
                }
                style={{ accentColor: '#62b5e5' }}
                disabled
              />
            </Col>
          </InputGroup>

          <InputGroup>
            <Col lg={6} md={6} sm={12}>
              <label className="common_label">{t('If you ticked incorrectly - fill in the entire checkbox:')}</label>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <label className="common_label d-flex">
                {t('Correctly filled in:')}
                <Form.Check
                  className="pr-2 ml-4"
                  name="is_ticked"
                  type="radio"
                  checked={!checkList?.isTickInCorrectly}
                  onChange={() =>
                    setcheckList({
                      ...checkList,
                      isTickInCorrectly: !checkList.isTickInCorrectly,
                    })
                  }
                  style={{ accentColor: '#62b5e5' }}
                  disabled
                />
                <Form.Check
                  type="radio"
                  name="is_ticked"
                  label={t('Incorrectly filled in:')}
                  checked={checkList?.isTickInCorrectly}
                  onChange={() =>
                    setcheckList({
                      ...checkList,
                      isTickInCorrectly: !checkList.isTickInCorrectly,
                    })
                  }
                  style={{ accentColor: '#62b5e5' }}
                  disabled
                />
              </label>
            </Col>
          </InputGroup>

          <InputGroup>
            <Col lg={6} md={6} sm={12}>
              <label className="common_label">{t('Write numbers like this:')}</label>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Control type="number" placeholder={t('1 2 3 4 5 6 7 8 9 0')} disabled />
            </Col>
          </InputGroup>

          <InputGroup className="mt-2">
            <Col lg={6} md={6} sm={12}>
              <label className="common_label">{t('The date is filled in according to the formula YYYYMMDD:')}</label>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Control type="date" max={new Date().toISOString().split('T')[0]} disabled />
            </Col>
          </InputGroup>
        </Row>
      </div>
      <div className="ml-2 mr-2 p-2">
        <Row>
          <Col lg={6} md={6} sm={12}>
            {empCertificateLeft?.map((con, idx) => (
              <div key={idx}>
                <h6 className="content_head">{t(con?.title) || ''}</h6>
                <p className="content_para">{t(con?.description) || ''}</p>
                <p className="content_para">{t(con?.subtitle) || ''}</p>
                <p className="content_para">{t(con?.sub_description1) || ''}</p>
                <p className="content_para">{t(con?.sub_description2) || ''}</p>
                <p className="content_para">{t(con?.other_des) || ''}</p>
              </div>
            ))}
          </Col>

          <Col lg={6} md={6} sm={12}>
            {empCertificateRight?.map((content, idx) => (
              <div key={idx}>
                <h6 className="content_head">{t(content?.title) || ''}</h6>
                <p className="content_para">{t(content?.description) || ''}</p>
                <p className="content_para">{t(content?.subtitle) || ''}</p>
                <p className="content_para">{t(content?.sub_description1) || ''}</p>
                <p className="content_para">{t(content?.sub_description2) || ''}</p>
                <p className="content_para">{t(content?.other_des) || ''}</p>
              </div>
            ))}
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <h6 className="content_head">Note: {'Supplement to Employer Certificate'}</h6>
            <h6 className="content_head">
              {'ATTENTION! This supplement should only be filled in and sent if necessary!'}
            </h6>
            <p className="content_para">
              {
                "Compensation that is not stated on the main form 'Employer's certificate' must be entered here. Only remuneration that is taxed as income from service shallbrought up."
              }
            </p>
          </Col>
        </Row>
      </div>

      {/* Income from Service Table Start */}
      <IncomeServiceTable incomeServiceFields={incomeServiceFields} handleIncomeService={handleIncomeService} />
      {/* Income from Service Table End */}

      {/* Other Information form */}
      <div className="d-flex w-100">
        <div className="ml-2 w-50 mr-2 border border-dark rounded p-2">
          <Row>
            <Col lg={12} md={12} sm={12}>
              <label className="common_label">{t(' 1 Information about person and employer')}</label>
            </Col>
            <InputGroup className="other_info_col">
              <Col className="mt-2" lg={6} md={6} sm={12}>
                <label className="common_label">{t('Employee Name:')}</label>
              </Col>

              <Col lg={6} md={6} sm={12}>
                <Form.Control
                  type="text"
                  name="userName"
                  className={`remove_border form-control ${fieldsError?.userNameError ? 'invalidInput mb-1' : ''}`}
                  placeholder={t('Kesseli, Mikae')}
                  value={fields.userName}
                  onChange={(e) => {
                    handleChange('userName')(e);
                    handleCharLength('userName', e.target.value);
                  }}
                />
                {isTextError?.userNameError && <Form.Text className="form_textt">{t(message)}</Form.Text>}
              </Col>
            </InputGroup>

            <InputGroup className="other_info_col">
              <Col className="mt-2" lg={6} md={6} sm={12}>
                <label className="common_label">{t('Employer name:')}</label>
              </Col>

              <Col lg={6} md={6} sm={12}>
                <Form.Control
                  type="text"
                  name="employerName"
                  className={`remove_border form-control ${fieldsError?.employerNameError ? 'invalidInput mb-1' : ''}`}
                  placeholder={t('Invozio LV SIA')}
                  value={fields?.employerName}
                  onChange={(e) => {
                    handleChange('employerName')(e);
                    handleCharLength('employerName', e.target.value);
                  }}
                />
                {isTextError?.employerNameError && <Form.Text className="form_textt">{t(message)}</Form.Text>}
              </Col>
            </InputGroup>

            <InputGroup className="other_info_col">
              <Col className="mt-2" lg={6} md={6} sm={12}>
                <label className="common_label">{t('Street address:')}</label>
              </Col>

              <Col lg={6} md={6} sm={12}>
                <Form.Control
                  type="text"
                  name="streetAddress"
                  className={`remove_border form-control ${fieldsError?.streetAddressError ? 'invalidInput mb-1' : ''}`}
                  placeholder={t('AHTRI 12')}
                  value={fields.streetAddress}
                  onChange={(e) => {
                    handleChange('streetAddress')(e);
                    handleCharLength('streetAddress', e.target.value);
                  }}
                />
                {isTextError?.streetAddressError && <Form.Text className="form_textt">{t(message)}</Form.Text>}
              </Col>
            </InputGroup>

            <InputGroup className="other_info_col">
              <Col className="mt-2" lg={6} md={6} sm={12}>
                <label className="common_label">{t('Mailing address:')}</label>
              </Col>

              <Col lg={6} md={6} sm={12}>
                <Form.Control
                  type="text"
                  name="mailingAddress"
                  className={`remove_border form-control ${
                    fieldsError?.mailingAddressError ? 'invalidInput mb-1' : ''
                  }`}
                  placeholder={t('10151 Tallinn')}
                  value={fields.mailingAddress}
                  onChange={(e) => {
                    handleChange('mailingAddress')(e);
                    handleCharLength('mailingAddress', e.target.value);
                  }}
                />
                {isTextError?.mailingAddressError && <Form.Text className="form_textt">{t(message)}</Form.Text>}
              </Col>
            </InputGroup>

            <InputGroup className="other_info_col">
              <Col className="mt-2" lg={6} md={6} sm={12}>
                <label className="common_label">{t('Organization number:')}</label>
              </Col>

              <Col lg={6} md={6} sm={12}>
                <Form.Control
                  type="number"
                  name="organizationNumber"
                  className={`remove_border form-control ${
                    fieldsError?.organizationNumberError ? 'invalidInput mb-1' : ''
                  }`}
                  placeholder={t('Organization number')}
                  value={fields.organizationNumber}
                  onChange={handleChange('organizationNumber')}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      'wheel',
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                />
              </Col>
            </InputGroup>

            <InputGroup className="other_info_col">
              <Col className="mt-2" lg={6} md={6} sm={12}>
                <label className="common_label">{t('Telephone number of the informant:')}</label>
              </Col>

              <Col lg={6} md={6} sm={12}>
                <Form.Control
                  type="number"
                  name="telephoneNumber"
                  className={`remove_border form-control ${fieldsError?.telephoneNumberError ? 'invalidInput' : ''}`}
                  placeholder="010-264 88 10"
                  value={fields?.telephoneNumber}
                  onChange={handleChange('telephoneNumber')}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      'wheel',
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                />
              </Col>
            </InputGroup>
          </Row>
        </div>

        <div className="ml-2 mr-2 w-50 p-2">
          <Row className="left_info">
            <Col lg={12} md={12} sm={12}>
              <label className="common_label">{t('Social security number (12 digits)')}</label>
            </Col>
            <Col lg={12} md={12} sm={12}>
              <Form.Control
                type="number"
                name="socialSecurityNumber"
                className={`form-control ${fieldsError?.socialSecurityNumberError ? 'invalidInput' : ''}`}
                placeholder={t('790502-7178')}
                value={fields.socialSecurityNumber}
                onChange={handleChange('socialSecurityNumber')}
                min={0}
                onFocus={(e) =>
                  e.target.addEventListener(
                    'wheel',
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
              />
            </Col>
          </Row>
        </div>
      </div>

      <div className="m-2 border border-dark rounded p-2">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <label className="common_label">{t('2 Other information')}</label>
          </Col>
          <InputGroup>
            <Col lg={12} md={12} sm={12}>
              {t('2. Details of the employment')}
              <Form.Control
                as="textarea"
                name="otherInfomationEmployment"
                value={fields?.otherInfomationEmployment || null}
                onChange={(e) => {
                  handleChange('otherInfomationEmployment')(e);
                  handleCharLength('otherInfomationEmployment', e.target.value);
                }}
              />
            </Col>
          </InputGroup>
        </Row>
      </div>

      <div className="ml-2 mr-2">
        <Row>
          <span className="pl-3"> {t("3 The employer's (or representative's) signature")}</span>
          <InputGroup>
            <Col lg={3} md={3} sm={12}>
              <label className="common_label">{t('Date')}</label>
            </Col>

            <Col lg={3} md={3} sm={12}>
              <label className="common_label">{t('City')}</label>
            </Col>

            <Col lg={4} md={4} sm={12}>
              <label className="common_label">{t('Signature')}</label>
            </Col>
            <Col lg={2} md={2} sm={12}>
              <label className="common_label">{t('Name clarification')}</label>
            </Col>
          </InputGroup>
        </Row>
      </div>

      <div className="ml-2 mr-2 border border-dark p-2">
        <Row>
          <InputGroup>
            <Col className="first_remove" lg={3} md={3} sm={12}>
              <Form.Control
                type="date"
                name="employersDate"
                className={`remove_border form-control ${fieldsError?.employersDateError ? 'invalidInput' : ''}`}
                placeholder={t('2024-03-04')}
                value={fields.employersDate}
                onChange={handleChange('employersDate')}
                max={new Date().toISOString().split('T')[0]}
              />
            </Col>

            <Col className="first_remove" lg={2} md={2} sm={12}>
              <Form.Control
                type="text"
                name="representativeCity"
                className={`remove_border form-control ${fieldsError?.representativeCityError ? 'invalidInput' : ''}`}
                placeholder={t('City')}
                value={fields.representativeCity}
                onChange={(e) => {
                  handleChange('representativeCity')(e);
                  handleCharLength('representativeCity', e.target.value);
                }}
              />
              {isTextError?.representativeCityError && <Form.Text className="form_textt">{t(message)}</Form.Text>}
            </Col>

            <Col className="first_remove mt-1" lg={5} md={5} sm={12}>
              <InputGroup>
                <Form.Check
                  type="checkbox"
                  name="isDigtally"
                  className="common_label"
                  checked={!checkList?.isDigtally}
                  onChange={() =>
                    setcheckList({
                      ...checkList,
                      isDigtally: !checkList.isDigtally,
                    })
                  }
                  label={t('Sign pad')}
                  style={{ accentColor: '#62b5e5' }}
                />
                <Form.Check
                  name="isDigtally"
                  className="common_label pl-4"
                  label={t('Upload Image')}
                  type="checkbox"
                  checked={checkList?.isDigtally}
                  onChange={() =>
                    setcheckList({
                      ...checkList,
                      isDigtally: !checkList.isDigtally,
                    })
                  }
                  style={{ accentColor: '#62b5e5' }}
                />
              </InputGroup>
              {!checkList?.isDigtally ? (
                <>
                  <Button onClick={() => openModal('signature')}>Add Signature</Button>
                  <OpenSignatureModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onSave={onSaveSignature}
                  />
                  {fields?.signature && (
                    <div>
                      <img
                        src={fields?.signature}
                        alt="Signature"
                        style={{
                          cursor: 'move',
                          width: '200px',
                        }}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {fields?.signature && (
                    <div className="mt-3">
                      <Button
                        onClick={() =>
                          setFields((prev) => ({
                            ...prev,
                            signature: null,
                          }))
                        }
                        className="remove_image"
                      >
                        X
                      </Button>
                      <img
                        src={fields?.signature}
                        alt="Signature"
                        style={{
                          cursor: 'move',
                          width: '200px',
                        }}
                      />
                    </div>
                  )}
                  {fields?.signature === null && (
                    <Form.Control
                      type="file"
                      name="signature"
                      className={`form-control mt-2 ${fieldsError?.signatureError ? 'invalidInput' : ''}`}
                      onChange={handleImageChange('signature')}
                    />
                  )}
                </>
              )}
            </Col>

            <Col lg={2} md={2} sm={12}>
              <Form.Control
                type="text"
                name="nameClarification"
                className={`remove_border form-control ${fieldsError?.nameClarificationError ? 'invalidInput' : ''}`}
                placeholder={t('Name')}
                value={fields?.nameClarification}
                onChange={(e) => {
                  handleChange('nameClarification')(e);
                  handleCharLength('nameClarification', e.target.value);
                }}
              />

              {isTextError?.nameClarificationError && <Form.Text className="form_textt">{t(message)}</Form.Text>}
            </Col>
          </InputGroup>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default memo(CertificateEmployeeContent);
