import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export const vatFormats = {
  BE: { regex: /^\d{10}$/, maxLength: 10 },
  AD: { regex: /^\d{10}$/, maxLength: 6 },
  BG: { regex: /^\d{9,10}$/, minLength: 9, maxLength: 10 },
  LB: { regex: /^\d{9,10}$/, minLength: 9, maxLength: 8 },
  DK: { regex: /^\d{8}$/, maxLength: 8 },
  EE: { regex: /^\d{9}$/, maxLength: 9 },
  FI: { regex: /^\d{8}$/, maxLength: 8 },
  ZW: { regex: /^\d{8}$/, maxLength: 8 },
  FR: { regex: /^\d{11}$/, maxLength: 11 },
  EL: { regex: /^\d{9}$/, maxLength: 9 },
  IT: { regex: /^\d{11}$/, maxLength: 11 },
  HR: { regex: /^\d{11}$/, maxLength: 11 },
  LV: { regex: /^\d{11}$/, maxLength: 11 },
  LT: { regex: /^\d{9,12}$/, minLength: 9, maxLength: 12 },
  LU: { regex: /^\d{8}$/, maxLength: 8 },
  MT: { regex: /^\d{8}$/, maxLength: 8 },
  NL: { regex: /^\d{9}[bB]\d{2}$/, maxLength: 12 },
  XI: { regex: /^(\d{9}|\d{12}|GD\d{3}|HA\d{3})$/, maxLength: 12 },
  PL: { regex: /^\d{10}$/, maxLength: 10 },
  PT: { regex: /^\d{9}$/, maxLength: 9 },
  RO: { regex: /^\d{2,10}$/, minLength: 2, maxLength: 10 },
  SK: { regex: /^\d{10}$/, maxLength: 10 },
  SI: { regex: /^\d{8}$/, maxLength: 8 },
  GB: { regex: /^(\d{9}|\d{12}|GD\d{3}|HA\d{3})$/, maxLength: 12 },
  SE: { regex: /^\d{12}$/, maxLength: 12 },
  CZ: { regex: /^\d{8,10}$/, minLength: 8, maxLength: 10 },
  DE: { regex: /^\d{9}$/, maxLength: 9 },
  HU: { regex: /^\d{8}$/, maxLength: 8 },
  AT: { regex: /^U\d{8}$/, maxLength: 9 },

  CY: { regex: /^\d{8}[a-zA-Z]$/, maxLength: 9, allowedCharacters: /^[a-zA-Z0-9]$/ },
  IE: { regex: /^\d{7,8}[a-zA-Z]$/, minLength: 8, maxLength: 9, allowedCharacters: /^[a-zA-Z0-9]$/ },
  ES: { regex: /^\d{8}[a-zA-Z]$/, maxLength: 9, allowedCharacters: /^[a-zA-Z0-9]$/ },
};

export const validateVatNumber = (countryCode, vatNumber) => {
  const format = vatFormats[countryCode];
  if (!format) return true;

  const { regex, minLength, maxLength } = format;
  if (!regex.test(vatNumber)) {
    if (minLength && maxLength) {
      return `Invalid VAT number format. It should be between ${minLength} and ${maxLength} digits long.`;
    }
    return `Invalid VAT number format. It should be ${maxLength} digits long.`;
  }
  return true;
};
