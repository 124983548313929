import React from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';
import { Box, Modal, Button, Typography } from '@mui/material';

const HoursModal = ({
  getUserId,
  downloadData,
  toggleHourModal,
  incomeData,
  years,
  settoggleHourModal,
  setSelectedYear,
  setincomeData,
  currentYear
}) => {
  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  return (
    <>
      <Modal
        open={toggleHourModal || false}
        onClose={() => {
          settoggleHourModal(false);
          setSelectedYear(currentYear)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{display: "flex"}}>
            Working Hours
            <Button  onClick={() => {settoggleHourModal(false); setSelectedYear(currentYear); }}>
              <RxCross2 />
            </Button>
          </Typography>

          <div className="select-btn-wrapper mt-4">
            <div className="styled-select">
              <MdKeyboardArrowDown className="text-primary arrow-down" size={30} />
              <select
                onChange={(e) => {
                  setSelectedYear(e.target.value);
                  setincomeData({ values: [], statusCode: null });
                }}
              >
                {years?.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <Button
              variant="outline-primary"
              className="mb-2"
              onClick={() => {
                downloadData(getUserId);
              }}
            >
              <span>{'View'}</span>
            </Button>
          </div>

          <div className="mb-3">
            <div className="d-flex justify-content-center flx_wrap">
              <div className="width_set">
                <div className="clr_rund">
                  <p className="m-0">{'January'}</p>
                </div>
                <div className="border_set text-center">
                  <p className="m-0 ml-auto">Hr {incomeData?.values?.janMonthHours || 0}</p>
                </div>
              </div>
              <div className="width_set">
                <div className="clr_rund">
                  <p className="m-0">{'February'}</p>
                </div>
                <div className="border_set text-center">
                  <p className="m-0 ml-auto">Hr {incomeData?.values?.febMonthHours || 0}</p>
                </div>
              </div>
              <div className="width_set">
                <div className="clr_rund">
                  <p className="m-0">{'March'}</p>
                </div>
                <div className="border_set text-center">
                  <p className="m-0 ml-auto">Hr {incomeData?.values?.marMonthHours || 0}</p>
                </div>
              </div>
              <div className="width_set">
                <div className="clr_rund">
                  <p className="m-0">{'April'}</p>
                </div>
                <div className="border_set text-center">
                  <p className="m-0 ml-auto">Hr {incomeData?.values?.aprMonthHours || 0}</p>
                </div>
              </div>
              <div className="width_set">
                <div className="clr_rund">
                  <p className="m-0">{'May'}</p>
                </div>
                <div className="border_set text-center">
                  <p className="m-0 ml-auto">Hr {incomeData?.values?.mayMonthHours || 0}</p>
                </div>
              </div>
              <div className="width_set">
                <div className="clr_rund">
                  <p className="m-0">{'June'}</p>
                </div>
                <div className="border_set text-center">
                  <p className="m-0 ml-auto">Hr {incomeData?.values?.juneMonthHours || 0}</p>
                </div>
              </div>
              <div className="width_set">
                <div className="clr_rund">
                  <p className="m-0">{'July'}</p>
                </div>
                <div className="border_set text-center">
                  <p className="m-0 ml-auto">Hr {incomeData?.values?.julyMonthHours || 0}</p>
                </div>
              </div>
              <div className="width_set">
                <div className="clr_rund">
                  <p className="m-0">{'August'}</p>
                </div>
                <div className="border_set text-center">
                  <p className="m-0 ml-auto">Hr {incomeData?.values?.augMonthHours || 0}</p>
                </div>
              </div>
              <div className="width_set">
                <div className="clr_rund">
                  <p className="m-0">{'September'}</p>
                </div>
                <div className="border_set text-center">
                  <p className="m-0 ml-auto">Hr {incomeData?.values?.septMonthHours || 0}</p>
                </div>
              </div>
              <div className="width_set">
                <div className="clr_rund">
                  <p className="m-0">{'October'}</p>
                </div>
                <div className="border_set text-center">
                  <p className="m-0 ml-auto">Hr {incomeData?.values?.octMonthHours || 0}</p>
                </div>
              </div>
              <div className="width_set">
                <div className="clr_rund">
                  <p className="m-0">{'November'}</p>
                </div>
                <div className="border_set text-center">
                  <p className="m-0 ml-auto">Hr {incomeData?.values?.novMonthHours || 0}</p>
                </div>
              </div>
              <div className="width_set">
                <div className="clr_rund">
                  <p className="m-0">{'December'}</p>
                </div>
                <div className="border_set text-center">
                  <p className="m-0 ml-auto">Hr {incomeData?.values?.decMonthHours || 0}</p>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default HoursModal;
