/* eslint-disable prefer-const */
/* eslint-disable arrow-body-style */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { TablePagination } from '@mui/material';
import { ListHead } from '../../../sections/@dashboard/table';

export default function HolidayPayoutSavings({
  tab,
  setLoading,
  searchQuery,
  selectedTabValue,
  datesData,
  selectedMonth,
  selectedYear,
}) {
  const [usersData, setUsersData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState('createdDate');
  const [order, setOrder] = useState('desc');

  const TABLE_HEAD = [
    { id: 'userId', label: 'Employee Id', alignRight: false },
    { id: 'firstName', label: 'Employee', alignRight: false },
    {
      id: 'email',
      label: 'Email',
      alignRight: false,
    },
    { id: 'ssn', label: 'SSN/DOB', alignRight: false },
    { id: 'totalHolidayPayoutAmount', label: 'Holiday Payout', alignRight: false },
  ];

  const getUsersData = async () => {
    try {
      setLoading(true);
      let URL = `/admin/adminsalary/total-holidaypayout-amount?PageNumber=${
        page + 1
      }&PageSize=${rowsPerPage}&searchQuery=${searchQuery}`;
      if (datesData?.from && datesData?.to) {
        URL += `&fromDate=${datesData?.from}&toDate=${datesData?.to}`;
      } else if (!selectedMonth && selectedYear) {
        URL += `&year=${selectedYear}-01-01`;
      } else if (selectedMonth && selectedYear) {
        const firstDateOfMonth = new Date(selectedYear, selectedMonth - 1, 1);
        const finalMonth = firstDateOfMonth.toISOString()?.split('T')[0];
        URL += `&Month=${finalMonth}`;
      }
      const result = await axios.post(URL);
      if (result.status === 200) {
        setLoading(false);
        setTotalCount(result.data.totalCount);
        setUsersData(result.data.response);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (tab === '2') getUsersData();
    }, 100);
    return () => clearTimeout(timeoutId);
  }, [page, rowsPerPage, selectedTabValue, searchQuery, selectedMonth, selectedYear, datesData]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const filteredRows = applySortFilter(
      usersData,
      getComparator(isAsc ? 'desc' : 'asc', orderBy),
      event.target.value,
      usersData
    );
    setUsersData(filteredRows);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <ListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={usersData?.length}
            onRequestSort={handleRequestSort}
            hideCheckboxColumnHeader="true"
          />
          <TableBody>
            {usersData?.length ? (
              usersData?.map((item) => {
                return (
                  <TableRow hover key={item?.userId}>
                    <TableCell>{item?.users?.userId}</TableCell>
                    <TableCell>
                      {item?.users?.firstName} {item?.users?.lastName}
                    </TableCell>
                    <TableCell>{item?.users?.email}</TableCell>
                    <TableCell>{item?.users?.ssn ? item?.users?.ssn : item?.users?.dateOfBirth}</TableCell>
                    <TableCell>{item?.totalHolidayPayoutAmount}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow className="text-center">
                <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                  No record found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[100, 250, 500]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
