/* eslint-disable */
import React from 'react';
import { TableRow, TableCell, Tooltip, Button } from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import NoteIcon from '@mui/icons-material/Note';
import HistoryIcon from '@mui/icons-material/History';

const PermanentDeactivatedUser = ({
  userId,
  row,
  firstName,
  lastName,
  getUserDetails,
  createdDate,
  notes,
  getUserDetailsInfo,
  setaccidentalReactivate,
  navigate,
}) => {
  return (
    <TableRow
      key={userId}
      tabIndex={-1}
      style={{
        backgroundColor: row?.isSelected ? 'lightgrey' : '',
        cursor: 'pointer',
      }}
    >
      <TableCell align="left">{userId}</TableCell>

      {firstName?.length >= 9 ? (
        <Tooltip title={firstName} arrow placement="top">
          <TableCell
            align="left"
            onClick={(event) => {
              getUserDetails(userId);
              event.stopPropagation();
            }}
            style={{ fontSize: '13px', padding: '6px' }}
          >
            {firstName?.substring(0, 9).concat('...')}
          </TableCell>
        </Tooltip>
      ) : (
        <TableCell
          align="left"
          className="userColumn"
          onClick={(event) => {
            getUserDetails(userId);
            event.stopPropagation();
          }}
        >
          {firstName}
        </TableCell>
      )}

      <TableCell align="left" style={{ fontSize: '13px', padding: '6px' }}>
        {row?.email}
      </TableCell>

      <TableCell align="left" style={{ fontSize: '13px', padding: '6px' }}>
        {row?.ssn ? row?.ssn : row?.dateOfBirth}
      </TableCell>

      <TableCell align="left" style={{ fontSize: '13px', padding: '6px' }}>
        {createdDate?.split('T')[0]}
      </TableCell>

      <TableCell align="left" style={{ fontSize: '13px', padding: '6px' }}>
        <Tooltip title="Notes">
          <Button
            type="submit"
            data-toggle="tooltip"
            data-placement="top"
            title="Notes"
            variant={notes ? '' : 'contained'}
            onClick={() => {
              getUserDetailsInfo(userId);
            }}
            style={{
              width: 50,
              minWidth: '50px',
              fontSize: '12px',
              padding: '6px',
              marginLeft: '8px',
            }}
          >
            <NoteIcon style={{ color: notes && 'rgb(226,204,97)' }}>NoteIcon</NoteIcon>
          </Button>
        </Tooltip>
      </TableCell>

      <TableCell align="left" style={{ fontSize: '13px', padding: '6px' }}>
        <div className="d-flex">
          <Tooltip title="Re-activate">
            <Button
              type="submit"
              data-toggle="tooltip"
              data-placement="top"
              title="Re-activate"
              variant={'contained'}
              onClick={() => {
                setaccidentalReactivate({
                  show: true,
                  userId: userId,
                });
              }}
              style={{
                width: 50,
                minWidth: '50px',
                fontSize: '20px',
                padding: '6px',
                marginLeft: '8px',
              }}
            >
              <UndoIcon />
            </Button>
          </Tooltip>

          <Tooltip title="View logs">
            <Button
              type="submit"
              onClick={() =>
                navigate(`/dashboard/view-logs?type=user&id=${userId}`, {
                  state: {
                    type: 'name',
                    name: firstName + ' ' + lastName,
                  },
                })
              }
              style={{
                width: 50,
                minWidth: '50px',
                fontSize: '12px',
                padding: '6px',
                marginLeft: '8px',
              }}
            >
              <HistoryIcon />
            </Button>
          </Tooltip>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default PermanentDeactivatedUser;
