/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, Typography, Table, TableCell } from '@mui/material';
import { TableHead, TableRow, TableBody, TablePagination } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Col, InputGroup, Row, Spinner } from 'react-bootstrap';
import { RxCross2 } from 'react-icons/rx';
import { getTomorrowDate } from '../../../utils/commonFunc';
import { ListToolbar } from '../../../sections/@dashboard/table';
import { createModaloption, getModalBoxStyle } from '../../../utils/commonFunc';

const CreateSalary = ({ isAddCreateFile, setIsAddCreateFile, setLoading, loading, getSalariesData }) => {
  const [isLoading, setisLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [userCount, setUserCount] = useState([]);
  const [rowsPerPageUser, setRowsPerPageUser] = useState(10);
  const [pageUser, setPageUser] = useState(0);
  const [searchQuery, setsearchQuery] = useState('');
  const [searchDropValue, setSearchDropValue] = useState('');
  const [formData, setFormData] = useState({
    paidDate: '',
  });
  const [selectedUserModal, setselectedUserModal] = useState({
    userDetails: {},
    show: false,
  });

  const getAllUsers = async () => {
    setLoading(true);
    try {
      let URL;
      if (searchDropValue && searchQuery) {
        URL = `/admin/adminsalary/all/users?PageNumber?PageSize=${rowsPerPageUser}&PageNumber=${
          pageUser + 1
        }&searchQuery=${searchQuery}&SearchColumn=${searchDropValue}`;
      } else {
        URL = `/admin/adminsalary/all/users?PageNumber?PageSize=${rowsPerPageUser}&PageNumber=${
          pageUser + 1
        }&searchQuery=${searchQuery}`;
      }

      const result = await axios.get(URL);
      if (result?.status === 200) {
        setLoading(false);
        const { response, totalCount } = result?.data;
        setUserList(response);
        setUserCount(totalCount);
      }
    } catch (err) {
      if (err?.response?.data?.message === 'Something went wrong please try again after sometime.') {
        getAllUsers();
        setLoading(true);
      } else {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      }
    }
  };

  const handleChangePageUser = (event, newPage) => {
    setPageUser(newPage);
  };

  const handleChangeRowsPerPageUser = (event) => {
    setRowsPerPageUser(parseInt(event.target.value, 10));
    setPageUser(0);
  };

  const handleFilter = (event) => {
    setsearchQuery(event.target.value);
  };

  const handleSelectedUser = (isOpen, userData) => {
    setselectedUserModal({
      userDetails: userData,
      show: isOpen,
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePaidSalary = async () => {
    if (!formData?.paidDate) return toast.error('Please select date');
    setisLoading(true);
    try {
      const response = await axios.post('/admin/adminsalary/create-manualadminsalary-id', {
        userId: selectedUserModal?.userDetails?.userId,
        paidDate: formData?.paidDate,
      });
      if (response?.data?.message === 'Success') {
        getSalariesData();
        toast.success('Salary has been created successfully');
        setFormData({
          paidDate: '',
        });
        setselectedUserModal({
          userDetails: {},
          show: false,
        });
        setIsAddCreateFile(false);
        setsearchQuery('');
        setSearchDropValue('');
      }

      setisLoading(false);
    } catch (err) {
      setisLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (isAddCreateFile) {
      getAllUsers();
    }
  }, [pageUser, rowsPerPageUser, isAddCreateFile, searchQuery]);

  return (
    <>
      <Modal
        open={isAddCreateFile}
        onClose={() => setIsAddCreateFile(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="uniquemodalwidth"
        animation={false}
      >
        <Box sx={getModalBoxStyle()}>
          <Box sx={{ height: 'fit-content' }} id="usersList-modal">
            <Typography id="modal-modal-title" variant="h6" component="h2" className="flex items-center">
              Users List
              <RxCross2
                onClick={() => {
                  setIsAddCreateFile(false);
                  setsearchQuery('');
                  setSearchDropValue('');
                }}
                style={{ float: 'right', cursor: 'pointer' }}
              />
            </Typography>

            <ListToolbar
              hideEmailButton={'true'}
              onFilter={handleFilter}
              searchQuery={searchQuery}
              dropOptions={createModaloption}
              searchDropValue={searchDropValue}
              setSearchDropValue={setSearchDropValue}
              isAddCreateFile={isAddCreateFile}
            />

            <Table sx={{ position: 'relative' }}>
              <TableHead>
                <TableRow>
                  <TableCell>User Id</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>SSN No.</TableCell>
                  <TableCell>Create Salary</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5} sx={{ position: 'relative' }}>
                      <div
                        className="salary_spinner"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '250px',
                        }}
                      >
                        <Spinner variant="primary" />
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  userList?.map((item) => (
                    <TableRow key={item.userId}>
                      <TableCell>{item.userId}</TableCell>
                      <TableCell>{item.firstName}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>{item.ssn || item?.dateOfBirth}</TableCell>
                      <TableCell>
                        <Button onClick={() => handleSelectedUser(true, item)}>
                          <AddIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[100, 250, 500]}
              component="div"
              count={userCount}
              rowsPerPage={rowsPerPageUser}
              page={pageUser}
              onPageChange={handleChangePageUser}
              onRowsPerPageChange={handleChangeRowsPerPageUser}
            />
            <div style={{ marginTop: '25px', float: 'right' }}>
              <Button
                onClick={() => {
                  setIsAddCreateFile(false);
                  setFormData({
                    paidDate: '',
                  });
                  setSearchDropValue('');
                  setsearchQuery('');
                }}
                sx={{ marginRight: '10px' }}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={selectedUserModal?.show}
        onClose={() => {
          setselectedUserModal({
            userDetails: {},
            show: false,
          });
          setFormData({
            paidDate: '',
          });
          setSearchDropValue('');
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        animation={false}
      >
        <Box sx={getModalBoxStyle()}>
          <Box sx={{ height: 'fit-content' }} id="usersList-modal">
            <Typography id="modal-modal-title" variant="h6" component="h2" className="flex items-center">
              Create Salary
              <RxCross2
                onClick={() => {
                  setselectedUserModal({
                    userDetails: {},
                    show: false,
                  });
                  setFormData({
                    paidDate: '',
                  });
                  setSearchDropValue('');
                }}
                style={{ float: 'right', cursor: 'pointer' }}
              />
            </Typography>
          </Box>

          <div className="border border-dark mt-5 pl-3 pt-5 pb-5">
            <Row className="mb-2">
              <InputGroup>
                <Col lg={4} md={4} sm={12}>
                  <h6>{'User Name :'}</h6>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <h6>
                    {selectedUserModal?.userDetails?.firstName} {''} {selectedUserModal?.userDetails?.lastName}
                  </h6>
                </Col>
              </InputGroup>
            </Row>
            <Row className="mb-2">
              <InputGroup>
                <Col lg={4} md={4} sm={12}>
                  <h6>{'User Id :'}</h6>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <h6>{selectedUserModal?.userDetails?.userId}</h6>
                </Col>
              </InputGroup>
            </Row>
            <Row>
              <InputGroup>
                <Col lg={4} md={4} sm={12}>
                  <h6>{'Select salary date :'}</h6>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <input
                    type="date"
                    name="paidDate"
                    className="paidDateInput"
                    onChange={handleChange}
                    value={formData.paidDate}
                    min={getTomorrowDate()}
                  />
                </Col>
              </InputGroup>
            </Row>
          </div>

          <div style={{ marginTop: '25px', float: 'right' }}>
            <Button
              onClick={() => {
                setselectedUserModal({
                  userDetails: {},
                  show: false,
                });
                setFormData({
                  paidDate: '',
                });
                setSearchDropValue('');
              }}
              sx={{ marginRight: '10px' }}
            >
              Cancel
            </Button>
            <Button disabled={isLoading} onClick={handlePaidSalary} variant="contained" color="primary">
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default CreateSalary;
