/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Button, Modal, Typography, TextField, Alert } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { toast } from 'react-toastify';
import { downloadFile, getModalBoxStyle, monthsOfYear, salariesDropoptions, years } from '../../../utils/commonFunc';
import Loader from '../../../components/Loader';
import SalariesTable from './salary/SalaryTable';
import TaxFileTable from './tax-file/TaxFileTable';
import { ListToolbar } from '../../../sections/@dashboard/table';
import ViewSalaryTab from './salary/ViewSalaryTab';
import SalaryDownloadPdfModal from './SalaryDownloadPdf';
import CreateSalary from './CreateSalary';
import SalaryFortnoxAsync from './fortnox-async/SalaryFortnoxAsync';
import useGetYears from '../../../hooks/useGetYears';

export const TabsUI = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { holidayPayId } = useParams();
  const { salaryId } = useParams();

  const [selectedTabValue, setSelectedTabValue] = useState('2');
  const [page, setPage] = useState(0);
  const [salarySelected, setSalarySelected] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCalendarNestedTabValue, setSelectedCalendarNestedTabValue] = useState('1');
  const [selectedSalaryNestedTabValue, setSelectedSalaryNestedTabValue] = useState('1');
  const [selectedHPNestedTabValue, setSelectedHPNestedTabValue] = useState('1');
  const [selectedTFNestedTabValue, setSelectedTFNestedTabValue] = useState('1');
  const [loading, setLoading] = useState(false);
  const [userClicked, setUserClicked] = useState(false);
  const [holidayPayClicked, setHolidayPayClicked] = useState(false);
  const [salaryClicked, setSalaryClicked] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [salariesData, setSalariesData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [undoSalaryModal, setUndoSalaryModal] = useState(false);
  const [deleteSalaryModal, setDeleteSalaryModal] = useState(false);
  const [isAddTaxFile, setIsAddTaxFile] = useState(false);
  const [IsToggleXml, setIsToggleXml] = useState(false);
  const [taxFileData, setTaxFileData] = useState([]);

  const [monthValue, setMonthValue] = useState('');
  const [yearValue, setYearsValue] = useState('');
  const [toggleModal, settoggleModal] = useState(false);
  const [isError, setisError] = useState(null);

  const [isAddCreateFile, setIsAddCreateFile] = useState(false);

  const [selectedFortnoxNestedTabValue, setSelectedFortnoxNestedTabValue] = useState('1');
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [searchDropValue, setSearchDropValue] = useState();

  const salariesTab = [
    {
      value: '1',
      tab: 2,
    },
    {
      value: '2',
      tab: 3,
    },
    {
      value: '3',
      tab: 1,
    },

    {
      value: '4',
      tab: false,
    },
    {
      value: '5',
      tab: 5,
    },
  ];

  const taxFileTab = [
    {
      value: '1',
      tab: 1,
    },
    {
      value: '2',
      tab: 2,
    },
    {
      value: '3',
      tab: false,
    },
  ];

  const handleTabChange = (e, newValue) => {
    setSearchQuery('');
    setPage(0);
    setSalarySelected([]);
    setSelectedTabValue(newValue);
  };

  const handleNestedTabChange = (e, newValue, tab) => {
    setSalarySelected([]);
    if (tab === 1) {
      setSelectedCalendarNestedTabValue(newValue);
    }
    if (tab === 2) {
      setSelectedSalaryNestedTabValue(newValue);
    }
    if (tab === 3) {
      setSelectedHPNestedTabValue(newValue);
    }
    if (tab === 4) {
      setSelectedTFNestedTabValue(newValue);
    }
  };

  const handleSalariesClick = (id) => {
    if (id) {
      navigate(`/dashboard/salaries/salary/${id}`);
    }
  };

  useEffect(() => {
    if (userId) {
      setUserClicked(true);
    } else {
      setUserClicked(false);
    }
  }, [userId]);

  useEffect(() => {
    if (holidayPayId) {
      setHolidayPayClicked(true);
    } else {
      setHolidayPayClicked(false);
    }
  }, [holidayPayId]);

  useEffect(() => {
    if (salaryId) {
      setSalaryClicked(true);
    } else {
      setSalaryClicked(false);
    }
  }, [salaryId]);

  const getSalariesData = async () => {
    setLoading(true);
    try {
      let URL;
      let tab;
      if (selectedSalaryNestedTabValue === '1') {
        tab = 2;
      } else if (selectedSalaryNestedTabValue === '2') {
        tab = 3;
      } else if (selectedSalaryNestedTabValue === '3') {
        tab = 1;
      } else {
        tab = null;
      }
      if (tab) {
        URL = `/admin/adminsalary/get-all-salary?PageNumber=${
          page + 1
        }&PageSize=${rowsPerPage}&active=${tab}&searchQuery=${searchQuery}`;
      } else {
        URL = `/admin/adminsalary/get-all-salary?PageNumber=${
          page + 1
        }&PageSize=${rowsPerPage}&searchQuery=${searchQuery}`;
      }
      if (searchDropValue) {
        URL += `&searchColumn=${searchDropValue}`;
      }
      const result = await axios.get(URL);
      if (result?.status === 200) {
        setLoading(false);
        const { response, totalCount } = result?.data;
        const updatedResponse = response.map((item) => ({ ...item, isSendEmail: true }));
        setSalariesData(updatedResponse);
        setTotalCount(totalCount);
      }
    } catch (err) {
      if (err?.response?.data?.message === 'Something went wrong please try again after sometime.') {
        getSalariesData();
        setLoading(true);
      } else {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      }
    }
  };
  const location = useLocation();
  useEffect(() => {
    if (selectedTabValue === '2' && location.pathname === '/dashboard/salaries') {
      const timeoutId = setTimeout(() => {
        getSalariesData();
      }, 600);
      return () => clearTimeout(timeoutId);
    }
  }, [selectedTabValue, selectedSalaryNestedTabValue, page, rowsPerPage, searchQuery, location]);

  const handleFilter = (event) => {
    setSearchQuery(event.target.value);
  };

  const onMoveSalary = () => {
    setConfirmationModal(true);
  };
  const onUndoSalary = () => {
    setUndoSalaryModal(true);
  };

  const onDeleteSalary = () => {
    setDeleteSalaryModal(true);
  };

  const handleSalaryChecked = async (tabValue) => {
    setLoading(true);
    try {
      let URL;
      if (tabValue === 1) {
        URL = '/admin/adminsalary';
      } else if (tabValue === 2) {
        URL = '/admin/adminsalary/bankFile';
      } else if (tabValue === 3) {
        URL = '/admin/adminsalary/regenerate-bank-file';
      }
      const filteredSalaries = salariesData
        ?.filter((item) => salarySelected?.includes(item.salaryId))
        ?.map((item) => {
          return {
            salaryId: item?.salaryId,
            isSend: item?.isSendEmail,
            amount: item?.amount,
          };
        });

      const hasError = filteredSalaries.some((salary) => {
        if (salary.amount === 0) {
          toast.error('To generate bank file, the salary amount must be greater than 0');
          return true;
        }
        return false;
      });
      if (hasError) {
        setLoading(false);
        return;
      }

      let result;
      if (selectedSalaryNestedTabValue !== '1') {
        result = await axios.post(URL, salarySelected);
      } else {
        result = await axios.post(URL, filteredSalaries);
      }
      if (result?.status === 200) {
        setLoading(false);

        if (tabValue === 2 || tabValue === 3) {
          downloadFile(result?.data?.response);
        }
        toast.success(
          tabValue === 1
            ? 'Checked successfully'
            : tabValue === 2 || tabValue === 3
            ? 'Bank file generated successfully'
            : ''
        );
        getSalariesData();
        setConfirmationModal(false);
      }
    } catch (err) {
      setLoading(false);
      setisError(err?.response?.data?.message);
    }
  };

  const handleUndoSalary = async () => {
    setLoading(true);
    setUndoSalaryModal(false);
    try {
      const URL = '/admin/adminsalary/return-salary-ids';

      const result = await axios.post(URL, salarySelected);

      if (result?.status === 200) {
        setLoading(false);

        toast.success('Moved successfully');
        getSalariesData();
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  const handleDeleteSalary = async () => {
    setLoading(true);
    try {
      const URL = '/admin/adminsalary/salaryids';
      const result = await axios.delete(URL, { data: salarySelected });
      if (result?.status === 200) {
        setLoading(false);
        setDeleteSalaryModal(false);
        toast.success('Bank file deleted successfully');
        getSalariesData();
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  const onAddTaxFile = () => {
    setIsAddTaxFile(true);
  };

  const yearFunc = years();
  const yearOptions = yearFunc.map((year) => ({ label: year }));

  const getTaxFileData = async () => {
    setLoading(true);
    try {
      let URL;
      let tab;
      if (selectedTFNestedTabValue === '1') {
        tab = 1;
      } else if (selectedTFNestedTabValue === '2') {
        tab = 2;
      } else if (selectedTFNestedTabValue === '3') {
        tab = 3;
      } else {
        tab = null;
      }
      if (tab) {
        URL = `/admin/adminsalary/get-all-tax-file?PageNumber=${
          page + 1
        }&PageSize=${rowsPerPage}&active=${tab}&searchQuery=${searchQuery}`;
      } else {
        URL = `/admin/adminsalary/get-all-tax-file?PageNumber=${
          page + 1
        }&PageSize=${rowsPerPage}&searchQuery=${searchQuery}`;
      }
      const result = await axios.get(URL);
      if (result?.status === 200) {
        setLoading(false);
        const { response, totalCount } = result?.data;
        setTaxFileData(response);
        setTotalCount(totalCount);
      }
    } catch (err) {
      if (err?.response?.data?.message === 'Something went wrong please try again after sometime.') {
        getTaxFileData();
        setLoading(true);
      } else {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (selectedTabValue === '4') {
      const timeoutId = setTimeout(() => {
        getTaxFileData();
      }, 600);
      return () => clearTimeout(timeoutId);
    }
  }, [selectedTabValue, selectedTFNestedTabValue, page, rowsPerPage, searchQuery]);

  const handleCreateTaxFile = async () => {
    if (!yearValue || !monthValue) {
      toast.error('Please select month and year');
    } else {
      setLoading(true);
      try {
        const URL = `/admin/adminsalary/create-tax-file-by-year&month?year=${yearValue}&month=${monthValue}`;
        const result = await axios.post(URL);

        if (result?.status === 200) {
          setLoading(false);
          toast.success('Tax file has been created successfully');
          setIsAddTaxFile(false);
          getTaxFileData();
        }
      } catch (err) {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      }
    }
  };

  const handlegenerateXmlForCheked = async () => {
    setLoading(true);
    try {
      let URL;
      if (selectedSalaryNestedTabValue === '2') {
        URL = '/admin/adminsalary/salary-xml-file';
      } else if (selectedSalaryNestedTabValue === '3') {
        URL = '/admin/adminsalary/regenerate-salaryxml-file';
      }
      const result = await axios.post(URL, salarySelected);
      if (result?.status === 200) {
        const { nationalXmlDoc, interNationalXmlDoc } = result?.data?.response;
        if (nationalXmlDoc) {
          downloadBase64File(nationalXmlDoc, 'national_salary.xml', 'application/xml');
        }
        if (interNationalXmlDoc) {
          downloadBase64File(interNationalXmlDoc, 'international_salary.xml', 'application/xml');
        }
        setLoading(false);
        toast.success('XML files generated successfully');
        setIsToggleXml(false);
        getSalariesData();
      }
    } catch (err) {
      setLoading(false);
      setisError(err?.response?.data?.message);
    }
  };

  const downloadBase64File = (base64Data, fileName, mimeType) => {
    const binaryString = window.atob(base64Data);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: mimeType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const onAddCreateBtn = () => {
    setIsAddCreateFile(true);
  };

  const getYears = useGetYears();

  const handleChangeDate = async (e, type) => {
    setLoading(true);
    try {
      let newDate;
      let formattedDate;
      let url;

      if (type === 'month') {
        if (!year) {
          setYear(new Date().getFullYear());
        }
        const newMonth = e.target.value;
        newDate = new Date(year, newMonth);
        formattedDate = newDate.toISOString().split('T')[0];
        setMonth(newMonth);
        url = `/admin/adminsalary/get-all-salary?month=${formattedDate}`;
      } else {
        const newYear = e.target.value;
        newDate = new Date(`${newYear}-01-01`);
        formattedDate = newDate.toISOString().split('T')[0];
        setYear(newYear);
        url = `/admin/adminsalary/get-all-salary?year=${formattedDate}`;
      }

      const result = await axios.get(url);

      if (result?.status === 200) {
        setLoading(false);
        const { response, totalCount } = result?.data;
        setSalariesData(response);
        setTotalCount(totalCount);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  const handleClearFilter = () => {
    setMonth(null);
    setYear(null);
    getSalariesData();
  };

  return (
    <>
      {loading && <Loader />}

      <TabContext value={selectedTabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(e, newValue) => handleTabChange(e, newValue)} aria-label="lab API tabs example">
            <Tab label="Salary" value="2" />
            <Tab label="Tax file" value="4" />
          </TabList>
        </Box>

        <TabPanel value="2" sx={{ padding: 0, margin: '10px 0 0 20px' }}>
          <TabContext value={selectedSalaryNestedTabValue}>
            {salaryClicked ? (
              <ViewSalaryTab
                loading={loading}
                selectedSalaryNestedTabValue={selectedSalaryNestedTabValue}
                setLoading={setLoading}
              />
            ) : (
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                {selectedSalaryNestedTabValue !== '5' && (
                  <ListToolbar
                    salarySelected={salarySelected.length}
                    searchQuery={searchQuery}
                    onFilter={handleFilter}
                    onMoveSalary={onMoveSalary}
                    onUndoSalary={onUndoSalary}
                    onDeleteSalary={onDeleteSalary}
                    selectedSalaryNestedTabValue={selectedSalaryNestedTabValue}
                    settoggleModal={settoggleModal}
                    setIsToggleXml={setIsToggleXml}
                    isAddCreateBtn={'true'}
                    onAddCreateBtn={onAddCreateBtn}
                    getYears={getYears}
                    handleChangeDate={handleChangeDate}
                    year={year}
                    month={month}
                    handleClearFilter={handleClearFilter}
                    dropOptions={salariesDropoptions}
                    searchDropValue={searchDropValue}
                    setSearchDropValue={setSearchDropValue}
                  />
                )}

                <TabList
                  onChange={(e, newValue) => handleNestedTabChange(e, newValue, 2)}
                  aria-label="nested tabs example"
                >
                  <Tab label="Unpaid" value="1" />
                  <Tab label="Checked" value="2" />
                  <Tab label="Paid" value="3" />
                  <Tab label="All" value="4" />
                  {/* <Tab label="Fortnox Async" value="5" /> */}

                  <SalaryDownloadPdfModal
                    salarySelected={salarySelected}
                    toggleModal={toggleModal}
                    settoggleModal={settoggleModal}
                    getSalariesData={getSalariesData}
                    selectedSalaryNestedTabValue={selectedSalaryNestedTabValue}
                  />
                </TabList>

                {salariesTab.map((item) => (
                  <TabPanel value={item?.value} sx={{ padding: 0 }} key={item?.value}>
                    {item?.value === '5' ? (
                      <SalaryFortnoxAsync
                        selectedFortnoxNestedTabValue={selectedFortnoxNestedTabValue}
                        setSelectedFortnoxNestedTabValue={setSelectedFortnoxNestedTabValue}
                        setLoading={setLoading}
                      />
                    ) : (
                      <SalariesTable
                        tab={item?.tab}
                        handleSalariesClick={handleSalariesClick}
                        setSalarySelected={setSalarySelected}
                        salarySelected={salarySelected}
                        setRowsPerPage={setRowsPerPage}
                        salariesData={salariesData}
                        setSalariesData={setSalariesData}
                        totalCount={totalCount}
                        rowsPerPage={rowsPerPage}
                        selectedSalaryNestedTabValue={selectedSalaryNestedTabValue}
                        setLoading={setLoading}
                      />
                    )}
                  </TabPanel>
                ))}
              </Box>
            )}
          </TabContext>
        </TabPanel>

        <TabPanel value="4" sx={{ padding: 0, margin: '10px 0 0 20px' }}>
          <TabContext value={selectedTFNestedTabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <ListToolbar
                isAddFileBtn={'true'}
                onAddTaxFile={onAddTaxFile}
                searchQuery={searchQuery}
                onFilter={handleFilter}
              />
              <TabList
                onChange={(e, newValue) => handleNestedTabChange(e, newValue, 4)}
                aria-label="nested tabs example"
              >
                <Tab label="Created" value="1" />
                <Tab label="Paid" value="2" />
                <Tab label="Sent Tax Files" value="3" />
              </TabList>
            </Box>
            {taxFileTab.map((item) => (
              <TabPanel value={item?.value} sx={{ padding: 0 }} key={item?.value}>
                <TaxFileTable
                  setLoading={setLoading}
                  selectedTFNestedTabValue={selectedTFNestedTabValue}
                  taxFileData={taxFileData}
                  setTaxFileData={setTaxFileData}
                  getTaxFileData={getTaxFileData}
                  totalCount={totalCount}
                />
              </TabPanel>
            ))}
          </TabContext>
        </TabPanel>
      </TabContext>

      <Modal
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={getModalBoxStyle()}>
          {isError !== null && (
            <Alert className="mb-2" severity="error">
              {isError}
            </Alert>
          )}
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {selectedSalaryNestedTabValue === '1'
              ? 'Are you sure you want to move to checked?'
              : selectedSalaryNestedTabValue === '2'
              ? 'Are you sure you want to generate bank file?'
              : selectedSalaryNestedTabValue === '3'
              ? 'Are you sure you want to generate bank file?'
              : ''}
          </Typography>
          <div
            style={{
              margin: '25px 0 0',
              float: 'right',
              gap: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => {
                setisError(null);
                setConfirmationModal(false);
              }}
              sx={{ float: 'right', backgroundColor: '#62b5e514' }}
              autoFocus
            >
              No
            </Button>
            <Button
              onClick={() =>
                handleSalaryChecked(
                  selectedSalaryNestedTabValue === '1'
                    ? 1
                    : selectedSalaryNestedTabValue === '2'
                    ? 2
                    : selectedSalaryNestedTabValue === '3'
                    ? 3
                    : null
                )
              }
              sx={{ float: 'right', backgroundColor: '#62b5e514' }}
              autoFocus
            >
              Yes
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={undoSalaryModal}
        onClose={() => setUndoSalaryModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={getModalBoxStyle()}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {selectedSalaryNestedTabValue === '3'
              ? 'Are you sure you want to move to checked?'
              : selectedSalaryNestedTabValue === '2'
              ? 'Are you sure you want to move to unpaid?'
              : ''}
          </Typography>
          <div
            style={{
              margin: '25px 0 0',
              float: 'right',
              gap: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => setUndoSalaryModal(false)}
              sx={{ float: 'right', backgroundColor: '#62b5e514' }}
              autoFocus
            >
              No
            </Button>
            <Button onClick={() => handleUndoSalary()} sx={{ float: 'right', backgroundColor: '#62b5e514' }} autoFocus>
              Yes
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={deleteSalaryModal}
        onClose={() => setDeleteSalaryModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={getModalBoxStyle()}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete bank file?
          </Typography>
          <div
            style={{
              margin: '25px 0 0',
              float: 'right',
              gap: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => setDeleteSalaryModal(false)}
              sx={{ float: 'right', backgroundColor: '#62b5e514' }}
              autoFocus
            >
              No
            </Button>
            <Button onClick={handleDeleteSalary} sx={{ float: 'right', backgroundColor: '#62b5e514' }} autoFocus>
              Yes
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={isAddTaxFile}
        onClose={() => setIsAddTaxFile(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={getModalBoxStyle()}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className="container">
              <Box
                sx={{
                  display: 'flex',
                  marginTop: '9px',
                  flexDirection: 'column',
                  '& .MuiTextField-root': { width: '25ch' },
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <Autocomplete
                    freeSolo
                    // value={watch('userAccountType')}
                    id="combo-box-demo"
                    options={monthsOfYear}
                    sx={{ width: 300, marginTop: '12px' }}
                    onChange={(e, selectedOption) => {
                      if (selectedOption) {
                        setMonthValue(selectedOption?.value);
                      } else {
                        setMonthValue('');
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} style={{ marginBottom: '10px' }} label={'Month'} id="margin-dense" />
                    )}
                  />
                  <Autocomplete
                    freeSolo
                    // value={watch('userAccountType')}
                    id="combo-box-demo"
                    options={yearOptions}
                    sx={{ width: 300, marginTop: '12px' }}
                    onChange={(e, selectedOption) => {
                      if (selectedOption) {
                        setYearsValue(selectedOption?.label);
                      } else {
                        setYearsValue('');
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} style={{ marginBottom: '10px' }} label={'Year'} id="margin-dense" />
                    )}
                  />
                </div>
              </Box>
            </div>
          </Typography>
          <div
            style={{
              margin: '25px 0 0',
              float: 'right',
              gap: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => setIsAddTaxFile(false)}
              sx={{ float: 'right', backgroundColor: '#62b5e514', marginRight: '5px' }}
              autoFocus
            >
              Cancel
            </Button>
            <Button sx={{ float: 'right', backgroundColor: '#62b5e514' }} autoFocus onClick={handleCreateTaxFile}>
              Create
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={IsToggleXml}
        onClose={() => {
          setisError(null);
          setIsToggleXml(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={getModalBoxStyle()}>
          {isError !== null && (
            <Alert className="mb-2" severity="error">
              {isError}
            </Alert>
          )}
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to generate xml file?
          </Typography>
          <div
            style={{
              margin: '25px 0 0',
              float: 'right',
              gap: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => {
                setIsToggleXml(false);
                setisError(null);
              }}
              sx={{ float: 'right', backgroundColor: '#62b5e514' }}
              autoFocus
            >
              No
            </Button>
            <Button
              onClick={() => handlegenerateXmlForCheked()}
              sx={{ float: 'right', backgroundColor: '#62b5e514' }}
              autoFocus
            >
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
      <CreateSalary
        getSalariesData={getSalariesData}
        isAddCreateFile={isAddCreateFile}
        setIsAddCreateFile={setIsAddCreateFile}
        setLoading={setLoading}
        loading={loading}
      />
    </>
  );
};
