/* eslint-disable */
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import {
  costAgreementArticle,
  costPerDayArticle,
  costPerMonthArticle,
  costPerWeekArticle,
  HourlyWages,
} from '../pages/dashboard/Article';
import { InvoiveAsDescribedBellow, otherCost } from './stringUtils';

export default function downloadSalaryDetailsAsPdf() {
  const element = document.getElementById('salary-modal');
  if (element) {
    const { maxHeight } = document.getElementsByClassName('table-scroll')[0].style;
    document.getElementsByClassName('table-scroll')[0].style.overflow = 'none';
    document.getElementsByClassName('table-scroll')[0].style.maxHeight = '';
    html2canvas(element).then((canvas) => {
      const imgWidth = 210;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      document.getElementsByClassName('table-scroll')[0].style.overflow = 'none';
      document.getElementsByClassName('table-scroll')[0].style.maxHeight = maxHeight;
      const doc = new jsPDF('p', 'mm'); // eslint-disable-line new-cap
      let position = 0;
      const pageData = canvas.toDataURL('image/jpeg', 1.0);
      const imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      doc.save('salary-details.pdf');
    });
  }
}

export const getModalBoxStyle = () => {
  let style = {};

  style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    height: 400,
    p: 4,
  };

  return style;
};

export const formatDate = (dateString) => {
  const formattedDate = moment(dateString).format('Do MMM YYYY');
  return formattedDate;
};

export const formatDate2 = (dateString) => {
  let formattedDate = moment(dateString).format('YYYY-MM-DD');
  return formattedDate;
};

export const convertHouseworkType = (houseworkType) => {
  switch (houseworkType) {
    case 'CONSTRUCTION':
      return 'Build';
    case 'ELECTRICITY':
      return 'Electricity';
    case 'GLASSMETALWORK':
      return 'Glass/sheet metal work';
    case 'GROUNDDRAINAGEWORK':
      return 'Soil/drainage work';
    case 'MASONRY':
      return 'Masonry';
    case 'PAINTINGWALLPAPERING':
      return 'Painting/wallpapering';
    case 'HVAC':
      return 'Plumbing';
    case 'OTHERCOSTS':
      return 'Other costs';
    case 'CLEANING':
      return 'Cleaning';
    case 'TEXTILECLOTHING':
      return 'Clothing and textile care';
    case 'SNOWPLOWING':
      return 'Snow shoveling';
    case 'GARDENING':
      return 'Gardening';
    case 'BABYSITTING':
      return 'Child care';
    case 'OTHERCARE':
      return 'Personal care';
    case 'MOVINGSERVICES':
      return 'Removal services';
    case 'ITSERVICES':
      return 'IT services';
    case 'MAJORAPPLIANCEREPAIR':
      return 'Repair of white goods';
    case 'FURNISHING':
      return 'Furnishings';
    case 'HOMEMAINTENANCE':
      return 'Supervision of housing';
    case 'TRANSPORTATIONSERVICES':
      return 'Transport services';
    case 'WASHINGANDCAREOFCLOTHING':
      return 'Washing and care of clothes';
    default:
      return '';
  }
};

export const otherArticleOptions = [
  {
    value: otherCost,
    label: 'Övriga kostnader (resa och traktamente)',
    key: 'oth',
  },
  {
    value: InvoiveAsDescribedBellow,
    label: 'Utlägg enligt bifogad pdf',
    key: 'exp',
  },
];

export const otherArticlesTotalAmount = (keyVal, otherArticlesData, self) => {
  let totalSum = 0;

  if (self) {
    const amount = Number(otherArticlesData?.find((obj) => obj.key === keyVal)?.value);

    if (amount !== 0) {
      return isNaN(amount) ? null : amount;
    } else {
      return null;
    }
  }

  const processArticle = (article, index) => {
    if (article && Array.isArray(article)) {
      article.forEach((item) => {
        if (item.key === keyVal) {
          totalSum += parseFloat(item.value, 10) || 0;
        }
      });
    }
  };

  for (const index in otherArticlesData) {
    const article = otherArticlesData[index];
    processArticle(article);
  }

  return totalSum;
};

export const otherArticlesArray = (item, isLabel) => {
  switch (isLabel) {
    case 'desc':
      return item?.key === 'oth' ? otherArticleOptions[0].label : otherArticleOptions[1].label;

    case 'articleNo':
      return item?.key === 'oth' ? otherCost : InvoiveAsDescribedBellow;

    case 'unit':
      return item?.key === 'oth' ? 'km' : 'förp';

    default:
      return '';
  }
};

export const formatInvoiceDate = (prefillDate, paymentTerm) => {
  var currentDate = prefillDate ? new Date(prefillDate) : new Date();
  if (paymentTerm) currentDate.setDate(currentDate.getDate() + Number(paymentTerm));
  var options = { day: 'numeric', month: 'long', year: 'numeric' };
  var formattedDate = currentDate.toLocaleString('en-US', options);

  return formattedDate;
};

export const getMonthDates = (currentDate) => {
  const firstDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  firstDateOfMonth.setDate(firstDateOfMonth.getDate() + 1);

  // Format the dates
  const formattedFirstDate = firstDateOfMonth.toISOString().split('T')[0];
  return {
    formattedFirstDate,
  };
};

export const getEndOfThirdMonth = (inputDate) => {
  const date = new Date(inputDate);

  // last date third month
  let endOfThirdMonth = new Date(date.getFullYear(), date.getMonth() + 3, 1);

  endOfThirdMonth.setDate(endOfThirdMonth.getDate());

  const formattedEndDate = endOfThirdMonth.toISOString().split('T')[0];

  return formattedEndDate;
};

export const downloadFile = (res) => {
  const byteCharacters = atob(res);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'text/plain' }); // Change MIME type here
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'BGCDATA.IN'; // Change the filename accordingly
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export const downloadXMLFile = (res) => {
  const xmlText = atob(res);

  const blob = new Blob([xmlText], { type: 'application/xml' });

  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;

  // Get current time in Sweden timezone
  const options = { timeZone: 'Europe/Stockholm', year: 'numeric', month: '2-digit' };
  const formatter = new Intl.DateTimeFormat('sv-SE', options);
  const parts = formatter.formatToParts(new Date());
  const year = parts.find((part) => part.type === 'year').value;
  const month = parts.find((part) => part.type === 'month').value;

  // Format file name
  const fileName = `AGI_${year}_${month}_502085-4062.xml`;

  a.download = fileName;
  document.body.appendChild(a);

  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export const monthsOfYear = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
];

export const years = () => {
  const startYear = 2024;
  const endYear = 2050;

  const optionsYears = [];
  for (let year = startYear; year <= endYear; year++) {
    optionsYears.push(year.toString());
  }
  return optionsYears;
};

// Employee certificate
export const validate2 = (name) => {
  let data = { ...name };
  let isNotValid = false;

  for (let key of Object.keys(name).filter((key) => !key.includes('Error'))) {
    if (key !== 'otherReason') {
      if (name[key] === null || (typeof name[key] === 'string' && name[key].trim() === '')) {
        data[key + 'Error'] = true;
        isNotValid = true;
      } else {
        data[key + 'Error'] = false;
      }
    }
  }
  data['notValid'] = isNotValid;
  return data;
};

// otherReason
export const validate = (name) => {
  let data = { ...name };
  for (let key of Object.keys(name).filter(
    (key) =>
      !key.includes('Error') &&
      // key !== 'employmentPeriodEnd' &&
      key !== 'continueWorkEndDate' &&
      key !== 'continueWorkFromDate' &&
      key !== 'isContinueEmployeeAcceptedDate' &&
      key !== 'otherInfomationEmployment' &&
      key !== 'extendsIn' &&
      key !== 'leaveAbsenceFrom' &&
      key !== 'leaveAbsenceEnd' &&
      key !== 'otherInformation' &&
      key !== 'amountForOverTime'
  )) {
    if (typeof name[key] === 'string' && name[key].trim() === '') {
      data[key + 'Error'] = true;
      data['notValid'] = true;
    } else {
      data[key + 'Error'] = false;
      data['notValid'] = false;
    }
  }
  return data;
};

export const validateSpecificDates = (data) => {
  let validationData = { ...data };
  let notValid = false;
  const specificKeys = ['continueWorkFromDate', 'continueWorkEndDate', 'isContinueEmployeeAcceptedDate'];

  for (let key of specificKeys) {
    if (
      validationData[key] === null ||
      typeof validationData[key] === 'undefined' ||
      (typeof validationData[key] === 'string' && validationData[key].trim() === '')
    ) {
      validationData[key + 'Error'] = true;
      notValid = true;
    } else {
      validationData[key + 'Error'] = false;
    }
  }
  validationData['notValid'] = notValid;
  return validationData;
};

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const dataTable = (initialData) => {
  const prefillData = [];
  months.forEach((month) => {
    const monthData = initialData?.find((item) => item.month === month);
    if (monthData) {
      prefillData.push(monthData);
    } else {
      prefillData.push({
        month,
        hoursworked: '',
        absence: '',
        overtime: '',
        mertidFillingTime: '',
      });
    }
  });
  return prefillData;
};

export const empCertificateLeft = [
  {
    title: '1 Personal data',
    description: "Enter the employee's surname, first name and social security number.",
  },
  {
    title: '2 Details of the employment',
    description:
      'Duration of employment: Enter the dates between which the employment lasted. Write the first and last day of employment. If this employment was preceded by a previous employment, the employments must not be written on the same form.',
    subtitle:
      "If the employee had a so-called need-based employment, that is, over a longer period of time, several short-term employments (for example, so-called 'temporary temporary work'), these can be filled in on the same certificate.",
    sub_description1: 'Position (employed as): Enter title and/or duties during the last period',
    sub_description2:
      "If the employment has not ended, tick 'Still employed'. Then leave the 'to m box' empty. You should not fill in a date that is in the future, even if it is a fixed-term employment and thus has a future end date.",
    other_des: 'If leave of absence occurs/occurred, the time period and scope are indicated.',
  },
  {
    title: '3 Form of employment',
    description:
      'State whether the employment was permanent employment, temporary employment or probationary employment. In the case of trial employment and limited-term employment, the period of employment according to the original agreement must be specified.',
    subtitle:
      "If the employment period was dependent on a certain assignment or a certain work task, enter temporary employment. If the work has ended because the task, or task, has been completed, enter the date on which it was completed as 'Agreed end date'.",
  },
  {
    title: '4 Working',
    description:
      'Enter one of the options full-time, part-time or variable working hours. For fixed working hours, you must both tick the box and enter the working hours per week. In the case of part-time employment with fixed working hours, both the working hours per week and the extent of the position in percentage must be stated. You always fill in this way if the employee has a fixed length of service, even if the working time can actually vary between different weeks, for example because it is scheduled.',
    subtitle: 'Another reason',
    sub_description1: 'If the work has ceased for any reason other than those stated above, state the reason here.',
  },
  {
    title: '7 Compensation due to termination of employment',
    description:
      'Mark here if an agreement on severance pay or other compensation has been entered into due to termination of employment. This does not apply to already earned benefits, such as vacation pay or unpaid wages, which were paid out because the employment ended.',
    subtitle: 'Another reason',
    sub_description1: 'If the work has ceased for any reason other than those stated above, state the reason here.',
  },
  {
    title: '8 Offer of continued work',
    description:
      'If the employee has been offered work, state the type of employment and the scope. Do not forget to indicate whether the employee has accepted or not. If the employee has not accepted an offer, enter the date the employee declined the offer.',
    subtitle: 'Minutes are given in hundredths of an hour, eg 37 hours and 45 minutes are written as 37.75.',
  },
  {
    title: '9 Signature',
    description: "The certificate must be signed by the employer (or the employer's representative) on both sides.",
  },
  {
    title: '10 Social security number',
    description: 'Enter your social security number on page 2 as well.',
  },
  {
    title: '11 Time worked',
    description:
      'Enter the time period to which the reported time refers. Enter how many hours the employee worked during the last twelve months of employment. If the employee has had a break in work, for example due to illness, education, parental leave, full military service or other reasons, the task must be extended in time so that at least twelve months of work is reported (if the employment lasted that long).',
    subtitle: 'Always specify the work month by month.',
  },
  {
    title: 'Hours worked',
    description:
      'Overtime, additional time and filling time must not be included in the column for hours worked Mar. Leave with retained pay counts as work.',
    subtitle:
      "For those who have fixed working hours and receive a monthly salary, work-free 'red days' and 'squeeze days' are counted as work, if salary deductions are not made for the leave.",
    sub_description1:
      "Time when the employee received notice pay, holiday pay, weekend pay, waiting time compensation, layoff pay or compensation for seafarers must be counted as time worked. Holiday pay in connection with termination of employment shall not be included in time worked. Absence due to illness, the birth of a child or compulsory military service must not be entered  as time worked, even if the employee is entitled to sick pay or 'fill-in' during this time.",
    sub_description2:
      "Time on call, standby time, waiting time, travel time and waste time must not be entered on the form 'Employer's certificate'. Such time must be specified on the 'Supplementing to Employer Certificate'. The number of hours and how much compensation the employee received per month for such time is stated on the 'Complementary to Employer's Certificate'.",
  },
  {
    title: 'Absence',
    description:
      "The absence is given in hours. Absence must only be entered for those who have agreed working hours. The number of hours in the column for 'worked time' and in the column for 'absence' per month must correspond to the agreed working hours per month.",
    subtitle:
      'Enter the number of hours the employee was absent with salary deductions. The reason for the absence does not matter.',
    sub_description1:
      "Absence due to illness, the birth of a child or compulsory military service must be entered as absence, even if the employee is entitled to sick pay or 'fill-in' during this time.",
    sub_description2: "Time with sick pay is reported on 'Supplementing to Employer's Certificate'.",
    other_des:
      "If the employee is on leave with retained salary, for example paid holiday (during the period of employment), 'red days' or squeeze days, they should not be counted as absence - this leave is counted as worked time.",
  },
];

export const empCertificateRight = [
  {
    description:
      'If the working hours have not been determined, enter variable working hours. For example, when the employee has had several short-term employments over a longer period of time, for example in intermittent employments, temporary employments or the like.',
  },
  {
    title: 'Fixed working hours with hourly pay',
    description:
      'Please note that it is the agreed working hours that must be entered in this field. The salary is stated later under point 12.',
  },
  {
    title: 'Several different weekly working hours',
    description:
      "During employment, has the employee had periods of different weekly working hours, e.g. periodically worked 30 hours/week and periodically 20 hours/week, an employer's certificate must be issued per employment level. This also applies if the employee sometimes had variable and sometimes fixed working hours. Then write a certificate for each period.",
    subtitle: 'Minutes are given in hundredths of an hour, eg 37 hours and 45 minutes are written as 37.75.',
  },
  {
    title: '5 Special information about the employment',
    description:
      'Companies with personnel leasing operations and so-called staffing companies must provide information here if the employee has been employed to be hired out.',
  },
  {
    title: '6 Reason why the employment has ended in whole or in part',
    description:
      'Termination by the employer: State the reason why the employee was terminated. Also state when the employee was notified of the dismissal.',
    subtitle:
      'Terminated fixed-term employment: Enter this option if the employment was limited-term and ended at the time agreed upon.',
    sub_description1:
      'Also state when the employee received notice that the work would not continue after the agreed end date.',
    sub_description2:
      "If the employment was limited in time but ended prematurely, one of the other options 'Termination by the employer', 'The employee's own request' or 'other reason' is indicated.",
    other_des:
      "The employee's own request: Enter here if the employee resigned himself/herself. Here you also mark whether the employment has been terminated by an agreement between the employer and the employee.",
  },
  {
    title: 'Time with sick pay',
    description:
      "Sick leave that is compensated with sick pay according to the Act (1991:1047) on sick pay (the first 14 days of sick pay) must be reported partly as absence on the main form 'Employer's certificate' and partly as Other compensation on the 'Supplement to Employer's certificate'.",
    subtitle:
      "Leave of absence (before 1 January 2019) has not been replaced with sick pay and must therefore only be reported as absence on the 'Employer's certificate'.",
    sub_description1:
      "The days that are compensated by Försäkringskassan must only be reported as absence on the 'Employer's certificate'.",
    sub_description2:
      "Sickness pay is reported for each calendar month in which it has been paid on 'Complementing to Employer's certificate'. Use the column for 'Other remuneration'. You state that it is sick pay and the number of hours with sick pay in the 'Enter type' field. You enter earned sick pay in the field 'Kr/month'.",
  },
  {
    title: '12 Information about the salary',
    description:
      'The information must refer to the salary that the employee had when the employment ended. If the applicant has received a salary revision after he stopped working (for example during parental leave), the salary after the latest salary revision must be stated. Indicate the year to which the information provided refers.',
    subtitle: 'Enter only one option from the options hourly wage, daily wage, weekly wage or monthly wage',
    sub_description1:
      "Overtime/overtime: Details of pay for overtime and overtime/filling time must only be entered if there is such work. If the pay for overtime and overtime/fill-in time has not varied, the hourly pay is stated on the employer's certificate. If the salary has varied, the salary must be reported on 'Supplementing to employer's certificate. The working hours must always be stated on the employer's certificate.",
    sub_description2:
      "The salary must be stated excluding holiday pay. Paid vacation pay and vacation allowance must be reported on the 'Complementary to Employer Certificate' during the month it was paid. Holiday pay paid in cash and holiday allowance in connection with termination of employment must be reported in the last month of employment.",
    other_des:
      "On-call etc.: On-call, readiness, waiting time, travel time and waste time are specified on the 'Supplement to Employer Certificate', both regarding time and salary.",
  },
  {
    title: 'Commission salary',
    description:
      "The commission part of the salary is specified on the form 'Completion to employer's certificate'. On 'Completion to employer's certificate' the total commission sum per month for the same months as specified on the 'Employer's certificate' form is entered. The working hours and basic salary are recorded on the form 'Employer's certificate'. If the employee has had no basic salary, this is stated under 'Other information' on the 'Employer certificate' form.",
    subtitle:
      "Wage, salary per assignment or similar: The salary is specified on the form 'completion to employer's certificate'. If the time worked is known, it is specified on the form 'Employer's certificate'. If the time worked is not known, this is stated under 'Other information' on the 'Employer certificate' form.",
    sub_description1:
      "Remuneration other than salary: Remuneration other than salary that is taxed as income from service is specified on the form 'Complementing to employer's certificate'. For example, it could be car or mileage compensation, which is taxed as income from services.",
    sub_description2:
      "Variable allowances: Variable allowances, for example on-call and OB allowance, must be entered on the form 'Complementing employer's certificate'.",
  },
  {
    title: '13 Residency pay and holiday pay',
    description:
      'Employment in an activity linked to the school year: State whether the employee had a position with holiday pay, holiday pay or subsistence pay. When the employee is entitled to subsistence wages, the amount of money the employee is entitled to in subsistence wages is stated',
  },
  {
    title: '14 Other information',
    description:
      "Here you can provide additional information if you think it is necessary. If there is not enough space, you can write on a separate piece of paper. Then don't forget to write the employee's social security number on the paper, since the unemployment fund registers cases based on social security number.",
  },
  {
    title: '15 Employer and signature',
    description: 'Enter contact details and phone number.',
  },
];

export const taxIncomeService = [
  {
    monthdate: '',
    numberOfDaysWorked: '',
    dutyHours: '',
    nokMonth: '',
    otherRemunerationEnterArt: '',
    nokMonthAmount: '',
  },
  {
    monthdate: '',
    numberOfDaysWorked: '',
    dutyHours: '',
    nokMonth: '',
    otherRemunerationEnterArt: '',
    nokMonthAmount: '',
  },
  {
    monthdate: '',
    numberOfDaysWorked: '',
    dutyHours: '',
    nokMonth: '',
    otherRemunerationEnterArt: '',
    nokMonthAmount: '',
  },
  {
    monthdate: '',
    numberOfDaysWorked: '',
    dutyHours: '',
    nokMonth: '',
    otherRemunerationEnterArt: '',
    nokMonthAmount: '',
  },
  {
    monthdate: '',
    numberOfDaysWorked: '',
    dutyHours: '',
    nokMonth: '',
    otherRemunerationEnterArt: '',
    nokMonthAmount: '',
  },
  {
    monthdate: '',
    numberOfDaysWorked: '',
    dutyHours: '',
    nokMonth: '',
    otherRemunerationEnterArt: '',
    nokMonthAmount: '',
  },
  {
    monthdate: '',
    numberOfDaysWorked: '',
    dutyHours: '',
    nokMonth: '',
    otherRemunerationEnterArt: '',
    nokMonthAmount: '',
  },
  {
    monthdate: '',
    numberOfDaysWorked: '',
    dutyHours: '',
    nokMonth: '',
    otherRemunerationEnterArt: '',
    nokMonthAmount: '',
  },
  {
    monthdate: '',
    numberOfDaysWorked: '',
    dutyHours: '',
    nokMonth: '',
    otherRemunerationEnterArt: '',
    nokMonthAmount: '',
  },
  {
    monthdate: '',
    numberOfDaysWorked: '',
    dutyHours: '',
    nokMonth: '',
    otherRemunerationEnterArt: '',
    nokMonthAmount: '',
  },
  {
    monthdate: '',
    numberOfDaysWorked: '',
    dutyHours: '',
    nokMonth: '',
    otherRemunerationEnterArt: '',
    nokMonthAmount: '',
  },
  {
    monthdate: '',
    numberOfDaysWorked: '',
    dutyHours: '',
    nokMonth: '',
    otherRemunerationEnterArt: '',
    nokMonthAmount: '',
  },
  {
    monthdate: '',
    numberOfDaysWorked: '',
    dutyHours: '',
    nokMonth: '',
    otherRemunerationEnterArt: '',
    nokMonthAmount: '',
  },
];

export const validateFields = (fields) => {
  const errors = [];
  for (const key in fields) {
    if (fields.hasOwnProperty(key)) {
      const value = fields[key];
      if (typeof value === 'string' && value.length < 3) {
        errors.push(`${key} must be at least 3 characters long.`);
      }
    }
  }
  return errors;
};

export const userDropOptions = [
  { id: 1, label: 'Id', alignRight: false },
  { id: 2, label: 'First Name', alignRight: false },
  { id: 3, label: 'Email', alignRight: false },
  { id: 4, label: 'Profession ', alignRight: false },
  { id: 5, label: 'Created Date', alignRight: false },
  { id: 6, label: 'SSN', alignRight: false },
  { id: 7, label: 'DOB', alignRight: false },
];

export const customerDropOptions = [
  { id: 1, label: 'Id', alignRight: false },
  { id: 2, label: 'Name', alignRight: false },
  { id: 3, label: 'Email', alignRight: false },
];

export const createModaloption = [
  { id: 1, label: 'UserId', alignRight: false },
  { id: 6, label: 'Name', alignRight: false },
  { id: 7, label: 'Email', alignRight: false },
  { id: 8, label: 'SSN', alignRight: false },
];

export const invoiceDropOptions = [
  { id: 1, label: 'Id', alignRight: false },
  { id: 2, label: ' Customer Name', alignRight: false },
  { id: 3, label: 'Amount', alignRight: false },
  { id: 4, label: 'Created Date', alignRight: false },
  { id: 5, label: 'Due Date', alignRight: false },
];

export const hSavingsDropOptions = [
  { id: 1, label: 'Employee Id', alignRight: false },
  { id: 2, label: 'Employee', alignRight: false },
  { id: 3, label: 'Email', alignRight: false },
  { id: 4, label: 'SSN', alignRight: false },
  { id: 5, label: 'Date Of Birth', alignRight: false },
  { id: 6, label: 'Amount', alignRight: false },
  { id: 7, label: 'Balance', alignRight: false },
];

export const pensionDropOptions = [
  { id: 1, label: 'Employee Id', alignRight: false },
  { id: 2, label: 'Employee', alignRight: false },
  { id: 3, label: 'Email', alignRight: false },
  { id: 4, label: 'SSN', alignRight: false },
  { id: 5, label: 'DOB', alignRight: false },
  { id: 6, label: 'Amount', alignRight: false },
];

export const supportDropOptions = [
  { id: 1, label: 'Ticket', alignRight: false },
  { id: 2, label: 'Description', alignRight: false },
  { id: 3, label: 'Created Date', alignRight: false },
];

export const certificateDropOptions = [
  { id: 1, label: 'Certificate No', alignRight: false },
  { id: 2, label: 'User Name', alignRight: false },
  { id: 3, label: 'User Id', alignRight: false },
  { id: 4, label: 'Created At', alignRight: false },
  { id: 5, label: 'Email Address', alignRight: false },
];

export const hPayoutDropOptions = [
  { id: 1, label: 'Id', alignRight: false },
  { id: 2, label: 'User Id', alignRight: false },
  { id: 3, label: 'User Name', alignRight: false },
  { id: 4, label: 'Total amount requested', alignRight: false },
  { id: 5, label: 'Amount', alignRight: false },
  { id: 6, label: 'Tax Amount', alignRight: false },
  { id: 7, label: 'To Pay Amount', alignRight: false },
];

export const socialUsersDropOptions = [
  { id: 1, label: 'Name', alignRight: false },
  { id: 2, label: 'Link', alignRight: false },
];

export const emailTempDropoptions = [
  { id: 1, label: 'Id', alignRight: false },
  { id: 2, label: 'Template Name', alignRight: false },
  { id: 3, label: 'Last Update', alignRight: false },
];

export const salariesDropoptions = [
  { id: 1, label: 'User ID', alignRight: false },
  { id: 2, label: 'Employee Name', alignRight: false },
  { id: 3, label: 'Salary ID', alignRight: false },
  { id: 4, label: 'To Pay', alignRight: false },
  { id: 5, label: 'Gross Salary', alignRight: false },
  { id: 6, label: 'Tax', alignRight: false },
  { id: 7, label: 'Holiday Payout ID', alignRight: false },
  { id: 8, label: 'Invoice ID', alignRight: false },
  { id: 9, label: 'Paid Date', alignRight: false },
];

export const getMonthName = (monthYear) => {
  const [year, month] = monthYear.split('-');
  const date = new Date(year, month - 1);
  return date.toLocaleString('default', { month: 'long', year: 'numeric' });
};

export const getTomorrowDate = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const year = tomorrow.getFullYear();
  const month = String(tomorrow.getMonth() + 1).padStart(2, '0');
  const day = String(tomorrow.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getArticleName = (number) => {
  if (number === '92') {
    return HourlyWages.description;
  }
  if (number === '94') {
    return costPerDayArticle.description;
  }
  if (number === '95') {
    return costPerWeekArticle.description;
  }

  if (number === '96') {
    return costPerMonthArticle.description;
  }

  if (number === '97') {
    return costAgreementArticle.description;
  }
};

export const monthsData = [
  { id: 1, title: 'January' },
  { id: 2, title: 'February' },
  { id: 3, title: 'March' },
  { id: 4, title: 'April' },
  { id: 5, title: 'May' },
  { id: 6, title: 'June' },
  { id: 7, title: 'July' },
  { id: 8, title: 'August' },
  { id: 9, title: 'September' },
  { id: 10, title: 'October' },
  { id: 11, title: 'November' },
  { id: 12, title: 'December' },
];

export const timlonSemester = '138';
export const ovrigBruttolon = '1381';
export const utgifterForMaterialOchVerktygSkattefritt = '892';
export const reseutgifterEnligtBifogadPdfSkattefritt = '893';
export const semesterSparadManadsavlonad = '2232';
export const traktamenteUtrikesSkattefri = '8132';
export const milersattningPrivatSkattefri = '821';
export const traktamenteInrikesHalvdagSkattefri = '8113';
export const traktamenteInrikesSkattefri = '811';
export const traktamenteUtrikesSkattepliktig = '8125';
export const milersattningPrivatSkattepliktig = '822';
export const traktamenteInrikesNattSkattepliktig = '8121';
export const endastraktamenteInrikesSkattepliktig = '8122';

export const formatDateStr = (date, id) => {
  const year = date?.getFullYear();
  const month = String(date?.getMonth() + 1).padStart(2, '0');
  const day = String(date?.getDate()).padStart(2, '0');
  if (id) {
    return `${year}-${month}-${day}-${id}`;
  } else {
    return `${year}-${month}-${day}`;
  }
};

export function compareInvoicesHours(initialInvoiceHours, invoiceHours) {
  if (initialInvoiceHours.length !== invoiceHours.length) {
    return false;
  }
  for (let i = 0; i < initialInvoiceHours.length; i++) {
    const originalIndexKeys = Object.keys(initialInvoiceHours[i]);
    const invoiceIndexKeys = Object.keys(invoiceHours[i]);
    if (originalIndexKeys.length !== invoiceIndexKeys.length) {
      return false;
    }
    for (let key of originalIndexKeys) {
      if (!invoiceIndexKeys.includes(key)) {
        return false;
      }
    }
  }
  return true;
}