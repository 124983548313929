/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */

import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Popover, Form } from 'react-bootstrap';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import { monthsData } from '../utils/commonFunc';

export const PayoutFilter = ({
  toolTipError,
  getDate,
  setDate,
  setFilteredMonth,
  setFilteredYear,
  filteredMonth,
  filteredYear,
  handleClearData,
  handleChangeDate,
  searchQuery,
  setSearchQuery,
}) => {
  const [years, setYears] = useState([]);

  const getYears = () => {
    const currentYear = new Date().getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
    const years = range(2022, currentYear, 1);
    years.sort((a, b) => a - b);
    setYears(years);
  };

  const handleDateChange = (type) => (e) => {
    const dateString = e.target.value;
    const newState = {
      ...getDate,
      [type]: dateString || '',
    };
    if (type === 'to' && newState.from && new Date(dateString) < new Date(newState.from)) {
      toast.error('End date must be greater than start date');
    } else if (type === 'from' && newState.to && new Date(newState.to) < new Date(dateString)) {
      toast.error('End date must should be greater than start date');
    } else {
      setDate(newState);
    }
    setSearchQuery('');
    setFilteredMonth('');
    setFilteredYear('');
  };

  useEffect(() => {
    getYears();
  }, []);

  return (
    <div className="agreements-list holiday_Wrapper">
      <div className="row m-0 invoices-row">
        <div className="col-md-3 pl-0 col-sm-6 mb-2 from">
          <OverlayTrigger
            show={toolTipError?.invalidFromdate || toolTipError?.fromDateNotSelected}
            placement="top"
            overlay={
              <Popover id={`popover-positioned-top`} style={{ margin: 0 }}>
                <Popover.Header className="text-danger" as="h3">
                  {toolTipError?.invalidFromdate && 'The start date should be greater than the end date.'}
                  {toolTipError?.fromDateNotSelected && 'Please select the starting date from the calander.'}
                </Popover.Header>
              </Popover>
            }
          >
            <div>
              <p className="date_label">{'From'}</p>
              <input
                type="date"
                value={getDate?.from || ''}
                onChange={handleDateChange('from')}
                className={`selectedDate`}
                placeholder="From Date"
              />
            </div>
          </OverlayTrigger>
        </div>
        <div className="col-md-3 pl-0 col-sm-6 mb-2 to">
          <OverlayTrigger
            show={toolTipError?.toDateNotSelected}
            placement="top"
            overlay={
              <Popover id={`popover-positioned-top`} style={{ margin: 0 }}>
                <Popover.Header className="text-danger" as="h3">
                  {toolTipError?.toDateNotSelected && 'Please select the end date from the calander'}
                </Popover.Header>
              </Popover>
            }
          >
            <div>
              <p className="date_label ml-2">{'To'}</p>
              <input
                type={'date'}
                className={`selectedDate ml-0`}
                value={getDate?.to || ''}
                onChange={handleDateChange('to')}
              />
            </div>
          </OverlayTrigger>
        </div>

        <div className="date_div">
          <span>
            <Form.Select
              aria-label="Default select example"
              className="year_picker fit-content ml-0"
              value={filteredYear || ''}
              onChange={(e) => handleChangeDate(e, 'year')}
              defaultValue={''}
            >
              <option value={''} disabled={true}>
                {'Year'}
              </option>
              {years?.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Form.Select>
            <Form.Select
              aria-label="Default select example"
              className="month_picker ml-2"
              value={filteredMonth || ''}
              onChange={(e) => handleChangeDate(e, 'month')}
              defaultValue={''}
              placeholder="check"
            >
              <option value={''} disabled={true}>
                {'Month'}
              </option>
              {monthsData?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.title}
                </option>
              ))}
            </Form.Select>
            <Button
              className="ml-2 clear_btn"
              variant="contained"
              onClick={() => {
                handleClearData();
              }}
              style={{ height: '40px', width: '80px' }}
              disabled={
                filteredYear === '' &&
                filteredMonth === '' &&
                getDate?.from === '' &&
                getDate?.to === '' &&
                searchQuery === ''
              }
            >
              {'Clear'}
            </Button>
          </span>
        </div>
      </div>
    </div>
  );
};
