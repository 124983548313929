/* eslint-disable */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, Box, Stack, Container, Typography, IconButton, Snackbar } from '@mui/material';

import Iconify from '../../../components/Iconify';
import Page from '../../../components/Page';
import { TabsUI } from './TabsUI';

export default function Salaries() {
  const navigate = useNavigate();
  useEffect(() => {
    window.onload = () => {
      if (window.location.pathname.includes('/dashboard/salaries/')) {
        navigate(-1);
      }
    };
  }, []);

  return (
    <Page title="Salaries">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Stack direction="row" justifyContent="space-between">
            <IconButton onClick={() => navigate(-1)}>
              <Iconify icon="eva:arrow-back-fill" width={24} height={24} />
            </IconButton>
            <Typography variant="h4" sx={{ ml: 1 }} gutterBottom>
              Salaries
            </Typography>
          </Stack>
        </Stack>
        {/* 
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isSnackBarOpen}
          autoHideDuration={autoHideDuration}
          onClose={handleSnackBarClose}
        >
          <Alert onClose={handleSnackBarClose} severity={alertStatus} sx={{ width: '100%' }}>
            {alertMessage}
          </Alert>
        </Snackbar> */}

        <Card>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabsUI />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
