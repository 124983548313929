import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { useOutletContext, Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Link,
  Button,
} from '@mui/material';
import { toast } from 'react-toastify';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { ListHead, ListToolbar } from '../../sections/@dashboard/table';
import Loader from '../../components/Loader';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'notificationId', label: 'Notification Id', alignRight: false },
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'userName', label: 'Username', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'email', label: 'Email Address', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const escapeRegExp = (value) => value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    const searchRegex = new RegExp(escapeRegExp(query), 'i');
    return filter(array, (_row) =>
      Object.keys(_row).some((field) => {
        if (_row[field]) return searchRegex.test(_row[field].toString());
        return false;
      })
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function Notifications({ PageSize, PageNumber }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected] = useState([]);
  const [orderBy, setOrderBy] = useState('username');
  const [searchQuery, setSearchQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [notifications, setNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);

  const { notificationList, loading, totalCount } = useOutletContext();

  const fetchNotifications = async () => {
    try {
      setNotifications(notificationList);
      setFilteredNotifications(notificationList);
    } catch (err) {
      setNotifications([]);
      setFilteredNotifications([]);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const filteredRows = applySortFilter(
      filteredNotifications,
      getComparator(isAsc ? 'desc' : 'asc', orderBy),
      event.target.value
    );
    setFilteredNotifications(filteredRows);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    PageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    PageSize(parseInt(event.target.value, 10));
    PageNumber(0);
    setPage(0);
  };

  const handleFilter = (event) => {
    setSearchQuery(event.target.value);
    if (event.target.value) {
      const filteredRows = applySortFilter(filteredNotifications, getComparator(order, orderBy), event.target.value);
      setFilteredNotifications(filteredRows);
    } else {
      setFilteredNotifications(notifications);
    }
  };

  const isNotFound = filteredNotifications.length === 0;
  const [isLoading, setisLoading] = useState(false);
  const handleReActivate = async (id) => {
    try {
      setisLoading(true);
      const resp = await axios.put(`/admin/user/${id}?isActive=true`);
      if (resp?.data?.statusCode === 200) {
        toast.success('User is Re-Activated successfully');
        window.location.reload();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setisLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [notificationList]);

  return (
    <Page title="Notifications">
      {(isLoading || loading) && <Loader />}
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Notifications
          </Typography>
        </Stack>

        <Card>
          <ListToolbar
            hideEmailButton={'true'}
            numSelected={selected.length}
            searchQuery={searchQuery}
            onFilter={handleFilter}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredNotifications.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  hideCheckboxColumnHeader={'true'}
                />
                <TableBody>
                  {filteredNotifications.length &&
                    filteredNotifications?.map((row, i) => {
                      const {
                        id,
                        userName,
                        title,
                        description,
                        createdAt,
                        routeName,
                        notificationVal,
                        email,
                        notificationTypeId,
                        isReactivated,
                      } = row;
                      let route = '';

                      switch (routeName) {
                        case 'addNotifications':
                        case 'employer-certificate':
                          route = `/dashboard/${routeName}?notificationVal=${notificationVal}`;
                          break;

                        case ' Reactivate user':
                          route = `/dashboard/users?notificationVal=${notificationVal}`;
                          break;

                        case 'SSN No':
                          route = `/dashboard/users?notificationVal=${notificationVal}`;
                          break;
                        case 'Holiday Payout':
                          route = `/dashboard/holiday-pay?holidayId=${notificationVal}`;
                          break;
                        case 'User Note':
                          route = `/dashboard/users?notificationVal=${notificationVal}`;
                          break;
                        case 'Customer Note':
                          route = `/dashboard/customers?notificationVal=${notificationVal}`;
                          break;
                        case 'Invoice Note':
                          route = `/dashboard/invoices?notificationVal=${notificationVal}`;
                          break;
                        case 'invoices':
                          route = `/dashboard/invoices?notificationVal=${notificationVal}`;
                          break;
                        default:
                          route = `/dashboard/${routeName}`;
                          break;
                      }

                      return (
                        <TableRow key={i}>
                          <TableCell align="left">{id}</TableCell>
                          <TableCell align="left">{notificationVal}</TableCell>
                          <TableCell align="left">{userName}</TableCell>
                          <TableCell align="left">
                            <Link component={RouterLink} to={route}>
                              {title}
                            </Link>
                          </TableCell>
                          <TableCell align="left">
                            {!isReactivated && notificationTypeId === 10 && (
                              <Button
                                disabled={isLoading}
                                variant="outlined"
                                onClick={() => handleReActivate(notificationVal)}
                                style={{ marginRight: '10px', width: '130px', minWidth: '50px' }}
                              >
                                {!isReactivated && notificationTypeId === 10 && 'Re-Activate'}
                              </Button>
                            )}

                            {isReactivated && notificationTypeId === 10 && (
                              <Button
                                disabled={isReactivated}
                                variant="outlined"
                                onClick={() => handleReActivate(notificationVal)}
                                style={{ marginRight: '10px', width: '130px', minWidth: '50px' }}
                              >
                                {isReactivated && notificationTypeId === 10 && 'Activated'}
                              </Button>
                            )}
                          </TableCell>
                          <TableCell align="left">{email || '---'}</TableCell>
                          <TableCell align="left">{description}</TableCell>
                          <TableCell align="left">
                            {createdAt.date ? new Date(createdAt.date).toISOString().split('T')[0] : ''}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={searchQuery} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
