import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TablePagination } from '@mui/material';
import { ListHead } from '../../../../sections/@dashboard/table';
import { formatDate2 } from '../../../../utils/commonFunc';

export const ViewHolidayPay = ({ holidayPayId, setLoading }) => {
  const [holidayPayById, setHolidayPayById] = useState([]);
  const [totalHPCount, setTotalHPCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);

  const getHolidayPayById = async () => {
    try {
      setLoading(true);
      const URL = `/admin/adminsalary/all/holidaypay?id=${holidayPayId}&PageNumber=${page + 1}&PageSize=${rowsPerPage}`;
      const result = await axios.get(URL);
      if (result?.status === 200) {
        setLoading(false);
        setHolidayPayById(result?.data?.response);
        setTotalHPCount(result?.data?.totalCount);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (holidayPayId) {
      getHolidayPayById();
    }
  }, [holidayPayId, page, rowsPerPage]);

  const TABLE_HEAD = [
    { id: 'userId', label: 'User Id', alignRight: false },
    { id: 'invoiceId', label: 'Invoice Id', alignRight: false },
    {
      id: 'holidaySavingsAmount',
      label: 'Holiday Savings Amount',
      alignRight: false,
    },
    { id: 'createdDate', label: 'Created Date', alignRight: false },
  ];

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <ListHead
            //   order={order}
            //   orderBy={orderBy}
            headLabel={TABLE_HEAD}
            //   rowCount={users?.length}
            //   numSelected={selected?.length}
            //   onRequestSort={handleRequestSort}
            //   onSelectAllClick={handleSelectAllClick}
            hideCheckboxColumnHeader="true"
          />
          <TableBody>
            {holidayPayById?.length ? (
              holidayPayById?.map((item) => {
                return (
                  <TableRow
                    key={item?.userId}
                    // onClick={() => handleUserClick(item?.userId)}
                  >
                    <TableCell>{item?.userId}</TableCell>
                    <TableCell>{item?.invoiceId}</TableCell>
                    <TableCell>{item?.holidaySavingsAmount}</TableCell>
                    <TableCell>{formatDate2(item?.createdDate)}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow className="text-center">
                <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                  No record found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[100, 250, 500]}
        component="div"
        count={totalHPCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
