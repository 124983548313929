/* eslint-disable */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import PdfPreview from './PdfPreview';
import logo from '../assets/img/fakt-logo_360.png';
import { HourlyWages, InvoiveAsDescribedBellow, otherCost } from '../utils/stringUtils';
import { formatInvoiceDate, otherArticleOptions, otherArticlesTotalAmount } from '../utils/commonFunc';
import { Button, FormControl, NativeSelect } from '@mui/material';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { toast } from 'react-toastify';

const ShowInvoiceDialog = ({
  show,
  selectedFreelancers,
  onClose,
  customer,
  formValues,
  calculatedAmounts,
  currency,
  user,
  otherArticleValues,
  createdDate,
  getPdfUrl,
  totalHours,
}) => {
  const [preferredLang, setPreferredLang] = useState('en');

  const totalworkingHours = totalHours?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const handleClose = () => {
    handleLanguageChange({ target: { value: 'en' } });

    if (onClose) onClose();
  };

  const { t, i18n } = useTranslation();

  const handleLanguageChange = (e) => {
    setPreferredLang(e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  const date = new Date();
  let dateStr = `${date.getFullYear()} -`;
  dateStr += date.getMonth() + 1 > 9 ? date.getMonth() + 1 : 0 + (date.getMonth() + 1);
  dateStr += date.getDate() > 9 ? -+date.getDate() : -+0 + date.getDate();

  let mergeArticleNo92;
  if (formValues?.invoiceHours) {
    const cloneInvoiceHours = [...formValues?.invoiceHours];

    const mergeTotalAmount =
      cloneInvoiceHours[0] &&
      Object?.values(cloneInvoiceHours[0]).map((item, index) => {
        return {
          ...item,
          totalAmount: parseFloat(item.amount) * parseFloat(item.units),
          calculatedAmounts: parseFloat(item.amount) * parseFloat(item.units),
          otherArticleSum: 0,
        };
      });

    const otherArticleSum = mergeTotalAmount?.reduce((acc, curr) => {
      if (curr.article?.articleNumber !== HourlyWages) {
        acc += parseFloat(curr.amount);
      }
      return acc;
    }, 0);

    mergeArticleNo92 = mergeTotalAmount?.reduce((result, item) => {
      if (item.article?.articleNumber === HourlyWages) {
        const existingItem = result.find((i) => i.article.articleNumber === HourlyWages);
        if (existingItem) {
          existingItem.units = parseFloat(existingItem.units) + parseFloat(item.units);
          existingItem.amount = parseFloat(existingItem.amount) + parseFloat(item.amount);
          existingItem.totalAmount += item.amount * item.units;
          existingItem.calculatedAmounts = calculatedAmounts?.amount;
          existingItem.otherArticleSum = otherArticleSum;
        } else {
          result.push({ ...item });
        }
      } else {
        result.push({ ...item });
      }
      return result;
    }, []);
  }

  const handleInvoiceDownloadPdf = () => {
    const element = document.getElementById('invoice-dialog-div');
    if (element) {
      html2canvas(element, { scale: 3 }).then((canvas) => {
        const imgWidth = 210;
        const pageHeight = 297;
        const { width, height } = canvas;
        const aspectRatio = height / width;
        const imgHeight = imgWidth * aspectRatio;
        let heightLeft = imgHeight;
        const doc = new jsPDF('p', 'mm', 'a4');
        let position = 0;
        const pageData = canvas.toDataURL('image/jpeg', 1.0);

        doc.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
        doc.setLineWidth(15);
        heightLeft -= pageHeight;
        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
          doc.setLineWidth(15);
          heightLeft -= pageHeight;
        }
        toast.success('Downloaded successfully');
        doc.save(`${user?.userId}-${user?.firstName}-invoice.pdf`);

        onClose();
        i18n.changeLanguage('en');
      });
    }
  };

  return (
    <Modal className="invoice-dialog invoice-modal" size="lg" show={show} onHide={handleClose} scrollable>
      <Modal.Header>
        <h5 className="modal-title" id="exampleModalLabel">
          {t('Select Your Invoice Language')}
        </h5>

        <div className="ml-4">
          <FormControl>
            <NativeSelect
              defaultValue={30}
              inputProps={{
                name: 'lang',
                id: 'uncontrolled-native',
              }}
              value={preferredLang}
              onChange={handleLanguageChange}
            >
              <option value="en">English</option>
              <option value="de">Swedish</option>
            </NativeSelect>
          </FormControl>
        </div>

        <AiOutlineClose className="ml-auto cursor-pointer" size={24} onClick={handleClose} />
      </Modal.Header>

      <Modal.Body className="p-3">
        <div id="invoice-dialog-div">
          <div className="p-3">
            <div className="logo-info ">
              <div className="">
                <div className="heading d-flex align-items-center justify-content-between px-3">
                  <div className="logo-pdf">
                    <img src={logo} alt="logo" className="logo" />
                  </div>
                  <div className="date-text">
                    <h4 className="mb-0">
                      {t('Preliminary Invoice')} <span>{formatInvoiceDate(createdDate, false)}</span>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="px-3 py-2 mb-3 flex-change">
                  <h3 className="text-right">{customer?.name}</h3>
                  <p className="text-right m-0">{customer?.address}</p>
                  <p className="text-right m-0">{customer?.phoneNumber}</p>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <div className="customer d-flex align-items-center justify-content-between">
                  <div className="first">
                    <div className="inner-group">
                      <div className="customer-data d-flex align-items-center">
                        <h4 className="m-0">{t('Customer no')}:</h4>
                        <p className="m-0">{customer?.customerId}</p>
                      </div>
                      <div className="customer-data d-flex align-items-center">
                        <h4 className="m-0">
                          {t('Invoice No')}: {t('Not created yet')}{' '}
                        </h4>
                      </div>
                      <div className="customer-data d-flex align-items-center">
                        <h4 className="m-0">{t('Your reference')}:</h4>
                        <p className="m-0">{customer?.reference}</p>
                      </div>
                      <div className="customer-data d-flex align-items-center">
                        <h4 className="m-0">{t('Your VAT number')}:</h4>
                        <p className="m-0">{customer?.vatNumber}</p>
                      </div>
                    </div>
                  </div>
                  <div className="second">
                    <div className="inner-group">
                      <div className="customer-data d-flex align-items-center">
                        <h4 className="m-0">{t('Our reference')}:</h4>
                        <p className="m-0">
                          {user?.firstName} {user?.lastName}
                        </p>
                      </div>
                      <div className="customer-data d-flex align-items-center">
                        <h4 className="m-0">{t('Payment terms')}:</h4>
                        <p className="m-0">
                          {customer?.paymentTerm} {t('Days')}
                        </p>
                      </div>
                      <div className="customer-data d-flex align-items-center">
                        <h4 className="m-0">
                          {t('Due date')}: {formatInvoiceDate(createdDate, customer?.paymentTerm)}{' '}
                        </h4>
                      </div>
                      <div className="customer-data d-flex align-items-center">
                        <h4 className="m-0">
                          {t('Invoice date')}: {formatInvoiceDate(createdDate, false)}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col overflow-auto show-invoice-wrapper">
                <table className="table">
                  <tr className="border-invoice">
                    {!formValues?.isFixed && <th>{t('Article No.')}</th>}
                    <th>{t('Name')}</th>
                    {!formValues?.isFixed && (
                      <>
                        <th>{t('Quantity')}</th>
                        <th>{t('Unit')}</th>
                        <th>{t('Price per unit')}</th>
                      </>
                    )}
                    <th>{t('Total')}</th>
                  </tr>
                  {formValues?.isFixed ||
                  (formValues.showDates !== 'true' && selectedFreelancers && selectedFreelancers?.length === 0)
                    ? mergeArticleNo92?.map((invoiceItem, index) => {
                        return (
                          <tr key={index}>
                            {!formValues.isFixed && <td className="m-0">{invoiceItem?.article?.articleNumber}</td>}
                            <td className="m-0">{t(invoiceItem?.article?.description)}</td>
                            {!formValues.isFixed && (
                              <>
                                <td className="m-0">
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={t(invoiceItem?.article?.description)}
                                    style={{ cursor: 'default' }}
                                  >
                                    {invoiceItem?.article?.articleNumber === HourlyWages ? invoiceItem?.units : 1}
                                  </span>
                                </td>
                                <td className="m-0 cursor-pointer" title={t(invoiceItem?.article.description)}>
                                  {invoiceItem?.article?.unit}
                                </td>

                                <td className="m-0">
                                  {formValues.isFixed
                                    ? Number.isInteger(
                                        (invoiceItem?.calculatedAmounts - invoiceItem?.otherArticleSum) /
                                          invoiceItem.units
                                      )
                                      ? (invoiceItem?.calculatedAmounts - invoiceItem?.otherArticleSum) /
                                        invoiceItem.units
                                      : parseFloat(
                                          (invoiceItem?.calculatedAmounts - invoiceItem?.otherArticleSum) /
                                            invoiceItem.units
                                        ).toFixed(2)
                                    : Number.isInteger(invoiceItem.totalAmount / invoiceItem.units)
                                    ? invoiceItem.totalAmount / invoiceItem.units
                                    : parseFloat(invoiceItem.totalAmount / invoiceItem.units).toFixed(2)}
                                </td>
                              </>
                            )}

                            <td className="m-0">
                              {formValues?.isFixed ? t('Fixed Price') : ''}{' '}
                              {!formValues?.isFixed &&
                                invoiceItem?.article &&
                                invoiceItem?.article?.unit &&
                                invoiceItem?.totalAmount?.toFixed(2)}
                            </td>
                          </tr>
                        );
                      })
                    : formValues?.invoiceHours?.map((allIndex, allIndexKey) =>
                        Object.keys(allIndex)?.map((date, index) => {
                          const userFirstName = user?.firstName;
                          const userLastName = user?.lastName;
                          return (
                            <tr key={index}>
                              {!formValues.isFixed && <td className="m-0">{allIndex[date]?.article?.articleNumber}</td>}

                              <td className="m-0">
                                {t(allIndex[date]?.article?.description)}{' '}
                                {!formValues?.isFixed
                                  ? formValues?.showDates === 'true' &&
                                    (() => {
                                      const [year, month, day] = date?.split('-');
                                      return `${year}-${month}-${day} `;
                                    })()
                                  : ''}{' '}
                                {allIndexKey === 0
                                  ? `(${userFirstName} ${userLastName})`
                                  : formValues?.freelancer?.[allIndexKey - 1]?.signUpUser
                                  ? `(${formValues.freelancer[allIndexKey - 1].signUpUser.firstName} ${
                                      formValues.freelancer[allIndexKey - 1].signUpUser.lastName
                                    })`
                                  : ''}
                              </td>
                              {!formValues?.isFixed && (
                                <>
                                  <td className="m-0">
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title={t(allIndex[date]?.article?.description)}
                                      style={{ cursor: 'default' }}
                                    >
                                      {allIndex[date]?.article?.articleNumber === HourlyWages
                                        ? allIndex[date]?.units
                                        : 1}
                                    </span>
                                  </td>
                                  <td className="m-0 cursor-pointer" title={t(allIndex[date]?.article?.description)}>
                                    {allIndex[date]?.article?.unit}
                                  </td>
                                  <td className="m-0">
                                    {allIndex[date]?.amount !== '' ? Number(allIndex[date]?.amount).toFixed(2) : 0}
                                  </td>
                                </>
                              )}
                              <td className="m-0">
                                {formValues?.isFixed ? t('Fixed Price') : ''}{' '}
                                {!formValues?.isFixed &&
                                  allIndex[date]?.article &&
                                  allIndex[date]?.article?.unit &&
                                  allIndex[date]?.amount !== '' &&
                                  (parseFloat(allIndex[date]?.amount) * parseFloat(allIndex[date]?.units)).toFixed(2)}
                                {!formValues?.isFixed &&
                                  allIndex[date]?.article &&
                                  allIndex[date]?.article?.unit &&
                                  allIndex[date]?.amount === '' &&
                                  0 * parseFloat(allIndex[date]?.units)}
                              </td>
                            </tr>
                          );
                        })
                      )}

                  {!formValues?.isFixed &&
                    Object.entries(otherArticleValues)?.map(([key, values]) =>
                      values?.map((item, index) => (
                        <tr key={index}>
                          <td className="m-0">{item?.key === 'oth' ? otherCost : InvoiveAsDescribedBellow}</td>
                          <td className="m-0">
                            {item?.key === 'oth' ? t(otherArticleOptions[0]?.label) : t(otherArticleOptions[1]?.label)}{' '}
                            {values?.[index]?.userId === user?.userId ? (
                              <>
                                ({user?.firstName} {user?.lastName})
                              </>
                            ) : values?.[index]?.userId === formValues?.freelancer[key - 1]?.userId ? (
                              <>
                                ({formValues?.freelancer[key - 1]?.signUpUser?.firstName}{' '}
                                {formValues?.freelancer[key - 1]?.signUpUser?.lastName})
                              </>
                            ) : (
                              ' '
                            )}
                          </td>
                          <td className="m-0">
                            <span
                              data-toggle="tooltip"
                              data-placement="top"
                              title={
                                item?.key === 'oth'
                                  ? t(otherArticleOptions[0]?.label)
                                  : t(otherArticleOptions[1]?.label)
                              }
                              style={{ cursor: 'default' }}
                            >
                              1
                            </span>
                          </td>
                          <td
                            className="m-0 cursor-pointer"
                            title={
                              item?.key === 'oth' ? t(otherArticleOptions[0]?.label) : t(otherArticleOptions[1]?.label)
                            }
                          >
                            {item?.key === 'oth' ? 'km' : 'förp'}
                          </td>
                          <td className="m-0">
                            {item?.key === 'oth'
                              ? otherArticlesTotalAmount('oth', values, 'self')
                              : otherArticlesTotalAmount('exp', values, 'self')}
                          </td>

                          <td className="m-0">
                            {formValues?.isFixed
                              ? t('Fixed Price')
                              : item?.key === 'oth'
                              ? otherArticlesTotalAmount('oth', values, 'self')
                              : otherArticlesTotalAmount('exp', values, 'self')}{' '}
                          </td>
                        </tr>
                      ))
                    )}
                </table>
              </div>
            </div>
            {!formValues?.isFixed && (
              <div className="d-flex align-items-right justify-content-end">
                <h4 className="m-0">{t('Total working hours:')}&nbsp;</h4>
                <p>{totalworkingHours || 0}</p>
              </div>
            )}

            <div className="row">
              <div className="col">
                <div className=" p-0 description" style={{ wordWrap: 'break-word' }}>
                  <p className="m-0">{formValues?.description}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="border border-light vat rounded p-2 mb-3">
                  <div className="d-flex mb-3 response-data justify-content-between">
                    <div className="text-center">
                      <h6 className="m-0">{t('Excluding VAT')}</h6>
                      <h6 className="m-0">
                        {Number.isInteger(calculatedAmounts?.amount)
                          ? calculatedAmounts?.amount
                          : parseFloat(calculatedAmounts?.amount)?.toFixed(2)}
                      </h6>
                    </div>
                    <div className="text-center">
                      <h6 className="m-0">{t('VAT')} %</h6>
                      <h6 className="m-0">{formValues?.vat}</h6>
                    </div>
                    <div className="text-center">
                      <h6 className="m-0">{t('VAT')}</h6>
                      <h6 className="m-0">{calculatedAmounts?.vat}</h6>
                    </div>
                    <div className="text-center">
                      <h6 className="m-0">{t('Round-off')}</h6>
                      <h6 className="m-0">0</h6>
                    </div>
                    <div className="text-center">
                      <h6 className="m-0">{t('Currency')}</h6>
                      <h6 className="m-0">{formValues?.currency}</h6>
                    </div>

                    <div className="text-center">
                      <h6 className="m-0">{t('ROT / RUT')}</h6>
                      <h6 className="m-0">
                        {calculatedAmounts?.rootDeductionAmount} / {calculatedAmounts?.rutDeductionAmount}
                      </h6>
                    </div>

                    <div className="text-center">
                      <h6 className="m-0 font-weight-bold">{t('To pay')}</h6>
                      <h6 className="m-0 font-weight-bold">
                        <span> {currency} </span>{' '}
                        {Number.isInteger(calculatedAmounts?.amountIncludingVat)
                          ? calculatedAmounts?.amountIncludingVat
                          : parseFloat(calculatedAmounts?.amountIncludingVat)?.toFixed(2)}
                      </h6>
                    </div>
                  </div>
                </div>
                <p className="text-center invoice m-0">{t('PLEASE NOTE THAT THIS IS A PRELIMINARY INVOICE')}</p>
              </div>
            </div>
            <div className="bottom-part px-3">
              <h4 className="invoice m-0">{t('INVOZIO LV SIA')}</h4>
              <div className="contact">
                <p className="m-0">010-264 88 10</p>
                <p className="m-0">{t('support@invozio.com')}</p>
              </div>
              <div className="address">
                <p className="m-0">{t('Invozio LV SIA')}</p>
                <p className="m-0">
                  {t('Org. Number')}: <span> 502085-4062</span>
                </p>
                <p className="m-0">
                  {t('Vat Number')}: <span>SE502085406201 </span>
                </p>
              </div>
            </div>
            <h1 className="background-txt mb-0">{t('Preliminaryinvoice')}</h1>
            <PdfPreview pdfPreview={getPdfUrl} existingPdf={formValues} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="contained" color="primary" onClick={handleInvoiceDownloadPdf}>
          Download
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShowInvoiceDialog;
