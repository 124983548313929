/* eslint-disable */
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
// form
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  Tooltip,
  Box,
  Modal,
} from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { getModalBoxStyle, hPayoutDropOptions } from '../../utils/commonFunc';
import SearchNotFound from '../../components/SearchNotFound';
import { ListHead, ListToolbar } from '../../sections/@dashboard/table';
import Loader from '../../components/Loader';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'userId', label: 'User Id', alignRight: false },
  { id: 'userName', label: 'User Name', alignRight: false },
  { id: 'amount', label: 'Total amount requested', alignRight: false },
  { id: 'administratorFee ', label: 'Administrator Fee', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'taxAmount', label: 'Tax Amount', alignRight: false },
  { id: 'toPayAmount', label: 'To Pay Amount', alignRight: false },
  { id: '', label: 'Actions' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const escapeRegExp = (value) => value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

function applySortFilter(array, comparator, query, orgArray) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    const searchRegex = new RegExp(escapeRegExp(query), 'i');
    return filter(orgArray, (_row) =>
      Object.keys(_row).some((field) => {
        if (_row[field]) return searchRegex.test(_row[field].toString());
        return false;
      })
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}
export default function HolidayPayout() {
  const navigate = useNavigate();
  const [holidayPayouts, setHolidayPayouts] = useState([]);
  const [filteredHolidayPayouts, setFilteredQuestions] = useState([]);
  const [selectedCertificateId] = useState(0);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertStatus, setAlertStatus] = useState('success');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdDate');
  const [searchQuery, setSearchQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loading, setLoading] = useState(false);
  const [selectedTabValue, setSelectedTabValue] = useState('2');
  const [hideCheckboxColumn, setHideCheckboxColumn] = useState('true');
  const [totalCount, setTotalCount] = useState(0);
  const [orignalPayout, setorignalPayout] = useState([]);
  const [isAllSelected, setAllSelected] = useState(false);
  const [activateModal, setActivateModal] = useState({
    id: null,
    show: false,
    isActive: 'false',
  });
  const [payoutModal, setPayoutModal] = useState({
    id: null,
    show: false,
  });
  const [searchDropValue, setSearchDropValue] = useState();
  const [selectedUndo, setSelectedUndo] = useState(null);
  const [isopenDialog, setIsopenDialog] = useState(false);

  const { search } = useLocation();

  useEffect(() => {
    fetchHolidayPayouts();
  }, [page, rowsPerPage, selectedTabValue, searchQuery]);

  const fetchHolidayPayouts = async () => {
    try {
      setLoading(true);
      const holidayId = new URLSearchParams(search).get('holidayId');
      let URL;
      if (searchDropValue && searchQuery) {
        URL = `/admin/adminsalary?PageSize=${rowsPerPage}&PageNumber=${
          page + 1
        }&active=${selectedTabValue}&searchQuery=${searchQuery}&SearchColumn=${searchDropValue}`;
      } else {
        URL = `/admin/adminsalary?PageSize=${rowsPerPage}&PageNumber=${
          page + 1
        }&active=${selectedTabValue}&searchQuery=${searchQuery}`;
      }

      const res = await axios.get(URL);
      if (res?.status === 200) {
        setLoading(false);
        const filteredRows = applySortFilter(
          res.data.response,
          getComparator(order, orderBy),
          searchQuery,
          orignalPayout
        );
        setorignalPayout(res.data.response);
        const notificationFilteredList = filteredRows?.map((item) => ({
          ...item,
          isSelected: item.id === Number(holidayId),
        }));
        selectAllClick(isAllSelected, notificationFilteredList);
        setHolidayPayouts(notificationFilteredList);
        setTotalCount(res.data.totalCount);
        if (selectedTabValue === '1') {
          setHideCheckboxColumn('true');
          setSelectedTabValue('1');
          setFilteredQuestions(notificationFilteredList);
        } else if (selectedTabValue === '2') {
          setHideCheckboxColumn('true');
          setSelectedTabValue('2');
          setFilteredQuestions(notificationFilteredList.filter((f) => f.isActive));
        } else if (selectedTabValue === '3') {
          setHideCheckboxColumn(false);
          setSelectedTabValue('3');
          setFilteredQuestions(notificationFilteredList.filter((f) => !f.isActive));
        }
      }
    } catch (err) {
      if (err?.response?.data?.message === 'Something went wrong please try again after sometime.') {
        fetchHolidayPayouts();
        setLoading(true);
      } else {
        setHolidayPayouts([]);
        setLoading(false);
      }
      // setHolidayPayouts([]);
      console.log(err);
    }
    // finally {
    //   setLoading(false);
    // }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const filteredRows = applySortFilter(
      filteredHolidayPayouts,
      getComparator(isAsc ? 'desc' : 'asc', orderBy),
      event.target.value,
      orignalPayout
    );
    setFilteredQuestions(filteredRows);
  };

  const selectAllClick = (isSelected, filteredHolidayPayouts) => {
    if (isSelected) {
      const newSelecteds = filteredHolidayPayouts?.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleSelectAllClick = (event) => {
    setAllSelected(event.target.checked);
    selectAllClick(event.target.checked, filteredHolidayPayouts);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (event) => {
    setSearchQuery(event.target.value);
    if (event.target.value) {
      const filteredRows = applySortFilter(
        filteredHolidayPayouts,
        getComparator(order, orderBy),
        event.target.value,
        orignalPayout
      );
      setFilteredQuestions(filteredRows);
    } else {
      setFilteredQuestions(orignalPayout);
    }
  };

  const deleteEmployerCertificate = async (id) => {
    try {
      handleDialogClose();
      setLoading(true);
      await axios.delete(`/AdminEmployeeCertificate/${id}`).then(() => {
        setAlertMessage(`CertificateId #${id} has been deleted successfully.`);
        setAlertStatus('success');
        setIsSnackBarOpen(true);
      });
      await fetchHolidayPayouts();
    } catch (err) {
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    await deleteQuestions();
  };

  const deleteQuestions = async () => {
    try {
      handleDialogClose();
      setLoading(true);
      await axios.delete(`/admin/AdminQA`).then((res) => {
        const isDeletedCount = res.data.response.isDeleted;
        if (isDeletedCount > 0) {
          setAlertMessage(
            `${
              isDeletedCount === res.data.totalCount
                ? `Selected ${isDeletedCount > 1 ? 'holiday payouts' : 'holiday payout'} has been deleted successfully.`
                : `${isDeletedCount} ${isDeletedCount > 1 ? 'holiday payouts' : 'holiday payout'} out of ${
                    res.data.totalCount
                  } has been deleted successfully.`
            }`
          );
          setAlertStatus('success');
        } else {
          setAlertMessage(`Selected ${isDeletedCount > 1 ? 'holiday payouts' : 'holiday payout'} can't been deleted.`);
          setAlertStatus('error');
        }
        setIsSnackBarOpen(true);
        fetchHolidayPayouts();
      });
    } catch (err) {
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
    } finally {
      setLoading(false);
      setSelected([]);
    }
  };

  const activateOrDeactivateQuestion = async (id, type) => {
    try {
      setLoading(true);
      await axios.put(`/admin/adminsalary/${id}?isActive=${type}`).then(async () => {
        setAlertMessage(`CertificateId #${id} has been ${type === 'true' ? 'Activated' : 'Deactivated'} successfully.`);
        setAlertStatus('success');
        setIsSnackBarOpen(true);
        await fetchHolidayPayouts();
        setActivateModal({ show: false });
      });
    } catch (err) {
      setActivateModal({ show: false });
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
    } finally {
      setActivateModal({ show: false });
      setLoading(false);
    }
  };

  const isNotFound = filteredHolidayPayouts.length === 0;

  const handleDialogClose = () => {
    setIsOpenDialog(false);
  };

  const handleDialogOpen = (id) => {
    setSelectedUndo(id);
    setIsopenDialog(true);
  };

  const handledialogClose = () => {
    setIsopenDialog(false);
    setSelectedUndo(null);
  };

  const handleSnackBarClose = () => {
    setIsSnackBarOpen(false);
  };

  const handleTabChange = (e, newValue) => {
    setSearchQuery('');
    setPage(0);
    setSelected([]);
    setSelectedTabValue(newValue);
    setAllSelected(false);
    if (newValue === '1') {
      setHideCheckboxColumn('true');
      setFilteredQuestions(holidayPayouts);
    } else if (newValue === '2') {
      setHideCheckboxColumn('true');
      setFilteredQuestions(holidayPayouts.filter((f) => f.isActive));
    } else if (newValue === '3') {
      setHideCheckboxColumn(false);
      setFilteredQuestions(holidayPayouts.filter((f) => !f.isActive));
    }
  };

  const handlePayout = async (id) => {
    try {
      setLoading(true);
      await axios.post(`/admin/adminsalary/holidaypayout-id?holidayId=${id}`);
      setAlertMessage(`Paid successfully.`);
      setAlertStatus('success');
      setIsSnackBarOpen(true);
      fetchHolidayPayouts();
    } catch (err) {
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUndo = async (id) => {
    try {
      setLoading(true);
      const result = await axios.post(`/admin/adminsalary/unpaid-holidaypayout-id?holidayId=${id}`);
      if (result?.status === 200) {
        setAlertMessage('Undo successful.');
        setAlertStatus('success');
        setIsSnackBarOpen(true);
        fetchHolidayPayouts();
        handledialogClose();
      }
    } catch (err) {
      setAlertMessage(err?.response?.data?.message || 'Failed to undo salary payment.');
      setAlertStatus('error');
      setIsSnackBarOpen(true);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const renderQuestions = () => (
    <>
      <ListToolbar
        hideEmailButton={'true'}
        numSelected={selected.length}
        searchQuery={searchQuery}
        onFilter={handleFilter}
        onDelete={handleDelete}
        dropOptions={hPayoutDropOptions}
        searchDropValue={searchDropValue}
        setSearchDropValue={setSearchDropValue}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <ListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={holidayPayouts.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              hideCheckboxColumnHeader={hideCheckboxColumn}
            />
            <TableBody>
              {filteredHolidayPayouts.length > 0 &&
                filteredHolidayPayouts?.map((row) => {
                  const {
                    id,
                    amount,
                    taxAmount,
                    administratorFee,
                    toPayAmount,
                    totalAmountRequested,
                    isSalaryCreated,
                    isActive,
                    status,
                  } = row;
                  const isItemSelected = selected.indexOf(id) !== -1;
                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                      style={{ backgroundColor: row?.isSelected ? 'lightgrey' : '', cursor: 'pointer' }}
                    >
                      {!hideCheckboxColumn && (
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                        </TableCell>
                      )}
                      <TableCell align="left">{id}</TableCell>
                      <TableCell align="left">{row?.signUpUser?.userId}</TableCell>
                      <TableCell align="left">
                        {row?.signUpUser?.firstName} {row?.signUpUser?.lastName}
                      </TableCell>
                      <TableCell align="left">{totalAmountRequested}</TableCell>
                      <TableCell align="center">{administratorFee || 0}</TableCell>
                      <TableCell align="left">{amount}</TableCell>
                      <TableCell align="left">{taxAmount}</TableCell>
                      <TableCell align="left">{toPayAmount}</TableCell>

                      <TableCell
                        align="right"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row',
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={() => {
                            if (isSalaryCreated && status === 2) {
                            } else {
                              if (isSalaryCreated) {
                                handleDialogOpen(id);
                              } else {
                                handlePayout(id);
                              }
                            }
                          }}
                          // onClick={() => handlePayout(id)}
                          style={{
                            marginRight: '10px',
                            border: isSalaryCreated && status === 2 && '1px solid green',
                            backgroundColor:
                              isSalaryCreated && status === 2 ? '' : isSalaryCreated ? '#c34152' : '#62b5e5',
                            color: isSalaryCreated && status === 2 ? 'green' : isSalaryCreated ? '#ffffff' : '#ffffff',
                          }}
                          disabled={loading}
                        >
                          {isSalaryCreated && status === 1
                            ? 'Undo'
                            : isSalaryCreated && status === 2
                            ? 'Paid'
                            : 'Payout'}
                        </Button>
                        <Tooltip title={!isActive ? 'Activate' : 'Deactivate'}>
                          <Button
                            variant="contained"
                            color={isActive ? 'primary' : 'error'}
                            onClick={() => {
                              setActivateModal({
                                id,
                                show: true,
                                isActive: isActive ? 'true' : 'false',
                              });
                            }}
                            // onClick={() => {
                            //     activateOrDeactivateQuestion(isActive, id);
                            // }}
                            style={{ width: 111 }}
                          >
                            {isActive ? 'Activated' : 'Deactivated'}
                          </Button>
                        </Tooltip>

                        <Tooltip title="View logs">
                          <Button
                            type="submit"
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/dashboard/view-logs?type=payout&id=${id}`, {
                                state: {
                                  type: 'name',
                                  name: row?.signUpUser?.firstName + ' ' + row?.signUpUser?.lastName,
                                },
                              });
                            }}
                            style={{
                              width: 50,
                              minWidth: '50px',
                              fontSize: '12px',
                              padding: '6px',
                            }}
                          >
                            <HistoryIcon />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              <Modal
                open={isopenDialog}
                onClose={handledialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <Box sx={getModalBoxStyle()}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Are you sure you want to undo this Holiday Payout?
                  </Typography>
                  <div
                    style={{ margin: '25px 0 0', float: 'right', gap: '10px', display: 'flex', alignItems: 'center' }}
                  >
                    <Button onClick={handledialogClose} sx={{ float: 'right', backgroundColor: '#62b5e514' }} autoFocus>
                      No
                    </Button>
                    <Button
                      onClick={(event) => {
                        event.stopPropagation();
                        handleUndo(selectedUndo);
                      }}
                      sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                      autoFocus
                    >
                      Yes
                    </Button>
                  </div>
                </Box>
              </Modal>
              <Dialog
                open={isOpenDialog}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this holiday payout?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDialogClose}>No</Button>
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      deleteEmployerCertificate(selectedCertificateId);
                    }}
                    autoFocus
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </TableBody>

            <Modal
              open={activateModal.show}
              onClose={() => setActivateModal({ show: false })}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={getModalBoxStyle()}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Are you sure you want to {activateModal.isActive === 'true' ? 'deactivate' : 'activate'} this holiday
                  payout?
                </Typography>
                <div style={{ margin: '25px 0 0', float: 'right', gap: '10px', display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={() => setActivateModal({ show: false })}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    No
                  </Button>
                  <Button
                    onClick={() => {
                      activateOrDeactivateQuestion(
                        activateModal.id,
                        activateModal.isActive === 'true' ? 'false' : 'true'
                      );
                    }}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    Yes
                  </Button>
                </div>
              </Box>
            </Modal>

            <Modal
              open={payoutModal.show}
              onClose={() => setPayoutModal({ show: false })}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={getModalBoxStyle()}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Are you sure you want to pay?
                </Typography>
                <div style={{ margin: '25px 0 0', float: 'right', gap: '10px', display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={() => setPayoutModal({ show: false })}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    No
                  </Button>
                  <Button
                    onClick={() => {
                      handlePayout(payoutModal.id);
                      setPayoutModal({ show: false });
                    }}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    Yes
                  </Button>
                </div>
              </Box>
            </Modal>

            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={searchQuery} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );

  return (
    <Page title="Questions">
      {loading && <Loader />}
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Holiday Payouts
          </Typography>
        </Stack>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isSnackBarOpen}
          autoHideDuration={3000}
          onClose={handleSnackBarClose}
        >
          <Alert onClose={handleSnackBarClose} severity={alertStatus} sx={{ width: '100%' }}>
            {alertMessage}
          </Alert>
        </Snackbar>
        <Card className="table-data">
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={selectedTabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={(e, newValue) => handleTabChange(e, newValue)} aria-label="lab API tabs example">
                  <Tab label="ACTIVE" value="2" />
                  <Tab label="INACTIVE" value="3" />
                  <Tab label="ALL" value="1" />
                </TabList>
              </Box>
              <TabPanel value="1">{renderQuestions()}</TabPanel>
              <TabPanel value="2">{renderQuestions()}</TabPanel>
              <TabPanel value="3">{renderQuestions()}</TabPanel>
            </TabContext>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
