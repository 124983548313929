import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import axios from 'axios';
import './components/scss/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-calendar/dist/Calendar.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { logOut } from './redux/auth/authSlice';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  (req) => {
    const user = JSON.parse(localStorage.getItem('admin'));
    if (user) {
      req.headers.Authorization = `Bearer ${user.token}`;
    }
    return req;
  },
  (err) => Promise.reject(err)
);

// ----------------------------------------------------------------------

export default function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    axios.interceptors.request.use(
      (req) => {
        let user = JSON.parse(localStorage.getItem('admin'));
        if (user) {
          if (new Date().getTime() > new Date(user.expireIn).getTime()) {
            user = null;
            localStorage.removeItem('admin');
            dispatch(logOut());
            navigate('/login');
            throw new axios.Cancel('Session Expired');
          }
          req.headers.Authorization = `Bearer ${user.token}`;
        }
        return req;
      },
      (err) => Promise.reject(err)
    );
  }, [dispatch, navigate]);

  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ThemeProvider>
  );
}
