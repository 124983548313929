/* eslint-disable no-dupe-else-if */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Calendar } from 'react-multi-date-picker';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getEndOfThirdMonth, getMonthDates, getMonthName, monthsData } from '../../../../utils/commonFunc';
import { WorkingHoursList } from './WorkingHoursList';
import { FilterTable } from './FilterTable';
import Loader from '../../../../components/Loader';
import useGetYears from '../../../../hooks/useGetYears';

export const ViewUsers = ({ setDataArray }) => {
  const currentDate = new Date();
  const getMonthDate = getMonthDates(currentDate);
  const location = useLocation();

  const { state } = location;

  const { userId } = useParams();
  const [isloading, seIstLoading] = useState(false);

  const [dateFrom, setDateFrom] = useState(getMonthDate?.formattedFirstDate);
  let endDateFormat;
  if (dateFrom !== '') {
    endDateFormat = getEndOfThirdMonth(dateFrom);
  }

  const [dateTo, setDateTo] = useState(endDateFormat);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());

  const [calendarStartDate, setCalendarStartDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [highlightedDates, setHighlightedDates] = useState([]);
  const [reservedDates, setReservedDates] = useState([]);
  const [viewDate, setViewDate] = useState([]);
  const [datesChanged, setDatesChanged] = useState(false);
  const [filterTableData, setfilterTableData] = useState([]);
  const [employeeSalary, setemployeeSalarys] = useState([]);

  const getYears = useGetYears();

  const handleChangeDate = (e, type) => {
    let newDate;
    if (type === 'month') {
      const newMonth = e.target.value;
      newDate = new Date(year, newMonth - 1);
      setMonth(newMonth);
    } else {
      const newYear = e.target.value;
      const getMonthSplit = dateFrom?.split('-')[1];
      const month = parseInt(getMonthSplit, 10);
      newDate = new Date(newYear, month - 1);
      setYear(newYear);
    }
    setSelectedDate(newDate);
    setCalendarStartDate(newDate);
    postDatesData(newDate);
  };

  const getData = async () => {
    seIstLoading(true);
    try {
      let URL = `/admin/adminsalary/all/hours?UserId=${userId}`;
      // if (dateFrom && dateTo === undefined) {
      // URL += `&Month=${dateFrom}`;
      // }
      if (dateFrom && dateTo) {
        URL += `&fromDate=${dateFrom}&toDate=${dateTo}`;
      }
      const result = await axios.get(URL);
      if (result?.status === 200) {
        seIstLoading(false);
        const response = result?.data?.response;
        setReservedDates(response?.workingHours);
        setemployeeSalarys(response?.employeeSalarys);
        const dates = response?.workingHours.map((item) => new Date(item?.workingDate));
        setHighlightedDates(dates);
      }
    } catch (err) {
      seIstLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (dateFrom && dateTo) getData();
  }, [dateFrom, dateTo]);

  const postDatesData = async (newDate) => {
    seIstLoading(true);
    const currentMonth = newDate ? newDate.getMonth() + 1 : month;
    const currentYear = newDate ? newDate.getFullYear() : year;
    const firstDateOfMonth = new Date(currentYear, currentMonth - 1, 1);
    firstDateOfMonth.setDate(firstDateOfMonth.getDate() + 1);
    const formattedFirstDate = firstDateOfMonth.toISOString().split('T')[0];
    setDateFrom(formattedFirstDate);

    const threeMonthsLater = new Date(currentYear, currentMonth + 2, 1); // Start of the month 3 months later
    threeMonthsLater.setDate(1); // Set to the last day of the previous month
    const formattedLastDate = threeMonthsLater.toISOString().split('T')[0];
    setDateTo(formattedLastDate);

    try {
      let URL = `/admin/adminsalary/all/hours?UserId=${userId}`;
      // if (formattedFirstDate) {
      // URL += `&Month=${formattedFirstDate}`;
      // }
      if (formattedFirstDate && dateTo) {
        URL += `&fromDate=${formattedFirstDate}&toDate=${dateTo}`;
      }
      const result = await axios.get(URL);
      if (result?.status === 200) {
        seIstLoading(false);
        const response = result?.data?.response;
        setReservedDates(response?.workingHours);
        setemployeeSalarys(response?.employeeSalarys);
        const dates = response?.workingHours?.map((item) => new Date(item?.workingDate));
        setHighlightedDates(dates);
      }
    } catch (err) {
      seIstLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (month && year) postDatesData();
  }, []);

  const tileClassName = (date) => {
    const jsDateUpdate = date.toDate();
    const isSameDate = (d1, d2) =>
      d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();
    if (highlightedDates?.length) {
      if (highlightedDates.some((d) => isSameDate(d, jsDateUpdate))) {
        return 'highlighted';
      }
    }
    if (isSameDate(jsDateUpdate, currentDate)) {
      return 'current-date';
    }
    if (isSameDate(jsDateUpdate, selectedDate)) {
      return 'selected-date';
    }
    return null;
  };

  const normalizeDate = (date) => {
    const normalized = new Date(date);
    normalized.setHours(0, 0, 0, 0);
    return normalized;
  };

  const onChange = (date) => {
    const checkDate = normalizeDate(date.toDate());
    setDatesChanged(true);
    setSelectedDate(checkDate);
    const newMonth = checkDate.getMonth() + 1;
    const newYear = checkDate.getFullYear();
    setMonth(newMonth);
    setYear(newYear);
    setCalendarStartDate(checkDate);
    // postDatesData(checkDate);
    const filteredDates = reservedDates?.filter((item) => {
      const itemDate = normalizeDate(new Date(item?.workingDate));
      return itemDate.getTime() === checkDate.getTime();
    });
    setViewDate(filteredDates);
  };

  useEffect(() => {
    setfilterTableData(reservedDates);
    setDataArray(reservedDates);
  }, [reservedDates, month, selectedDate]);

  const handleActiveStartDateChange = ({ activeStartDate }) => {
    const newMonth = activeStartDate.getMonth() + 1;
    const newYear = activeStartDate.getFullYear();
    setMonth(newMonth);
    setYear(newYear);
    setCalendarStartDate(activeStartDate);
    postDatesData(activeStartDate);
  };

  const handleDateFromChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const formattedDate = selectedDate.toISOString().split('T')[0];
    if (dateTo && selectedDate > new Date(dateTo)) {
      toast.error('From date cannot be greater than to date');
      return;
    }
    setDateFrom(formattedDate);
    setMonth(selectedDate.getMonth() + 1);
    setYear(selectedDate.getFullYear());
    setSelectedDate(selectedDate);
    setCalendarStartDate(selectedDate);
    const endDateFormat = getEndOfThirdMonth(formattedDate);
    setDateTo(endDateFormat);
    setMonth('');
  };

  const handleDateToChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const formattedDate = selectedDate.toISOString().split('T')[0];
    if (dateFrom && selectedDate < new Date(dateFrom)) {
      toast.error('To date cannot be less than from date');
      return;
    }
    setDateTo(formattedDate);
    setMonth('');
  };

  const [TotalsByMonth, setTotalsByMonth] = useState([]);

  useEffect(() => {
    const filterDataByDateRange = (data, from, to, key, dateKey) => {
      const result = {};
      const fromDate = new Date(from);
      const toDate = new Date(to);
      fromDate.setHours(0, 0, 0, 0);
      toDate.setHours(23, 59, 59, 999);

      data.forEach((item) => {
        const itemDate = new Date(item[dateKey]);
        itemDate.setHours(0, 0, 0, 0);
        if (itemDate >= fromDate && itemDate <= toDate) {
          const month = itemDate.getMonth() + 1;
          const year = itemDate.getFullYear();
          const monthYear = `${year}-${month.toString().padStart(2, '0')}`;
          if (!result[monthYear]) {
            result[monthYear] = { totalHours: 0, totalAmount: 0 };
          }
          if (key === 'number') {
            result[monthYear].totalHours += item.number || 0;
          } else if (key === 'amount') {
            result[monthYear].totalAmount += item.amount || 0;
          }
        }
      });
      return result;
    };
    if (dateFrom && dateTo) {
      const hoursData = filterDataByDateRange(reservedDates, dateFrom, dateTo, 'number', 'workingDate');
      const amountData = filterDataByDateRange(employeeSalary, dateFrom, dateTo, 'amount', 'paidDate');
      const totalsByMonth = Object.entries(hoursData).map(([monthYear, totals]) => ({
        monthYear,
        totalHours: totals.totalHours,
        totalAmount: amountData[monthYear]?.totalAmount || 0,
      }));
      setTotalsByMonth(totalsByMonth);
    } else if (month && year) {
      const singleMonthFrom = new Date(year, month - 1, 1);
      const singleMonthTo = new Date(year, month, 0);

      const hoursData = filterDataByDateRange(reservedDates, singleMonthFrom, singleMonthTo, 'number', 'workingDate');
      const amountData = filterDataByDateRange(employeeSalary, singleMonthFrom, singleMonthTo, 'amount', 'paidDate');

      const totalsByMonth = Object.entries(hoursData).map(([monthYear, totals]) => ({
        monthYear,
        totalHours: totals.totalHours,
        totalAmount: amountData[monthYear]?.totalAmount || 0,
      }));

      setTotalsByMonth(totalsByMonth);
    }
  }, [reservedDates, employeeSalary, dateFrom, dateTo, month, year]);

  return (
    <>
      {isloading && <Loader />}
      <div id="calendar_details">
        <div className="d-flex">
          <div className="mt-3 ml-2">
            <h6>
              <span className="font-weight-bold"> Username: </span>
              <span className="mt-2">{state?.firstName}</span>
              <>
                <span className="font-weight-bold ml-4">{state?.ssn ? ' SSN No.' : 'DOB'}: </span>{' '}
                <span> {state?.ssn || state?.dateOfBirth}</span>
              </>
            </h6>

            <div>
              <h6>
                <span className="font-weight-bold"> UserId: </span>
                <span>{state?.userId}</span>
              </h6>
            </div>
          </div>
          <div className="ml-auto mr-2 mt-2">
            <div>
              <FormControl sx={{ minWidth: 120 }} size="small" style={{ marginTop: '7px' }}>
                <InputLabel id="demo-select-small-label">Year</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={year}
                  onChange={(e) => handleChangeDate(e, 'year')}
                >
                  {getYears &&
                    getYears?.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <div className="d-flex mt-4 ml-2">
          <div>
            <h6>Show pay period</h6>
            <div>
              <FormControl sx={{ minWidth: 120 }} size="small" style={{ marginTop: '7px' }}>
                <InputLabel id="demo-select-small-label">Months</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={month}
                  label="Months"
                  onChange={(e) => handleChangeDate(e, 'month')}
                >
                  <option disabled value="">
                    {'Select month'}
                  </option>
                  {monthsData?.map((item) => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="mx-5">
            <h6>Show salary range</h6>
            <div style={{ marginTop: '14px' }}>
              <input
                type="date"
                value={dateFrom}
                onChange={handleDateFromChange}
                // max={new Date().toISOString().split('T')[0]}
                min={'2023-01-01'}
              />
              <input
                className="mx-3"
                type="date"
                value={dateTo}
                onChange={handleDateToChange}
                // max={new Date().toISOString().split('T')[0]}
                min={'2023-01-01'}
              />
            </div>
          </div>
        </div>

        <h6 className="pl-2 mt-4">
          <span className="font-weight-bold"> Click any date to view calendar summary </span>
        </h6>
        <div className="calendar_wrap">
          <div style={{ maxHeight: '200' }}>
            <Calendar
              onChange={onChange}
              value={selectedDate}
              defaultView="month"
              activeStartDate={calendarStartDate}
              onActiveStartDateChange={handleActiveStartDateChange}
              // maxDate={currentDate}
              minDate={new Date(2023, 0, 1)}
              numberOfMonths={3}
              mapDays={({ date }) => {
                const className = tileClassName(date);
                return {
                  className,
                };
              }}
            />
          </div>

          <div className="total_amount_wrap">
            {TotalsByMonth.map(({ monthYear, totalHours, totalAmount }) => (
              <div key={monthYear} className="ml-3 mb-2 total_amount">
                <h6>
                  <span className="font-weight-bold">Total hours in {getMonthName(monthYear)}: </span>
                  <span className="mt-2">{totalHours || 0}</span>
                </h6>
                <div>
                  <h6>
                    <span className="font-weight-bold">Total Amount in {getMonthName(monthYear)}: </span>
                    <span>{totalAmount?.toFixed(2) || 0}</span>
                  </h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {datesChanged && (
        <div className="mt-4">
          <WorkingHoursList viewDate={viewDate} datesChanged={datesChanged} setDatesChanged={setDatesChanged} />
        </div>
      )}

      <FilterTable filterData={filterTableData} />
    </>
  );
};
