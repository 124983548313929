import { Alert, LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import { Stack } from 'react-bootstrap';
import * as Yup from 'yup';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Loader from '../../../components/Loader';
import { RHFTextField, FormProvider } from '../../../components/hook-form';

const ForgotForm = () => {
  const [isLoading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const defaultValues = {
    email: '',
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const res = await axios.get(`admin/user/admin-forgot-password?email=${values.email}`);
      if (res.data.statusCode === 200) {
        setAlert(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {isLoading && <Loader />}
      <Stack spacing={3}>
        <RHFTextField name="email" label="Email address" sx={{ my: 2 }} />
      </Stack>
      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Send
      </LoadingButton>
      {alert && (
        <Alert onClose={() => setAlert(false)} sx={{ my: 2 }}>
          We have sent you an email with instructions on how to reset your password.
        </Alert>
      )}
    </FormProvider>
  );
};

export default ForgotForm;
