import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import globalDataReducer from './globalData/globalDataSlice';
import userDataReducer from './globalData/userSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    globalData: globalDataReducer,
    createdInvoiceUserData: userDataReducer,
  },
});

export default store;
