import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

// layouts
import DashboardLayout from './layouts/dashboard/index';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
// import Users from './pages/dashboard/Users';
import Notifications from './pages/dashboard/Notifications';

import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/dashboard/DashboardApp';
import Customers from './pages/dashboard/customers/Customers';
// import Support from './pages/dashboard/Support';
import Profile from './pages/dashboard/Profile';
import ForgotPassword from './pages/ForgotPassword';
import EmployerCertificate from './pages/dashboard/EmployerCertificate';
import HolidayPayout from './pages/dashboard/HolidayPayout';
import EmailTemplates from './pages/dashboard/children/EmailTemplates';
import InvoiceTool from './pages/dashboard/invoice-tool/InvoiceTool';
import Users from './pages/dashboard/users/Users';
import SocialUsers from './pages/dashboard/SocialUsers';
import UserInvoices from './pages/dashboard/UserInvoices';
import Salaries from './pages/dashboard/salaries/Salaries';
import { ViewHolidayPay } from './pages/dashboard/salaries/holiday-pay/ViewHolidayPay';
import { ViewUsers } from './pages/dashboard/salaries/calendar/ViewUsers';
import ViewSalaryTab from './pages/dashboard/salaries/salary/ViewSalaryTab';
import HolidaySavings from './pages/dashboard/holiday-savings/HolidaySavings';
import PensionSavings from './pages/dashboard/pension-savings/PensionSavings';
import HolidayDetails from './pages/dashboard/holiday-savings/HolidayDetail';
import PensionDetails from './pages/dashboard/pension-savings/PensionDetail';
import Invoices from './pages/dashboard/invoices/Invoices';
import LogsUI from './pages/dashboard/LogsUI';
// import AmbassadorUsers from './pages/dashboard/AmbassadorUsers';

// ----------------------------------------------------------------------

export default function Router() {
  const location = useLocation();
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    if (!location?.pathname?.includes('dashboard/holiday-savings')) {
      localStorage.removeItem('holidayId');
    }
    if (!location?.pathname?.includes('dashboard/pension-savings')) {
      localStorage.removeItem('pensionId');
    }
    if (!location?.pathname?.includes('dashboard/users')) {
      localStorage.removeItem('selected_invoice');
    }
  }, [location]);

  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout pageSize={pageSize} pageNumber={pageNumber} />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'users', element: <Users /> },
        { path: 'customers', element: <Customers /> },
        { path: 'user/invoices/:userId', element: <UserInvoices /> },
        // { path: 'support', element: <Support /> },
        { path: 'invoices', element: <Invoices /> },
        {
          path: 'salaries',
          element: <Salaries />,
          children: [
            // { path: 'view-user/:userId', element: <ViewUsers /> },
            { path: 'holiday-pay/:holidayPayId', element: <ViewHolidayPay /> },
            { path: 'salary/:salaryId', element: <ViewSalaryTab /> },
          ],
        },
        { path: 'holiday-savings', element: <HolidaySavings /> },
        { path: 'holiday-savings/:holidayId', element: <HolidayDetails /> },
        { path: 'pension-savings/:pensionId', element: <PensionDetails /> },

        {
          path: 'employer-certificate',
          element: <EmployerCertificate />,
          children: [{ path: 'view-user/:userId', element: <ViewUsers /> }],
        },

        { path: 'pension-savings', element: <PensionSavings /> },
        { path: 'notifications', element: <Notifications PageSize={setPageSize} PageNumber={setPageNumber} /> },
        { path: 'profile', element: <Profile /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'holiday-pay', element: <HolidayPayout /> },
        { path: 'email-templates', element: <EmailTemplates /> },
        { path: 'invoice-tool/:id', element: <InvoiceTool /> },
        { path: 'social-users', element: <SocialUsers /> },
        // { path: 'ambassador-users', element: <AmbassadorUsers /> },
        { path: 'view-logs', element: <LogsUI /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />,
    },
  ]);
}
