// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'users',
    path: '/dashboard/users',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'customers',
    path: '/dashboard/customers',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'invoices',
    path: '/dashboard/invoices',
    icon: getIcon('uil:invoice'),
  },
  {
    title: 'Salaries',
    path: '/dashboard/salaries',
    icon: getIcon('fa-solid:money-bill-alt'),
  },
  {
    title: 'Holiday Savings',
    path: '/dashboard/holiday-savings',
    icon: getIcon('streamline:give-gift'),
  },
  {
    title: 'Pension Savings',
    path: '/dashboard/pension-savings',
    icon: getIcon('fluent:person-money-24-regular'),
  },
  {
    title: 'Notifications',
    path: '/dashboard/notifications',
    onClick: true,
    icon: getIcon('akar-icons:chat-dots'),
  },
  {
    title: 'Employer Certificate',
    path: '/dashboard/employer-certificate',
    icon: getIcon('mdi:certificate'),
  },
  {
    title: 'Holiday Payout',
    path: '/dashboard/holiday-pay',
    icon: getIcon('cib:samsung-pay'),
  },
  {
    title: 'Social Users',
    path: '/dashboard/social-users',
    icon: getIcon('streamline:interface-favorite-award-ribbon-reward-like-social-rating-media'),
  },
  // {
  //   title: 'Ambassador Users',
  //   path: '/dashboard/ambassador-users',
  //   icon: getIcon('emojione-monotone:handshake'),
  // },
  {
    title: 'Setting',
    children: [
      {
        title: 'Email Templates',
        path: '/dashboard/email-templates',
        icon: getIcon('material-symbols:attach-email-outline-rounded'),
      },
    ],
    icon: getIcon('ant-design:setting-twotone'),
  },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon('eva:shopping-bag-fill'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
