/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-duplicates */
/* eslint-disable arrow-body-style */
/* eslint-disable no-dupe-else-if */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableContainer, TablePagination, Checkbox, Container, IconButton, Select } from '@mui/material';
import { Table, TableRow, TableBody, TableCell, Box, Tab, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import Paper from '@mui/material/Paper';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { hSavingsDropOptions } from '../../../utils/commonFunc';
import { ListHead, ListToolbar } from '../../../sections/@dashboard/table';
import { PayoutFilter } from '../../../components/DateFilter';
import Loader from '../../../components/Loader';
import Page from '../../../components/Page';

import Iconify from '../../../components/Iconify';
import DownloadPdf from './DownloadPdf';
import HolidayPayoutSavings from './holidayPayoutSavings';

const TABLE_HEAD = [
  { id: 'userId', label: 'Employee Id', alignRight: false },
  { id: 'userName', label: 'Employee', alignRight: false },
  { id: 'invoiceId', label: 'Email', alignRight: false },
  { id: 'ssn', label: 'SSN/DOB', alignRight: false },
  { id: 'balance', label: 'Balance', alignRight: false },
  { id: 'totalHolidayAmount', label: 'Amount', alignRight: false },
];
const HolidayPaoutTable = [
  {
    value: '1',
    tab: 2,
  },
  {
    value: '2',
    tab: 3,
  },
  {
    value: '3',
    tab: false,
  },
];

const HolidaySavings = () => {
  const getSelectedItem = localStorage.getItem('holidayId');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [holidaySavings, setHolidaySavings] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdDate');

  const [searchQuery, setSearchQuery] = useState(getSelectedItem !== null ? getSelectedItem : '');
  const [tabs, settabs] = useState('1');
  const [selectedRow, setSelectedRow] = useState([]);
  const [isAllSelected, setAllSelected] = useState(false);
  const [downloadSelectedRow, setdownloadSelectedRow] = useState([]);
  const [toggleDownload, settoggleDownload] = useState(false);
  const [SelectedFileType, setSelectedFileType] = useState('');

  const handleChange = (event, newValue) => {
    settabs(newValue);
  };
  const [getDate, setDate] = useState({
    from: '',
    to: '',
  });

  const [filteredMonth, setFilteredMonth] = useState('');
  const [filteredYear, setFilteredYear] = useState('');
  const [searchDropValue, setSearchDropValue] = useState();

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const filteredRows = applySortFilter(
      holidaySavings,
      getComparator(isAsc ? 'desc' : 'asc', orderBy),
      event.target.value,
      holidaySavings
    );
    setHolidaySavings(filteredRows);
  };

  const handleFilter = (event) => {
    setSearchQuery(event.target.value);
  };

  const getHolidayId = (event, id) => {
    if (id) {
      if (!event.target.closest('input[type="checkbox"]')) {
        localStorage.setItem('holidayId', id);
        navigate(`/dashboard/holiday-savings/${id}`);
      }
    }
  };

  // const handleUserClick = (item) => {
  //   if (item?.userId) {
  //     navigate(`/dashboard/salaries/view-user/${item?.userId}`, { state: item });
  //   }
  // };

  const getHolidaySavings = async () => {
    try {
      let URL;
      if (searchDropValue && searchQuery) {
        URL = `/admin/adminsalary/total-holidaypay-amount?PageNumber=${
          page + 1
        }&PageSize=${rowsPerPage}&searchQuery=${searchQuery}&SearchColumn=${searchDropValue}`;
      } else {
        URL = `/admin/adminsalary/total-holidaypay-amount?PageNumber=${
          page + 1
        }&PageSize=${rowsPerPage}&searchQuery=${searchQuery}`;
      }

      if (getDate?.from && getDate?.to) {
        URL += `&fromDate=${getDate?.from}&toDate=${getDate?.to}`;
      } else if (!filteredMonth && filteredYear) {
        URL += `&year=${filteredYear}-01-01`;
      } else if (filteredMonth && filteredYear) {
        const firstDateOfMonth = new Date(filteredYear, filteredMonth - 1, 1);
        const finalMonth = firstDateOfMonth.toISOString()?.split('T')[0];
        URL += `&Month=${finalMonth}`;
      }
      setLoading(true);
      const result = await axios.get(URL);
      if (result.status === 200) {
        setLoading(false);
        setTotalCount(result.data.totalCount);
        selectAllClick(isAllSelected, result?.data.response);
        setHolidaySavings(result.data.response);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  const handleChangeDate = (e, type) => {
    if (type === 'month' && filteredYear === '') {
      return toast.error('Please select year before choosing month');
    }
    if (type === 'month') {
      setFilteredMonth(e.target.value);
      setDate({ from: '', to: '' });
      setSearchQuery('');
    } else {
      setFilteredYear(e.target.value);
      setFilteredMonth('');
      setDate({ from: '', to: '' });
      setSearchQuery('');
    }
  };

  // Selected Row Start
  const selectAllClick = (isSelected, filteredRows) => {
    if (isSelected) {
      const newSelecteds = filteredRows?.map((n) => n?.users?.userId);
      setSelectedRow(newSelecteds);
      return;
    }
    setSelectedRow([]);
  };

  useEffect(() => {
    if (selectedRow) {
      const filteredData = holidaySavings.filter((item) => selectedRow?.includes(item?.users?.userId));
      setdownloadSelectedRow(filteredData);
    }
  }, [selectedRow]);

  const handleAllSelectRow = (event) => {
    setAllSelected(event.target.checked);
    selectAllClick(event.target.checked, holidaySavings);
  };

  const handleSingalSelection = (event, id) => {
    const selectedIndex = selectedRow.indexOf(id);
    let newSelectedRow = [];
    if (selectedIndex === -1) {
      newSelectedRow = newSelectedRow.concat(selectedRow, id);
    } else if (selectedIndex === 0) {
      newSelectedRow = newSelectedRow.concat(selectedRow.slice(1));
    } else if (selectedIndex === selectedRow.length - 1) {
      newSelectedRow = newSelectedRow.concat(selectedRow.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRow = newSelectedRow.concat(selectedRow.slice(0, selectedIndex), selectedRow.slice(selectedIndex + 1));
    }
    setSelectedRow(newSelectedRow);
    setAllSelected(newSelectedRow.length === holidaySavings.length);
  };

  // Generate XML File Start
  const handleClearData = () => {
    localStorage.removeItem('holidayId');
    setAllSelected(false);
    setSelectedRow([]);
    setFilteredMonth('');
    setFilteredYear('');
    setDate({ from: '', to: '' });
    setSearchQuery('');
  };

  // Effects
  useEffect(() => {
    if (tabs === '1') getHolidaySavings();
  }, [searchQuery, filteredMonth, filteredYear, getDate, page, rowsPerPage, tabs]);

  // Filter Functionality for Tab 2
  const [filterMonthTab2, setFilterMonthTab2] = useState('');
  const [filterYearTab2, setFilterYearTab2] = useState('');
  const [getDateTab2, setgetDateTab2] = useState({
    from: '',
    to: '',
  });

  const handleChangeDateTab2 = (e, type) => {
    if (type === 'month' && filterYearTab2 === '') {
      return toast.error('Please select year before choosing month');
    }
    if (type === 'month') {
      setFilterMonthTab2(e.target.value);
      setgetDateTab2({ from: '', to: '' });
      setSearchQuery('');
    } else {
      setFilterYearTab2(e.target.value);
      setgetDateTab2({ from: '', to: '' });
      setSearchQuery('');
    }
  };

  const handleClearDataTab2 = () => {
    setFilterMonthTab2('');
    setFilterYearTab2('');
    setgetDateTab2({ from: '', to: '' });
    setSearchQuery('');
  };

  const handleSelect = (e) => {
    const value = e.target.value;
    setSelectedFileType(value);
    if (value) {
      settoggleDownload(true);
    } else {
      settoggleDownload(false);
    }
  };

  return (
    <Page title={tabs === '1' ? 'Holiday Savings' : 'Holiday Pay'}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Stack direction="row" justifyContent="space-between">
            <IconButton onClick={() => navigate(-1)}>
              <Iconify icon="eva:arrow-back-fill" width={24} height={24} />
            </IconButton>
            <Typography variant="h4" sx={{ ml: 1 }} gutterBottom>
              Holiday Savings
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            {selectedRow && selectedRow?.length > 0 && (
              <Typography component="div" variant="subtitle1">
                {selectedRow?.length} Selected
                <select className="select_filetype" onChange={handleSelect} value={SelectedFileType}>
                  <option disabled value="">
                    Select file type
                  </option>
                  <option value="XLS">Excel</option>
                  <option value="PDF">Pdf</option>
                </select>
              </Typography>
            )}
          </Stack>
        </Stack>

        <TabContext value={tabs}>
          {loading && <Loader />}

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Holiday Savings" value="1" />
              <Tab label="Holiday Pay" value="2" />
            </TabList>
          </Box>

          <TabPanel value="1" sx={{ padding: 0, margin: '10px 0 0 20px' }}>
            <TableContainer component={Paper}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <div className="d-flex holiday_filter">
                  <div className="w-fit fitwidth">
                    <ListToolbar
                      searchQuery={searchQuery}
                      onFilter={handleFilter}
                      dropOptions={hSavingsDropOptions}
                      searchDropValue={searchDropValue}
                      setSearchDropValue={setSearchDropValue}
                    />
                    <PayoutFilter
                      getDate={getDate}
                      setDate={setDate}
                      filteredMonth={filteredMonth}
                      setFilteredMonth={setFilteredMonth}
                      filteredYear={filteredYear}
                      setFilteredYear={setFilteredYear}
                      handleChangeDate={handleChangeDate}
                      handleClearData={handleClearData}
                      searchQuery={searchQuery}
                      setSearchQuery={setSearchQuery}
                    />
                  </div>
                </div>
              </Box>

              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleAllSelectRow}
                  hideCheckboxColumnHeader="true"
                  hideForOthers
                  isAllSelected={isAllSelected}
                />
                <TableBody>
                  {holidaySavings?.length ? (
                    holidaySavings?.map((item) => {
                      const isItemSelected = selectedRow?.indexOf(item?.users?.userId) !== -1;
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          onClick={(event) => getHolidayId(event, item?.users?.userId)}
                          key={item?.users?.userId}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleSingalSelection(event, item?.users?.userId)}
                            />
                          </TableCell>
                          <TableCell>{item?.users?.userId}</TableCell>
                          <TableCell>
                            {item?.users?.firstName} {item?.users?.lastName}
                          </TableCell>
                          <TableCell>{item?.users?.email}</TableCell>
                          <TableCell>{item?.users?.ssn || item?.users?.dateOfBirth} </TableCell>
                          <TableCell>{item?.balance?.toFixed(2)} </TableCell>
                          <TableCell>{item?.totalHolidayAmount?.toFixed(2)} </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow className="text-center">
                      <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                        No record found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[50, 250, 500]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TabPanel>

          <TabPanel value="2" sx={{ padding: 0, margin: '10px 0 0 20px' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <div className="d-flex holiday_filter">
                <div className="w-25">
                  <ListToolbar searchQuery={searchQuery} onFilter={handleFilter} />
                </div>
                <div className="w-75">
                  <PayoutFilter
                    getDate={getDateTab2}
                    setDate={setgetDateTab2}
                    filteredMonth={filterMonthTab2}
                    setFilteredMonth={setFilterMonthTab2}
                    filteredYear={filterYearTab2}
                    setFilteredYear={setFilterYearTab2}
                    handleChangeDate={handleChangeDateTab2}
                    handleClearData={handleClearDataTab2}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                  />
                </div>
              </div>
              {HolidayPaoutTable?.map((item) => (
                <TabPanel value={item?.value} sx={{ padding: 0 }} key={item?.value}>
                  <HolidayPayoutSavings
                    tab={tabs}
                    setLoading={setLoading}
                    searchQuery={searchQuery}
                    selectedTabValue={tabs}
                    datesData={getDateTab2}
                    selectedMonth={filterMonthTab2}
                    selectedYear={filterYearTab2}
                  />
                </TabPanel>
              ))}
            </Box>
          </TabPanel>
        </TabContext>
      </Container>

      <DownloadPdf
        downloadSelectedRow={downloadSelectedRow}
        toggleDownload={toggleDownload}
        settoggleDownload={settoggleDownload}
        filteredYear={filteredYear}
        filteredMonth={filteredMonth}
        getDate={getDate}
        SelectedFileType={SelectedFileType}
        setSelectedFileType={setSelectedFileType}
      />
    </Page>
  );
};

export default HolidaySavings;
