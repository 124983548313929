import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useLocation } from 'react-router-dom';

export const SearchType = ({ dropOptions, searchDropValue, setSearchDropValue }) => {
  const location = useLocation();
  const pathName = location.pathname;
  return (
    <div
      style={{
        marginRight:
          pathName === '/dashboard/holiday-savings' || pathName === '/dashboard/pension-savings' ? '12px' : 'auto',
        marginLeft: pathName === '/dashboard/holiday-savings' || pathName === '/dashboard/pension-savings' ? '12px' : "15px",
        border: "none"
      }}
      className="typeright"
    >
      <FormControl sx={{ minWidth: 110 }} size="small" style={{ marginTop: '7px' }}>
        <InputLabel id="demo-select-small-label">Type</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={searchDropValue}
          label="Months"
          onChange={(e) => setSearchDropValue(e.target.value)}
          style={{ fontSize: '14px' }}
        >
          {dropOptions?.map((item) => (
            <MenuItem key={item?.id} value={item?.id}>
              {item?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
