import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// material
import {
  Stack,
  Container,
  Typography,
  Avatar,
  Button,
  Card,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Loader from '../../components/Loader';
import account from '../../_mock/account';
import { getUser, updateUser } from '../../redux/auth/authSlice';
import { FormProvider, RHFTextField } from '../../components/hook-form';

export default function Profile() {
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const { user, isLoading } = useSelector((state) => state.auth);

  const AnswerSchema = Yup.object().shape({
    // answer: Yup.string().required('Answer is required'),
  });

  let defaultValues;

  if (user) {
    defaultValues = {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      address: user?.address,
      city: user?.city,
      zipCode: user?.zipCode,
      organisation: user?.organisation,
      country: user?.country,
      phoneNo: user?.phoneNo,
      ssn: user?.ssn,
    };
  }


  const methods = useForm({
    resolver: yupResolver(AnswerSchema),
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    if (user) reset();
  }, [user, reset]);

  const onSubmit = async (values) => {
    dispatch(updateUser(values));
    setEdit(false);
  };

  return (
    <Page title="Profile">
      {isLoading && <Loader />}
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Profile
          </Typography>
          <Button variant="outlined" sx={{ ml: 'auto' }} onClick={() => setEdit(true)}>
            Edit
          </Button>
        </Stack>

        <Grid container justifyContent="center">
          <Grid item xs={6}>
            {
              user &&
              <Card sx={{ p: 3 }}>
                <Avatar src={account.photoURL} alt="photoURL" sx={{ width: 86, height: 86, m: 'auto', mb: 3 }} />
                <Stack direction="row" alignItems="center" justifyContent="space-between" mx={4} mb={2}>
                  <Typography variant="subtitle1">First Name:</Typography>
                  <Typography variant="body1">{user.firstName}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mx={4} mb={2}>
                  <Typography variant="subtitle1">Last Name:</Typography>
                  <Typography variant="body1"> {user.lastName}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mx={4} mb={2}>
                  <Typography variant="subtitle1">Email:</Typography>
                  <Typography variant="body1">{user.email}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mx={4} mb={2}>
                  <Typography variant="subtitle1">Address:</Typography>
                  <Typography variant="body1"> {user.address}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mx={4} mb={2}>
                  <Typography variant="subtitle1">City:</Typography>
                  <Typography variant="body1">{user.city}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mx={4} mb={2}>
                  <Typography variant="subtitle1">Postal Code:</Typography>
                  <Typography variant="body1">{user.zipCode}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mx={4} mb={2}>
                  <Typography variant="subtitle1">C/O:</Typography>
                  <Typography variant="body1">{user.organisation}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mx={4} mb={2}>
                  <Typography variant="subtitle1">Country:</Typography>
                  <Typography variant="body1">{user.country}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mx={4} mb={2}>
                  <Typography variant="subtitle1">Phone Number:</Typography>
                  <Typography variant="body1">{user.phoneNo}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mx={4} mb={2}>
                  <Typography variant="subtitle1">Social Security Number:</Typography>
                  <Typography variant="body1">{user.ssn}</Typography>
                </Stack>
              </Card>
            }

          </Grid>
        </Grid>
      </Container>
      <Dialog
        open={edit}
        onClose={() => {
          setEdit(false);
          reset();
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Profile</DialogTitle>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <RHFTextField name="firstName" label="First Name" />
            <RHFTextField name="lastName" label="Last Name" sx={{ mt: 2 }} />
            <RHFTextField name="email" label="Email" sx={{ mt: 2 }} />
            <RHFTextField name="address" label="Address" sx={{ mt: 2 }} />
            <RHFTextField name="city" label="City" sx={{ mt: 2 }} />
            <RHFTextField name="zipCode" label="Postal Code" sx={{ mt: 2 }} />
            <RHFTextField name="organisation" label="C/O" sx={{ mt: 2 }} />
            <RHFTextField name="country" label="Country" sx={{ mt: 2 }} />
            <RHFTextField name="phoneNo" label="Phone Number" sx={{ mt: 2 }} />
            <RHFTextField name="ssn" label="Social Security Number" sx={{ mt: 2 }} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setEdit(false);
                reset();
              }}
              color="error"
            >
              Cancel
            </Button>
            <Button type="submit">Submit</Button>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </Page>
  );
}
