/* eslint-disable */
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Link } from '@mui/material';
// components
import { monthsOfYear } from '../../utils/commonFunc';
import Page from '../../components/Page';
// sections
import { AppCurrentVisits, AppWidgetSummary } from '../../sections/@dashboard/app';
import Loader from '../../components/Loader';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [loading, setLoading] = useState(false);
  const [allTypeSalaries, setAllTypeSalaries] = useState({});
  const [questions, setQuestions] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const allTypeSalaries = await axios.get('/admin/dashboard');
        const adminqa = await axios.get('/admin/adminqa');
        const res = await Promise.all([allTypeSalaries, adminqa]);
        if (allTypeSalaries?.status === 200) {
          setLoading(false);
          setAllTypeSalaries(res[0]?.data?.response);
          setQuestions(res[1].data.response);
        }
      } catch (err) {
        if (err?.response?.data?.message === 'Something went wrong please try again after sometime.') {
          fetchData();
          setLoading(true);
        } else {
          setLoading(false);
        }
        console.log(err);
      }
      // finally {
      //   setLoading(false);
      // }
    };
    fetchData();
  }, []);

  const currentMonth = new Date();
  const currentActiveMonth = monthsOfYear[currentMonth.getMonth()]?.label;

  return (
    <Page title="Dashboard">
      {loading && <Loader />}
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
          <Link sx={{ textDecoration: 'none' }} component={RouterLink} to="/dashboard/users">
              <AppWidgetSummary title="Active Users" total={allTypeSalaries?.activeUser} icon={'bxs:user'} />
            </Link>
          
          </Grid>

          <Grid item xs={12} sm={4}>
          <Link sx={{ textDecoration: 'none' }} component={RouterLink} to="/dashboard/users">
              <AppWidgetSummary
                title={`New Users: ${currentActiveMonth}`}
                total={allTypeSalaries?.totalCurrentMonthUsers}
                icon={'bxs:user'}
              />
            </Link>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Link sx={{ textDecoration: 'none' }} component={RouterLink} to="/dashboard/invoices">
              <AppWidgetSummary
                title="Unpaid Invoices"
                total={allTypeSalaries?.totalUnPaidInvoice}
                icon={'fa6-solid:file-invoice-dollar'}
              />
            </Link>
          </Grid>
          <Grid item xs={12} sm={4}>
          
          </Grid>

          <Grid item xs={12} sm={4}>
            <Link sx={{ textDecoration: 'none' }} component={RouterLink} to="/dashboard/invoices">
              <AppWidgetSummary
                title={`Invoices Amount In ${currentMonth.toDateString().split(' ')[1]}`}
                total={
                  allTypeSalaries?.currentMonthInvoiceAmount && allTypeSalaries?.currentMonthInvoiceAmount.toFixed()
                }
                icon={'fa6-solid:money-bill-1'}
                currentMonth="true"
              />
            </Link>
          </Grid>

          {(allTypeSalaries?.activeUser ||
            allTypeSalaries?.totalUnPaidInvoice ||
            allTypeSalaries?.totalEmployeeSalary) && (
            <Grid item xs={12} md={12} lg={12}>
              <AppCurrentVisits
                title="Current Visits"
                chartData={[
                  { label: 'users', value: Number(allTypeSalaries?.activeUser) },
                  { label: 'invoices', value: allTypeSalaries?.totalUnPaidInvoice },
                  { label: 'Total Employee Salary', value: allTypeSalaries?.totalEmployeeSalary },
                  // { label: 'Total Employee Salary', value: Number(JSON.stringify(allTypeSalaries.totalEmployeeSalary || 0)?.substring(0, JSON.stringify(parseInt(allTypeSalaries.totalEmployeeSalary, 10)).length - JSON.stringify(parseInt(allTypeSalaries.totalInvoice, 10)).length)) },
                  { label: 'Q&A', value: questions?.length },
                ]}
                chartColors={[
                  theme.palette.primary.main,
                  theme.palette.chart?.blue[0],
                  theme.palette.chart?.violet[0],
                  theme.palette.chart?.yellow[0],
                ]}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
}
