/* eslint-disable no-nested-ternary */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { Button, TablePagination, Modal, Box, Typography, Tooltip } from '@mui/material';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import UndoIcon from '@mui/icons-material/Undo';
import { ListHead } from '../../../../sections/@dashboard/table';
import { downloadXMLFile, formatDate2, getModalBoxStyle } from '../../../../utils/commonFunc';

export default function TaxFileTable({
  setLoading,
  selectedTFNestedTabValue,
  taxFileData,
  setTaxFileData,
  getTaxFileData,
  totalCount,
}) {
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [isGenerateTaxFile, setIsGenerateTaxFile] = useState(false);
  const [isDeleteTaxFile, setIsDeleteTaxFile] = useState(false);
  const [isUndoTaxFile, setIsUndoTaxFile] = useState(false);
  const [employerId, setEmployerId] = useState(false);
  const [orderBy, setOrderBy] = useState('createdDate');
  const [order, setOrder] = useState('desc');

  const TABLE_HEAD = [
    { id: 'taxPeriod', label: 'Tax Period', alignRight: false },
    { id: 'totalBasis', label: 'Total Basis', alignRight: false },
    {
      id: 'totalEmployerCont',
      label: 'Total Employer Contribution',
      alignRight: false,
    },
    { id: 'basisForTaxDeduction', label: 'Basis for Tax Deductions', alignRight: false },
    { id: 'deductedTax', label: 'Deducted Tax', alignRight: false },
    { id: 'toPay', label: 'To Pay', alignRight: false },
    { id: 'individualData', label: 'Individual Data', alignRight: false },
    { id: 'adjustment', label: 'Adjustment SEK 1,000 Limit/Sportsperson', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
  ];

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleGenerateTaxFile = async () => {
    setLoading(true);
    setIsGenerateTaxFile(false);
    try {
      let URL;
      let result;
      if (selectedTFNestedTabValue === '1') {
        const id = [employerId];
        URL = '/admin/adminsalary/employerids-send-to-paid';
        result = await axios.post(URL, id);
      } else {
        URL = `/admin/adminsalary/generate-xml-file?employerId=${employerId}`;
        result = await axios.post(URL);
      }

      if (result?.status === 200) {
        setLoading(false);
        toast.success(
          selectedTFNestedTabValue === '1'
            ? 'Moved successfully'
            : selectedTFNestedTabValue === '2'
            ? 'Tax file generated successfully'
            : 'Download successfully'
        );
        getTaxFileData();
        downloadXMLFile(result?.data?.response);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  const handleDeleteTaxFile = async () => {
    setLoading(true);
    setIsDeleteTaxFile(false);
    try {
      const URL = `/admin/adminsalary/employerids`;
      const payload = [employerId];
      const result = await axios.delete(URL, { data: payload });
      if (result?.status === 200) {
        setLoading(false);
        toast.success('Tax file deleted successfully');
        getTaxFileData();
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  const handleUndoTaxFile = async () => {
    setLoading(true);
    setIsUndoTaxFile(false);
    try {
      const URL = `/admin/adminsalary/return-taxfile`;
      const payload = [employerId];
      const result = await axios.post(URL, payload);
      if (result?.status === 200) {
        setLoading(false);
        toast.success('Tax file moved successfully');
        getTaxFileData();
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const filteredRows = applySortFilter(
      taxFileData,
      getComparator(isAsc ? 'desc' : 'asc', orderBy),
      event.target.value,
      taxFileData
    );
    setTaxFileData(filteredRows);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <ListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={taxFileData?.length}
            onRequestSort={handleRequestSort}
            hideCheckboxColumnHeader="true"
          />
          <TableBody>
            {taxFileData?.length ? (
              taxFileData?.map((item) => {
                return (
                  <TableRow
                    hover
                    key={item?.userId}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <TableCell> {formatDate2(item?.period)}</TableCell>
                    <TableCell>{item?.totalSalaries}</TableCell>
                    <TableCell>{item?.totalEmployerContribution}</TableCell>
                    <TableCell>{item?.totalSalaries}</TableCell>
                    <TableCell>{item?.deductedTax}</TableCell>
                    <TableCell>{item?.toPayAmount}</TableCell>
                    <TableCell>{item?.individualData}</TableCell>
                    <TableCell>{item?.adjustmentSettlementLimit}</TableCell>

                    <TableCell>{item?.accountType === 1 ? 'Private Customer' : 'Business Customer'}</TableCell>

                    <TableCell
                      style={{
                        display: 'flex',
                        gap: '0',
                      }}
                    >
                      <Tooltip
                        title={
                          selectedTFNestedTabValue === '1'
                            ? 'Move to paid'
                            : selectedTFNestedTabValue === '2'
                            ? 'Generate Tax File'
                            : 'Download Tax File'
                        }
                      >
                        <Button
                          onClick={() => {
                            setEmployerId(item?.employerId);
                            setIsGenerateTaxFile(true);
                          }}
                        >
                          {selectedTFNestedTabValue === '1' ? (
                            <DriveFileMoveIcon />
                          ) : selectedTFNestedTabValue === '2' ? (
                            <SaveAsIcon />
                          ) : (
                            <FileDownloadIcon fontSize="medium" />
                          )}
                        </Button>
                      </Tooltip>
                      {selectedTFNestedTabValue !== '1' ? (
                        <Tooltip title={selectedTFNestedTabValue === '3' ? 'Move to paid' : 'Move to created'}>
                          <Button
                            onClick={() => {
                              setEmployerId(item?.employerId);
                              setIsUndoTaxFile(true);
                            }}
                          >
                            <UndoIcon />
                          </Button>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Delete Tax File">
                          <Button
                            onClick={() => {
                              setEmployerId(item?.employerId);
                              setIsDeleteTaxFile(true);
                            }}
                          >
                            <DeleteIcon />
                          </Button>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow className="text-center">
                <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                  No record found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[100, 250, 500]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Modal
        open={isGenerateTaxFile}
        onClose={() => setIsGenerateTaxFile(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <div className="container">
            <Box sx={getModalBoxStyle()}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Are you sure you want to{' '}
                {selectedTFNestedTabValue === '1'
                  ? 'move to paid'
                  : selectedTFNestedTabValue === '2'
                  ? 'generate'
                  : 'download'}{' '}
                tax file ?
              </Typography>
              <div
                style={{
                  margin: '25px 0 0',
                  float: 'right',
                  gap: '10px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Button
                  onClick={() => setIsGenerateTaxFile(false)}
                  sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                  autoFocus
                >
                  No
                </Button>
                <Button onClick={handleGenerateTaxFile} sx={{ float: 'right', backgroundColor: '#62b5e514' }} autoFocus>
                  Yes
                </Button>
              </div>
            </Box>
          </div>
        </Typography>
      </Modal>

      <Modal
        open={isDeleteTaxFile}
        onClose={() => setIsDeleteTaxFile(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <div className="container">
            <Box sx={getModalBoxStyle()}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Are you sure you want to delete tax file ?
              </Typography>
              <div
                style={{
                  margin: '25px 0 0',
                  float: 'right',
                  gap: '10px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Button
                  onClick={() => setIsDeleteTaxFile(false)}
                  sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                  autoFocus
                >
                  No
                </Button>
                <Button onClick={handleDeleteTaxFile} sx={{ float: 'right', backgroundColor: '#62b5e514' }} autoFocus>
                  Yes
                </Button>
              </div>
            </Box>
          </div>
        </Typography>
      </Modal>

      <Modal
        open={isUndoTaxFile}
        onClose={() => setIsUndoTaxFile(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <div className="container">
            <Box sx={getModalBoxStyle()}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Are you sure you want to {selectedTFNestedTabValue === '3' ? 'move to paid' : 'move to created'} ?
              </Typography>
              <div
                style={{
                  margin: '25px 0 0',
                  float: 'right',
                  gap: '10px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Button
                  onClick={() => setIsUndoTaxFile(false)}
                  sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                  autoFocus
                >
                  No
                </Button>
                <Button onClick={handleUndoTaxFile} sx={{ float: 'right', backgroundColor: '#62b5e514' }} autoFocus>
                  Yes
                </Button>
              </div>
            </Box>
          </div>
        </Typography>
      </Modal>
    </>
  );
}
