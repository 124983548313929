/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { otherArticleOptions, otherArticlesTotalAmount } from '../../../utils/commonFunc';
import axios from 'axios';

const OtherArticlesRow = ({
  otherArticleValues,
  isFixed,
  currency,
  setOtherArticleValues,
  showTooltipError,
  setShowTooltipError,
  indexValue,
  setOtherValuesChanged,
}) => {
  const { t } = useTranslation();

  const [formattedData, setFormattedData] = useState([]);
  const handleCurrencyRate = async () => {
    try {
      const resp = await axios.get('admin/invoice/fortnox-currencies');
      const data = resp?.data?.response;
      const filteredCurr = data?.filter((g) => g?.description === currency);
      if (filteredCurr?.length > 0) {
        let sellRate = filteredCurr[0].globalValue;
        if (filteredCurr[0].description === 'SEK') {
          sellRate = 1;
        }
        // const sellRate = filteredCurr?.[0]?.globalValue;
        let newUpdatedArticleValues = { ...otherArticleValues };
        Object.keys(newUpdatedArticleValues).forEach((key) => {
          newUpdatedArticleValues[key] = newUpdatedArticleValues[key]?.map((item) => ({
            ...item,
            value: (parseFloat(item?.value) * sellRate).toFixed(2),
          }));
        });
        setFormattedData(newUpdatedArticleValues);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleCurrencyRate();
  }, [otherArticleValues, currency]);

  return (
    <>
      {otherArticleValues?.[indexValue]?.map((item) => (
        <div className={`row mobile-scroll flex-row mx-0 No-root-selected`} key={item?.key}>
          <div className="desktop-row d-initial align-items-center justify-content-start desktop-1"></div>
          <div className="desktop-row d-flex justify-content-between align-items-start desktop-2">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <Select
                id="articleSelect"
                isDisabled={true}
                className="ml-0 w-50"
                labelKey="description"
                menuPortalTarget={document.querySelector('body')}
                value={item?.key === 'oth' ? otherArticleOptions[0] : otherArticleOptions[1]}
                options={otherArticleOptions?.map((item) => ({
                  ...item,
                  value: item.label,
                }))}
                getOptionLabel={(option) => t(option?.label)}
              />
            </div>
          </div>

          <div className="desktop-row row d-flex align-items-center justify-content-start desktop-3">
            <div className="col-lg-8 col-sm-12 ghd fld_responsive row mx-0">
              <div className="col pl-0 d-flex align-items-center mobile-inline">
                <OverlayTrigger
                  show={showTooltipError.negativeAmount}
                  placement="top"
                  overlay={
                    <Popover id={`popover-positioned-top`} style={{ margin: 0 }}>
                      <Popover.Header className="text-danger" as="h3">
                        {showTooltipError.negativeAmount && t("Amount value can't be Negative")}
                      </Popover.Header>
                    </Popover>
                  }
                >
                  <input
                    className="form-control w-100 aa no-arrows"
                    type="number"
                    step="any"
                    value={
                      item?.key === 'oth'
                        ? otherArticlesTotalAmount('oth', otherArticleValues[indexValue], 'self')
                        : otherArticlesTotalAmount('exp', otherArticleValues[indexValue], 'self')
                    }
                    onFocus={(e) =>
                      e.target.addEventListener(
                        'wheel',
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    placeholder={currency}
                    min="0"
                    onInput={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = '';
                        setShowTooltipError({
                          negativeAmount: true,
                        });
                        setTimeout(() => {
                          setShowTooltipError({
                            negativeAmount: false,
                          });
                        }, 3000);
                      } else if (e.target.value === 0) {
                        e.target.value = '';
                      } else {
                        const inputValue = e.target.value;
                        const maxLength = 16;

                        if (inputValue?.length > maxLength) {
                          e.target.value = inputValue.slice(0, maxLength);
                        }

                        if (parseFloat(inputValue) <= 0) {
                          e.target.value = '';
                        }

                        const key = item?.key;
                        setOtherArticleValues((prevState) => ({
                          ...prevState,
                          [indexValue]: prevState[indexValue]?.map((item) => {
                            if (item.key === key) {
                              // Update the value for the specific key
                              return { ...item, value: e.target.value };
                            }
                            return item;
                          }),
                        }));
                        setOtherValuesChanged(true);
                      }
                    }}
                  />
                </OverlayTrigger>
                <p className="m-0 ml-1">{currency}</p>
              </div>
            </div>
            <div className="col-lg-4">
              <p className="col-4 m-0 text-right curency nw_rpns mobile-inline px-0">
                {isFixed
                  ? 'Fixed Price'
                  : item?.key === 'oth'
                  ? otherArticlesTotalAmount('oth', formattedData[indexValue], 'self')
                  : item?.key === 'exp'
                  ? otherArticlesTotalAmount('exp', formattedData[indexValue], 'self')
                  : ''}
                {isFixed ? '' : <span className="ml-1">SEK</span>}
              </p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default OtherArticlesRow;