/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import countries from 'countries-list';
import { AiOutlineClose } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { vatFormats } from '../../utils/formatNumber';

const EditCustomerDialog = ({ show, onClose, onSubmit, title, customer, paymentTerms = [], globalData }) => {
  let countryCode = '';

  let updatedDefaultValue = customer;

  if (customer) {
    const characters = customer?.vatNumber?.match(/[a-zA-Z]+/g);
    const characterResult = characters ? characters.join('').slice(0, 2) : '';

    countryCode = characterResult;
    const numberResult = customer?.vatNumber?.slice(2);
    updatedDefaultValue = { ...customer, vatNumber: numberResult };
  }
  const {
    register,
    handleSubmit,
    watch,
    resetField,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: updatedDefaultValue,
  });
  const { t } = useTranslation();

  const isVATRegistred = watch('isVATRegistred');
  const [isVatRegisterSelected, setIsVatRegisterSelected] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  useEffect(() => {
    if (isInitialLoad && customer) {
      const vatStatus = customer.isVATRegistred === 'true' || customer.isVATRegistred === true;
      setIsVatRegisterSelected(vatStatus);
      setValue('isVATRegistred', vatStatus ? 'true' : 'false');
      setIsInitialLoad(false);
    }
  }, [customer, setValue, isInitialLoad]);
  useEffect(() => {
    if (!isInitialLoad) {
      setIsVatRegisterSelected(isVATRegistred === 'true');
    }
  }, [isVATRegistred, isInitialLoad]);

  const selectedPrefillCountry = watch('country');
  const accountType = watch('accountType');
  const [countryName, setCountryName] = useState('');
  const [countryVal, setCountryValue] = useState(false);
  const [VatNumberError, setVatNumberError] = useState('');
  const invoiceType = watch('invoiceType');

  const newDetails = [];

  Object.keys(countries.countries).forEach((code) => {
    newDetails.push(countries.countries[code]);
  });

  Object.keys(countries.countries).forEach((code) => {
    if (countries.countries[code].name === countryName) {
      countryCode = code;
    }
    if (countryName === 'USA') {
      countryCode = 'US';
    }
  });

  useEffect(() => {
    if (selectedPrefillCountry === 'Sweden') {
      setCountryValue(true);
    }
  }, [selectedPrefillCountry]);

  useEffect(() => {
    if (accountType === 1 || accountType === '1') {
      const getSSNVal = getValues('ssn');

      if (getSSNVal) {
        resetField('ssn', { defaultValue: getSSNVal });
      } else {
        resetField('ssn', { defaultValue: '' });
      }
    } else {
      const getORGVal = getValues('orgNo');
      if (getORGVal) {
        resetField('orgNo', { defaultValue: getORGVal });
      } else {
        resetField('orgNo', { defaultValue: '' });
      }
    }
  }, [accountType, getValues, resetField]);

  const countryOptions = [
    { value: 'Sweden', label: 'Sweden' },
    { value: 'USA', label: 'USA' },
    { value: 'Canada', label: 'Canada' },
    { value: 'Panama', label: 'Panama' },
    ...newDetails.map((country) => ({
      value: country.name,
      label: country.name,
    })),
  ];

  const customStyles = {
    control: (styles, { isFocused }) => {
      if (errors?.country) {
        styles.borderColor = '#dc3545';
      } else if (isFocused) {
        styles.borderColor = 'none';
        styles.boxShadow = '0 0 0 0 gray';
      }

      styles['&:hover'] = {
        borderColor: 'none',
      };

      styles.fontSize = '13px';

      return styles;
    },
  };

  const validateVatNumber = (countryCode, vatNumber) => {
    const format = vatFormats[countryCode];
    if (!format) {
      setVatNumberError('');
      return true;
    }
    const { regex, minLength, maxLength } = format;
    if (!regex.test(vatNumber)) {
      let errorMessage = '';
      if (minLength && maxLength) {
        errorMessage = `${t('Invalid VAT number format. It should be between')} ${minLength} ${t(
          'and'
        )} ${maxLength} ${t('digits long.')}`;
      } else {
        errorMessage = `${t('Invalid VAT number format. It should be')} ${maxLength} ${t('digits long.')}`;
      }
      setVatNumberError(errorMessage);
      return false;
    }
    setVatNumberError('');
    return true;
  };

  return (
    <Modal size="md" centered show={show} onHide={onClose} className="custom-top">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <AiOutlineClose className="ml-auto cursor-pointer" size={24} onClick={onClose} />
      </Modal.Header>
      <form
        onSubmit={handleSubmit((values) => {
          onSubmit(values);
        })}
      >
        <Modal.Body className="edit-customer">
          <div className="row flex-xs-column gap-0">
            {/* {!customer && ( */}
            <div className="col">
              <label htmlFor="account-type">{t('Business Type')}</label>
              <select
                id="account-type"
                type="text"
                className={`form-control form-control-md mb-3 ${errors.accountType ? 'is-invalid' : ''}`}
                {...register('accountType', {
                  required: true,
                })}
              >
                <option value="">{t('Select Account Type')}</option>
                {globalData &&
                  globalData.customerAccountType?.map((accountType) => (
                    <option key={accountType.key} value={accountType.value}>
                      {t(accountType.text)}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col">
              <label htmlFor="invoice-type">{t('Invoice Type')}</label>
              <select
                id="invoice-type"
                type="text"
                className={`form-control form-control-md mb-3 ${errors.invoiceType ? 'is-invalid' : ''}`}
                {...register('invoiceType', {
                  required: true,
                })}
              >
                <option value="" selected disabled>
                  {t('Select Invoice Type')}
                </option>
                <option value={1}>{t('Email (send to email address)')}</option>
                <option value={2}>{t('E-invoice (send as electronic invoice)')}</option>
                <option value={3}>{t('Post (60 SEK)')}</option>
              </select>
            </div>
          </div>
          {(invoiceType === '2' || invoiceType === 2) && (
            <>
              <div className="row flex-xs-column gap-0">
                <div className="col">
                  <label htmlFor="ourElectronicReference">{t('Our electronic reference')}</label>
                  <input
                    type="text"
                    id="ourElectronicReference"
                    className={`form-control form-control-md mb-3 ${errors.ourElectronicReference ? 'is-invalid' : ''}`}
                    placeholder={t('Our electronic reference')}
                    defaultValue="7230020"
                    readOnly
                    {...register('ourElectronicReference', {
                      required: false,
                    })}
                  />
                </div>
                <div className="col col-md-6 col-sm-12">
                  <label htmlFor="yourElectronicReference">{t('Your electronic reference')}</label>
                  <input
                    type="text"
                    id="yourElectronicReference"
                    className={`form-control form-control-md mb-3 ${errors.yourElectronicReference ? 'is-invalid' : ''
                      }`}
                    placeholder={t('Your electronic reference')}
                    {...register('yourElectronicReference', {
                      required: true,
                    })}
                  />
                </div>
              </div>
              <div className="row flex-xs-column gap-0">
                <div className="col">
                  <label htmlFor="extraField1">{t('Extra field 1 e-invoice (optional)')}</label>
                  <input
                    type="text"
                    id="extraField1"
                    className={`form-control form-control-md mb-3 ${errors.extraField1 ? 'is-invalid' : ''}`}
                    placeholder={t('Extra field 1 e-invoice')}
                    {...register('extraField1', {
                      required: false,
                    })}
                  />
                </div>
                <div className="col col-md-6 col-sm-12">
                  <label htmlFor="extraField2">{t('Extra field 2 e-invoice (optional)')}</label>
                  <input
                    type="text"
                    id="extraField2"
                    className={`form-control form-control-md mb-3 ${errors.extraField2 ? 'is-invalid' : ''}`}
                    placeholder={t('Extra field 2 e-invoice')}
                    {...register('extraField2', {
                      required: false,
                    })}
                  />
                </div>
              </div>
            </>
          )}
          <div className="row flex-xs-column gap-0">
            <div className="col">
              <label htmlFor="name">{t('Name')}</label>
              <input
                id="name"
                type="text"
                className={`form-control form-control-md mb-3 ${errors.name ? 'is-invalid' : ''}`}
                placeholder={t('Name')}
                {...register('name', {
                  required: true,
                })}
              />
            </div>
            {accountType === '1' || accountType === 1 ? (
              <div className="col">
                <label htmlFor="ssn-no">{t('SSN No.')}</label>
                <input
                  id="ssn-no"
                  type="number"
                  className={`form-control form-control-md mb-3 ${errors.ssn ? 'is-invalid' : ''}`}
                  placeholder="YYYYMMDD0000"
                  {...register('ssn', {
                    required: true,
                    minLength: 12,
                  })}
                />
                <span>
                  {errors.ssn && <p className="text-danger">{t('SSN number should be greater then 11 digits')}</p>}
                </span>
              </div>
            ) : (
              <div className="col">
                <label htmlFor="orgNo">{t('Org No')}</label>
                <input
                  id="orgNo"
                  type="text"
                  className={`form-control form-control-md mb-3 ${errors.orgNo ? 'is-invalid' : ''}`}
                  placeholder={t('Org No')}
                  {...register('orgNo', {
                    required: true,
                  })}
                />
              </div>
            )}
          </div>
          <div className="row flex-xs-column gap-0">
            <div className="col">
              <label htmlFor="phoneNumber">{t('Phone Number')}</label>
              <input
                id="phoneNumber"
                type="number"
                className={`form-control form-control-md mb-3 ${errors.phoneNumber ? 'is-invalid' : ''}`}
                placeholder={t('Phone Number')}
                {...register('phoneNumber', {
                  required: true,
                })}
              />
            </div>
            <div className="col">
              <label htmlFor="reference">{t('Reference')}</label>
              <input
                id="reference"
                type="text"
                className={`form-control form-control-md mb-3 ${errors.reference ? 'is-invalid' : ''}`}
                placeholder={t('Reference')}
                {...register('reference')}
              />
            </div>
          </div>
          <div className="row flex-xs-column gap-0">
            <div className="col">
              <label htmlFor="email">{t('Email')}</label>
              <input
                id="email"
                type="text"
                className={`form-control form-control-md mb-3 ${errors.email ? 'is-invalid' : ''}`}
                placeholder={t('Email')}
                {...register('email', {
                  required: invoiceType !== '3' && true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
              />
            </div>
            <div className="col">
              <label htmlFor="address">{t('Address')}</label>
              <input
                id="address"
                type="text"
                className={`form-control form-control-md mb-3 ${errors.address ? 'is-invalid' : ''}`}
                placeholder={t('Address')}
                {...register('address', {
                  required: true,
                })}
              />
            </div>
          </div>

          <div className="row flex-xs-column gap-0">
            {accountType !== '1' && accountType !== 1 && (
              <div className="col">
                <label htmlFor="address">{t('Address 2 (optional)')}</label>
                <input
                  id="address"
                  type="text"
                  className={`form-control form-control-md mb-3`}
                  placeholder={t('Address 2')}
                  {...register('address2')}
                />
              </div>
            )}
          </div>

          <div className="row flex-xs-column gap-0">
            <div className="col">
              <label htmlFor="city">{t('City')}</label>
              <input
                id="city"
                type="text"
                className={`form-control form-control-md mb-3 ${errors?.city ? 'is-invalid' : ''}`}
                placeholder={t('City')}
                {...register('city', {
                  required: true,
                })}
              />
            </div>

            <div className="col">
              <label htmlFor="zipCode">{t('Zip Code')}</label>
              <input
                id="zipCode"
                type="text"
                className={`form-control form-control-md mb-3 ${errors.zipCode ? 'is-invalid' : ''}`}
                placeholder={t('Zip Code')}
                {...register('zipCode', {
                  required: true,
                })}
                onInput={(e) => {
                  const maxLength = 9;
                  e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
                  if (e.target.value.length > maxLength) {
                    e.target.value = e.target.value.slice(0, maxLength);
                  }
                }}
              />
            </div>
            {invoiceType === '3' && (
              <div className="col col-md-6 col-sm-12">
                <label htmlFor="careOf">{t('CareOf (C/O)')}</label>
                <input
                  type="text"
                  className={`form-control form-control-md mb-3 ${errors.careOf ? 'is-invalid' : ''}`}
                  placeholder={t('C/O')}
                  {...register('co')}
                />
              </div>
            )}
          </div>

          {accountType !== '1' && accountType !== 1 && (
            <div className="row flex-xs-column mt-1 gap-0">
              <div className="col">
                <label htmlFor="have_vatnumber">{t('Do you have VAT number?')}</label>
                <select
                  id="have_vatnumber"
                  className="form-control form-control-md mb-3"
                  {...register('isVATRegistred', { required: true })}
                  defaultValue={isVatRegisterSelected ? 'true' : 'false'}
                  onChange={(e) => {
                    const value = e.target.value === 'true';
                    setIsVatRegisterSelected(value); // Update the state based on user selection
                    setValue('isVATRegistred', e.target.value); // Update the form value for isVATRegistred
                  }}
                >
                  <option value="true">{t('Yes')}</option>
                  <option value="false">{t('No')}</option>
                </select>
              </div>
            </div>
          )}

          <div className="row flex-xs-column gap-0">
            <div className="col">
              <label htmlFor="country">{t('Country')}</label>
              <Select
                placeholder={t('Select Country')}
                styles={customStyles}
                defaultValue={countryOptions?.find((item) => item.label === customer?.country)}
                options={countryOptions}
                {...register('country', {
                  required: true,
                })}
                onChange={(selectedOption) => {
                  const selectedCountry = selectedOption.value;
                  if (selectedCountry === 'Sweden') {
                    setCountryValue(true);
                  } else {
                    setCountryValue(false);
                  }
                  setCountryName(selectedOption.value);
                  resetField('vatNumber', { defaultValue: '' });
                  if (!selectedCountry) {
                    setValue('country', '');
                  } else {
                    setValue('country', selectedOption.value);
                  }
                  setVatNumberError('');
                }}
              />
            </div>

            {accountType !== '1' && accountType !== 1 && isVatRegisterSelected && (
              <div className="col">
                <label htmlFor="vatNumber">{t('Vat. Number')}</label>
                <div className="select-box">
                  <div className="arrow-downcheck">
                    <p> {countryCode}</p>
                  </div>
                  <div className="input-box">
                    <input
                      min={4}
                      type="text"
                      className={`form-control ${errors.vatNumber ? 'is-invalid' : ''}`}
                      onInput={(e) => {
                        const allowedCharacters = vatFormats[countryCode]?.allowedCharacters || /^[A-Za-z0-9]*$/;
                        e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, '');
                        const maxLength = vatFormats[countryCode]?.maxLength || 18;
                        if (e.target.value.length > maxLength) {
                          e.target.value = e.target.value.slice(0, maxLength);
                        }
                        Object.keys(countries.countries).forEach((code) => {
                          if (countries.countries[code].name === selectedPrefillCountry) {
                            countryCode = code;
                          }
                        });
                      }}
                      onKeyDown={(e) => {
                        const allowedCharacters = vatFormats[countryCode]?.allowedCharacters || /^[A-Za-z0-9]*$/;
                        if (
                          allowedCharacters.test(e.key) ||
                          ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)
                        ) {
                          return true;
                           // eslint-disable-next-line no-else-return
                        } else {
                          e.preventDefault();
                          return false;
                        }
                      }}
                      {...register('vatNumber', {
                        required: countryVal === false && accountType !== '1' && accountType !== 1,
                        validate: (value) => validateVatNumber(countryCode, value),
                      })}
                    />
                  </div>
                </div>
                {VatNumberError !== '' && <span className="vatNumberError">{VatNumberError}</span>}
              </div>
            )}
          </div>
          <div className="row flex-xs-column mt-2 gap-0">
            <div className="col">
              <label htmlFor="payment">{t('Payment Term')}</label>
              <select
                id="payment"
                type="text"
                className={`form-control form-control-md mb-3 ${errors.paymentTerm ? 'is-invalid' : ''}`}
                {...register('paymentTerm', {
                  required: true,
                })}
              >
                <option value="">{t('Payment Term')}</option>
                {paymentTerms?.map((paymentTerm) => (
                  <option key={paymentTerm.id} value={paymentTerm.globalValue}>
                    {paymentTerm.globalValue} {t('days')}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            {t('Close')}
          </Button>
          <Button className="text-light" variant="primary" type="subnit">
            {t('Save')}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditCustomerDialog;
