/* eslint-disable no-plusplus */

const PdfTable = ({ doc, dataArray, pageHeight }) => {
  const startX = 10;
  const startY = 10;
  const rowHeight = 10;
  let y = startY;
  const columns = ['S.No', 'User ID', 'Date', 'Description', 'Hours', 'Invoice ID'];
  const columnWidths = [20, 20, 40, 50, 30, 30];

  const setHeaderStyles = () => {
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(12);
    doc.setTextColor(255, 255, 255);
    doc.setFillColor(98, 181, 229);
  };

  const setRowStyles = () => {
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
  };

  const setAlternateRowStyles = () => {
    doc.setFillColor(240, 240, 240);
  };

  if (dataArray.length > 0) {
    setHeaderStyles();
    columns.forEach((column, index) => {
      const x = startX + columnWidths.slice(0, index).reduce((a, b) => a + b, 0);
      doc.setFillColor(98, 181, 229);
      doc.rect(x, y - rowHeight + 2, columnWidths[index], rowHeight, 'F');
      doc.setTextColor(255, 255, 255);
      doc.text(column, x + 1, y);
    });

    y += rowHeight;

    dataArray.forEach((row, rowIndex) => {
      if (rowIndex % 2 === 0) {
        setAlternateRowStyles();
        doc.rect(
          startX,
          y - rowHeight + 2,
          columnWidths.reduce((a, b) => a + b, 0),
          rowHeight,
          'F'
        );
      }
      setRowStyles();
      const rowData = [
        rowIndex + 1,
        row.userId,
        new Date(row.workingDate).toLocaleDateString(),
        'Payment Per Hour',
        row.workingHour,
        row.invoiceId,
      ];
      rowData.forEach((data, index) => {
        const x = startX + columnWidths.slice(0, index).reduce((a, b) => a + b, 0);
        doc.text(String(data), x + 1, y);
      });
      y += rowHeight;
      if (y > pageHeight - 10 && rowIndex !== dataArray.length - 1) {
        doc.addPage();
        y = startY + rowHeight;
      }
    });
  }
};

export default PdfTable;
