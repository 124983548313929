/* eslint-disable */
import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import {
  HourlyWages,
  InvoiveAsDescribedBellow,
  costAgreement,
  costPerDay,
  costPerMonth,
  costPerWeek,
  otherCost,
} from '../../../utils/stringUtils';

const WorkingHoursRow = ({
  index,
  rootDeduction,
  rutDeduction,
  dayArticle,
  dates,
  indexValue,
  setDates,
  setRemoveClickedDate,
  dateStr,
  handleChangeInvoiceArticle,
  showTooltipError,
  selectedIndex,
  articles,
  control,
  isFixed,
  setShowTooltipError,
  register,
  currency,
  total,
  handleChangeAmount,
  watch,
  setSelectedIndex,
  setUnitDateStr,
  handleChangeUnit,
  setOtherArticlesData,
  showDates,
  setValue
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <div
        key={index}
        className={`row mobile-scroll ${
          !(rootDeduction || rutDeduction) || dayArticle?.articleNumber !== otherCost
            ? 'No-root-selected'
            : 'Root-selected'
        } flex-row mx-0 ${index === dates[indexValue]?.length - 1 ? 'mb-3 mx-0' : ''}`}
      >
        <div className="desktop-row d-initial align-items-center justify-content-start desktop-1">
          <div className="d-flex justify-content-start align-items-center">
            <AiOutlineClose
              size={24}
              className="text-primary-custom cursor-pointer close-invoice"
              onClick={() => {
                Object.keys(dates).forEach((item, dateIndex) => {
                  if (dateIndex === indexValue) {
                    setDates((prevState) => {
                      return {
                        ...prevState,
                        [item]: dates[item]?.filter((_, idx) => idx !== index),
                      };
                    });
                  }
                });
                if (index === 0) {
                  setOtherArticlesData((prevData) => {
                    return {
                      ...prevData,
                      [indexValue]: [],
                    };
                  });
                }
                setRemoveClickedDate({ indexValue, dateStr });
                setValue(`defaultHours[${indexValue}.hoursPerDay]`);
                setValue(`defaultAmount[${indexValue}.amountValue]`);
              }}
            />
            <h6 className="m-0 ml-2" style={{ color: showDates !== 'true' && '#b0b0b0' }}>
              {(() => {
                const [year, month, day] = dateStr?.split('-');
                return `${year}-${month}-${day} `;
              })()}
            </h6>
          </div>
        </div>
        <div className="desktop-row d-flex justify-content-between align-items-start desktop-2">
          <div className="col-12 d-flex justify-content-between align-items-center">
            <Controller
              className="w-100"
              control={control}
              defaultValue={articles[0]}
              name={`invoiceHours[${indexValue}.[${dateStr}].article]`}
              render={({ field, fieldState }) => (
                <Select
                  {...field}
                  id="articleSelect"
                  isDisabled={isFixed}
                  className="ml-0 w-100"
                  labelKey="description"
                  selected={field.value}
                  defaultValue={articles[0]}
                  placeholder={t('Select unit')}
                  menuPortalTarget={document.querySelector('body')}
                  getOptionLabel={(option) => t(option?.description)}
                  onChange={(e) => {
                    handleChangeInvoiceArticle(e, dateStr, indexValue);
                  }}
                  noOptionsMessage={({ inputValue }) => (!inputValue ? t('No options') : t('No results found'))}
                  options={articles?.map((description) => ({
                    ...description,
                    value: description.description,
                  }))}
                  isOptionSelected={(option, selectValue) =>
                    selectValue.some((val) => val.articleNumber === option.articleNumber)
                  }
                />
              )}
              rules={{ required: t('Select an article') }}
            />
            {dayArticle?.articleNumber === HourlyWages && (
              <>
                {rootDeduction && JSON.parse(rootDeduction) && (
                  <Controller
                    name={`invoiceHours[${indexValue}.[${dateStr}].houseworkType]`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <Select
                        {...field}
                        className="ml-2"
                        selected={field.value}
                        isDisabled={isFixed}
                        getOptionLabel={(option) => option?.name}
                        menuPortalTarget={document.querySelector('body')}
                        id="houseworkTypeSelect"
                        placeholder="Select work type"
                        labelKey="name"
                        style={{ width: '100%' }}
                        positionFixed
                        options={[
                          {
                            value: 'CONSTRUCTION',
                            name: 'Build',
                          },
                          {
                            value: 'ELECTRICITY',
                            name: 'Electricity',
                          },
                          {
                            value: 'GLASSMETALWORK',
                            name: 'Glass/sheet metal work',
                          },
                          {
                            value: 'GROUNDDRAINAGEWORK',
                            name: 'Soil/drainage work',
                          },
                          {
                            value: 'MASONRY',
                            name: 'Masonry',
                          },
                          {
                            value: 'PAINTINGWALLPAPERING',
                            name: 'Painting/wallpapering',
                          },
                          {
                            value: 'HVAC',
                            name: 'Plumbing',
                          },
                          {
                            value: 'OTHERCOSTS',
                            name: 'Other costs',
                          },
                        ]}
                      />
                    )}
                    rules={{ required: !isFixed }}
                  />
                )}
                {rutDeduction && JSON.parse(rutDeduction) && (
                  <Controller
                    name={`invoiceHours[${indexValue}.[${dateStr}].houseworkType]`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <Select
                        {...field}
                        className="ml-2"
                        selected={field.value}
                        isDisabled={isFixed}
                        getOptionLabel={(option) => option?.name}
                        menuPortalTarget={document.querySelector('body')}
                        id="houseworkTypeSelect"
                        placeholder="Select work type"
                        labelKey="name"
                        style={{ width: '50%' }}
                        positionFixed
                        options={[
                          {
                            value: 'CLEANING',
                            name: 'Cleaning',
                          },
                          {
                            value: 'TEXTILECLOTHING',
                            name: 'Clothing and textile care',
                          },
                          {
                            value: 'SNOWPLOWING',
                            name: 'Snow shoveling',
                          },
                          {
                            value: 'GARDENING',
                            name: 'Gardening',
                          },
                          {
                            value: 'BABYSITTING',
                            name: 'Child care',
                          },
                          {
                            value: 'OTHERCARE',
                            name: 'Personal care',
                          },
                          {
                            value: 'MOVINGSERVICES',
                            name: 'Removal services',
                          },
                          {
                            value: 'ITSERVICES',
                            name: 'IT services',
                          },
                          {
                            value: 'MAJORAPPLIANCEREPAIR',
                            name: 'Repair of white goods',
                          },
                          {
                            value: 'FURNISHING',
                            name: 'Furnishings',
                          },
                          {
                            value: 'HOMEMAINTENANCE',
                            name: 'Supervision of housing',
                          },
                          {
                            value: 'TRANSPORTATIONSERVICES',
                            name: 'Transport services',
                          },
                          {
                            value: 'WASHINGANDCAREOFCLOTHING',
                            name: 'Washing and care of clothes',
                          },
                          {
                            value: 'OTHERCOSTS',
                            name: 'Other costs',
                          },
                        ]}
                      />
                    )}
                    rules={{ required: !isFixed }}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className="desktop-row d-flex align-items-center justify-content-start desktop-3">
          <div className="col-md-9 col-sm-12 ghd row mx-0 p-0">
            {!(
              dayArticle?.articleNumber === otherCost ||
              dayArticle?.articleNumber === InvoiveAsDescribedBellow ||
              dayArticle?.articleNumber === costPerDay ||
              dayArticle?.articleNumber === costPerMonth ||
              dayArticle?.articleNumber === costPerWeek ||
              dayArticle?.articleNumber === costAgreement
            ) && (
              <div className="col-6  pl-0 pr-1 pr-1 d-flex align-items-center mobile-inline">
                <OverlayTrigger
                  show={
                    showTooltipError.inputZeroValue ||
                    showTooltipError.inputGreater24Value ||
                    showTooltipError.nullUnit ||
                    showTooltipError.invalidUnit ||
                    showTooltipError.negativeHours ||
                    showTooltipError.inputNegatieveValue ||
                    showTooltipError.travelAllowanceGraterValue ||
                    showTooltipError.travelAllowanceLessValue
                  }
                  placement="top"
                  overlay={
                    <Popover id={`popover-positioned-top`} style={{ margin: 0 }}>
                      <Popover.Header className="text-danger" as="h3">
                        {selectedIndex === index && showTooltipError.nullUnit && t('Please enter a unit value')}
                        {selectedIndex === index &&
                          showTooltipError.invalidUnit &&
                          t("Hours Wages value can't be grater then 13")}
                        {selectedIndex === index &&
                          showTooltipError.negativeHours &&
                          t("Hours Value Can't Be Negative")}
                        {selectedIndex === index &&
                          showTooltipError.inputNegatieveValue &&
                          t('Value cannot be Negative')}
                        {selectedIndex === index && showTooltipError.inputZeroValue && t('Value cannot be Zero')}
                        {selectedIndex === index &&
                          showTooltipError.inputGreater24Value &&
                          t('Value cannot be Greater than 24')}
                        {selectedIndex === index &&
                          showTooltipError.travelAllowanceGraterValue &&
                          t('Travel Allowance value can not be grater then 220')}
                        {selectedIndex === index &&
                          showTooltipError.travelAllowanceLessValue &&
                          t('Travel Allowance value can not be less then 120')}
                      </Popover.Header>
                    </Popover>
                  }
                >
                  <input
                    className="form-control w-100 no-arrows"
                    type="number"
                    step="0.01"
                    min={0}
                    max={dayArticle?.articleNumber === otherCost ? 13 : 24}
                    placeholder="Hr"
                    readOnly={isFixed}
                    onClick={() => {
                      setSelectedIndex(index);
                    }}
                    onFocus={(e) =>
                      e.target.addEventListener(
                        'wheel',
                        (e) => {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    onInput={(object) => {
                      setSelectedIndex(index);
                      if (dayArticle?.articleNumber === otherCost && object.target.value < 0) {
                        object.target.value = '';
                        setShowTooltipError({
                          negativeHours: true,
                        });
                        setTimeout(() => {
                          setShowTooltipError({
                            negativeHours: false,
                          });
                        }, 3000);
                      }
                      //  else if (object.target.value > 13 && dayArticle?.articleNumber === HourlyWages) {
                      //   object.target.value = 13;
                      //   setShowTooltipError({ invalidUnit: true })
                      //   setTimeout(() => {
                      //     setShowTooltipError({
                      //       invalidUnit: false
                      //     })
                      //   }, 3000);
                      // }
                      else if (object.target.value < 0) {
                        object.target.value = '';
                        setShowTooltipError({ inputNegatieveValue: true });
                        setTimeout(() => {
                          setShowTooltipError({
                            inputNegatieveValue: false,
                          });
                        }, 3000);
                      } else if (object.target.value === '0') {
                        object.target.value = '';
                        setShowTooltipError({ inputZeroValue: true });
                        setTimeout(() => {
                          setShowTooltipError({
                            inputZeroValue: false,
                          });
                        }, 3000);
                      } else if (parseFloat(object.target.value) > 24) {
                        object.target.value = '';
                        setShowTooltipError({
                          inputGreater24Value: true,
                        });
                        setTimeout(() => {
                          setShowTooltipError({
                            inputGreater24Value: false,
                          });
                        }, 3000);
                      } else {
                        const value = parseFloat(object.target.value);
                        if (value < 1 || value > 24) {
                          object.target.value = '';
                        } else {
                          const decimalPlaces = (value.toString().split('.')[1] || '').length;
                          if (decimalPlaces > 2) {
                            object.target.value = value.toFixed(2);
                          }
                        }
                      }
                    }}
                    {...register(`invoiceHours[${indexValue}.[${dateStr}].units]`, {
                      valueAsNumber: true,
                      required: !isFixed,
                      value: watch(`defaultHours[${indexValue}.hoursPerDay]`) || 8,
                      onChange: (e) => {
                        handleChangeUnit();
                        setUnitDateStr({
                          value: e.target.value,
                          dateStr,
                          indexValue,
                        });
                      },
                    })}
                  />
                </OverlayTrigger>
                {dayArticle && (
                  <p className="m-0 ml-1">
                    {dayArticle?.articleNumber === 94
                      ? 'day'
                      : dayArticle?.articleNumber === otherCost
                      ? t('q')
                      : t(dayArticle?.unit)}
                  </p>
                )}
              </div>
            )}
            <div className="col-6 pl-0 pr-1 d-flex align-items-center mobile-inline">
              <OverlayTrigger
                show={showTooltipError.LessAmount || showTooltipError.NullAmount || showTooltipError.negativeAmount}
                placement="top"
                overlay={
                  <Popover id={`popover-positioned-top`} style={{ margin: 0 }}>
                    <Popover.Header className="text-danger" as="h3">
                      {selectedIndex === index &&
                        showTooltipError.negativeAmount &&
                        t("Amount value can't be Negative")}
                    </Popover.Header>
                  </Popover>
                }
              >
                <input
                  className="form-control w-100 aa no-arrows"
                  type="text"
                  step="0.1"
                  onFocus={(e) =>
                    e.target.addEventListener(
                      'wheel',
                      (e) => {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  placeholder={currency}
                  onClick={() => {
                    setSelectedIndex(index);
                    isFixed &&
                      setShowTooltipError({
                        fixedPriceUnselect: true,
                      });
                    setTimeout(() => {
                      setShowTooltipError({
                        fixedPriceUnselect: false,
                      });
                    }, 3000);
                  }}
                  readOnly={isFixed}
                  min="0"
                  onInput={(object) => {
                    const regex = /^\d*\.?\d*$/;
                    let inputValue = object.target.value;

                    if (!regex.test(inputValue)) {
                      // If not, remove invalid characters
                      inputValue = inputValue.replace(/[^\d.]/g, '');
                      object.target.value = inputValue;
                      const maxLength = 16;

                      if (inputValue.length > maxLength) {
                        object.target.value = inputValue.slice(0, maxLength);
                      }
                    }

                    setSelectedIndex(index);
                    if (object.target.value < 0) {
                      object.target.value = '';
                      setSelectedIndex(index);
                      setShowTooltipError({
                        negativeAmount: true,
                      });
                      setTimeout(() => {
                        setShowTooltipError({
                          negativeAmount: false,
                        });
                      }, 3000);
                    } else if (object.target.value === 0) {
                      object.target.value = '';
                    } else {
                      const inputValue = object.target.value;
                      const maxLength = 16;

                      if (inputValue.length > maxLength) {
                        object.target.value = inputValue.slice(0, maxLength);
                      }

                      if (parseFloat(inputValue) <= 0) {
                        object.target.value = '';
                      }
                    }
                  }}
                  {...register(`invoiceHours[${indexValue}.[${dateStr}].amount]`, {
                    // valueAsNumber: true,
                    // required: !isFixed,
                    value: watch(`defaultAmount[${indexValue}.amountValue]`) || 500,
                    onChange: (e) => {
                      handleChangeAmount();
                    },
                  })}
                />
              </OverlayTrigger>
              <p className="m-0 ml-1">
                {currency}
                {dayArticle?.articleNumber === otherCost ? '' : '/'}
                {dayArticle && dayArticle?.articleNumber === 94
                  ? t('day')
                  : dayArticle?.articleNumber === otherCost
                  ? t('')
                  : t(dayArticle?.unit)}
              </p>
            </div>
          </div>
          <p className="col-3 mt-2 m-0 text-right curency mobile-inline px-0">
            {isFixed ? 'Fixed Price' : total.toFixed(2)} {isFixed ? '' : currency}
          </p>
        </div>
      </div>
    </div>
  );
};

export default WorkingHoursRow;