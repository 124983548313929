import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

function TooltipWithCopy({ text }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 4500); // Reset copied state after 1.5 seconds
  };

  return (
    <div className="tooltip-container">
      <span className="tooltip-text">{text}</span>
      <CopyToClipboard text={text} onCopy={handleCopy}>
        <button type='submit' className="copy-button">
          <span style={{ color: '#343a40' }}> {text?.slice(0, 30).concat('...')}</span> {copied ? 'Copied!' : 'Copy'}
        </button>
      </CopyToClipboard>
    </div>
  );
}

export default TooltipWithCopy;
