import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { TableCell, TableRow } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { formatDate2, getArticleName } from '../../../../utils/commonFunc';

export const FilterTable = ({ filterData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentItems = filterData?.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filterData?.length / itemsPerPage);

  useEffect(() => {
    setCurrentPage(1);
  }, [filterData]);

  return (
    <div className="mt-4 p-3">
      <h6>
        <span className="font-weight-bold"> Selected month calendar summary </span>
      </h6>

      <Table responsive="sm" size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>User Id</th>
            <th>Date</th>
            <th>Description</th>
            <th>Hours</th>
            <th>Invoice Id</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.length ? (
            currentItems.map((item, index) => (
              <tr key={item.id}>
                <td>{startIndex + index + 1}</td>
                <td>{item.userId}</td>
                <td>{formatDate2(item.workingDate)}</td>
                <td>{getArticleName(item?.articleNumber)}</td>
                <td>{item.number}</td>
                <td>{item.invoiceId}</td>
              </tr>
            ))
          ) : (
            <TableRow className="text-center">
              <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                <p className="text-dark p-3">No record found</p>
              </TableCell>
            </TableRow>
          )}
        </tbody>
      </Table>

      {filterData?.length > 16 && (
        <Pagination
          className="justify-content-center"
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          variant="outlined"
          shape="rounded"
        />
      )}
    </div>
  );
};
